import { columnType, actionArrType } from "DataTypes/Table";
import { ControlledTable } from "Components";
import { BreadArrType } from "DataTypes/Common";
import { FormDataType } from "DataTypes/Form";
import { label } from "Constant/En";

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.FullLengthCol("30%", label.catName),
  ControlledTable.ColumnData.orderByCol("25%", true),
  ControlledTable.ColumnData.quesCountCol("25%", true),
  ControlledTable.ColumnData.ActionCol("20%"),
];

export const actionArr: (
  handleEdit: () => void,
  handleDeleteIcon: () => void
) => actionArrType[] = (handleEdit, handleDeleteIcon) => {
  const Arr: actionArrType[] = [
    ControlledTable.ActionData.editAction({ handleClick: handleEdit }),
    ControlledTable.ActionData.deleteAction(handleDeleteIcon),
  ];
  return Arr;
};

export const DataArr1: FormDataType[] = [
  {
    id: "category",
    label: "Category Name",
    placeholder: "Enter Category Name",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "category",
    required: true,
  },
  {
    id: "orderBy",
    label: "Display Sequence Number",
    placeholder: "Select Display Sequence Number",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "number",
    type: "number",
    maxLength: 3,
    required: true,
  },
  {
    id: "CategoryType",
    label: "CarePlan Summary Section",
    placeholder: "Select CarePlan Summary Section",
    boxType: "single-select-drop",
    dropArr: [
      {
        id: 1,
        label: "Add to Goal Section of CarePlan Summary",
        value: "Goals",
      },
      {
        id: 2,
        label: "Add to Intervention Section of CarePlan Summary",
        value: "Interventions",
      },
    ],
    boxSize: 3,
    validatetype: "dropdown",
  },
];

export const CategoryMasterBreadArr = () => {
  const Arr: BreadArrType[] = [
    {
      id: 1,
      label: "Question Category Master",
      path: "",
    },
  ];
  return Arr;
};
