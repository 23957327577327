import { RedoxCategoryType } from "DataTypes/PatientManagement.type";
import { CategorySearchType } from "DataTypes/Services/Redox.type";

const FormatCatSearch: (
  res: CategorySearchType,
  arr: RedoxCategoryType[]
) => RedoxCategoryType[] = (res, arr) => {
  return arr.map((el) => {
    const filter = res.response.filter((el1) => el1.documentType == el.docKey);
    return {
      ...el,
      docList: filter,
      count: res.facet_counts.facet_fields.documentType[el.countKey],
    };
  });
};

export default { FormatCatSearch };
