import { addProviderArrayType } from "DataTypes/CarePlanProblems";
import { FormDataType } from "DataTypes/Form";
import { MyInfoType } from "DataTypes/Redux";
import { CheckNumber, CheckStringEmpty } from "Utils/common";

const saveTeamPayload: (
  careplanId: number | null,
  practiceLocationId: number | null,
  programId: number | null,
  practice_id: number | null,
  patientId: string | null,
  primaryInfoArr: any,
  AdditionalProvidarInfoArr: addProviderArrayType[],
  prgm: string,
  teamInitiatorRoleId: number | null
) => any = (
  careplanId,
  practiceLocationId,
  programId,
  practice_id,
  patientId,
  primaryInfoArr,
  AdditionalProvidarInfoArr,
  prgm,
  teamInitiatorRoleId
) => {
  const additionalProvidarArr = AdditionalProvidarInfoArr.map((el) => {
    return {
      ...(el.additionalProvidarArrId && el.additionalProvidarArrId > 0
        ? { id: el.additionalProvidarArrId }
        : {}),
      careplanId: careplanId,
      programId: programId,
      patientId,
      practiceId: practice_id,
      practiceLocationId: practiceLocationId,
      additionalProviderName: CheckStringEmpty(
        el?.additionalProvidarData[0].value
      ),
      additionalProviderSpeciality: CheckStringEmpty(
        el?.additionalProvidarData[1].value
      ),
      addProviderOrganization: CheckStringEmpty(
        el?.additionalProvidarData[2].value
      ),
      phoneNumber: CheckStringEmpty(el?.additionalProvidarData[3].value),
      faxNumber: CheckStringEmpty(el?.additionalProvidarData[4].value),
    };
  });
  if (prgm == "TCM") {
    if (teamInitiatorRoleId == 5) {
      //Provider Login
      return {
        data: {
          careplanId: careplanId,
          programId: programId,
          patientId: patientId,
          practiceId: practice_id,
          practiceLocationId: practiceLocationId,
          caremanagerId: CheckNumber(primaryInfoArr[0].dropValue),
          billableProviderId: CheckNumber(primaryInfoArr[1].dropValue),
          teamProviderId: CheckNumber(primaryInfoArr[2].dropValue),
          authorizedRepresentative1: CheckStringEmpty(primaryInfoArr[4].value),
          authorizedRepresentative2: CheckStringEmpty(primaryInfoArr[5].value),
          authorizedRepresentative3: CheckStringEmpty(primaryInfoArr[6].value),
          careplanAdditionalProvider: additionalProvidarArr,
        },
      };
    } else {
      //careManager Login
      return {
        data: {
          careplanId: careplanId,
          programId: programId,
          patientId: patientId,
          practiceId: practice_id,
          practiceLocationId: practiceLocationId,
          caremanagerId: CheckNumber(primaryInfoArr[0].dropValue),
          teamProviderId: CheckNumber(primaryInfoArr[1].dropValue),
          billableProviderId: "",
          authorizedRepresentative1: CheckStringEmpty(primaryInfoArr[3].value),
          authorizedRepresentative2: CheckStringEmpty(primaryInfoArr[4].value),
          authorizedRepresentative3: CheckStringEmpty(primaryInfoArr[5].value),
          careplanAdditionalProvider: additionalProvidarArr,
        },
      };
    }
  } else {
    if (teamInitiatorRoleId == 5) {
      //Provider Login
      return {
        data: {
          careplanId: careplanId,
          programId: programId,
          patientId: patientId,
          practiceId: practice_id,
          practiceLocationId: practiceLocationId,
          caremanagerId: CheckNumber(primaryInfoArr[0].dropValue),
          billableProviderId: CheckNumber(primaryInfoArr[1].dropValue),
          teamProviderId: CheckNumber(primaryInfoArr[2].dropValue),
          primaryCareCoordinatorId: CheckNumber(primaryInfoArr[4].dropValue),
          secondaryCareCoordinatorId: CheckNumber(primaryInfoArr[5].dropValue),
          authorizedRepresentative1: CheckStringEmpty(primaryInfoArr[6].value),
          authorizedRepresentative2: CheckStringEmpty(primaryInfoArr[7].value),
          authorizedRepresentative3: CheckStringEmpty(primaryInfoArr[8].value),
          careplanAdditionalProvider: additionalProvidarArr,
        },
      };
    } else {
      //careManager Login
      return {
        data: {
          careplanId: careplanId,
          programId: programId,
          patientId: patientId,
          practiceId: practice_id,
          practiceLocationId: practiceLocationId,
          caremanagerId: CheckNumber(primaryInfoArr[0].dropValue),
          billableProviderId: "",
          teamProviderId: CheckNumber(primaryInfoArr[1].dropValue),
          primaryCareCoordinatorId: CheckNumber(primaryInfoArr[3].dropValue),
          secondaryCareCoordinatorId: CheckNumber(primaryInfoArr[4].dropValue),
          authorizedRepresentative1: CheckStringEmpty(primaryInfoArr[5].value),
          authorizedRepresentative2: CheckStringEmpty(primaryInfoArr[6].value),
          authorizedRepresentative3: CheckStringEmpty(primaryInfoArr[7].value),
          careplanAdditionalProvider: additionalProvidarArr,
        },
      };
    }
  }
};

const formatCareplanSearchRes = (
  data: any,
  arr1: FormDataType[],
  arr2: addProviderArrayType[],
  providerRes: any,
  prgm: string,
  teamInitiatorRoleId: number | null
) => {
  const foundVal = providerRes.find((el: any) => el.id == data?.providerId);
  const newArr1 = [...arr1];
  if (prgm == "TCM") {
    if (teamInitiatorRoleId == 5) {
      //providerLogin
      newArr1[0].dropValue = newArr1[0].value = data?.caremanagerId;
      newArr1[1].dropValue = newArr1[1].value = data?.billableProviderId;
      newArr1[2].dropValue = newArr1[2].value = data?.providerId;
      newArr1[3].value = CheckStringEmpty(foundVal?.speciality);
      newArr1[4].value = data?.authorizedRepresentative1;
      newArr1[5].value = data?.authorizedRepresentative2;
      newArr1[6].value = data?.authorizedRepresentative3;
    } else {
      //caremanagerLogin
      newArr1[0].dropValue = newArr1[0].value = data?.caremanagerId;
      newArr1[1].dropValue = newArr1[1].value = data?.providerId;
      newArr1[2].value = CheckStringEmpty(foundVal?.speciality);
      newArr1[3].value = data?.authorizedRepresentative1;
      newArr1[4].value = data?.authorizedRepresentative2;
      newArr1[5].value = data?.authorizedRepresentative3;
    }
  } else {
    if (teamInitiatorRoleId == 5) {
      //providerLogin
      newArr1[0].dropValue = newArr1[0].value = data?.caremanagerId;
      newArr1[1].dropValue = newArr1[1].value = data?.billableProviderId;
      newArr1[2].dropValue = newArr1[2].value = data?.providerId;
      newArr1[3].value = CheckStringEmpty(foundVal?.speciality);
      newArr1[4].dropValue = newArr1[4].value = data?.primaryCareCoordinatorId;
      newArr1[5].dropValue = newArr1[5].value =
        data?.secondaryCareCoordinatorId;
      newArr1[6].value = data?.authorizedRepresentative1;
      newArr1[7].value = data?.authorizedRepresentative2;
      newArr1[8].value = data?.authorizedRepresentative3;
    } else {
      //caremanagerLogin
      newArr1[0].dropValue = newArr1[0].value = data?.caremanagerId;
      newArr1[1].dropValue = newArr1[1].value = data?.providerId;
      newArr1[2].value = CheckStringEmpty(foundVal?.speciality);
      newArr1[3].dropValue = newArr1[3].value = data?.primaryCareCoordinatorId;
      newArr1[4].dropValue = newArr1[4].value =
        data?.secondaryCareCoordinatorId;
      newArr1[5].value = data?.authorizedRepresentative1;
      newArr1[6].value = data?.authorizedRepresentative2;
      newArr1[7].value = data?.authorizedRepresentative3;
    }
  }

  const uniqueArray: any = [];
  data?.careplanAdditionalProvider &&
    data?.careplanAdditionalProvider.length &&
    data?.careplanAdditionalProvider.map((el: any) => {
      if (el?.isDeleted == false) {
        uniqueArray.push(el);
      }
    });
  const newArr2 = Array.isArray(uniqueArray)
    ? uniqueArray?.map((el: any, index: number) => {
        const subArr = JSON.parse(
          JSON.stringify(arr2[0].additionalProvidarData)
        );
        subArr[0].value =
          typeof el?.additionalProviderName == "string"
            ? el?.additionalProviderName
            : "";
        subArr[1].value =
          typeof el?.additionalProviderSpeciality == "string"
            ? el?.additionalProviderSpeciality
            : "";
        subArr[2].value =
          typeof el?.addProviderOrganization == "string"
            ? el?.addProviderOrganization
            : "";
        subArr[3].value =
          typeof el?.phoneNumber == "string" ? el?.phoneNumber : "";
        subArr[4].value = typeof el?.faxNumber == "string" ? el?.faxNumber : "";

        return {
          id: index + 1,
          additionalProvidarArrId: el?.id,
          additionalProvidarData: subArr,
        };
      })
    : [];
  return {
    primaryInfoArray: newArr1,
    secondaryInfoArray: newArr2,
  };
};

export default { saveTeamPayload, formatCareplanSearchRes };
