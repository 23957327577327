import { AppointmentType } from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";
import PatientFormatter from "Utils/Patient.Formatter";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "Utils/common";

const AppointmentList: (data: any) => AppointmentType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: AppointmentType[] = CheckedArr.map((el) => {
    const careplanId = CheckNumber(el?.careplanId);
    const provider = CheckStringEmpty(
      careplanId ? el?.teamProviderName : el?.providerName
    );
    const providerDes = CheckStringEmpty(
      careplanId ? el?.teamProviderDesignation : ""
    );
    const staff = CheckStringEmpty(el?.clinicalStaff);
    const staffDes = CheckStringEmpty(el?.clinicalStaffDesignation);
    return {
      ...PatientFormatter.ListPatientInfo1(el),
      activity: CheckStringEmpty(el?.appointmentType),
      appointmentType: CheckStringEmpty(el?.nextSchedule),
      appointmentTimzone: CheckStringEmpty(el?.timezone),
      careplanId,
      careplanRecurringId: CheckNumber(el?.careplanRecurringId),
      careplanState: CheckStringEmpty(el?.careplanState),
      clinicalStaff: staff ? `${staff}${staffDes ? ", " + staffDes : ""}` : "",
      datetime: DateTime.ValidDate(el?.appointmentDatetime),
      id: CheckNumber(el?.appointmentId),
      importance: CheckStringEmpty(el?.loginUserImportance),
      notes: CheckStringEmpty(el?.notes),
      patientId: CheckStringId(el?.patientId),
      programId: CheckNumber(el?.programId),
      provider: provider
        ? `${provider}${providerDes ? ", " + providerDes : ""}`
        : "",
      timezone: CheckStringEmpty(el?.practiceLocationTimezone),
    };
  });
  return newArr;
};

export default { AppointmentList };
