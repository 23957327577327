import { FC, Fragment } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import { CommonStyle, ControlledForm } from "Components";
import ImgPath from "Constant/Imgs";
import CstmBtn from "Components/CstmBtn";
import { styled } from "@mui/material/styles";
import { DropdownArrPropsType } from "DataTypes/Form";
import { CheckStringId } from "Utils/common";

interface ModalProps {
  changeFile: (file: File | null) => void;
  fileName: string;
  progress: number;
  inProgress: boolean;
  changeCategory: (val: string | null) => void;
  categoryVal: string | null;
  handleUpload: () => void;
  handleClose: () => void;
}

const DropArr: DropdownArrPropsType[] = [
  {
    id: 1,
    label: "Lab Result",
    value: "Lab Result",
  },
  {
    id: 2,
    label: "Consent Form",
    value: "Consent Form",
  },
  {
    id: 3,
    label: "Clinical Document",
    value: "Clinical Document",
  },
  {
    id: 4,
    label: "Treatment Plan",
    value: "Treatment Plan",
  },
];

const AddModal: FC<ModalProps> = (props) => {
  const {
    changeFile,
    fileName,
    progress,
    inProgress,
    changeCategory,
    categoryVal,
    handleUpload,
    handleClose,
  } = props;

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <Fragment>
      <Box sx={{ ...sxHeader, ...CommonStyle.sxRow }}>
        <Typography fontWeight="bold">Upload Document</Typography>
        <Box display={"flex"} alignItems={"center"}>
          <Box
            sx={{ ...CommonStyle.sxRoundIconsMainSm, mr: 1 }}
            onClick={handleClose}
          >
            <ImgPath.CloseIcon titleAccess="Close" />
          </Box>
        </Box>
      </Box>
      <Box sx={{ ...sxMain }}>
        <Box display={"flex"} alignItems={"flex-end"}>
          <Box width={"30%"}>
            <ControlledForm.SingleSelectDrop
              id="seleted_category"
              label="Category"
              placeholder="Select a category"
              dropArr={DropArr}
              dropValue={categoryVal}
              readOnly={inProgress}
              onClick={(el) => changeCategory(CheckStringId(el))}
            />
          </Box>
          <Box width={"30%"} mx={1}>
            <ControlledForm.Input
              id="File Name"
              value={fileName}
              label="Selected File"
              placeholder="Select a file"
              readOnly={true}
            />
          </Box>
          <CstmBtn
            label="Choose File"
            onClick={() => {
              const element = document.getElementById("myFileInput");
              if (element) element.click();
            }}
            sxProps={{ ml: 1 }}
            disable={inProgress || !categoryVal}
          >
            <VisuallyHiddenInput
              onChange={(e) =>
                changeFile(
                  e?.target?.files && e?.target?.files?.length > 0
                    ? e?.target.files?.[0]
                    : null
                )
              }
              type="file"
              accept=".pdf, .doc, .docx, .txt, .png, .jpg, .jpeg"
              id="myFileInput"
            />
          </CstmBtn>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 2,
          }}
        >
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ width: "30%" }}
          />
          <Typography ml={1}>{`${progress}%`}</Typography>
          {inProgress && <CircularProgress size={20} sx={{ ml: 1 }} />}
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box
          display="flex"
          justifyContent={"flex-end"}
          sx={{ "&>button": { ml: 1 } }}
        >
          <CstmBtn
            label={
              inProgress ? "Uploading" : progress == 100 ? "Uploaded" : "Upload"
            }
            onClick={handleUpload}
            disable={inProgress || progress == 100 || !fileName}
          />
          <CstmBtn label={"Close"} onClick={handleClose} btnType="border" />
        </Box>
      </Box>
    </Fragment>
  );
};

export default AddModal;

const sxHeader = {
  justifyContent: "space-between",
  height: "10%",
  px: 2,
};

const sxMain = {
  height: "85%",
  overflow: "auto",
  px: 2,
};
