import { FC } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { RouteUrls } from "Constant/RouteUrls";

///UnProtected Routes
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/Forgot";
import PasswordRecovery from "Pages/PasswordRecovery";
import SignUp from "Pages/SignUp";

///Protected Routes
import Home from "./Pages/Home";
import HomeScreen from "./Pages/Home/HomeScreen";
import HeadScreen from "Pages/Home/HeadScreen";
import AllPatients from "Pages/Home/HeadScreen/AllPatients";
import ChangePassword from "Pages/Home/HeadScreen/ChangePassword";
import MyProfile from "Pages/Home/HeadScreen/MyProfile";
import Settings from "Pages/Home/HeadScreen/Settings";
import UserAudit from "Pages/Home/HeadScreen/UserAudit";
import Notifications from "Pages/Home/HeadScreen/Notifications";
import AboutUs from "Pages/Home/HeadScreen/AboutUs";

///Master
import Master from "Pages/Home/HeadScreen/Master";
import MasterProgram from "Pages/Home/HeadScreen/Master/Program";
import MasterProblems from "Pages/Home/HeadScreen/Master/Problem";
import ProblemList from "Pages/Home/HeadScreen/Master/Problem/List";
import ProblemICDCodes from "Pages/Home/HeadScreen/Master/Problem/Codes";
import ProgramProblem from "Pages/Home/HeadScreen/Master/PrgmProbMapper";
import PrgmInsuranceMapper from "Pages/Home/HeadScreen/Master/PrgmInsuranceMapper";
import QuesCategory from "Pages/Home/HeadScreen/Master/Category";
import MasterQuestion from "Pages/Home/HeadScreen/Master/Questionnaire";
import QuestionList from "Pages/Home/HeadScreen/Master/Questionnaire/List";
import QuestionUpdate from "Pages/Home/HeadScreen/Master/Questionnaire/Update";

///Patient Management
import PatientMgmt from "Pages/Home/HeadScreen/PatientMgmt";
import Details from "Pages/Home/HeadScreen/PatientMgmt/Details";
import ClinicalSummary from "Pages/Home/HeadScreen/PatientMgmt/Summary";
import CallLogs from "Pages/Home/HeadScreen/PatientMgmt/CallLog";
import DischargeSummary from "Pages/Home/HeadScreen/PatientMgmt/DischargeSummary";
import ConsentGained from "Pages/Home/HeadScreen/PatientMgmt/ConsentGained";
import CarePlan from "Pages/Home/HeadScreen/PatientMgmt/CarePlan";
import CareProblems from "Pages/Home/HeadScreen/PatientMgmt/CarePlan/Problems/index";
import CarePlanQuestionnaire from "Pages/Home/HeadScreen/PatientMgmt/CarePlan/Questions";
import AssignTeam from "Pages/Home/HeadScreen/PatientMgmt/CarePlan/Team";
import EditTeam from "Pages/Home/HeadScreen/PatientMgmt/CarePlan/EditTeam";
import CarePlanSummary from "Pages/Home/HeadScreen/PatientMgmt/CarePlan/Summary";
import Recurring from "Pages/Home/HeadScreen/PatientMgmt/CarePlan/Recurring";

///List Management Screens
import ListMgmt from "Pages/Home/HeadScreen/ListMgmt";
import Eligible from "Pages/Home/HeadScreen/ListMgmt/Eligible";
import PendingAssignment from "Pages/Home/HeadScreen/ListMgmt/PendingAssignment";
import Enrolled from "Pages/Home/HeadScreen/ListMgmt/Enrolled";
import MyPatients from "Pages/Home/HeadScreen/ListMgmt/MyPatients";
import PendingProviderApproval from "Pages/Home/HeadScreen/ListMgmt/PendingProviderApproval";
import PendingCare from "Pages/Home/HeadScreen/ListMgmt/PendingCare";
import CompleteCare from "Pages/Home/HeadScreen/ListMgmt/CompleteCare";
import Inactive from "Pages/Home/HeadScreen/ListMgmt/Inactive";
import Ongoing from "Pages/Home/HeadScreen/ListMgmt/Ongoing";
import Billing from "Pages/Home/HeadScreen/ListMgmt/Billing";
import Consent from "Pages/Home/HeadScreen/ListMgmt/Consent";

///Practice Management
import OrgMgmt from "Pages/Home/HeadScreen/OrgMgmt";
import OrganizationList from "Pages/Home/HeadScreen/OrgMgmt/List";
import OrganizationUpdate from "Pages/Home/HeadScreen/OrgMgmt/Update";
import OrganizationStaff from "Pages/Home/HeadScreen/OrgMgmt/Staff";
import OrganizationStaffList from "Pages/Home/HeadScreen/OrgMgmt/Staff/List";
import OrganizationStaffUpdate from "Pages/Home/HeadScreen/OrgMgmt/Staff/Update";

///Task Management
import TaskMgmt from "Pages/Home/HeadScreen/TaskMgmt";
import IncomingTasks from "Pages/Home/HeadScreen/TaskMgmt/Incoming";
import OutgoingTasks from "Pages/Home/HeadScreen/TaskMgmt/Outgoing";
import GroupTasks from "Pages/Home/HeadScreen/TaskMgmt/Group";
import GlobalTasks from "Pages/Home/HeadScreen/TaskMgmt/Global";
import Task from "Pages/Home/HeadScreen/PatientMgmt/Task";
import PatientAuditTrail from "Pages/Home/HeadScreen/PatientMgmt/PatientAudit";
import AddOnTimeLog from "Pages/Home/HeadScreen/PatientMgmt/AddOnTimeLog";
import PatientImport from "Pages/Home/HeadScreen/OrgMgmt/PatientImport";
import RedoxSettings from "Pages/Home/HeadScreen/RedoxSettings";
import ApplicationSettings from "Pages/Home/HeadScreen/ApplicationSettings";

const Router: FC = () => {
  const token = localStorage.getItem("cm-token");

  const routes = useRoutes([
    { path: RouteUrls.login, element: <Login /> },
    { path: RouteUrls.forgot, element: <ForgotPassword /> },
    { path: RouteUrls.passwordRecovery, element: <PasswordRecovery /> },
    { path: `${RouteUrls.signUp}/:token`, element: <SignUp /> },
    {
      path: "/",
      element: token ? <Home /> : <Navigate to={RouteUrls.login} replace />,
      children: [
        { path: "", element: <HomeScreen /> },
        {
          path: RouteUrls.hs,
          element: <HeadScreen />,
          children: [
            { path: "", element: <Navigate to="/" /> },
            { path: RouteUrls.allPatients, element: <AllPatients /> },
            { path: RouteUrls.redoxSettings, element: <RedoxSettings /> },
            { path: RouteUrls.appSettings, element: <ApplicationSettings /> },
            { path: RouteUrls.changePass, element: <ChangePassword /> },
            { path: RouteUrls.aboutUs, element: <AboutUs /> },
            {
              path: RouteUrls.list,
              element: <ListMgmt />,
              children: [
                {
                  path: "",
                  element: <Navigate to={RouteUrls.eligible} />,
                },
                {
                  path: RouteUrls.eligible,
                  element: <Eligible />,
                },
                {
                  path: RouteUrls.pendingAssign,
                  element: <PendingAssignment />,
                },
                {
                  path: RouteUrls.enrolled,
                  element: <Enrolled />,
                },
                {
                  path: RouteUrls.myPatients,
                  element: <MyPatients />,
                },
                {
                  path: RouteUrls.pendingApproval,
                  element: <PendingProviderApproval />,
                },
                {
                  path: RouteUrls.pendingCare,
                  element: <PendingCare />,
                },
                {
                  path: RouteUrls.completedCare,
                  element: <CompleteCare />,
                },
                {
                  path: RouteUrls.inactive,
                  element: <Inactive />,
                },
                {
                  path: RouteUrls.ongoing,
                  element: <Ongoing />,
                },
                {
                  path: RouteUrls.billing,
                  element: <Billing />,
                },
                {
                  path: RouteUrls.consent,
                  element: <Consent />,
                },
              ],
            },
            {
              path: RouteUrls.master,
              element: <Master />,
              children: [
                { path: "", element: <Navigate to={RouteUrls.category} /> },
                { path: RouteUrls.category, element: <QuesCategory /> },
                {
                  path: RouteUrls.insPrgmMap,
                  element: <PrgmInsuranceMapper />,
                },
                { path: RouteUrls.prgmProbMap, element: <ProgramProblem /> },
                {
                  path: RouteUrls.prob,
                  element: <MasterProblems />,
                  children: [
                    { path: "", element: <Navigate to={RouteUrls.list} /> },
                    { path: RouteUrls.list, element: <ProblemList /> },
                    { path: RouteUrls.icdCodes, element: <ProblemICDCodes /> },
                  ],
                },
                { path: RouteUrls.prgm, element: <MasterProgram /> },
                {
                  path: RouteUrls.questionnaire,
                  element: <MasterQuestion />,
                  children: [
                    { path: "", element: <Navigate to={RouteUrls.list} /> },
                    { path: RouteUrls.list, element: <QuestionList /> },
                    {
                      path: `:categoryId/${RouteUrls.add}`,
                      element: <QuestionUpdate />,
                    },
                    {
                      path: `:categoryId/:quesId/${RouteUrls.update}`,
                      element: <QuestionUpdate />,
                    },
                    {
                      path: `:categoryId/:parentId/:parentOptionId/${RouteUrls.add}`,
                      element: <QuestionUpdate />,
                    },
                    {
                      path: `:categoryId/:parentId/:parentOptionId/:quesId/${RouteUrls.update}`,
                      element: <QuestionUpdate />,
                    },
                  ],
                },
              ],
            },
            { path: RouteUrls.myProfile, element: <MyProfile /> },
            {
              path: RouteUrls.organization,
              element: <OrgMgmt />,
              children: [
                {
                  path: "",
                  element: <Navigate to={RouteUrls.list} />,
                },
                {
                  path: RouteUrls.patientUpload,
                  element: <PatientImport />,
                },
                { path: RouteUrls.list, element: <OrganizationList /> },
                { path: RouteUrls.add, element: <OrganizationUpdate /> },
                {
                  path: `:orgId/${RouteUrls.update}`,
                  element: <OrganizationUpdate />,
                },
                {
                  path: `${RouteUrls.myOrganization}/:orgId/${RouteUrls.View}`,
                  element: <OrganizationUpdate />,
                },
                {
                  path: `:orgId/${RouteUrls.staff}`,
                  element: <OrganizationStaff />,
                  children: [
                    {
                      path: "",
                      element: <Navigate to={`${RouteUrls.list}`} />,
                    },
                    {
                      path: `${RouteUrls.list}`,
                      element: <OrganizationStaffList />,
                    },
                    {
                      path: `${RouteUrls.add}`,
                      element: <OrganizationStaffUpdate />,
                    },
                    {
                      path: `:staffId/${RouteUrls.update}`,
                      element: <OrganizationStaffUpdate />,
                    },
                  ],
                },
              ],
            },
            {
              path: `${RouteUrls.patient}/:patientId`,
              element: <PatientMgmt />,
              children: [
                { path: "", element: <Navigate to={RouteUrls.details} /> },
                { path: RouteUrls.details, element: <Details /> },
                { path: RouteUrls.addonTime, element: <AddOnTimeLog /> },
                {
                  path: RouteUrls.clinicalSummary,
                  element: <ClinicalSummary />,
                },
                { path: RouteUrls.callLog, element: <CallLogs /> },
                {
                  path: RouteUrls.patientAudit,
                  element: <PatientAuditTrail />,
                },
                {
                  path: `${RouteUrls.task}/${RouteUrls.add}`,
                  element: <Task />,
                },
                {
                  path: `${RouteUrls.tasking}/${RouteUrls.incoming}/:taskId/${RouteUrls.update}`,
                  element: <Task />,
                },
                {
                  path: `${RouteUrls.tasking}/${RouteUrls.outgoing}/:taskId/${RouteUrls.update}`,
                  element: <Task />,
                },
                {
                  path: `${RouteUrls.tasking}/${RouteUrls.global}/:taskId/${RouteUrls.update}`,
                  element: <Task />,
                },
                {
                  path: `${RouteUrls.task}/:taskId/${RouteUrls.View}`,
                  element: <Task />,
                },
                { path: RouteUrls.consent, element: <ConsentGained /> },
                { path: RouteUrls.program, element: <ConsentGained /> },
                {
                  path: RouteUrls.dischargeSummary,
                  element: <DischargeSummary />,
                },
                {
                  path: `${RouteUrls.carePlan}/:careplanId`,
                  element: <CarePlan />,
                  children: [
                    {
                      path: "",
                      element: <Navigate to={RouteUrls.careProblem} />,
                    },
                    { path: RouteUrls.careProblem, element: <CareProblems /> },
                    {
                      path: RouteUrls.careQuestion,
                      element: <CarePlanQuestionnaire />,
                    },
                    { path: RouteUrls.careTeam, element: <AssignTeam /> },
                    { path: RouteUrls.editTeam, element: <EditTeam /> },
                    {
                      path: RouteUrls.careSummary,
                      element: <CarePlanSummary />,
                    },
                    {
                      path: `${RouteUrls.recurring}/:recurringId`,
                      element: <Recurring />,
                      children: [
                        {
                          path: "",
                          element: <Navigate to={RouteUrls.careQuestion} />,
                        },
                        {
                          path: RouteUrls.careQuestion,
                          element: <CarePlanQuestionnaire />,
                        },
                        {
                          path: RouteUrls.careSummary,
                          element: <CarePlanSummary />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: `${RouteUrls.tcm}/:careplanId`,
                  element: <CarePlan />,
                  children: [
                    {
                      path: "",
                      element: <Navigate to={RouteUrls.careQuestion} />,
                    },
                    {
                      path: RouteUrls.careQuestion,
                      element: <CarePlanQuestionnaire />,
                    },
                    { path: RouteUrls.careTeam, element: <AssignTeam /> },
                    {
                      path: RouteUrls.careSummary,
                      element: <CarePlanSummary />,
                    },
                  ],
                },
              ],
            },
            { path: RouteUrls.setting, element: <Settings /> },
            {
              path: RouteUrls.tasking,
              element: <TaskMgmt />,
              children: [
                {
                  path: "",
                  element: <Navigate to={RouteUrls.incoming} />,
                },
                {
                  path: RouteUrls.incoming,
                  element: <IncomingTasks />,
                },
                {
                  path: RouteUrls.outgoing,
                  element: <OutgoingTasks />,
                },
                {
                  path: RouteUrls.group,
                  element: <GroupTasks />,
                },
                {
                  path: RouteUrls.global,
                  element: <GlobalTasks />,
                },
              ],
            },
            { path: RouteUrls.userAudit, element: <UserAudit /> },
            { path: RouteUrls.notifications, element: <Notifications /> },
          ],
        },
      ],
    },
    { path: "*", element: <></> },
  ]);
  return routes;
};
export default Router;
