import { FC, useState } from "react";
import { Snackbar, Alert, IconButton } from "@mui/material";
import { ScreenInitialStateType } from "DataTypes/Redux";
import { clearAlertData } from "Redux/Screen/Action";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ImgPath from "Constant/Imgs";

const AlertPopUp: FC = () => {
  const [displayFull, setDisplyaFull] = useState(false);
  const dispatch = useDispatch();
  const { alert }: ScreenInitialStateType = useSelector(
    (state: any) => state?.screen
  );

  const handleAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(clearAlertData());
    setDisplyaFull(false);
  };

  return (
    <Snackbar
      open={alert?.openAlert}
      autoHideDuration={5000}
      onClose={handleAlert}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleAlert}
        severity={alert?.alertVariant || "success"}
        sx={{
          wordBreak: "break-word",
          alignItems: "flex-end",
          "&>div:nth-of-type(2)": { maxHeight: "80vh" },
        }}
      >
        {displayFull || alert?.alertMessage?.length < 500
          ? alert?.alertMessage
          : `${alert?.alertMessage?.slice(0, 500)}...`}
        {alert?.alertMessage?.length > 499 && (
          <IconButton
            onClick={() => setDisplyaFull(!displayFull)}
            sx={{ "& svg": { color: "white" }, p: 0 }}
          >
            {displayFull ? (
              <ImgPath.ArrowDropUp titleAccess="View Less" />
            ) : (
              <ImgPath.ArrowDropDown titleAccess="View All" />
            )}
          </IconButton>
        )}
      </Alert>
    </Snackbar>
  );
};

export default AlertPopUp;
