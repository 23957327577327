import { ControlledForm, ControlledTable } from "Components";
import { FormDataType } from "DataTypes/Form";
import { columnType } from "DataTypes/Table";
import { BreadArrType } from "DataTypes/Common";

export const TableCol: () => columnType[] = () => {
  return [
    ControlledTable.ColumnData.CallerNameCol("15%"),
    ControlledTable.ColumnData.DateTimeCol("15%"),
    ControlledTable.ColumnData.CallResultCol("15%"),
    ControlledTable.ColumnData.NextCallCol("20%"),
    ControlledTable.ColumnData.CallNoteCol("35%"),
  ];
};

export const FormDataArr: () => FormDataType[] = () => {
  return [
    ControlledForm.FormData.CallName(),
    ControlledForm.FormData.CallResult(),
    ControlledForm.FormData.NextCall(),
    ControlledForm.FormData.CallNotes(),
  ];
};

export const CallLogsBreadArr: BreadArrType[] = [
  {
    id: 1,
    label: "Home",
    path: "/",
  },
  {
    id: 2,
    label: "Call Logs",
    path: "",
  },
];
