import { FC, Fragment } from "react";
import { Box, Typography } from "@mui/material";
import Loader from "Components/Loader";
import NoData from "Components/NoData";
import DateTime from "Utils/DateTime";
import { CommonStyle } from "Components";
import ImgPath from "Constant/Imgs";
import { QualityTabType } from "DataTypes/Common";

const TestDue: FC<QualityTabType> = ({ data, loading, handleEdit }) => {
  return (
    <Box sx={{ ...sxMain }}>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          {data.length > 0 ? (
            data.map((el) => {
              return (
                <Box
                  key={el.id}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    py: 0.5,
                    borderBottom: "1px solid",
                    borderColor: "custom.main",
                  }}
                >
                  <Box width="85%">
                    <Box display="flex">
                      <Typography variant="body2" fontWeight={"bold"}>
                        {el.canonicalName}
                      </Typography>
                      <Typography variant="subtitle1" mx={2}>
                        Due Date: {DateTime.ToLocalDate(el.dueDate)}
                      </Typography>
                      {DateTime.GetTimeStamp(DateTime.CurrentDate()) >
                        DateTime.GetTimeStamp(
                          DateTime.ToLocalDate(el.dueDate)
                        ) && (
                        <Typography
                          fontWeight={"bold"}
                          variant="subtitle2"
                          sx={{ color: "error.main" }}
                        >
                          Past Due*
                        </Typography>
                      )}
                    </Box>
                    <Typography variant="subtitle1">
                      Notes:{" "}
                      {el.note.length > 60
                        ? el.note.slice(0, 50) + " ..."
                        : el.note}
                    </Typography>
                  </Box>
                  <Box display="flex" sx={{ "&>div": { ml: 1 } }}>
                    <Box
                      sx={{ ...CommonStyle.sxRoundIconsMainSm }}
                      onClick={() => handleEdit(el, true)}
                    >
                      <ImgPath.RemoveRedEyeOutlined />
                    </Box>
                    <Box
                      sx={{ ...CommonStyle.sxRoundIconsMainSm }}
                      onClick={() => handleEdit(el, false)}
                    >
                      <ImgPath.EditOutlined />
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <NoData />
          )}
        </Fragment>
      )}
    </Box>
  );
};

export default TestDue;

const sxMain = {
  height: "100%",
  overflow: "auto",
  px: 2,
};
