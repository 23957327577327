import { Box, Typography, Grid } from "@mui/material";
import { CommonStyle } from "Components";
import { FormDataType } from "DataTypes/Form";
import { bpdataArr, gludataArr, hrdataArr, pluoxdataArr, thrdataArr } from "./Data";
import { commonFormSwitch } from "Utils/SwitchCases";
import { HandleChangeProps } from "DataTypes/Common";

const ThresholdsModal = () => {
  const handleChange: (props: HandleChangeProps) => void = ({
    value,
    id,
  }) => {
    console.log(id, value);
  };
  return (
    <Box>
      <Box>
        <Box sx={{ ...CommonStyle.sxHeading, ...sxHeading }} width="100%">
          <Box>
            <Typography variant="body2" fontWeight="bold">
              Patient Name
            </Typography>
            <Typography variant="subtitle1">Tom Hanks</Typography>
          </Box>
          <Box sx={{ ...sxSperator }}></Box>
          <Box>
            <Typography variant="body2" fontWeight="bold">
              Organization Name
            </Typography>
            <Typography variant="subtitle1">Woodlands Family Medicine</Typography>
          </Box>
          <Box sx={{ ...sxSperator }}></Box>
          <Box>
            <Typography variant="body2" fontWeight="bold">
              Billable Provider Name
            </Typography>
            <Typography variant="subtitle1">Louis McDonald</Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ mb: 2 }}
        >
          <Box width={"20%"}>
            <Typography fontWeight="bold">Blood Pressure</Typography>
          </Box>
          {bpdataArr.map((el: FormDataType) => {
            return (
              <Box width={"40%"} key={el.id} sx={{ pl: 2 }}>
                {commonFormSwitch({ formObj: el, onChange: handleChange })}
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ mb: 2 }}
        >
          <Box width={"20%"}>
            <Typography fontWeight="bold">Heart Rate</Typography>
          </Box>
          {hrdataArr.map((el: FormDataType) => {
            return (
              <Box width={"40%"} key={el.id} sx={{ pl: 2 }}>
                {commonFormSwitch({ formObj: el, onChange: handleChange })}
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box>
        <Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ mb: 2 }}
          >
            <Box width={"20%"}>
              <Typography fontWeight="bold"> Glucometer/CGM</Typography>
            </Box>
            {gludataArr.map((el: FormDataType) => {
              return (
                <Box width={"40%"} key={el.id} sx={{ pl: 2 }}>
                  {commonFormSwitch({ formObj: el, onChange: handleChange })}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ mb: 2 }}
          >
            <Box width={"20%"}>
              <Typography fontWeight="bold"> Thermometer</Typography>
            </Box>
            {thrdataArr.map((el: FormDataType) => {
              return (
                <Box width={"40%"} key={el.id} sx={{ pl: 2 }}>
                  {commonFormSwitch({ formObj: el, onChange: handleChange })}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box>
        <Box display={"flex"} alignItems={"center"} sx={{ mb: 2 }}>
          <Box width={"20%"}>
            <Typography fontWeight="bold"> Pulse Oximeter</Typography>
          </Box>
          {pluoxdataArr.map((el: FormDataType) => {
            return (
              <Box width={"39%"} key={el.id} sx={{ pl: 1 }}>
                {commonFormSwitch({ formObj: el, onChange: handleChange })}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default ThresholdsModal;

const sxHeading = {
  display: "flex",
  flexDirection: "row",
  mb: 2,
  "& >div": {
    "& >p:first-of-type": {
      fontSize: "0.9rem",
    },
  },
};
const sxSperator = {
  mr: 4,
  ml: 4,
  backgroundColor: "#c3c3c3",
  width: "0.1%",
};
