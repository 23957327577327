import { ApiServiceProps } from "DataTypes/Services/Index.type";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { post } from ".";

const list: () => any = () => {
  const socket = new SockJS(
    `${process.env.REACT_APP_NOTIFICATION_URL}/notification/ws`
  );
  return Stomp.over(socket);
};

const search: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "notification/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const markAsRead: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `notification/${payload.id1}/mark-as-read`,
    data: {},
  };
  return post(request);
};

const markDelete: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `notification/${payload.id1}/delete`,
    data: {},
  };
  return post(request);
};

export default { list, search, markAsRead, markDelete };
