import BreadUrls from "Constant/BreadUrls";
import { BreadArrType } from "DataTypes/Common";
import { FormDataType } from "DataTypes/Form";

export const BreadArr: (name: string) => BreadArrType[] = (name) => {
  return [
    {
      id: 1,
      label: "Home",
      path: "/",
    },
    {
      id: 2,
      label: "Organization List",
      path: BreadUrls.OrgList(),
    },
    {
      id: 3,
      label: `${name} Patient Data Import`,
      path: "",
    },
  ];
};

export const FormDataArr: FormDataType[] = [
  {
    id: "practice",
    label: "Organization Name",
    placeholder: "Select Organization",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "org-name",
    readOnly: true,
  },
  {
    id: "location",
    label: "Location Name",
    placeholder: "Select Location",
    boxType: "single-select-drop",
    dropArr: [],
    boxSize: 6,
    validatetype: "dropdown",
  },
];
