import { FC, Fragment, useEffect, useState } from "react";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { DetailForm, LocationForm } from "./Data";
import Formate from "./Formate";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import UserApi from "Service/User.api";
import { CommonStyle, ControlledForm } from "Components";
import { label } from "Constant/En";
import { ControlledBoxSizeSwitch, commonFormSwitch } from "Utils/SwitchCases";
import ImgPath from "Constant/Imgs";
import CstmBtn from "Components/CstmBtn";
import PermissionApi from "Service/Permission.api";
import {
  CheckStringEmpty,
  ConvertStringToNum,
  HandleFormChange,
  ValidateFormData,
} from "Utils/common";
import SwitchCase from "Utils/SwitchCase";
import { useDispatch } from "react-redux";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import { UserRolePermissionApiType } from "DataTypes/Services/User.type";
import UserFormatter from "Utils/Users.Formatter";
import { HandleChangeProps } from "DataTypes/Common";
import {
  PracticeDetailsFormArrType,
  PracticeLocationArrType,
  PracticeProp,
} from "DataTypes/PracticeMangement.type";
import { ListInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { setMyInfo } from "Redux/User/Action";
import DateTime from "Utils/DateTime";

const Other: FC<PracticeProp> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { staffId } = useParams();
  const newStaffId = ConvertStringToNum(staffId);
  const {
    myPractice,
    myProfile,
    handleDisableUser,
    handleForgetUser,
    checkUserEmail,
    checkUserId,
  } = props;
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { roleDropList: roleList, genderList }: ListInitialStateType =
    useSelector((state: any) => state?.list);
  const [form1, setForm1] = useState<FormDataType[]>(
    JSON.parse(JSON.stringify(DetailForm([], [])))
  );
  const [form2, setForm2] = useState<PracticeDetailsFormArrType[]>([]);
  const [locationArr, setLocationArr] = useState<PracticeLocationArrType[]>([]);
  const [directArr, setDirectArr] = useState<DropdownArrPropsType[]>([]);
  const [ccnotes, setCCNotes] = useState(false);
  const [threshold, setThreshold] = useState(false);
  const [permissionArr, setPermissionArr] = useState<
    UserRolePermissionApiType[]
  >([]);

  useEffect(() => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
  }, []);

  useEffect(() => {
    myPractice && checkPracticeType();
  }, [myPractice]);

  const checkPracticeType = async () => {
    let FormatedPermission: UserRolePermissionApiType[] = [];
    if (permissionArr.length < 1) {
      const payload = {
        isUserlevelPermission: false,
        isDeleted: false,
      };
      const permissionRes = await PermissionApi.list({
        data: payload,
      });
      if (permissionRes?.success) {
        FormatedPermission = UserFormatter.VerifyUserRolePermissionResponse(
          permissionRes?.data
        );
        setPermissionArr(FormatedPermission);
      }
    }
    if (myPractice) {
      const filterRoleList = Formate.FilteredOtherRoleList(
        roleList,
        myPractice.businessModels.id
      );
      const localMyDetailsForm: FormDataType[] = JSON.parse(
        JSON.stringify(DetailForm(filterRoleList, genderList))
      );
      const newLocationArr: PracticeLocationArrType[] = [
        ...locationArr,
        ...Formate.DropLocationList(myPractice),
      ];
      const newDirectArr: DropdownArrPropsType[] = [
        ...directArr,
        ...Formate.DropDirectList(myPractice),
      ];
      if (newStaffId && myProfile) {
        setForm1(Formate.MapUserToForm(myProfile, localMyDetailsForm));

        const findCCNotesObj = FormatedPermission.find(
          (forPreEl) => forPreEl.slug == "pullforwardccnote:accesslevel"
        );
        if (findCCNotesObj) {
          const findCC = myProfile.permission.find(
            (myperEL) => myperEL.id == findCCNotesObj.id
          );
          if (findCC) setCCNotes(true);
        }
        const findThresObj = FormatedPermission.find(
          (forPreEl) => forPreEl.slug == "userpatientthreshold:accesslevel"
        );
        if (findThresObj) {
          const findTh = myProfile.permission.find(
            (myperEL) => myperEL.id == findThresObj.id
          );
          if (findTh) setThreshold(true);
        }
        const newAssign = Formate.OtherMapForm2Val(
          myProfile,
          LocationForm(newLocationArr.map((el) => el.dropVal))
        );
        Refreshment(newAssign, newLocationArr, newDirectArr);
      } else {
        setForm1(localMyDetailsForm);
        setForm2([
          {
            id: 1,
            practiceLocationOrder: null,
            status: "ACTIVE",
            formArr: JSON.parse(
              JSON.stringify(
                LocationForm(newLocationArr.map((el) => el.dropVal))
              )
            ),
          },
        ]);
        setLocationArr(newLocationArr);
        setDirectArr(newDirectArr);
        dispatch(stopLoading());
      }
    }
  };

  const Refreshment: (
    formArr: PracticeDetailsFormArrType[],
    locArr: PracticeLocationArrType[],
    dirArr: DropdownArrPropsType[]
  ) => void = (formArr, locArr, dirArr) => {
    let localFormArr = [...formArr];
    let localLocArr = [...locArr];
    let localDirArr = [...dirArr];
    localLocArr = localLocArr.map((locEl) => {
      const localVal = CheckStringEmpty(locEl.dropVal.value);
      const localArr = localVal.split(":");
      const find = localFormArr.find((assignEL) => {
        const assignVal = CheckStringEmpty(assignEL.formArr[0].dropValue);
        const assignArr = assignVal.split(":");
        if (localArr[0] == assignArr[0]) return assignEL;
      });
      locEl.dropVal.value = find
        ? find.formArr[0].dropValue
        : `${localArr[0]}:${localArr[1]}:0`;
      return locEl;
    });
    localDirArr = localDirArr.map((dirEl) => {
      const localVal = CheckStringEmpty(dirEl.value);
      const localArr = localVal.split(":");
      const find = localFormArr.find((assignEL) => {
        const assignVal = CheckStringEmpty(assignEL.formArr[1].dropValue);
        const assignArr = assignVal.split(":");
        if (localArr[0] == assignArr[0]) return assignEL;
      });
      if (find) dirEl.value = find.formArr[1].dropValue;
      else dirEl.value = `${localArr[0]}:${localArr[1]}:0`;
      return dirEl;
    });
    Promise.all(
      (localFormArr = localFormArr.map((formEl) => {
        if (formEl.status == "ACTIVE") {
          const val1 = formEl.formArr[0].dropValue;
          const arr1 = typeof val1 == "string" ? val1.split(":") : [];
          const currentLocId = arr1[0];
          formEl.formArr[0].dropArr = localLocArr
            .filter((locEl) => {
              const localVal = CheckStringEmpty(locEl.dropVal.value);
              const localArr = localVal.split(":");
              if (
                parseInt(localArr[2]) == formEl.id ||
                parseInt(localArr[2]) == 0
              )
                return locEl;
            })
            .map((locEl) => locEl.dropVal);
          const findLoc = localLocArr.find(
            (locEl) => locEl.dropVal.value == formEl.formArr[0].dropValue
          );
          formEl.practiceLocationOrder = findLoc
            ? findLoc.practiceLocationOrder
            : null;
          formEl.formArr[1].dropArr = localDirArr.filter((dirEl) => {
            const localVal = typeof dirEl.value == "string" ? dirEl.value : "";
            const localArr = localVal.split(":");
            if (
              localArr[1] == currentLocId &&
              (parseInt(localArr[2]) == formEl.id || parseInt(localArr[2]) == 0)
            )
              return dirEl;
          });
        }
        return formEl;
      }))
    ).then((newFormArr) => {
      setForm2(newFormArr);
      setLocationArr(localLocArr);
      setDirectArr(localDirArr);
      dispatch(stopLoading());
    });
  };

  const handleChange: (
    props: HandleChangeProps,
    newformArr: FormDataType[]
  ) => FormDataType[] = ({ id, value }, newformArr) => {
    if (id == "role") {
      if (value == 2) {
        Refreshment([], locationArr, directArr);
      } else {
        if (form1[6].dropValue == 2) {
          Refreshment(
            [
              {
                id: 1,
                practiceLocationOrder: null,
                status: "ACTIVE",
                formArr: JSON.parse(
                  JSON.stringify(
                    LocationForm(locationArr.map((locEl) => locEl.dropVal))
                  )
                ),
              },
            ],
            locationArr,
            directArr
          );
        }
      }
      if (value == 5) {
        newformArr[8].required = true;
        newformArr[4].required = true;
      } else {
        newformArr[8].required = false;
        newformArr[4].required = false;
      }
    }
    return HandleFormChange({ id, value }, newformArr);
  };

  const handleVerifyMailName: (
    props: HandleChangeProps,
    formArr: FormDataType[]
  ) => void = async ({ value, id }, formArr) => {
    if (id == "prim-email-id" || id == "userId") {
      if (id == "prim-email-id") {
        formArr[9].notUnique = await checkUserEmail(CheckStringEmpty(value));
      }
      if (id == "userId") {
        formArr[0].notUnique = await checkUserId(CheckStringEmpty(value));
      }
      const newArr = formArr.map((el) => {
        if (el.id == id) {
          const temp = SwitchCase.FormValidation(el);
          el.error = temp.error;
          el.errorText = temp.errorText;
        }
        return el;
      });
      setForm1(newArr);
    }
  };

  const handleAssignChange1: (
    props: HandleChangeProps,
    rowId: number
  ) => void = async (props, rowId) => {
    const { value, id } = props;
    let newAssignForm = [...form2];
    const localLocationArr = [...locationArr];
    const localDirectArr = [...directArr];
    newAssignForm = newAssignForm.map((formEL) => {
      if (formEL.id == rowId) {
        const val1 = CheckStringEmpty(value);
        const arr = val1.split(":");
        if (id == "location") {
          formEL.formArr[0].dropValue =
            formEL.formArr[0].value = `${arr[0]}:${arr[1]}:${rowId}`;
          const temp = SwitchCase.FormValidation(formEL.formArr[0]);
          formEL.formArr[0].error = temp.error;
          formEL.formArr[0].errorText = temp.errorText;
        } else {
          formEL.formArr[1].dropValue =
            formEL.formArr[1].value = `${arr[0]}:${arr[1]}:${rowId}`;
          const temp = SwitchCase.FormValidation(formEL.formArr[1]);
          formEL.formArr[1].error = temp.error;
          formEL.formArr[1].errorText = temp.errorText;
        }
      }
      return formEL;
    });
    Refreshment(newAssignForm, localLocationArr, localDirectArr);
  };

  const handleDelete: (rowId: number | null) => void = (rowId) => {
    if (rowId) {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      let newArr = form2.filter((el) => el.id != rowId);
      const newLocationArr = locationArr.map((locEl) => {
        const arr =
          typeof locEl.dropVal.value == "string"
            ? locEl.dropVal.value.split(":")
            : [];
        if (parseInt(arr[2]) == rowId)
          locEl.dropVal.value = `${arr[0]}:${arr[1]}:${0}`;
        return locEl;
      });
      const newDirectArr = directArr.map((dirEl) => {
        const arr =
          typeof dirEl.value == "string" ? dirEl.value.split(":") : [];
        if (parseInt(arr[2]) == rowId) dirEl.value = `${arr[0]}:${arr[1]}:${0}`;
        return dirEl;
      });
      newArr = newArr.map((el, index) => {
        return {
          ...el,
          id: index + 1,
          formArr: el.formArr.map((el1) => {
            let newVal = el1.dropValue;
            if (el1.dropValue && el1?.dropValue?.length > 0) {
              const arr = el1?.dropValue ? el1?.dropValue?.split(":") : [];
              if (arr?.length > 2) newVal = `${arr[0]}:${arr[1]}:${index + 1}`;
            }
            return {
              ...el1,
              dropValue: newVal,
              value: el.status == "ACTIVE" ? newVal : el1.value,
            };
          }),
        };
      });
      Refreshment(newArr, newLocationArr, newDirectArr);
    }
  };

  const handleAdd: () => void = () => {
    const newArr: PracticeDetailsFormArrType[] = [
      ...form2,
      {
        id: (form2[form2.length - 1].id || 0) + 1,
        practiceLocationOrder: null,
        status: "ACTIVE",
        formArr: JSON.parse(
          JSON.stringify(
            LocationForm(locationArr.map((locEl) => locEl.dropVal))
          )
        ),
      },
    ];
    Refreshment(newArr, locationArr, directArr);
  };

  const handleSubmit = async () => {
    let errorCheck = false;
    const newArr = ValidateFormData(form1);
    if (newArr.errorCheck) errorCheck = true;
    setForm1(newArr.formData);
    const newArr2 =
      form2.map((formEl) => {
        const newArr3 = ValidateFormData(formEl.formArr);
        if (newArr3.errorCheck) errorCheck = true;
        formEl.formArr = newArr3.formData;
        return formEl;
      }) || [];
    Refreshment(newArr2, locationArr, directArr);
    if (!errorCheck) {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      const newpermissionArr = permissionArr.filter((el) => {
        if (el.slug == "pullforwardccnote:accesslevel" && ccnotes) return el;
        if (el.slug == "userpatientthreshold:accesslevel" && threshold)
          return el;
      });
      if (myPractice) {
        const practicePayload = Formate.OtherLocationPayload(
          JSON.parse(JSON.stringify([...form2])),
          myPractice,
          typeof form1[6].dropValue == "number" ? form1[6].dropValue : 0
        );
        const payload = Formate.ApiPayload(
          form1,
          newpermissionArr,
          practicePayload,
          myProfile
        );
        const res = newStaffId
          ? await UserApi.update({
            id1: newStaffId,
            data: payload,
          })
          : await UserApi.create({ data: payload });
        if (res?.success) {
          if (myInfo.id == newStaffId) {
            const timezone = payload.timezone;
            const utcDiff = DateTime.HoursTimeZoneSwitch(timezone);
            const { date, datetime } = DateTime.TimeZoneRegionSwitch(timezone);
            dispatch(
              setMyInfo({
                ...myInfo,
                firstname: payload.firstName,
                lastname: payload.lastName,
                designation: payload.designation,
                timezone,
                dateFormat: date,
                dateTimeFormat: datetime,
                utcDiff: utcDiff == 0 ? null : utcDiff,
              })
            );
          }
          dispatch(setAlertData(res?.alert));
          navigate("../");
        }
      }
      dispatch(stopLoading());
    }
  };

  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox, overflow: "auto" }}>
      <Box sx={{ p: 2 }}>
        <Typography fontWeight="bold">{label.personalInfo}</Typography>
        <Grid container rowSpacing={1} columnSpacing={2}>
          {form1.length > 0
            ? form1.map((el: FormDataType) => {
              return (
                <Grid
                  key={el.id}
                  item
                  {...ControlledBoxSizeSwitch(el.boxSize)}
                >
                  {commonFormSwitch({
                    formObj: el,
                    onChange: (props) => {
                      const newArr = handleChange(props, form1);
                      setForm1(newArr);
                      handleVerifyMailName(props, form1);
                    },
                  })}
                </Grid>
              );
            })
            : null}
        </Grid>
        <Divider sx={{ mt: 2, mb: 1 }} />
        {form2.length > 0 && (
          <Fragment>
            <Typography fontWeight="bold">Assign Location</Typography>
            {form2.map((el) => {
              return (
                <Box sx={{ ...CommonStyle.sxRow }} key={el.id}>
                  <Grid container columnSpacing={2} sx={{ mb: 2 }} width="50%">
                    {el.formArr.length > 0
                      ? el.formArr.map((assignField: FormDataType) => {
                        return (
                          <Grid key={assignField.id} item sm={6}>
                            {commonFormSwitch({
                              formObj: assignField,
                              onChange: ({ value, id }) =>
                                handleAssignChange1(
                                  { value, id },
                                  el.id || 0
                                ),
                            })}
                          </Grid>
                        );
                      })
                      : null}
                  </Grid>
                  {form2.length > 1 && el.status == "ACTIVE" && (
                    <Box sx={{ ml: 2 }}>
                      <IconButton
                        sx={{
                          ...CommonStyle.sxRoundIconsMainSm,
                        }}
                        onClick={() => handleDelete(el.id)}
                      >
                        <ImgPath.CloseIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              );
            })}
            {myPractice?.status == "ACTIVE" && (
              <CstmBtn label="Assign Another Location" onClick={handleAdd} />
            )}
            <Divider sx={{ mt: 2, mb: 1 }} />
          </Fragment>
        )}
        <Typography fontWeight="bold">{label.accessLevel}</Typography>
        <Box sx={{ my: 1 }}>
          <ControlledForm.CheckBoxGroup
            label=""
            items={[
              {
                id: 1,
                title:
                  "Can the user pull forward the recent care coordination note?",
                checked: ccnotes,
              },
            ]}
            onChange={() => setCCNotes(ccnotes ? false : true)}
            labelVariant="subtitle1"
          />
        </Box>
        {/* <Box sx={{ my: 1 }}>
          <ControlledForm.CheckBoxGroup
            label=""
            items={[
              {
                id: 2,
                title: "Allow the user to insert/ edit the Patient Threshold ?",
                checked: threshold,
              },
            ]}
            onChange={() => setThreshold(threshold ? false : true)}
            labelVariant="subtitle1"
          />
        </Box> */}
        <Divider sx={{ my: 2 }} />
        <Box
          justifyContent="flex-end"
          sx={{
            ...CommonStyle.sxRow,
            "& >button:first-of-type": {
              mr: 1,
            },
          }}
        >
          {myProfile && myProfile?.role[0]?.id != 2 && (
            <CstmBtn
              label="Force Reset Password"
              onClick={handleForgetUser}
              width="13%"
              disable={
                myProfile?.status == "INACTIVE" ||
                myProfile?.status == "DISABLED"
              }
            />
          )}
          {myProfile && myProfile?.role[0]?.id != 2 && (
            <CstmBtn
              sxProps={{ mr: 1 }}
              label={myProfile?.status == "DISABLED" ? "Enable" : "Disable"}
              onClick={handleDisableUser}
              width="10%"
              disable={myProfile?.status == "INACTIVE"}
            />
          )}
          <CstmBtn
            sxProps={{ mr: 1 }}
            label="Save"
            onClick={handleSubmit}
            width="10%"
          />
          <CstmBtn
            label="Cancel"
            onClick={() => navigate("../")}
            width="10%"
            btnType="border"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Other;
