import { Box, ModalProps } from "@mui/material";
import ControlledForm from "Components/ControlledForm";
import { Fragment } from "react";
type AddModalProps = {
  data: any
}
const AddModel = ({
  data,
}: AddModalProps) => {
  return (
    <Fragment>
      <Box height="50%">
        <ControlledForm.Input
          id="category"
          label=""
          placeholder="Enter Model Name"
          defaultValue={data?.model ? data?.model : ""}
        />
      </Box>
    </Fragment>
  );
};
export default AddModel;
