import { Box } from "@mui/material";
import { CommonStyle, SubNavBar } from "Components";
import { label } from "Constant/En";
import { RouteUrls } from "Constant/RouteUrls";
import { SubNavDataType } from "DataTypes/Layout";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const Tasks: FC = () => {
  const { myPermission }: any = useSelector((state: any) => state?.user);
  return (
    <Box height="91%">
      <SubNavBar data={TaskArr(myPermission?.dashlinks)} />
      <Box
        height="92%"
        sx={{ ...CommonStyle.sxWhiteBoxWithNavbar, px: 2, overflow: "auto" }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
export default Tasks;

export const TaskArr = (taskPermission: any) => {
  const Arr: SubNavDataType[] = [
    ...(taskPermission?.incomingtask
      ? [
        {
          id: RouteUrls.incoming,
          label: label.incoming,
          link: `${RouteUrls.incoming}`,
        },
      ]
      : []),
    ...(taskPermission?.outgoingtask
      ? [
        {
          id: RouteUrls.outgoing,
          label: label.outgoing,
          link: `${RouteUrls.outgoing}`,
        },
      ]
      : []),
    ...(taskPermission?.grouptask
      ? [
        {
          id: RouteUrls.group,
          label: label.groupTask,
          link: `${RouteUrls.group}`,
        },
      ]
      : []),
    ...(taskPermission?.globaltask
      ? [
        {
          id: RouteUrls.global,
          label: label.globalTask,
          link: `${RouteUrls.global}`,
        },
      ]
      : []),
  ];
  return Arr;
};
