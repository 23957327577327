import { TableRow, TableCell, Box } from "@mui/material";
import { TableBodyType } from "DataTypes/Table";
import SwitchCase from "Utils/SwitchCase";
import { FC, Fragment } from "react";

const TblBody: FC<TableBodyType> = (props: TableBodyType) => {
  const {
    tableColumn,
    tableRow,
    tableAction,
    cellType,
    verticalAlign,
    extraRow,
    searchText,
    rowColor,
  } = props;
  return (
    <Fragment>
      {tableRow &&
        tableRow.map((rowData) => {
          const actionData = tableAction && tableAction(rowData);
          const columnData = tableColumn && tableColumn(rowData);
          return (
            <Fragment key={rowData.id}>
              <TableRow
                sx={{
                  ...sxTblRow,
                  ...(rowData?.showColorRow && {
                    backgroundColor: rowColor || "#cecece",
                  }),
                }}
              >
                {columnData.map((col, index) => {
                  if (col.subCol) {
                    return col.subCol.map((sub) => {
                      const val = rowData[sub.id];
                      return (
                        <TableCell
                          key={`${col.id}`}
                          align={col.align}
                          width={col.width}
                          sx={{
                            ...(cellType == "border" &&
                              index != columnData.length - 1 &&
                              sxCellBorder),
                          }}
                        >
                          {SwitchCase.RowSwitchCase(
                            col,
                            rowData,
                            val,
                            actionData,
                            searchText
                          )}
                        </TableCell>
                      );
                    });
                  } else {
                    const val = rowData[col.id as keyof any];
                    return (
                      <TableCell
                        key={`${col.id}`}
                        align={col.align}
                        width={col.width}
                        sx={{
                          ...(verticalAlign && {
                            verticalAlign: verticalAlign,
                          }),
                          ...(cellType == "border" &&
                            index != tableColumn.length - 1 &&
                            sxCellBorder),
                        }}
                      >
                        {SwitchCase.RowSwitchCase(
                          col,
                          rowData,
                          val,
                          actionData,
                          searchText
                        )}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
              {rowData?.expand && <Box>expanddata</Box>}
            </Fragment>
          );
        })}
      {extraRow && extraRow}
    </Fragment>
  );
};
export default TblBody;

const sxTblRow = {
  zIndex: -1,
  "& >td": {
    zIndex: -1,
    padding: 0,
    p: 1,
  },
};

const sxCellBorder = {
  borderRight: "1px solid",
  borderColor: "custom.main",
};
