import { label } from "Constant/En";

export const columnArr = [
  {
    id:1,
    label:label.programs,
    width: "18%"
  },
  {
    id:2,
    label:label.status,
    width: "18%"
  },
  {
    id:3,
    label:label.action,
    width: "23%"
  },
  {
    id:4,
    label:label.activity,
    width: "16%"
  },
  {
    id:5,
    label:label.scheduleDate,
    width: "25%"
  },
];
