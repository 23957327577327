import { ClickIconArrDataType, FormDataType } from "DataTypes/Form";
import { label } from "Constant/En";

export const DataArr: FormDataType[] = [
  {
    id: "login",
    placeholder: label.userId,
    boxType: "login-input-box",
    validatetype: "userid",
    iconId: "login-icon",
    value: "",
    required:true,
    requiredSign:true,
    readOnly: true
  },
  {
    id: "password",
    placeholder: "New Password",
    boxType: "login-input-box",
    type: "password",
    validatetype: "password",
    iconId: "password-icon",
    iconClick: true,
    required:true,
    requiredSign:true,
  },
  {
    id: "confirm-password",
    placeholder: "Re-Enter password",
    boxType: "login-input-box",
    type: "password",
    validatetype: "password",
    iconId: "password-icon",
    iconClick: true,
    required:true,
    requiredSign:true,
  },
];

export const IconClickArr = (handlePassClick: (id: string) => void) => {
  const arr: ClickIconArrDataType[] = [
    {
      iconId: "password-icon",
      onClick: (id: string) => handlePassClick(id),
    },
  ];
  return arr;
};
