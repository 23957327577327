import { BreadArrType } from "DataTypes/Common";

export const BreadArr: BreadArrType[] = [
  {
    id: 1,
    label: "Home",
    path: "/",
  },
  {
    id: 2,
    label: "Patient Details",
    path: "",
  },
];
