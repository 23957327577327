import { Grid, Typography } from "@mui/material";
import { FormDataType } from "DataTypes/Form";
import { commonFormSwitch, ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { HandleChangeProps } from "DataTypes/Common";
import { Fragment, useEffect, useState } from "react";
import PatientApi from "Service/Patient.api";
import PatientFormatter from "Utils/Patient.Formatter";
import Format from "./Format";
import { FormatEligiblePrgmType } from "DataTypes/PatientManagement.type";
import { FormatDropdownData } from "Utils/DataFormatter";
import { HandleFormChange } from "Utils/common";
import { AppointmentFormData } from "./Data";
import Loader from "Components/Loader";
import AppointmentApi from "Service/Appointment.api";
import DateTime from "Utils/DateTime";
import Stages from "Constant/Stages";
import {
  AppointmentPatientType,
  AppointmentType,
} from "DataTypes/Services/Patient.type";

interface AppointmentModalProps {
  setForm: (data: FormDataType[]) => void;
  roleId: number | null;
  editAppointment: AppointmentType | null;
  handleSelectedPrgm: (data: FormatEligiblePrgmType | null) => void;
  handleSelectedPatient: (data: AppointmentPatientType | null) => void;
}

const AppointmentModal = ({
  setForm,
  editAppointment,
  handleSelectedPrgm,
  handleSelectedPatient,
}: AppointmentModalProps) => {
  const [formArr, setFormArr] = useState<FormDataType[]>([]);
  const [patientList, setPatientList] = useState<AppointmentPatientType[]>([]);
  const [prgmArr, setPrgmArr] = useState<FormatEligiblePrgmType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPatientList();
  }, []);

  const fetchPatientList = async () => {
    const newFormData: FormDataType[] = JSON.parse(
      JSON.stringify(AppointmentFormData(editAppointment ? true : false))
    );
    const res = await AppointmentApi.PatientList({
      offset: 0,
      limit: 1000,
    });
    let patientArr: AppointmentPatientType[] = [];
    if (res?.success) {
      patientArr = Format.AppointmentPatientList(res?.data);
      setPatientList(patientArr);
      newFormData[0].dropArr = FormatDropdownData(
        patientArr,
        "patientId",
        "name",
        "patientId"
      );
    }
    if (editAppointment) {
      const findPatient = patientArr.find(
        (el) => el.patientId == editAppointment.patientId
      );
      if (findPatient) handleSelectedPatient(findPatient);
      const prgmRes = await PatientApi.eligiblePrgmList({
        id1: editAppointment.patientId,
      });
      if (prgmRes?.success) {
        let newArr = PatientFormatter.FormatEligiblePrgmRes(prgmRes?.data);
        newArr = newArr.filter((el1) => {
          if (el1.careplanState != Stages.CPAssignment) {
            if (el1.abbrevation == "TCM") {
              if (
                el1.status == "Enrolled" &&
                el1.careplanState != Stages.Recurring
              )
                return el1;
            } else return el1;
          }
        });
        newFormData[1].dropArr = FormatDropdownData(
          newArr,
          "eligibleProgramId",
          "careplanActivity",
          "careplanActivity"
        );
        setPrgmArr(newArr);
        const findPrgm = newArr.find(
          (el) => el.careplanActivity == editAppointment.activity
        );
        if (findPrgm && editAppointment.activity) handleSelectedPrgm(findPrgm);
        newFormData[0].dropValue = editAppointment.patientId;
        newFormData[0].readOnly = true;
        newFormData[1].dropValue = newFormData[1].value =
          editAppointment.activity;
        newFormData[1].readOnly = true;
        newFormData[2].dropValue = newFormData[2].value =
          editAppointment.practice;
        newFormData[3].dropValue = newFormData[3].value =
          editAppointment.provider || findPrgm?.providerNameDes;
        const date = editAppointment.appointmentTimzone
          ? DateTime.AddHoursUTCToLocalDateTime(
            DateTime.HoursTimeZoneSwitch(editAppointment.appointmentTimzone),
            editAppointment.datetime
          )
          : DateTime.UTCToLocalDateTime(editAppointment.datetime);
        newFormData[4].dropValue = newFormData[4].value = date;
        newFormData[4].minVal = `${DateTime.GetYear(date)}-${DateTime.GetMonth(
          date
        )}-01T00:00`;
        newFormData[5].dropValue = newFormData[5].value =
          editAppointment.appointmentTimzone;
        ///As per the task requirement 10450
        // newFormData[5].readOnly = true;
        newFormData[6].value = editAppointment.notes;
      }
    }
    setForm(newFormData);
    setFormArr(newFormData);
    setLoading(false);
  };

  const handleChange: (props: HandleChangeProps) => void = async (props) => {
    const { id, value } = props;
    const newFormData: FormDataType[] = JSON.parse(JSON.stringify(formArr));
    if (id == "patient") {
      const find = patientList.find((el) => el.patientId == value);
      if (find && value) {
        handleSelectedPatient(find);
        newFormData[2].value = find.practice;
        newFormData[5].value = newFormData[5].dropValue = find.timezone;
        const prgmRes = await PatientApi.eligiblePrgmList({
          id1: find.patientId,
        });
        if (prgmRes?.success) {
          let newArr = PatientFormatter.FormatEligiblePrgmRes(prgmRes?.data);
          newArr = newArr.filter((el1) => {
            if (el1.careplanState != Stages.CPAssignment) {
              if (el1.abbrevation == "TCM") {
                if (
                  el1.status == "Enrolled" &&
                  el1.careplanState != Stages.Recurring
                )
                  return el1;
              } else return el1;
            }
          });
          newFormData[1].dropArr = FormatDropdownData(
            newArr,
            "eligibleProgramId",
            "careplanActivity",
            "careplanActivity"
          );
          newFormData[1].value = newFormData[1].dropValue = "";
          setPrgmArr(newArr);
          handleSelectedPrgm(null);
        } else {
          newFormData[1].dropArr = [];
          newFormData[1].value = newFormData[1].dropValue = "";
          setPrgmArr([]);
          handleSelectedPrgm(null);
        }
      } else {
        newFormData[2].value = "";
        newFormData[3].value = "";
        newFormData[1].dropArr = [];
        newFormData[1].value = newFormData[1].dropValue = "";
        setPrgmArr([]);
        handleSelectedPrgm(null);
        handleSelectedPatient(null);
      }
    }
    if (id == "appointment-type") {
      const findPrgm = prgmArr.find((el) => el.careplanActivity == value);
      if (findPrgm && value) {
        handleSelectedPrgm(findPrgm);
        newFormData[3].value = findPrgm.providerNameDes;
      } else {
        handleSelectedPrgm(null);
        newFormData[3].value = "";
      }
    }
    const newArr = HandleFormChange(props, newFormData);
    setFormArr(newArr);
    setForm(newArr);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <Grid container rowSpacing={1} columnSpacing={2}>
            {formArr.map((el: FormDataType) => {
              return (
                <Grid
                  key={el.id}
                  item
                  {...(el.boxSize && ControlledBoxSizeSwitch(el.boxSize))}
                >
                  {commonFormSwitch({
                    formObj: el,
                    onChange: handleChange,
                  })}
                </Grid>
              );
            })}
          </Grid>
          {!editAppointment && (
            <Typography variant="subtitle1" mt={1}>
              Note: Any, previously created appointments for the selected
              appointment type will get closed.
            </Typography>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
export default AppointmentModal;
