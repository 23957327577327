const sxWhiteCard = {
  backgroundColor: "common.white",
  p: 2,
  boxShadow: "0px 0px 6px #00000029",
  borderRadius: 1.25,
};

const sxWhiteBox = {
  backgroundColor: "common.white",
  boxShadow: "0px 0px 6px #00000029",
  borderRadius: 1.25,
};

const sxWhiteBoxWithNavbar = {
  backgroundColor: "common.white",
  boxShadow: "0px 0px 6px #00000029",
  borderBottomLeftRadius: 1.25,
  borderBottomRightRadius: 1.25,
};

const sxProgramCard = {
  backgroundColor: "common.white",
  px: 1,
  py: 0.5,
  boxShadow: "0px 0px 6px #00000029",
  fontSize: "0.8rem",
  borderRadius: 1.25,
  width: "fit-content",
};

const sxRow = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const sxRowCenter = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
};
const sxContentLeft = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
};
const sxContentLeftWithoutAlignCenter = {
  display: "flex",
  flexDirection: "row",

  justifyContent: "flex-start",
};
const sxFlexDirectionColumn = {
  display: "flex",
  flexDirection: "column",
};
const sxColumnCenter = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const sxHeading = {
  fontSize: "1.2rem",
  fontWeight: "bold",
};
const menuLinkBorder = {
  position: "relative",
  ":after": {
    content: "''",
    display: "block",
    margin: "auto",
    height: "2px",
    width: "0px",
    background: "transparent",
    transition: " width .5s ease, background-color .5s ease",
    position: "absolute",
    top: "75%",
  },
  ":hover": {
    "&:after": {
      width: "100%",
      backgroundColor: "textHover.main",
    },
    "& >svg": {
      color: "textHover.main",
    },
    "& >div": {
      "& >p": {
        color: "textHover.main",
      },
    },
  },
};

const sxSelect = {
  "&:after": {
    width: "100%",
    backgroundColor: "primary.main",
  },
  "& >svg": {
    path: {
      fill: "primary.main",
    },
  },
  "& >div": {
    "& >p": {
      color: "primary.main",
      fontWeight: "bold",
    },
  },
};

const smallIconFont = {
  fontSize: "0.9rem",
};

const largeIconFont = {
  fontSize: "1rem",
};

const accordianExpand = {
  borderRadius: "50px",
  backgroundColor: "common.main",
  borderColor: "#000",
  border: "1px solid #c3c3c3",
};
const OverlayBox = {
  transition: "all 0.6s",
  backgroundColor: "secondary.main",
  position: "absolute",
  height: "9em",
  width: "9em !important",
  right: "-4.5em",
  zIndex: "1",
  borderRadius: "50%",
  top: "-4.5em",
  "@media screen and (max-width:1580px)": {
    height: "8.5em",
    width: "9em!important",
  },
};

const sxTiles = {
  transition: "all 0.6s",
  display: "block",
  padding: "1em 0em",
  position: "relative",
  overflow: "hidden",
  borderRadius: "2rem",
  boxShadow: "-5px 9px 6px 0px #ddd !important",
  "& >div": {
    margin: "0 auto",
  },
  h1: {
    textAlign: "right",
    top: "0.6em",
    right: "0.9em",
    position: "absolute",
    zIndex: "9",
    color: "common.white",
    transition: "all 0.6s",
    fontSize: "1.4rem",
    "@media screen and (max-width:1580px)": {
      top: "0.3em",
      right: "0.6em",
    },
  },
  h4: {
    marginTop: 2,
    fontSize: "0.9rem",
    "@media screen and (max-width:1580px)": {
      fontSize: "0.8rem",
    },
  },
  svg: {
    width: "2em",
    paddingTop: 2.5,
    path: {
      fill: "#002255",
    },
  },
  "& >p": {
    fontSize: "0.9rem",
    "@media screen and (max-width:1580px)": {
      fontSize: "0.8rem",
    },
  },
  "&:hover": {
    transition: "all 0.6s",
    transform: "translateY(-5px)",
    h1: {
      color: "common.white",
      transition: "all 0.6s",
      "&:after": {
        backgroundColor: "active.main",
        transition: "all 0.6s",
      },
    },
    h4: {
      color: "active.main",
    },
    "& >p:first-of-type": {
      backgroundColor: "primary.main",
      transition: "all 0.6s",
    },
    "& >svg": {
      path: {
        fill: "#EE7404",
      },
    },
    p: {
      svg: {
        path: {
          fill: "#EE7404",
        },
      },
      span: {
        backgroundColor: "#EE7404",
      },
    },
  },
};

export const sxRoundIcons = {
  ...sxRowCenter,
  color: "common.white",
  p: 0,
  borderRadius: 10,
  "& >svg": {
    fontWeight: "bold",
  },
  "& path": {
    fill: "#fff",
  },
};

export const sxRoundIconsMain = {
  ...sxRoundIcons,
  cursor: "pointer",
  backgroundColor: "roundiconbg.main",
  ":hover": {
    backgroundColor: "roundiconbghover.main",
  },
};

export const sxRoundIconsMainSm = {
  ...sxRoundIconsMain,
  height: 25,
  width: 25,
  "& >svg": {
    fontSize: "1.3rem",
  },
};

export const sxRoundIconsMainMd = {
  ...sxRoundIconsMain,
  height: 30,
  width: 30,
  "& >svg": {
    fontSize: "1.3rem",
  },
};

export const sxRoundIconsSelected = {
  ...sxRoundIcons,
  backgroundColor: "primary.main",
};

export const sxRoundIconsSelectedSm = {
  ...sxRoundIconsSelected,
  height: 25,
  width: 25,
  "& >svg": {
    fontSize: "1.3rem",
  },
};

export const sxRoundIconsSelectedMd = {
  ...sxRoundIconsSelected,
  height: 30,
  width: 30,
  "& >svg": {
    fontSize: "1.3rem",
  },
};

export const sxRoundIconsDisabled = {
  ...sxRoundIcons,
  backgroundColor: "roundiconbg.main",
  opacity: "50%",
  cursor: "default",
  ":hover": {
    backgroundColor: "roundiconbg.main",
  },
};

export const sxRoundIconsDisabledSm = {
  ...sxRoundIconsDisabled,
  height: 25,
  width: 25,
  "& >svg": {
    fontSize: "1.3rem",
  },
};

export const sxRoundIconsDisabledMd = {
  ...sxRoundIconsDisabled,
  height: 30,
  width: 30,
  "& >svg": {
    fontSize: "1.3rem",
  },
};

export default {
  sxWhiteCard,
  sxWhiteBox,
  sxWhiteBoxWithNavbar,
  sxProgramCard,
  sxRow,
  sxRowCenter,
  sxContentLeft,
  sxContentLeftWithoutAlignCenter,
  sxFlexDirectionColumn,
  sxColumnCenter,
  sxHeading,
  menuLinkBorder,
  sxSelect,
  smallIconFont,
  largeIconFont,
  accordianExpand,
  sxTiles,
  sxRoundIconsMainSm,
  sxRoundIconsMainMd,
  sxRoundIconsSelectedSm,
  sxRoundIconsSelectedMd,
  sxRoundIconsDisabledSm,
  sxRoundIconsDisabledMd,
  OverlayBox,
};
