import { TimerContextType } from "DataTypes/Common";
import { ReactNode, useState, createContext, FC } from "react";
// @types.todo.ts

interface Props {
  children: ReactNode;
}

export const TimerContext = createContext<TimerContextType | null>(null);

const TimerProvider: FC<Props> = ({ children }) => {
  const [status, setStatus] = useState(1);

  return (
    <TimerContext.Provider
      value={{
        status,
        setStatus,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

export default TimerProvider;
