import { ControlledForm } from "Components";
import { TimeZoneArr } from "Constant/Arr";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";

export const DetailForm: (
  roleList: DropdownArrPropsType[],
  genderList: DropdownArrPropsType[],
  uniqueMailIds?: string[]
) => FormDataType[] = (roleList, genderList, uniqueMailIds) => {
  return [
    ControlledForm.FormData.UserIdData({ readonly: true }),
    ControlledForm.FormData.FirstNameData(),
    ControlledForm.FormData.LastNameData(),
    ControlledForm.FormData.GenderData({ dropArr: genderList }),
    ControlledForm.FormData.SpecialityData(),
    ControlledForm.FormData.DesignationData(),
    ControlledForm.FormData.RoleData({ dropArr: roleList, readonly: true }),
    ControlledForm.FormData.LicenseIdData(),
    ControlledForm.FormData.ProviderNpiData(),
    ControlledForm.FormData.PrimEmailData({ uniqueArr: uniqueMailIds }),
    ControlledForm.FormData.MobNoData(),
    ControlledForm.FormData.OfficeNoData(),
    ControlledForm.FormData.DirectNoData(),
    ControlledForm.FormData.DobData(),
    ControlledForm.FormData.AddressData(),
    ControlledForm.FormData.CityNameData(),
    ControlledForm.FormData.StateNameData(),
    ControlledForm.FormData.ZipcodeData(),
    ControlledForm.FormData.CountryNameData(),
    ControlledForm.FormData.TimezoneData({ dropArr: TimeZoneArr }),
  ];
};
