import { Fragment } from "react";
import { ControlledForm } from "Components";
import ImgPath from "Constant/Imgs";
import { CommonFormSwitchType } from "DataTypes/Form";
import { Checkbox, Typography } from "@mui/material";
import { columnType } from "DataTypes/Table";
import { objectValues } from "DataTypes/Services/Index.type";
import { ChipDropDownProps } from "DataTypes/Master";
import { CheckStringEmpty } from "./common";

export const commonFormSwitch: (props: CommonFormSwitchType) => JSX.Element = (
  props
) => {
  const {
    formObj: data,
    onChange: handleChange,
    iconClickArr: IconClickArr,
  } = props;
  const inputProps: any = {
    id: data.id,
    label: data.label,
    placeholder: data.placeholder,
    type: data.type,
    defaultValue: typeof data.defaultValue == "string" ? data.defaultValue : "",
    value: typeof data.value == "string" ? data.value : "",
    error: data.error,
    errorText: data.errorText,
    iconId: data.iconId,
    required: data.required,
    multiline: data.multiline,
    multilineRows: data.multilineRows,
    readOnly: data.readOnly,
    infoText: data.infoText,
  };
  if (data.maxLength) {
    inputProps.maxLength = data.maxLength;
  }
  const singledropArrProp = {
    id: data.id,
    label: data.label,
    placeholder: data.placeholder,
    dropArr: data.dropArr ? data.dropArr : [],
    error: data.error,
    errorText: data.errorText,
    required: data.required,
    dropValue:
      typeof data?.dropValue == "string" || typeof data?.dropValue == "number"
        ? data?.dropValue
        : "",
    onClick: (value: string | number | objectValues) => {
      handleChange && handleChange({ value: value, id: data?.id });
    },
    readOnly: data.readOnly,
  };

  const multidropArrProp = {
    id: data.id,
    label: data.label,
    placeholder: data.placeholder,
    dropArr: data.dropArr ? data.dropArr : [],
    error: data.error,
    errorText: data.errorText,
    required: data.required,
    dropValue: Array.isArray(data?.dropValue) ? data?.dropValue : [],
    onClick: (value: (string | number)[]) => {
      handleChange && handleChange({ value: value, id: data?.id });
    },
    readOnly: data.readOnly,
    disableSelectAll: data.disableSelectAll,
    disableArr: data.disableArr,
    width: data?.width,
  };

  const ChipDropProp: ChipDropDownProps = {
    id: data.id,
    label: data.label,
    placeholder: data.placeholder || "",
    dropArr: data.dropArr ? data.dropArr : [],
    chipLabel: data?.chipLabel || "",
    selectedVals: Array.isArray(data?.dropValue) ? data?.dropValue : [],
    handleSelect: (value: (string | number)[]) => {
      handleChange && handleChange({ value: value, id: data?.id });
    },
    handleRemoveChip: (value: string | number | null) => console.log(value),
    required: data?.required,
    readOnly: data.readOnly,
    error: data.error,
    errorText: data.errorText,
    width: data?.width,
  };

  const dateTimeProps = {
    id: data?.id,
    label: data?.label,
    placeholder: data?.placeholder,
    type: data?.type,
    error: data?.error,
    errorText: data?.errorText,
    required: data?.required,
    readOnly: data?.readOnly,
    value: CheckStringEmpty(data?.value),
    minVal: data?.minVal,
    maxVal: data?.maxVal,
  };

  const iconClickObj =
    IconClickArr && IconClickArr.find((el) => el.iconId == data.iconId);
  switch (data.boxType) {
    case "input-box":
      return (
        <ControlledForm.Input
          {...inputProps}
          {...(handleChange && {
            onChange: (value) =>
              handleChange({
                value: typeof value == "string" ? value : "",
                id: data.id,
              }),
          })}
          {...(data.iconClick && {
            iconClickHandler: (id: string) => iconClickObj?.onClick(id),
          })}
        />
      );
    case "text":
      return (
        <Fragment>
          <Typography
            variant="subtitle1"
            sx={{
              my: 0.5,
            }}
          >
            {data?.label}
          </Typography>
          <Typography sx={{ fontSize: "0.85rem" }}>
            {typeof data?.value == "string" ? data?.value : ""}
          </Typography>
          ``
        </Fragment>
      );
    case "view-only-text":
      return (
        <Fragment>
          <Typography
            fontWeight="bold"
            variant="body2"
            sx={{
              my: 0.5,
            }}
          >
            {data?.label}
          </Typography>
          <Typography variant="subtitle1">
            {typeof data?.value == "string" ? data?.value : ""}
          </Typography>
        </Fragment>
      );
    case "login-input-box":
      return (
        <ControlledForm.Input
          {...inputProps}
          {...(handleChange && {
            onChange: (value) =>
              handleChange({
                value: typeof value == "string" ? value : "",
                id: data.id,
              }),
          })}
          {...(data.iconClick && {
            iconClickHandler: (id: string) => iconClickObj?.onClick(id),
          })}
          sxProps={{ px: 1, py: 1, fontSize: "1rem" }}
        />
      );
    case "chip-drop":
      return <ControlledForm.ChipDropDown {...ChipDropProp} />;
    case "single-select-drop":
      return <ControlledForm.SingleSelectDrop {...singledropArrProp} />;
    case "multi-select-drop":
      return <ControlledForm.MultiSelectDrop {...multidropArrProp} />;
    case "multi-select-search-drop":
      return <ControlledForm.MultiSelectSearchDrop {...multidropArrProp} />;
    case "mui-date":
      return (
        <ControlledForm.MuiDatePicker
          {...dateTimeProps}
          {...(handleChange && {
            onChange: (value) =>
              handleChange({
                value: CheckStringEmpty(value),
                id: data.id,
              }),
          })}
        />
      );
    case "mui-datetime":
      return (
        <ControlledForm.DateTimePicker
          {...dateTimeProps}
          {...(handleChange && {
            onChange: (value) =>
              handleChange({
                value: CheckStringEmpty(value),
                id: data.id,
              }),
          })}
        />
      );
    case "empty-block":
      return <></>;
    default:
      return <ControlledForm.Input {...inputProps} />;
  }
};

export const InputImgSwitch: (iconId: string) => JSX.Element = (iconId) => {
  switch (iconId) {
    case "login-icon":
      return <img src={ImgPath.IDCard} alt="" />;
    case "password-icon":
      return <img src={ImgPath.Lock} alt="" />;
    case "search":
      return <ImgPath.SearchIcon color="primary" />;
    case "dropdown":
      return <ImgPath.ArrowDropDown color="primary" />;
    case "dropup":
      return <ImgPath.ArrowDropUp color="primary" />;
    case "calendar-icon":
      return <ImgPath.CalendarIcon color="primary" />;
    case "clock-icon":
      return <ImgPath.AddTimeLogIcon color="primary" />;
    case "info-icon":
      return <ImgPath.InfoOutlined color="primary" />;
    default:
      return <ImgPath.ArrowDropDown color="primary" />;
  }
};

export const ControlledBoxSizeSwitch: (size?: number) => {
  md: number;
  lg: number;
  xl: number;
} = (size) => {
  switch (size || 12) {
    case 3:
      return { md: 4, lg: 3, xl: 2 };
    case 4:
      return { md: 6, lg: 4, xl: 3 };
    case 6:
      return { md: 6, lg: 6, xl: 6 };
    case 12:
      return { md: 12, lg: 12, xl: 6 };
    case 24:
      return { md: 12, lg: 12, xl: 12 };
    default:
      return { md: 4, lg: 3, xl: 2 };
  }
};

export const ColumnSwitch: (colData: columnType) => JSX.Element = (
  colData: columnType
) => {
  switch (colData.colType) {
    case "checkbox":
      return (
        <Checkbox
          title="Select All"
          sx={{
            p: 0,
            "& path": {
              color: "common.white",
            },
          }}
          size="small"
          checked={colData.isColumnChecked}
          onClick={() =>
            colData?.columnClick ? colData?.columnClick() : console.log("test")
          }
        />
      );
    default:
      return (
        <Typography variant="body2" color="common.white">
          {colData.label}
        </Typography>
      );
  }
};
