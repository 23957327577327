import { TaskListType } from "DataTypes/Services/Tasks";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "./common";
import DateTime from "./DateTime";

const list: (data: any) => TaskListType[] = (data) => {
  const checkedArr = CheckArr(data);
  return checkedArr.map((el) => {
    const assigneeName = CheckStringEmpty(el?.assigneeName);
    const assigneeDes = CheckStringEmpty(el?.assigneeDesignation);
    const assignee = assigneeName
      ? assigneeName + `${assigneeDes ? ", " + assigneeDes : ""}`
      : "";
    const userName = CheckStringEmpty(el?.userName);
    const userDes = CheckStringEmpty(el?.userDesignation);
    const user = userName ? userName + `${userDes ? ", " + userDes : ""}` : "";
    return {
      assignee,
      assigneeId: CheckNumber(el?.assigneeUserId),
      datetime: DateTime.ValidDate(el?.createdAt),
      createdby: user,
      createdbyId: CheckNumber(el?.createdBy),
      id: CheckNumber(el?.id),
      note: CheckStringEmpty(el?.notes),
      name: CheckStringEmpty(el?.patientName),
      patientId: CheckStringId(el?.patientId),
      priority: CheckStringEmpty(el?.priority),
      source: CheckStringEmpty(el?.source),
      status: CheckStringEmpty(el?.status),
      subject: CheckStringEmpty(el?.subject),
      targetDate: DateTime.ValidDate(el?.targateDate),
      taskDescription: CheckStringEmpty(el?.taskDescription),
      tasktype: CheckStringEmpty(el?.type),
    };
  });
};

export default { list };
