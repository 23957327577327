import { BillingType } from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";
import {
  CheckArr,
  CheckBoolean,
  CheckNumber,
  CheckStringEmpty,
} from "Utils/common";

const BillingList: (res: any) => BillingType[] = (res) => {
  const CheckedArr = CheckArr(res);
  return CheckedArr.map((el) => {
    const clinicTeamSec = CheckNumber(el?.clinicTeamSec);
    const cvTeamSec = CheckNumber(el?.cvTeamSec);
    const clinicProviderSec = CheckNumber(el?.clinicProviderSec);
    const cmFirstName = CheckStringEmpty(el?.caremanagerFirstName);
    const cmLastName = CheckStringEmpty(el?.caremanagerLastName);
    const cmDesig = CheckStringEmpty(el?.caremanager_designation);
    const cmName = `${cmFirstName} ${cmLastName}`.trim();
    const clinicalStaff = `${
      cmName ? cmName + (cmDesig ? ", " + cmDesig : "") : ""
    }`;
    const proFirstName = CheckStringEmpty(el?.providerFirstName);
    const proLastName = CheckStringEmpty(el?.providerLastName);
    const proDesig = CheckStringEmpty(el?.provider_designation);
    const proName = proFirstName
      ? proFirstName +
        `${proLastName ? " " + proLastName : ""}` +
        `${proDesig ? ", " + proDesig : ""}`
      : "";
    const ptFirstName = CheckStringEmpty(el?.patientFirstName);
    const ptMiddleName = CheckStringEmpty(el?.patientMiddleName);
    const ptLastName = CheckStringEmpty(el?.patientLastName);
    const ptName = `${ptFirstName}${ptMiddleName ? ` ${ptMiddleName}` : ""}${
      ptLastName ? ` ${ptLastName}` : ""
    }`.trim();
    const totalTimeSec = CheckNumber(el?.totalTimeSec);
    return {
      careteamclinictime: Math.floor((clinicTeamSec || 0) / 60),
      careteamtime: Math.floor((cvTeamSec || 0) / 60),
      clinicalStaff,
      codebilled: CheckStringEmpty(el?.cptCode),
      dob: DateTime.ValidDate(el?.patientDob),
      id: CheckNumber(el?.billingId),
      name: ptName,
      program: CheckStringEmpty(el?.programAbbreviation),
      provider: proName,
      providerclinictime: Math.floor((clinicProviderSec || 0) / 60),
      scopeme: CheckBoolean(el?.scopeOfServiceMet),
      status: CheckStringEmpty(el?.status),
      totaltime: Math.floor((totalTimeSec || 0) / 60),
    };
  });
};

export default { BillingList };
