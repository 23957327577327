import { FC } from "react";
import ControlledTable from "Components/ControlledTable";
import { VisitsTabelCol } from "./Data";
import { TableLayout } from "Layouts";
import { Box } from "@mui/material";
import { VisitSearchType } from "DataTypes/Services/Redox.type";

const Visits: FC<{ data: VisitSearchType[]; loading: boolean }> = ({
  data,
  loading,
}) => {
  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => VisitsTabelCol}
      tableRow={data}
    />
  );

  return (
    <Box sx={{ ...sxMain }}>
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(VisitsTabelCol)}
        height={"95%"}
        loading={loading}
      />
    </Box>
  );
};

export default Visits;

const sxMain = {
  height: "100%",
  overflow: "auto",
  px: 2,
  "&>div":{
    pt: 2
  }
};
