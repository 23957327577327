import { FC, Fragment } from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import CstmBtn from "Components/CstmBtn";
import ImgPath from "Constant/Imgs";
import { AnsBoxType } from "DataTypes/Master";
import { SampleInputObject } from "./Data";
import { commonFormSwitch } from "Utils/SwitchCases";
import { HandleChangeProps } from "DataTypes/Common";
import { CheckStringEmpty } from "Utils/common";
import SwitchCase from "Utils/SwitchCase";

const AnsBox: FC<AnsBoxType> = (props) => {
  const { ansType, optionArr, handleChangeOptionType, handleDelete } = props;

  const handleChange: (props: HandleChangeProps, id: number) => void = (
    props,
    id
  ) => {
    const newArr = optionArr.map((el) => {
      if (el.id == id) {
        const find = optionArr.find(
          (optEl) => optEl.formObj.value == props.value && optEl.id != id
        );
        if (find) el.formObj.notUnique = true;
        else el.formObj.notUnique = false;
        el.formObj.value = el.formObj.dropValue = props.value;
        const temp = SwitchCase.FormValidation(el.formObj);
        el.formObj.error = temp.error;
        el.formObj.errorText = temp.errorText;
        el.formObj.value = props.value;
      }
      return el;
    });
    handleChangeOptionType(newArr);
  };

  const handleDiscard = (editId: number) => {
    const newArr = optionArr.map((el) => {
      if (el.id == editId) {
        el.formObj.readOnly = true;
        el.formObj.value = JSON.parse(JSON.stringify(el.lastVal));
        el.formObj.error = false;
        el.formObj.errorText = "";
        el.lastVal = "";
      }
      return el;
    });
    handleChangeOptionType(newArr);
  };

  const handleEdit = (editId: number) => {
    const newArr = optionArr.map((el) => {
      if (el.id == editId) {
        el.formObj.readOnly = false;
        el.lastVal = JSON.parse(
          JSON.stringify(CheckStringEmpty(el.formObj.value))
        );
      }
      return el;
    });
    handleChangeOptionType(newArr);
  };

  const handleSave = (saveOptionId: number) => {
    const newArr = optionArr.map((el) => {
      if (el.id == saveOptionId && !el.formObj.error) {
        el.formObj.readOnly = true;
        el.lastVal = "";
      }
      return el;
    });
    handleChangeOptionType(newArr);
  };

  const handleAddAnother = () => {
    handleChangeOptionType([
      ...optionArr,
      {
        id: optionArr[optionArr.length - 1].id + 1,
        optionId: null,
        required: false,
        formObj: SampleInputObject(
          `Option ${optionArr[optionArr.length - 1].id + 1}`,
          false
        ),
        lastVal: `Option ${optionArr[optionArr.length - 1].id + 1}`,
      },
    ]);
  };

  const handleCheck = (checkedId: number) => {
    const newArr = optionArr.map((el) => {
      if (el.id == checkedId) el.required = !el.required;
      else {
        if (ansType == "singleSelect") el.required = false;
      }
      return el;
    });
    handleChangeOptionType(newArr);
  };

  return (
    <Fragment>
      <Box sx={{ ...CommonStyle.sxRow, py: 0.5 }}>
        <Typography width="40%" fontWeight={"bold"} variant="body2">
          Options :
        </Typography>
        <Typography width="8%" fontWeight={"bold"} variant="body2">
          Required :
        </Typography>
        <Typography width="10%" fontWeight={"bold"} variant="body2">
          Actions :
        </Typography>
      </Box>
      <Box mb={1}>
        {optionArr.map((el, index) => {
          return (
            <Box key={el.id} sx={{ ...CommonStyle.sxRow, py: 0.5 }}>
              <Box width="40%" sx={{ ...CommonStyle.sxRow }}>
                <Typography
                  width="5%"
                  sx={{ mr: 1 }}
                  variant="subtitle1"
                  fontWeight={"bold"}
                >
                  {index + 1}.
                </Typography>
                <Box width="90%">
                  {commonFormSwitch({
                    formObj: el.formObj,
                    onChange: (props) => handleChange(props, el.id),
                  })}
                </Box>
              </Box>
              <Box width="8%">
                <Checkbox
                  checked={el.required}
                  onClick={() => handleCheck(el.id)}
                  color="secondary"
                  title={"Required"}
                  sx={{ p: 0 }}
                />
              </Box>
              <Box
                width="20%"
                sx={{ ...CommonStyle.sxRow, "&>div": { mr: 0.5 } }}
              >
                {el.formObj.readOnly ? (
                  <Box
                    sx={{ ...CommonStyle.sxRoundIconsMainMd }}
                    onClick={() => handleEdit(el.id)}
                  >
                    <ImgPath.PencilIcon titleAccess="Edit" />
                  </Box>
                ) : (
                  <Fragment>
                    <Box
                      sx={{ ...CommonStyle.sxRoundIconsMainMd }}
                      onClick={() => handleSave(el.id)}
                    >
                      <ImgPath.SaveIcon titleAccess="Save Changes" />
                    </Box>
                    <Box
                      sx={{ ...CommonStyle.sxRoundIconsMainMd }}
                      onClick={() => handleDiscard(el.id)}
                    >
                      <ImgPath.CloseIcon titleAccess="Discard Changes" />
                    </Box>
                  </Fragment>
                )}
                {optionArr.length > 2 && (
                  <Box
                    sx={{ ...CommonStyle.sxRoundIconsMainMd }}
                    onClick={() => handleDelete(el)}
                  >
                    <ImgPath.DeleteOutline titleAccess="Delete Option" />
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
      <CstmBtn
        label="Add Another Option"
        onClick={() => {
          handleAddAnother();
        }}
        width="15%"
      />
    </Fragment>
  );
};

export default AnsBox;
