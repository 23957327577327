import { CommonStyle, ControlledTable } from "Components";
import { TableLayout } from "Layouts";
import { FC, useContext, useEffect, useState } from "react";
import { MasterProblemICDBreadArr, TableCol } from "./Data";
import { Box } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ProblemApi from "Service/Problem.api";
import { HeadContext } from "Context/HeadContext";
import { HeadContextType } from "DataTypes/Common";
import { ConvertStringToNum } from "Utils/common";
import ProbFormatter from "Utils/Prob.Formatter";
import { IcdCodeType } from "DataTypes/Services/Problem.type";
import { RouteUrls } from "Constant/RouteUrls";

const ICDCodes: FC = () => {
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const codeId = searchParams.get("id");
  const [codeList, setCodeList] = useState<IcdCodeType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setBreadArr(MasterProblemICDBreadArr(location?.state?.problemName));
    if (codeId) getCodeList();
    else navigate(`../${RouteUrls.list}`);
  }, [codeId]);

  const getCodeList = async () => {
    const res: any = await ProblemApi.icdCodeList({
      id1: ConvertStringToNum(codeId),
    });
    if (res?.success) setCodeList(ProbFormatter.IcdCodes(res?.data));
    setLoading(false);
  };
  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={codeList}
      cellType="border"
    />
  );

  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox, px: 2 }}>
      <Box height="4%"></Box>
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol)}
        height={"93%"}
        loading={loading}
      />
    </Box>
  );
};
export default ICDCodes;
