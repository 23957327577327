import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { actionArr, NavArr, TableCol } from "./Data";
import { label } from "Constant/En";
import TabSection from "./TabSection";
import AppointmentSection from "./AppointmentSection";
import { CommonStyle, ControlledTable } from "Components";
import { TableLayout } from "Layouts";
import { useDispatch, useSelector } from "react-redux";
import { UserInitialStateType } from "DataTypes/Redux";
import PatientApi from "Service/Patient.api";
import { objectValues } from "DataTypes/Services/Index.type";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { RouteUrls } from "Constant/RouteUrls";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import DateTime from "Utils/DateTime";
import Format from "./Format";
import AppointmentApi from "Service/Appointment.api";
import PatientFormatter from "Utils/Patient.Formatter";
import Stages from "Constant/Stages";
import { AppointmentType } from "DataTypes/Services/Patient.type";

export const getScheduledData: (props: {
  fromDate?: string;
  toDate?: string;
}) => Promise<AppointmentType[]> = async ({ fromDate, toDate }) => {
  const payload = {
    ...(fromDate ? { dateFrom: fromDate } : {}),
    ...(toDate ? { dateTo: toDate } : {}),
  };
  const response = await AppointmentApi.Search({ data: payload });
  if (response?.success) {
    return Format.AppointmentList(response?.data);
  } else return [];
};

const HomeScreen: FC = () => {
  const { myPermission, myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permission = myPermission?.dashlinks;
  const [Todayrows, setTodayRows] = useState<AppointmentType[]>([]);
  const [overdueRows, setOverdueRows] = useState<AppointmentType[]>([]);
  const [tilesCount, setTilesCount] = useState<objectValues>();
  const [todayLoading, setTodayLoading] = useState(true);
  const [overdueLoading, setOverdueLoading] = useState(true);

  useEffect(() => {
    updateSchedule();
    fetchTilesCount();
  }, []);

  const updateSchedule: () => void = () => {
    permission?.todayschedule && setTodaysScheduleData();
    permission?.overdueschedule && setOverDueScheduleData();
  };

  const setTodaysScheduleData: () => void = async () => {
    const currentDate = DateTime.CurrentDate();
    const fromDate = DateTime.ToUTCDateTime(currentDate);
    const newDate = DateTime.AddSubMin(fromDate, 1439);
    const toDate = DateTime.ToDbUtcFormat(newDate);
    const formattedData = await getScheduledData({ fromDate, toDate });
    setTodayRows(formattedData);
    setTodayLoading(false);
  };

  const setOverDueScheduleData: () => void = async () => {
    const currentDate = DateTime.CurrentDate();
    const toDate = DateTime.ToUTCDateTime(currentDate);
    const formattedData = await getScheduledData({ toDate });
    setOverdueRows(formattedData);
    setOverdueLoading(false);
  };

  const handlePatientDetails: (data: AppointmentType) => void = (data) => {
    navigate(
      `/${RouteUrls.hs}/${RouteUrls.patient}/${data.patientId}/${RouteUrls.details}`
    );
  };

  const handleChart: (data: AppointmentType) => void = (data) => {
    navigate(
      `/${RouteUrls.hs}/${RouteUrls.patient}/${data.patientId}/${RouteUrls.clinicalSummary}`
    );
  };

  const handleCallDetails: (data: AppointmentType) => void = (data) => {
    const recId = data.careplanRecurringId;
    const careId = data.careplanId;
    const isTcm = data.activity.slice(0, 3) == "TCM" ? true : false;
    const baseRoute = "..";
    let route = "";
    if (recId) {
      if (isTcm) {
        route = `${RouteUrls.program}`;
      } else {
        route = `${RouteUrls.carePlan}/${careId}/${RouteUrls.recurring}/${recId}/${RouteUrls.careQuestion}`;
      }
    } else if (careId) {
      if (isTcm) {
        route = `${RouteUrls.tcm}/${careId}/${PatientFormatter.StateRouteSwitch(
          data.careplanState
        )}`;
      } else {
        route = `${
          RouteUrls.carePlan
        }/${careId}/${PatientFormatter.StateRouteSwitch(data.careplanState)}`;
      }
    } else route = `${RouteUrls.program}`;
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        callDefaultRoute: `${baseRoute}/${route}`,
        careplanId: careId,
        careplanRecurringId: recId,
        ...(data.programId ? { programId: data.programId } : {}),
        showLogTime: data.careplanState == Stages.CPSignOff ? false : true,
        ...(!recId ? { showSaveBtn: true } : {}),
        showSubmitBtn: true,
      })
    );
    navigate(
      `/${RouteUrls.hs}/${RouteUrls.patient}/${data.patientId}/${RouteUrls.callLog}`
    );
  };

  const handleTask: (data: AppointmentType) => void = (data) => {
    navigate(
      `/${RouteUrls.hs}/${RouteUrls.patient}/${data.patientId}/${RouteUrls.task}/${RouteUrls.add}`
    );
  };

  const TodayScheduleTblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol(myInfo?.roleName)}
      tableRow={Todayrows}
      tableAction={(data) =>
        actionArr(
          () => handleChart(data),
          () => handleCallDetails(data),
          () => handlePatientDetails(data),
          () => handleTask(data)
        )
      }
    />
  );

  const OverdueTblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol(myInfo?.roleName)}
      tableRow={overdueRows}
      tableAction={(data) =>
        actionArr(
          () => handleChart(data),
          () => handleCallDetails(data),
          () => handlePatientDetails(data),
          () => handleTask(data)
        )
      }
    />
  );

  const setData: (
    newObj: objectValues,
    result: objectValues,
    field: string
  ) => any = (newObj, result, field) => {
    if (result.success) {
      newObj[field] = result.data;
      return newObj;
    }
  };

  const fetchDataFromApi: (apiFunction: any) => any = async (apiFunction) => {
    const response = await apiFunction();
    return response;
  };

  const fetchTilesCount: () => void = async () => {
    const NewObj = {};
    const apiCallData = [
      {
        apiFunction: PatientApi.eligiblePatientCount,
        responseKey: "eligible",
      },
      {
        apiFunction: PatientApi.pendingAssignmentCount,
        responseKey: "pendingAssignment",
      },
      {
        apiFunction: PatientApi.enrolledPatientCount,
        responseKey: "enrolledPatient",
      },
      {
        apiFunction: PatientApi.pendingPlanApprovalCount,
        responseKey: "pendingPlanApproval",
      },
      {
        apiFunction: PatientApi.pendingContinuousCareCount,
        responseKey: "pendingContinuousCare",
      },
      {
        apiFunction: PatientApi.completedContinuousCareCount,
        responseKey: "completedContinuousCare",
      },
      {
        apiFunction: PatientApi.patientInactiveCount,
        responseKey: "patientInactive",
      },
      {
        apiFunction: PatientApi.taskCount,
        responseKey: "tasking",
      },
      {
        apiFunction: PatientApi.billingCount,
        responseKey: "billing",
      },
      {
        apiFunction: PatientApi.myPatientCount,
        responseKey: "myPatient",
      },
      {
        apiFunction: PatientApi.onGoingCount,
        responseKey: "onGoing",
      },
    ];
    const promises = apiCallData.map(async ({ apiFunction, responseKey }) => {
      try {
        const response = await fetchDataFromApi(apiFunction);
        return setData(NewObj, response, responseKey);
      } catch (error) {
        console.error(`API call failed for ${responseKey}:`, error);
      }
    });
    await Promise.all(promises);
    setTilesCount(NewObj);
  };

  return (
    <Box display="flex" flexDirection="row" sx={{ pt: 2 }}>
      <Box
        {...(permission?.schedular
          ? { sx: { width: "75%", pr: 2 } }
          : { sx: { width: "100%" } })}
      >
        <TabSection
          navArr={NavArr(myPermission?.dashlinks, tilesCount)}
          schedular={permission?.schedular ? permission?.schedular : false}
        />
        <Box>
          {permission?.todayschedule && (
            <Box height="25%" sx={{ ...CommonStyle.sxWhiteCard, mt: 2 }}>
              <Typography variant="body1" fontWeight="bold" height="10%">
                {`${label.today} ${label.schedule}`}
              </Typography>
              <TableLayout
                tblBody={TodayScheduleTblBodyJSX}
                tblHead={ControlledTable.TblHead(TableCol(myInfo?.roleName))}
                height={"20rem"}
                loading={todayLoading}
              />
            </Box>
          )}
          {permission?.overdueschedule && (
            <Box height="25%" sx={{ ...CommonStyle.sxWhiteCard, mt: 2 }}>
              <Typography variant="body1" fontWeight="bold" height="10%">
                {`${label.overdue}  ${label.schedule}`}
              </Typography>
              <TableLayout
                tblBody={OverdueTblBodyJSX}
                tblHead={ControlledTable.TblHead(TableCol(myInfo?.roleName))}
                height={"20rem"}
                loading={overdueLoading}
              />
            </Box>
          )}
        </Box>
      </Box>
      {permission?.schedular && (
        <AppointmentSection updateSchedule={updateSchedule} />
      )}
    </Box>
  );
};
export default HomeScreen;
