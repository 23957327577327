import { FormatCPProblemType } from "DataTypes/Master";
import { CPProblemArr } from "DataTypes/Services/Careplan.type";
import { IcdCodeType } from "DataTypes/Services/Problem.type";

const CPProblems: (Arr: CPProblemArr[]) => FormatCPProblemType[] = (Arr) => {
  function getNewArr(total: FormatCPProblemType[], current: CPProblemArr) {
    const newArr: FormatCPProblemType[] = JSON.parse(JSON.stringify(total));
    const grpIndex = newArr.findIndex(
      (el) => el.problemId == current.problemId
    );
    if (grpIndex == -1) {
      newArr.push({
        problemId: current.problemId,
        icdCodes: [current.icdCode],
      });
    } else newArr[grpIndex].icdCodes.push(current.icdCode);
    return newArr;
  }
  return Arr.reduce(getNewArr, []);
};

const CreatePayload: (data: IcdCodeType[]) => any[] = (data) => {
  function getNewArr(total: any[], current: IcdCodeType) {
    const newArr: any[] = JSON.parse(JSON.stringify(total));
    const grpIndex = newArr.findIndex((el) => el.id == current.problemId);
    if (grpIndex == -1) {
      newArr.push({
        id: current.problemId,
        icdCodes: [{ id: current.id }],
      });
    } else newArr[grpIndex].icdCodes.push({ id: current.id });
    return newArr;
  }
  return data.reduce(getNewArr, []);
};

export default { CPProblems, CreatePayload };
