import { ApiServiceProps } from "DataTypes/Services/Index.type";
import { post } from "Service";

const listApplicationSettings: (payload: ApiServiceProps) => any = ({
  data,
}) => {
  const request = {
    subUrl: "gobalsettings/search",
    data: {
      data: data,
    },
  };
  return post(request);
};

const UpdateAppSettings: (payload: ApiServiceProps) => any = ({
  data,
  id1,
}) => {
  const request = {
    subUrl: `gobalsettings/${id1}/update`,
    data: {
      data: data,
    },
  };
  return post(request);
};

const saveRedoxSettings: (payload: any) => any = ({ data }) => {
  const request = {
    subUrl: "setting/create",
    isRedox: true,
    data: {
      data: data,
    },
  };
  return post(request);
};

const listRedoxSettings: (payload: ApiServiceProps) => any = ({ data }) => {
  const request = {
    subUrl: "setting/search",
    isRedox: true,
    data: {
      data: data,
    },
  };
  return post(request);
};

const deleteRedoxSettings: (payload: ApiServiceProps) => any = ({ id1 }) => {
  const request = {
    subUrl: `setting/${id1}/delete`,
    isRedox: true,
  };
  return post(request);
};

const updateRedoxSettings: (payload: ApiServiceProps) => any = ({
  id1,
  data,
}) => {
  const request = {
    subUrl: `setting/${id1}/update`,
    isRedox: true,
    data: data,
  };
  return post(request);
};

const searchDropSettings: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "dropdownsettins/search",
    data: payload,
  };
  return post(request);
};

export default {
  listApplicationSettings,
  UpdateAppSettings,
  saveRedoxSettings,
  listRedoxSettings,
  deleteRedoxSettings,
  updateRedoxSettings,
  searchDropSettings,
};
