import { FC, Fragment, useContext, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { CommonStyle } from "Components";
import CstmBtn from "Components/CstmBtn";
import { FormDataType } from "DataTypes/Form";
import { ControlledBoxSizeSwitch, commonFormSwitch } from "Utils/SwitchCases";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import UserApi from "Service/User.api";
import UsersFormatter from "Utils/Users.Formatter";
import { UserListApiType } from "DataTypes/Services/User.type";
import CarePlanApi from "Service/CarePlan.api";
import { useNavigate } from "react-router-dom";
import CPFormatter from "Utils/CP.Formatter";
import { FormAssignment } from "./Format";
import { FormArr } from "./Data";
import { useDispatch } from "react-redux";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import { HeadContext } from "Context/HeadContext";
import { HandleChangeProps, HeadContextType } from "DataTypes/Common";
import { HandleFormChange, ValidateFormData } from "Utils/common";
import { CPTeamSearchType } from "DataTypes/Services/Careplan.type";
import { FormatDropdownData } from "Utils/DataFormatter";

const EditTeam: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { patientOrg, selectedPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const careId = selectedPrgm.careplanId;
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const [formData, setFormData] = useState<FormDataType[]>([]);
  const [teamRead, setTeamRead] = useState<CPTeamSearchType | null>(null);
  const [coordinators, setCoordinators] = useState<UserListApiType[]>([]);

  const UserApiCall: (
    roleId: number | null
  ) => Promise<UserListApiType[]> = async (roleId) => {
    const payload = {
      data: {
        practiceLocationId: patientOrg.practiceLocationId,
        roleId,
      },
    };
    const userRes = await UserApi.list(payload);
    if (userRes?.success) {
      const formattedArr = UsersFormatter.VerifyListArrResponse(userRes?.data);
      return formattedArr.filter((el) => el.status != "INACTIVE");
    } else return [];
  };

  useEffect(() => {
    setBreadArr([
      {
        id: 1,
        label: "Edit Team",
        path: "",
      },
    ]);
    handleTeamApiCall();
  }, [careId]);

  const handleTeamApiCall: () => void = async () => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    const CMArr = await UserApiCall(3);
    const CCArr = await UserApiCall(4);
    const ProviderArr = await UserApiCall(5);
    const CPTeamRes = await CarePlanApi.careplanTeamSearch({ id1: careId });
    if (CPTeamRes?.success) {
      const CheckRes = CPFormatter.CheckCPTeam(CPTeamRes?.data);
      setTeamRead(CheckRes);
      setFormData(
        FormAssignment(
          CheckRes,
          FormArr(CheckRes.programId),
          CMArr,
          CCArr,
          ProviderArr
        )
      );
    }
    setCoordinators(CCArr);
    dispatch(stopLoading());
  };

  const handleTeamChange: (data: HandleChangeProps) => void = (data) => {
    const { id, value } = data;
    const newFormData: FormDataType[] = JSON.parse(JSON.stringify(formData));
    if (id == "primary-care-coordinator") {
      if (value) {
        newFormData[4].dropArr = FormatDropdownData(
          coordinators.filter((el) => el.id != value),
          "id",
          "fullNameDes",
          "id"
        );
      } else {
        newFormData[4].dropArr = FormatDropdownData(
          coordinators,
          "id",
          "fullNameDes",
          "id"
        );
      }
    }
    if (id == "secondary-care-coordinator") {
      if (value) {
        newFormData[3].dropArr = FormatDropdownData(
          coordinators.filter((el) => el.id != value),
          "id",
          "fullNameDes",
          "id"
        );
      } else {
        newFormData[3].dropArr = FormatDropdownData(
          coordinators,
          "id",
          "fullNameDes",
          "id"
        );
      }
    }
    setFormData(HandleFormChange(data, newFormData));
  };

  const handleUpdateApiCall: () => void = async () => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData);
    if (!newArr.errorCheck && teamRead) {
      const payload = {
        data: {
          careplanId: teamRead.careplanId,
          programId: teamRead.programId,
          patientId: teamRead.patientId,
          practiceId: teamRead.practiceId,
          practiceLocationId: teamRead.practiceLocationId,
          caremanagerId: formData[0].dropValue,
          teamProviderId: formData[2].dropValue || "",
          primaryCareCoordinatorId:
            formData.length > 3
              ? formData[3].dropValue
              : teamRead.primaryCareCoordinatorId,
          secondaryCareCoordinatorId:
            formData.length > 4
              ? formData[4].dropValue
              : teamRead.secondaryCareCoordinatorId,
          authorizedRepresentative1: "",
          authorizedRepresentative2: "",
          authorizedRepresentative3: "",
          billableProviderId: formData[1].dropValue || "",
          careplanAdditionalProvider: [],
          isCareplanStateUpdateRequire: false,
        },
      };
      const res = await CarePlanApi.careplanTeamSave({
        id1: careId,
        data: payload,
      });
      if (res?.success) dispatch(setAlertData(res?.alert));
    }
    dispatch(stopLoading());
    navigate(-1);
  };

  return (
    <Box
      sx={{ ...CommonStyle.sxWhiteBox }}
      overflow={"auto"}
      px={2}
      width={"91%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Grid container rowSpacing={1} columnSpacing={2} mt={2}>
        {formData.map((el: FormDataType) => {
          // Since am not able to check whether this care is created by which role so i am hiding cp-provider field when billable providerId is empty
          if (
            el.id == "cp-billable-provider" &&
            selectedPrgm.teamInitiatorRoleId == 3
          )
            return <Fragment key={el.id} />;
          return (
            <Grid key={el.id} item {...ControlledBoxSizeSwitch(el.boxSize)}>
              {commonFormSwitch({
                formObj: el,
                onChange: (data) => handleTeamChange(data),
              })}
            </Grid>
          );
        })}
      </Grid>
      <Box
        mb={2}
        justifyContent="flex-end"
        sx={{
          ...CommonStyle.sxRow,
          "& >button:first-of-type": {
            mr: 1,
          },
        }}
      >
        <CstmBtn label="Save" onClick={handleUpdateApiCall} width="10%" />
        <CstmBtn
          label="Cancel"
          onClick={() => navigate(-1)}
          width="10%"
          btnType="border"
        />
      </Box>
    </Box>
  );
};

export default EditTeam;
