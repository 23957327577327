import {
  HeaderInitialStateType,
  MasterInitialStateType,
  OrganizationInitialStateType,
  PatientActionReturnType,
} from "DataTypes/Redux";
import {
  ADD_MASTER_CAT,
  CLEAR_MASTER,
  CLEAR_HEADER,
  ADD_MASTER_OPENARR,
  ADD_ORG,
  CLEAR_ORG,
} from "./type";

const MasterInitialState: MasterInitialStateType = {
  questionCatId: null,
  openQuesIdArr: [],
};

const OrganizationInitialState: OrganizationInitialStateType = {
  id: null,
  name: "",
  masterPractice: false,
  modelId: null,
  modelName: "",
};

const INITIAL_STATE: HeaderInitialStateType = {
  master: MasterInitialState,
  organization: OrganizationInitialState,
};

const reducer = (state = INITIAL_STATE, action: PatientActionReturnType) => {
  switch (action.type) {
    case ADD_MASTER_CAT:
      return {
        ...state,
        master: {
          ...state.master,
          questionCatId: action.payload,
        },
      };
    case ADD_MASTER_OPENARR:
      return {
        ...state,
        master: {
          ...state.master,
          openQuesIdArr: action.payload,
        },
      };
    case CLEAR_MASTER:
      return {
        ...state,
        master: MasterInitialState,
      };
    case ADD_ORG:
      return {
        ...state,
        organization: action.payload,
      };
    case CLEAR_ORG:
      return {
        ...state,
        organization: OrganizationInitialState,
      };
    case CLEAR_HEADER:
      return {
        ...state,
        master: MasterInitialState,
        organization: OrganizationInitialState,
      };
    default:
      return state;
  }
};

export default reducer;
