import { FC, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { NavArrType } from "DataTypes/Common";

const TabSection: FC<{ navArr: NavArrType[]; schedular: boolean }> = ({
  navArr,
  schedular,
}) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={2} {...(!schedular && { columns: 16 })}>
      {navArr.map((el: NavArrType) => {
        return (
          <Grid
            key={el.id}
            item
            xs={4}
            md={4}
            lg={2}
            xl={2}
            sx={{ ...sxTab }}
            onClick={() => navigate(el.link)}
            display="grid"
          >
            <Box
              sx={{
                ...CommonStyle.sxWhiteBox,
                ...CommonStyle.sxRowCenter,
                ...CommonStyle.sxTiles,
                ...(el.data.length > 2 && { ...TileLabelLarge }),
              }}
            >
              {el.type == "text" ? (
                <Fragment>
                  {el.Icon}
                  <Typography variant="h1" fontWeight="bold" width="35%">
                    {el.data}
                  </Typography>
                </Fragment>
              ) : (
                <Box width="35%" sx={{ pt: 0 }}>
                  <img
                    src={el.imgUrl}
                    width={40}
                    alt=""
                    style={{ paddingTop: 12 }}
                  />
                  <Typography sx={{ ...IconBox }}>
                    <ReportProblemOutlinedIcon />
                    <span>{el?.data}</span>
                  </Typography>
                </Box>
              )}
              <Typography sx={{ ...CommonStyle.OverlayBox }}></Typography>
              <Typography
                sx={{ ...CommonStyle.largeIconFont, ...TileLabel }}
                variant="h4"
                width="65%"
                textAlign="start"
              >
                {el.label}
              </Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
export default TabSection;

const sxTab = {
  "& >div": {
    textAlign: "center",
    cursor: "pointer",
    border: "1px solid transparent",
    minHeight: 110,
    width: "85%",
    margin: "0 auto",
    "@media screen and (max-width:1480px)": {
      width: "100%",
    },
    "&:after": {
      content: "''",
      width: 0,
      height: "2px",
      position: "absolute",
      transition: "all 0.4s linear",
      bottom: "-1px",
      left: 0,
      right: 0,
    },
    ":hover": {
      "& p": {
        color: "textHover.main",
      },
      "&:after": {
        background: "textHover.main",
        width: "100%",
      },
    },
  },
};

const IconBox = {
  position: "absolute",
  top: "17px",
  right: "10px",
  width: "25%",
  margin: "0 auto",
  zIndex: 99,
  "@media screen and (max-width:1580px)": {
    right: "3px",
  },
  "@media screen and (max-width:1200px)": {
    width: "11%",
    right: "9px",
  },
  "@media screen and (max-width:1440px)": {
    right: "3px",
  },
  "@media screen and (max-width:1080px)": {
    right: "11px",
  },

  svg: {
    paddingTop: "0 !important",
    width: "1.4em",
    path: {
      fill: "#fff",
    },
  },
  span: {
    position: "absolute",
    top: "-0.2rem",
    backgroundColor: "primary.main",
    fontSize: "0.8rem",
    height: "1.2rem",
    width: "1.2rem",
    borderRadius: "50%",
    right: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "common.white",
    "@media screen and (max-width:1200px)": {
      right: "0px",
    },
    "@media screen and (max-width:1080px)": {
      right: "-7px",
    },
  },
};
const TileLabel = {
  fontSize: "1.2rem",
  fontWeight: "bold",
  mt: 2,
  textAlign: "center",
  width: "100%",
  color: "primary.main",
};

const TileLabelLarge = {
  h1: {
    textAlign: "right",
    top: "1.2em",
    right: "0.9em",
    position: "absolute",
    zIndex: "9",
    color: "common.white",
    transition: "all 0.6s",
    fontSize: "1rem",
    "@media screen and (max-width:1580px)": {
      top: "1em",
      right: "0.6em",
    },
  },
};
