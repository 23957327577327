// import { useState, FC, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { Box, Radio, Typography } from "@mui/material";
// import { CpProblemCol, EnterProblemBreadArr, FormArr } from "./Data";
// import CstmBtn from "Components/CstmBtn";
// import { CommonStyle, ControlledTable } from "Components";
// import PatientApi from "Service/Patient.api";
// import ProgramApi from "Service/Program.api";
// import ProblemApi from "Service/Problem.api";
// import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
// import CarePlanProblemsApi from "Service/CarePlan.api";
// import { HandleChangeProps, HeadContextType } from "DataTypes/Common";
// import { HeadContext } from "Context/HeadContext";
// import { useDispatch } from "react-redux";
// import { PatientInitialStateType } from "DataTypes/Redux";
// import { useSelector } from "react-redux";
// import { RouteUrls } from "Constant/RouteUrls";
// import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
// import { addSelectedPrgm } from "Redux/Patient/Action";
// import { CheckArr, CheckNumber } from "Utils/common";
// import NoData from "Components/NoData";
// import { TableLayout } from "Layouts";
// import ProbFormatter from "Utils/Prob.Formatter";
// import { FormatDropdownData } from "Utils/DataFormatter";
// import CPFormatter from "Utils/CP.Formatter";
// import Format from "./Format";
// import { IcdCodeType } from "DataTypes/Services/Problem.type";
// import { CPProblemType } from "DataTypes/PatientManagement.type";
// import { commonFormSwitch } from "Utils/SwitchCases";
// import PatientFormatter from "Utils/Patient.Formatter";
// import Stages from "Constant/Stages";
// import DateTime from "Utils/DateTime";
// import CarePlanApi from "Service/CarePlan.api";
// import { RedoxProblemType } from "DataTypes/Services/Patient.type";
// import RedoxFormatter from "Utils/Redox.Formatter";

// const Problems: FC = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { patientInfo, selectedPrgm }: PatientInitialStateType = useSelector(
//     (state: any) => state?.patient
//   );
//   const careId = selectedPrgm.careplanId;
//   const readOnlyUptoDate = selectedPrgm
//     ? selectedPrgm.readOnlyArr.some((el) => el == "uptoDate")
//     : true;
//   const readOnlyProblems = selectedPrgm
//     ? selectedPrgm.readOnlyArr.some((el) => el == "problem")
//     : true;
//   const { setBreadArr } = useContext(HeadContext) as HeadContextType;
//   const [cpc, setCPC] = useState(false);
//   const [tblRows, setTblRows] = useState<IcdCodeType[]>([]);
//   const [masterData, setMasterData] = useState<CPProblemType[]>([]);
//   const [formData, setFormData] = useState<FormDataType[]>(FormArr);
//   const [problems, setProblems] = useState<DropdownArrPropsType[] | []>([]);
//   const [patientProblems, setPatientProblems] = useState<RedoxProblemType[]>(
//     []
//   );

//   useEffect(() => {
//     dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
//     setBreadArr(EnterProblemBreadArr(patientInfo));
//     handleRedoxCall();
//   }, []);

//   const handleRedoxCall: () => void = async () => {
//     const res: any = await PatientApi.RedoxProblems({ id1: patientInfo.id });
//     if (res?.success) {
//       const formatedData = RedoxFormatter.ProblemList(res?.data?.problem);
//       setPatientProblems(formatedData);
//     }
//     handleProblemArrCall();
//   };

//   const handleProblemArrCall: () => void = async () => {
//     const res = await ProgramApi.mappedProblemList({
//       id1: selectedPrgm?.programId || null,
//     });
//     if (res?.success) {
//       const formatRes = ProbFormatter.MappedProblems(res?.data);
//       setProblems(FormatDropdownData(formatRes, "id", "name", "id"));
//     }
//   };

//   useEffect(() => {
//     problems.length > 0 && handleCPProblem();
//   }, [problems, careId]);

//   const handleCPProblem: () => void = async () => {
//     let newFormArr: FormDataType[] = JSON.parse(JSON.stringify(formData));
//     const res = await CarePlanProblemsApi.careplanProblemsRead({
//       id1: careId,
//     });
//     if (res?.success) {
//       const CheckedRes = CPFormatter.CheckCPProblemRead(res?.data);
//       const ans = CheckedRes.isCareplanComprehensive == "yes" ? true : false;
//       newFormArr[0].dropArr = problems;
//       setCPC(ans);
//       const formatArr = Format.CPProblems(CheckedRes.careplanProblem);
//       const selectedRows: IcdCodeType[] = [];
//       await Promise.all(
//         formatArr.map(async (el) => {
//           const res = await ProblemApi.icdCodeList({ id1: el.problemId });
//           let formatedArr: IcdCodeType[] = [];
//           if (res?.success) {
//             formatedArr = ProbFormatter.IcdCodes(res?.data);
//           }
//           el.icdCodes.forEach((icdEl) => {
//             const find = formatedArr.find((fullEl) => fullEl.id == icdEl);
//             if (find) selectedRows.push(find);
//           });
//           return {
//             id: el.problemId,
//             icdCodes: formatedArr,
//           };
//         })
//       ).then((res) => {
//         setMasterData(res);
//         setTblRows(selectedRows);
//       });
//     }
//     if (readOnlyProblems) {
//       newFormArr = newFormArr.map((el) => {
//         el.readOnly = true;
//         return el;
//       });
//     }
//     setFormData(newFormArr);
//     dispatch(stopLoading());
//   };

//   const handleChange: (data: HandleChangeProps) => void = async (data) => {
//     dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
//     const { id, value } = data;
//     const newFormArr: FormDataType[] = JSON.parse(JSON.stringify(formData));
//     if (id == "cp-problem") {
//       const newVal = CheckNumber(value);
//       newFormArr[0].dropValue = newFormArr[0].value = newVal || "";
//       if (newVal) {
//         const findInMaster = masterData.find((el) => el.id == newVal);
//         let icdArr: IcdCodeType[] = [];
//         if (findInMaster) {
//           icdArr = findInMaster.icdCodes;
//         } else {
//           const res = await ProblemApi.icdCodeList({ id1: newVal });
//           let formatedArr: IcdCodeType[] = [];
//           if (res?.success) {
//             formatedArr = ProbFormatter.IcdCodes(res?.data);
//             icdArr = ProbFormatter.IcdCodes(res?.data);
//             setMasterData([
//               ...masterData,
//               {
//                 id: newVal,
//                 icdCodes: formatedArr,
//               },
//             ]);
//           }
//         }
//         const filterIcd = tblRows.filter((el) => el.problemId == newVal);
//         const selected = filterIcd.map((el) => el.id);
//         newFormArr[1].dropArr = icdArr.map((el) => {
//           return {
//             id: el.id || 0,
//             label: `${el.icdCode} - ${el.description}`,
//             value: el.id || 0,
//           };
//         });
//         newFormArr[1].dropValue = newFormArr[1].value = selected;
//       } else {
//         newFormArr[1].dropArr = [];
//         newFormArr[1].dropValue = newFormArr[1].value = [];
//       }
//     }
//     if (id == "cp-icdCodes") {
//       const newVal = CheckArr(value);
//       const problemId = CheckNumber(newFormArr[0].dropValue);
//       const masterIcds = masterData.find((el) => el.id == problemId);
//       const newSelectedArr: IcdCodeType[] = [];
//       if (masterIcds) {
//         newVal.forEach((el) => {
//           const find = masterIcds.icdCodes.find((el1) => el1.id == el);
//           if (find) newSelectedArr.push(find);
//         });
//       }
//       const nonSelected = tblRows.filter((el) => el.problemId != problemId);
//       setTblRows([...newSelectedArr, ...nonSelected]);
//       newFormArr[1].dropValue = newFormArr[1].value = newVal;
//     }
//     setFormData(newFormArr);
//     dispatch(stopLoading());
//   };

//   const handleUpdateApiCall: () => void = async () => {
//     if (tblRows.length > 0) {
//       dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
//       const payloadObj = {
//         problems: Format.CreatePayload(tblRows),
//         isComprehensive: cpc ? "Yes" : "No",
//       };
//       const res = await CarePlanProblemsApi.careplanProblemUpdate({
//         id1: careId,
//         data: payloadObj,
//       });
//       if (res?.success == true) {
//         dispatch(setAlertData(res?.alert));
//         if (cpc) {
//           const payload = { hasCareplanQuestionnaireCompleted: true };
//           const updateStatusRes = await CarePlanApi.CpQuestionStatusUpdate({
//             id1: careId,
//             data: payload,
//           });
//           if (updateStatusRes?.success) {
//             selectedPrgm &&
//               dispatch(
//                 addSelectedPrgm({
//                   ...selectedPrgm,
//                   ...(selectedPrgm.careplanStage >
//                   PatientFormatter.GetCPStage(Stages.CPProblem)
//                     ? {}
//                     : {
//                       careplanState: Stages.CPTeam,
//                       careplanStage: PatientFormatter.GetCPStage(
//                         Stages.CPTeam
//                       ),
//                     }),
//                   isCareplanComprehensive: true,
//                 })
//               );
//             navigate(`../${RouteUrls.careTeam}`, { replace: true });
//           }
//         } else {
//           selectedPrgm &&
//             dispatch(
//               addSelectedPrgm({
//                 ...selectedPrgm,
//                 ...(selectedPrgm.careplanStage >
//                 PatientFormatter.GetCPStage(Stages.CPProblem)
//                   ? {}
//                   : {
//                     careplanState: Stages.CPQues,
//                     careplanStage: PatientFormatter.GetCPStage(Stages.CPQues),
//                   }),
//                 isCareplanComprehensive: false,
//               })
//             );
//           navigate(`../${RouteUrls.careQuestion}`, { replace: true });
//         }
//       }
//       dispatch(stopLoading());
//     } else {
//       dispatch(
//         setAlertData({
//           alertMessage: "Please select atleast one icd problem to proceed",
//           alertVariant: "error",
//           openAlert: true,
//         })
//       );
//     }
//   };

//   const TblBodyJSX = (
//     <ControlledTable.TblBody
//       tableColumn={() => CpProblemCol}
//       tableRow={tblRows}
//     />
//   );

//   return (
//     <Box width="91%" sx={{ ...CommonStyle.sxWhiteCard, overflow: "auto" }}>
//       <Box display={"flex"}>
//         <Box width={"70%"}>
//           <Typography fontWeight={"bold"}>{"Care Plan Problems"}</Typography>
//           {selectedPrgm?.careplanInitDateInEhr && (
//             <Box mb={1}>
//               <Typography sx={{ fontSize: "0.9rem", pr: 3 }}>
//                 Is the Care Plan initiated on{" "}
//                 {DateTime.ToLocalDate(selectedPrgm.careplanInitDateInEhr)} by
//                 the provider in the EHR comprehensive and up to date?
//               </Typography>
//               <Box display="flex" alignItems="center">
//                 <Radio
//                   checked={cpc}
//                   value="yes"
//                   color="secondary"
//                   onChange={() => setCPC(true)}
//                   disabled={readOnlyUptoDate}
//                 />
//                 <Typography variant="subtitle1" mr={3}>
//                   Yes
//                 </Typography>
//                 <Radio
//                   checked={!cpc}
//                   value="no"
//                   color="secondary"
//                   onChange={() => setCPC(false)}
//                   disabled={readOnlyUptoDate}
//                 />
//                 <Typography variant="subtitle1">No</Typography>
//               </Box>
//             </Box>
//           )}
//           {formData.map((formEl) => {
//             return (
//               <Box width="90%" key={formEl.id}>
//                 {commonFormSwitch({
//                   formObj: formEl,
//                   onChange: handleChange,
//                 })}
//               </Box>
//             );
//           })}
//           <Box mt={2} width={"90%"}>
//             <TableLayout
//               tblBody={TblBodyJSX}
//               tblHead={ControlledTable.TblHead(CpProblemCol)}
//               height={""}
//             />
//           </Box>
//         </Box>
//         <Box width={"30%"}>
//           <Typography fontWeight={"bold"}>
//             {"Patient's Problem List"}
//           </Typography>
//           {patientProblems.length > 0 ? (
//             patientProblems?.map((el) => {
//               return (
//                 <Box key={el.id} sx={{ ...sxList, width: "80%" }}>
//                   <Typography variant="body2">{`${
//                     el.codeSystemName == "ICD-10-CM" ? el.code + " -" : ""
//                   } ${el.name}`}</Typography>
//                 </Box>
//               );
//             })
//           ) : (
//             <Box sx={{ ...NoDataBox }}>
//               <NoData />
//             </Box>
//           )}
//         </Box>
//       </Box>
//       {readOnlyProblems ? (
//         (selectedPrgm?.careplanStage || 0) >
//           PatientFormatter.GetCPStage(Stages.CPProblem) && (
//           <Box sx={{ ...CommonStyle.sxRow, justifyContent: "center", mt: 2 }}>
//             <CstmBtn
//               label={"Next"}
//               onClick={() =>
//                 navigate(
//                   `../${cpc ? RouteUrls.careTeam : RouteUrls.careQuestion}`,
//                   { replace: true }
//                 )
//               }
//               width="10%"
//             />
//           </Box>
//         )
//       ) : (
//         <Box sx={{ ...CommonStyle.sxRow, justifyContent: "center", mt: 2 }}>
//           <CstmBtn
//             label={"Save & Next"}
//             onClick={() => handleUpdateApiCall()}
//             width="10%"
//           />
//         </Box>
//       )}
//     </Box>
//   );
// };
// export default Problems;

// const sxList = {
//   display: "flex",
//   flexDirection: "row",
//   pt: 1.5,
//   pb: 1,
//   borderBottom: "1px solid",
//   borderBottomColor: "custom.main",
//   "& >p": {
//     fontSize: "0.8rem",
//   },
// };
// const NoDataBox = {
//   "&>div": {
//     justifyContent: "flex-start",
//   },
// };

import { useState, FC, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Radio, Typography } from "@mui/material";
import { CpProblemCol, EnterProblemBreadArr, FormArr } from "./Data";
import CstmBtn from "Components/CstmBtn";
import { CommonStyle, ControlledTable, Loader } from "Components";
import PatientApi from "Service/Patient.api";
import ProgramApi from "Service/Program.api";
import ProblemApi from "Service/Problem.api";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import CarePlanProblemsApi from "Service/CarePlan.api";
import { HandleChangeProps, HeadContextType } from "DataTypes/Common";
import { HeadContext } from "Context/HeadContext";
import { useDispatch } from "react-redux";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { RouteUrls } from "Constant/RouteUrls";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { CheckArr, CheckNumber } from "Utils/common";
import NoData from "Components/NoData";
import { TableLayout } from "Layouts";
import ProbFormatter from "Utils/Prob.Formatter";
import { FormatDropdownData } from "Utils/DataFormatter";
import CPFormatter from "Utils/CP.Formatter";
import Format from "./Format";
import { IcdCodeType } from "DataTypes/Services/Problem.type";
import { CPProblemType } from "DataTypes/PatientManagement.type";
import { commonFormSwitch } from "Utils/SwitchCases";
import PatientFormatter from "Utils/Patient.Formatter";
import Stages from "Constant/Stages";
import DateTime from "Utils/DateTime";
import CarePlanApi from "Service/CarePlan.api";
import { RedoxProblemType } from "DataTypes/Services/Patient.type";
import RedoxFormatter from "Utils/Redox.Formatter";

const Problems: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { patientInfo, selectedPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const careId = selectedPrgm.careplanId;
  const readOnlyUptoDate = selectedPrgm
    ? selectedPrgm.readOnlyArr.some((el) => el == "uptoDate")
    : true;
  const readOnlyProblems = selectedPrgm
    ? selectedPrgm.readOnlyArr.some((el) => el == "problem")
    : true;
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const [cpc, setCPC] = useState(false);
  const [tblRows, setTblRows] = useState<IcdCodeType[]>([]);
  const [masterData, setMasterData] = useState<CPProblemType[]>([]);
  const [formData, setFormData] = useState<FormDataType[]>(FormArr);
  const [problems, setProblems] = useState<DropdownArrPropsType[] | []>([]);

  const [redoxPrblm, setRedoxPrblm] = useState<RedoxProblemType[]>([]);
  const [redoxLoad, setRedoxLoad] = useState(true);

  useEffect(() => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    setBreadArr(EnterProblemBreadArr(patientInfo));
    handleRedoxCall();
    handleProblemArrCall();
  }, []);

  const handleRedoxCall: () => void = async () => {
    const res = await PatientApi.RedoxProblems({ id1: patientInfo.id });
    if (res?.success)
      setRedoxPrblm(RedoxFormatter.ProblemList(res?.data?.problem));
    setRedoxLoad(false);
  };

  const handleProblemArrCall: () => void = async () => {
    const res = await ProgramApi.mappedProblemList({
      id1: selectedPrgm?.programId || null,
      limit: 10000,
      offset: 0,
    });
    if (res?.success) {
      const formatRes = ProbFormatter.MappedProblems(res?.data);
      setProblems(FormatDropdownData(formatRes, "id", "name", "id"));
    }
  };

  useEffect(() => {
    if (problems.length > 0) handleCPProblem();
    else dispatch(stopLoading());
  }, [problems, careId]);

  const handleCPProblem: () => void = async () => {
    let newFormArr: FormDataType[] = JSON.parse(JSON.stringify(formData));
    const res = await CarePlanProblemsApi.careplanProblemsRead({
      id1: careId,
    });
    if (res?.success) {
      const CheckedRes = CPFormatter.CheckCPProblemRead(res?.data);
      const ans = CheckedRes.isCareplanComprehensive == "yes" ? true : false;
      newFormArr[0].dropArr = problems;
      setCPC(ans);
      const formatArr = Format.CPProblems(CheckedRes.careplanProblem);
      const selectedRows: IcdCodeType[] = [];
      await Promise.all(
        formatArr.map(async (el) => {
          const res = await ProblemApi.icdCodeList({ id1: el.problemId });
          let formatedArr: IcdCodeType[] = [];
          if (res?.success) {
            formatedArr = ProbFormatter.IcdCodes(res?.data);
          }
          el.icdCodes.forEach((icdEl) => {
            const find = formatedArr.find((fullEl) => fullEl.id == icdEl);
            if (find) selectedRows.push(find);
          });
          return {
            id: el.problemId,
            icdCodes: formatedArr,
          };
        })
      ).then((res) => {
        setMasterData(res);
        setTblRows(selectedRows);
      });
    }
    if (readOnlyProblems) {
      newFormArr = newFormArr.map((el) => {
        el.readOnly = true;
        return el;
      });
    }
    setFormData(newFormArr);
    dispatch(stopLoading());
  };

  const handleChange: (data: HandleChangeProps) => void = async (data) => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    const { id, value } = data;
    const newFormArr: FormDataType[] = JSON.parse(JSON.stringify(formData));
    if (id == "cp-problem") {
      const newVal = CheckNumber(value);
      newFormArr[0].dropValue = newFormArr[0].value = newVal || "";
      if (newVal) {
        const findInMaster = masterData.find((el) => el.id == newVal);
        let icdArr: IcdCodeType[] = [];
        if (findInMaster) {
          icdArr = findInMaster.icdCodes;
        } else {
          const res = await ProblemApi.icdCodeList({ id1: newVal });
          let formatedArr: IcdCodeType[] = [];
          if (res?.success) {
            formatedArr = ProbFormatter.IcdCodes(res?.data);
            icdArr = ProbFormatter.IcdCodes(res?.data);
            setMasterData([
              ...masterData,
              {
                id: newVal,
                icdCodes: formatedArr,
              },
            ]);
          }
        }
        const filterIcd = tblRows.filter((el) => el.problemId == newVal);
        const selected = filterIcd.map((el) => el.id);
        newFormArr[1].dropArr = icdArr.map((el) => {
          return {
            id: el.id || 0,
            label: `${el.icdCode} - ${el.description}`,
            value: el.id || 0,
          };
        });
        newFormArr[1].dropValue = newFormArr[1].value = selected;
      } else {
        newFormArr[1].dropArr = [];
        newFormArr[1].dropValue = newFormArr[1].value = [];
      }
    }
    if (id == "cp-icdCodes") {
      const newVal = CheckArr(value);
      const problemId = CheckNumber(newFormArr[0].dropValue);
      const masterIcds = masterData.find((el) => el.id == problemId);
      const newSelectedArr: IcdCodeType[] = [];
      if (masterIcds) {
        newVal.forEach((el) => {
          const find = masterIcds.icdCodes.find((el1) => el1.id == el);
          if (find) newSelectedArr.push(find);
        });
      }
      const nonSelected = tblRows.filter((el) => el.problemId != problemId);
      setTblRows([...newSelectedArr, ...nonSelected]);
      newFormArr[1].dropValue = newFormArr[1].value = newVal;
    }
    setFormData(newFormArr);
    dispatch(stopLoading());
  };

  const handleUpdateApiCall: () => void = async () => {
    if (tblRows.length > 0) {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      const payloadObj = {
        problems: Format.CreatePayload(tblRows),
        isComprehensive: cpc ? "Yes" : "No",
      };
      const res = await CarePlanProblemsApi.careplanProblemUpdate({
        id1: careId,
        data: payloadObj,
      });
      if (res?.success == true) {
        dispatch(setAlertData(res?.alert));
        if (cpc) {
          const payload = { hasCareplanQuestionnaireCompleted: true };
          const updateStatusRes = await CarePlanApi.CpQuestionStatusUpdate({
            id1: careId,
            data: payload,
          });
          if (updateStatusRes?.success) {
            selectedPrgm &&
              dispatch(
                addSelectedPrgm({
                  ...selectedPrgm,
                  ...(selectedPrgm.careplanStage >
                  PatientFormatter.GetCPStage(Stages.CPProblem)
                    ? {}
                    : {
                        careplanState: Stages.CPTeam,
                        careplanStage: PatientFormatter.GetCPStage(
                          Stages.CPTeam
                        ),
                      }),
                  isCareplanComprehensive: true,
                })
              );
            navigate(`../${RouteUrls.careTeam}`, { replace: true });
          }
        } else {
          selectedPrgm &&
            dispatch(
              addSelectedPrgm({
                ...selectedPrgm,
                ...(selectedPrgm.careplanStage >
                PatientFormatter.GetCPStage(Stages.CPProblem)
                  ? {}
                  : {
                      careplanState: Stages.CPQues,
                      careplanStage: PatientFormatter.GetCPStage(Stages.CPQues),
                    }),
                isCareplanComprehensive: false,
              })
            );
          navigate(`../${RouteUrls.careQuestion}`, { replace: true });
        }
      }
      dispatch(stopLoading());
    } else {
      dispatch(
        setAlertData({
          alertMessage: "Please select atleast one icd problem to proceed",
          alertVariant: "error",
          openAlert: true,
        })
      );
    }
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => CpProblemCol}
      tableRow={tblRows}
    />
  );

  return (
    <Box width="91%" sx={{ ...CommonStyle.sxWhiteCard, overflow: "auto" }}>
      <Box display={"flex"}>
        <Box width={"70%"}>
          <Typography fontWeight={"bold"}>{"Care Plan Problems"}</Typography>
          {selectedPrgm?.careplanInitDateInEhr && (
            <Box mb={1}>
              <Typography variant="body2">
                Is the Care Plan initiated on{" "}
                {DateTime.ToLocalDate(selectedPrgm.careplanInitDateInEhr)} by
                the provider in the EHR comprehensive and up to date?
              </Typography>
              <Box display="flex" alignItems="center">
                <Radio
                  checked={cpc}
                  value="yes"
                  color="secondary"
                  onChange={() => setCPC(true)}
                  disabled={readOnlyUptoDate}
                />
                <Typography variant="subtitle1" mr={3}>
                  Yes
                </Typography>
                <Radio
                  checked={!cpc}
                  value="no"
                  color="secondary"
                  onChange={() => setCPC(false)}
                  disabled={readOnlyUptoDate}
                />
                <Typography variant="subtitle1">No</Typography>
              </Box>
            </Box>
          )}
          {formData.map((formEl) => {
            return (
              <Box width="90%" key={formEl.id}>
                {commonFormSwitch({
                  formObj: formEl,
                  onChange: handleChange,
                })}
              </Box>
            );
          })}
          <Box mt={2} width={"90%"}>
            <TableLayout
              tblBody={TblBodyJSX}
              tblHead={ControlledTable.TblHead(CpProblemCol)}
              height={""}
            />
          </Box>
        </Box>
        <Box width="30%">
          <Typography fontWeight={"bold"}>
            {"Patient's Problem List"}
          </Typography>
          {redoxLoad ? (
            <Loader />
          ) : redoxPrblm.length ? (
            redoxPrblm.map((el) => {
              return (
                <Box key={el.id} sx={{ ...sxList, width: "80%" }}>
                  <Typography variant="body2">{`${
                    el.codeSystemName == "ICD-10-CM" ? el.code + " -" : ""
                  } ${el.name}`}</Typography>
                </Box>
              );
            })
          ) : (
            <NoData />
          )}
        </Box>
      </Box>
      {readOnlyProblems ? (
        (selectedPrgm?.careplanStage || 0) >
          PatientFormatter.GetCPStage(Stages.CPProblem) && (
          <Box sx={{ ...CommonStyle.sxRow, justifyContent: "center", mt: 2 }}>
            <CstmBtn
              label={"Next"}
              onClick={() =>
                navigate(
                  `../${cpc ? RouteUrls.careTeam : RouteUrls.careQuestion}`,
                  { replace: true }
                )
              }
              width="10%"
            />
          </Box>
        )
      ) : (
        <Box sx={{ ...CommonStyle.sxRow, justifyContent: "center", mt: 2 }}>
          <CstmBtn
            label={"Save & Next"}
            onClick={() => handleUpdateApiCall()}
            width="10%"
          />
        </Box>
      )}
    </Box>
  );
};
export default Problems;

const sxList = {
  display: "flex",
  flexDirection: "row",
  pt: 1.5,
  pb: 1,
  borderBottom: "1px solid",
  borderBottomColor: "custom.main",
  "& >p": {
    fontSize: "0.8rem",
  },
};
