import { FC, Fragment, useContext, useEffect, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { label } from "Constant/En";
import { DischargeSummaryBread, PersonalDataArr } from "./Data";
import { FormDataType } from "DataTypes/Form";
import CstmBtn from "Components/CstmBtn";
import { CommonStyle, Loader } from "Components";
import { commonFormSwitch, ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "Constant/RouteUrls";
import { HandleChangeProps, HeadContextType } from "DataTypes/Common";
import PatientApi from "Service/Patient.api";
import PatientFormatter from "Utils/Patient.Formatter";
import {
  CheckArr,
  CheckStringEmpty,
  HandleFormChange,
  ValidateFormData,
} from "Utils/common";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import ProgramApi from "Service/Program.api";
import { HeadContext } from "Context/HeadContext";
import DateTime from "Utils/DateTime";
import { addSelectedPrgm } from "Redux/Patient/Action";

const DischargeSummary: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const { patientInfo, selectedPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [formArr, setFormArr] = useState<FormDataType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setBreadArr(DischargeSummaryBread);
    handleSetdata();
  }, []);

  const handleSetdata: () => void = async () => {
    let newArr: FormDataType[] = JSON.parse(JSON.stringify(PersonalDataArr()));
    if (selectedPrgm.careplanId) {
      const res = await PatientApi.DischargeSummarySearch({
        id1: selectedPrgm.careplanId,
      });
      if (res?.success) {
        const CheckedArr = CheckArr(res?.data);
        if (CheckedArr.length > 0) {
          const formattedData = PatientFormatter.DischargeSummary(res?.data[0]);
          newArr = PatientFormatter.DischargeSummaryForm(formattedData, newArr);
          newArr = newArr.map((el) => {
            el.readOnly = true;
            return el;
          });
        } else {
          newArr[5].maxVal = DateTime.CurrentDate();
          newArr[6].maxVal = DateTime.CurrentDate();
        }
      }
    }
    setFormArr(newArr);
    setLoading(false);
  };

  const handleChange: (props: HandleChangeProps) => void = (props) => {
    const { value, id } = props;
    const newArr: FormDataType[] = JSON.parse(JSON.stringify(formArr));
    if (id == "date-of-admission") newArr[6].minVal = CheckStringEmpty(value);
    if (id == "date-of-discharge") newArr[5].maxVal = CheckStringEmpty(value);
    setFormArr(HandleFormChange(props, newArr));
  };

  const handleDischargeSummarySave = async () => {
    const newArr = ValidateFormData(formArr);
    setFormArr(newArr.formData);
    if (!newArr.errorCheck) {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      const enrollDate = DateTime.ToUTCDate(DateTime.CurrentDate());
      const tcmPayload = {
        data: {
          concentType: "Verbal",
          careplanCreatedInEhr: "No",
          careplanInitDateInEhr: "",
          concentReason: "",
          concent: "Enrolled",
          enrolmentDate: enrollDate ? enrollDate + "T00:00" : "",
        },
        id1: patientInfo.id,
        id2: selectedPrgm?.eligibleProgramId || null,
      };
      let newCpId = selectedPrgm?.careplanId || null;
      const tcmEnrollRes = await ProgramApi.EnrollProgram(tcmPayload);
      if (tcmEnrollRes.success) {
        newCpId = tcmEnrollRes?.data?.careplanId;
      }
      if (newCpId) {
        const payload = PatientFormatter.DischargeSummaryPayload(formArr);
        const res = await PatientApi.DischargeSummarySave({
          data: payload,
          id1: newCpId,
        });
        if (res?.success) {
          dispatch(addSelectedPrgm({ ...selectedPrgm, careplanId: newCpId }));
          dispatch(setAlertData(res.alert));
          navigate(`../${RouteUrls.clinicalSummary}`);
        } else {
          handleUnenroll();
        }
      }
      dispatch(stopLoading());
    }
  };

  const handleUnenroll: () => void = async () => {
    const payload = {
      concent: null,
      unenrollmentReason: "",
    };
    const res = await ProgramApi.UnenrollProgram({
      id1: patientInfo.id,
      id2: selectedPrgm?.eligibleProgramId || null,
      data: payload,
    });
  };

  return (
    <Box
      height="100%"
      width="100%"
      sx={{ ...CommonStyle.sxWhiteBox }}
      overflow={"auto"}
      px={2}
    >
      <Typography mt={2} fontWeight="bold">
        {label.dischargeSummary}
      </Typography>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <Grid container rowSpacing={1} columnSpacing={2}>
            {formArr && formArr.length > 0
              ? formArr.map((el: FormDataType) => {
                return (
                  <Grid
                    key={el.id}
                    item
                    {...ControlledBoxSizeSwitch(el.boxSize)}
                  >
                    {commonFormSwitch({
                      formObj: el,
                      onChange: handleChange,
                    })}
                  </Grid>
                );
              })
              : null}
          </Grid>
          <Divider sx={{ mt: 2, mb: 1 }} />
          <Box
            mb={2}
            justifyContent="flex-end"
            sx={{
              ...CommonStyle.sxRow,
              "& >button:first-of-type": {
                mr: 1,
              },
            }}
          >
            {!selectedPrgm.careplanId && (
              <CstmBtn
                label="Save"
                onClick={() => {
                  handleDischargeSummarySave();
                }}
                width="10%"
              />
            )}
            <CstmBtn
              label="Cancel"
              onClick={() => navigate(`../${RouteUrls.details}`)}
              width="10%"
              btnType="border"
            />
          </Box>
        </Fragment>
      )}
    </Box>
  );
};
export default DischargeSummary;
