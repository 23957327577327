import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import ImgPath from "Constant/Imgs";
import CstmBtn from "Components/CstmBtn";
import { DataArr, IconClickArr } from "./Data";
import { FormDataType } from "DataTypes/Form";
import { LoginLayout } from "Layouts";
import { commonFormSwitch } from "Utils/SwitchCases";
import { AlertProps } from "DataTypes/Common";
import AuthApi from "Service/Auth.api";
import { HandleFormChange, ValidateFormData } from "Utils/common";
import { setAlertData } from "Redux/Screen/Action";
import { useDispatch } from "react-redux";

const PasswordRecovery: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const attempId = queryParameters.get("passwordAttemptId");
  const userName = queryParameters.get("userName") || "";
  const [formData, setFormData] = useState<FormDataType[] | []>(
    JSON.parse(JSON.stringify(DataArr))
  );
  const [alertData, setalertData] = useState<AlertProps>({
    alertMessage: "",
    openAlert: false,
    alertVariant: "",
  });

  useEffect(() => {
    setFormData(HandleFormChange({ value: userName, id: "login" }, formData));
  }, []);

  const handlePassClick = (id: string) => {
    const newArr = formData.map((el) => {
      if (el.id == id) el.type = el.type == "text" ? "password" : "text";
      return el;
    });
    setFormData(newArr);
  };

  const handleSubmit = async () => {
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData || formData);
    if (!newArr.errorCheck) {
      if (newArr.formData[1].value == newArr.formData[2].value) {
        const payload = {
          data: {
            userName: userName,
            newPassword: formData[1].value,
            confirmPassword: formData[2].value,
            passwordAttemptId: attempId,
          },
        };
        const res: any = await AuthApi.reset(payload);
        if (res?.success) {
          dispatch(setAlertData(res?.alert));
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        }
      } else {
        dispatch(
          setAlertData({
            alertMessage: "Both passwords are different.",
            openAlert: true,
            alertVariant: "error",
          })
        );
      }
    }
  };

  const handleAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setalertData({
      alertMessage: "",
      openAlert: false,
      alertVariant: alertData.alertVariant,
    });
  };

  const rightBox = (
    <Box sx={{ ...sxRight }}>
      <img src={ImgPath.CV_Logo} alt="" />
      <Typography fontWeight={"bold"} variant="h6">
        Reset your password
      </Typography>
      {formData.map((el: FormDataType) => {
        return (
          <Box key={el.id}>
            {commonFormSwitch({
              formObj: el,
              onChange: (data) => setFormData(HandleFormChange(data, formData)),
              iconClickArr: IconClickArr(handlePassClick),
            })}
          </Box>
        );
      })}
      <CstmBtn
        label={"Submit"}
        onClick={handleSubmit}
        width="30%"
        py={1.5}
        fontSize="1rem"
      />
    </Box>
  );

  return (
    <LoginLayout
      alertPopUpData={{ alertData: alertData, handleAlert: handleAlert }}
      rightBox={rightBox}
    />
  );
};
export default PasswordRecovery;

const sxRight = {
  width: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "& >img:first-of-type": {
    width: "40%",
    mb: 2,
  },
  "& >div:nth-of-type(1)": {
    mt: 1,
    width: "60%",
    mb: 3.75,
  },
  "& >div:nth-of-type(2)": {
    width: "60%",
    mb: 3.75,
  },
  "& >div:nth-of-type(3)": {
    width: "60%",
    mb: 3.75,
  },
  "& >button:nth-of-type(1)": {
    mb: 1,
  },
  "& >a:last-child": {
    textDecoration: "none",
    cursor: "pointer",
  },
};
