import {
  ReduxLoadType,
  ScreenActionReturnType,
  ScreenInitialStateType,
} from "DataTypes/Redux";
import {
  CLEAR_ALERT,
  CLEAR_SCREEN,
  SET_ALERT,
  START_SCREEN_LOAD,
  STOP_SCREEN_LOAD,
} from "./type";
import { AlertProps } from "DataTypes/Common";

const alertInitialState: AlertProps = {
  alertMessage: "",
  openAlert: false,
  alertVariant: "",
};

const screenLoadInitialState: ReduxLoadType = {
  loading: false,
  loadtext: "",
};

const INITIAL_STATE: ScreenInitialStateType = {
  alert: alertInitialState,
  screenLoad: screenLoadInitialState,
};

const reducer = (state = INITIAL_STATE, action: ScreenActionReturnType) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    case CLEAR_ALERT:
      return {
        ...state,
        alert: alertInitialState,
      };
    case START_SCREEN_LOAD:
      return {
        ...state,
        screenLoad: action.payload,
      };
    case STOP_SCREEN_LOAD:
      return {
        ...state,
        screenLoad: screenLoadInitialState,
      };
    case CLEAR_SCREEN:
      return {
        ...state,
        alert: alertInitialState,
        screenLoad: screenLoadInitialState,
      };
    default:
      return state;
  }
};

export default reducer;
