import { FC, useState, useEffect, Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { TableLayout } from "Layouts";
import { ControlledTable, Filter2 } from "Components";
import { actionArr, ConsentTAbBreadArr, FilterArr, TableCol } from "./Data";
import { RouteUrls } from "Constant/RouteUrls";
import { CreateFilter2Payload } from "Utils/common";
import { useDispatch, useSelector } from "react-redux";
import { stopLoading } from "Redux/Screen/Action";
import PatientApi from "Service/Patient.api";
import Formate from "./Formate";
import { HeadContext } from "Context/HeadContext";
import { Filter2DataProps, HeadContextType } from "DataTypes/Common";
import CarePlanApi from "Service/CarePlan.api";
import { ConsentType } from "DataTypes/Services/Patient.type";
import CstmBtn from "Components/CstmBtn";

const Consent: FC = () => {
  const permission: any = useSelector(
    (state: any) => state?.user?.myPermission?.consent
  );
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [limit, setLimit] = useState<number>(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<ConsentType[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>(
    JSON.parse(JSON.stringify(FilterArr()))
  );
  const [searchText, setSearchText] = useState("");
  const [downloading, setDownloading] = useState(false);
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [donwloadHeight, setDownloadHeight] = useState<number>(0);
  const offsetDownload = document?.getElementById("download-id")?.offsetHeight;
  const tableHeight = permission?.download
    ? `calc(99% - ${filterHeight + donwloadHeight}px)`
    : `calc(99% - ${filterHeight}px)`;

  useEffect(() => {
    setDownloadHeight(offsetDownload || 0);
  }, [offsetDownload]);

  useEffect(() => {
    setBreadArr(ConsentTAbBreadArr);
  }, []);

  useEffect(() => {
    handleSetData(1, limit);
  }, [filterData]);

  const handleSetData: (
    currentPage: number,
    currentLimit: number
  ) => void = async (currentPage, currentLimit) => {
    setLoading(true);
    const newFilterPayload = CreateFilter2Payload(filterData);
    const payload = {
      ...(newFilterPayload || {}),
    };
    const res = await PatientApi.consentGained({
      data: payload,
      offset: (currentPage - 1) * currentLimit,
      limit: currentLimit,
    });
    if (res?.success) {
      setRows(Formate.ConsentList(res?.data));
      setPage(res.criterion.page);
      setTotalRecord(res?.criterion?.total);
      setLimit(res?.criterion?.limit);
      dispatch(stopLoading());
    } else {
      setRows([]);
    }
    setLoading(false);
  };

  const handlePatientDetails: (data: ConsentType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.details}`
    );
  };

  const handleChart: (data: ConsentType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.clinicalSummary}`
    );
  };

  const handlEnrollDownload: (data: ConsentType) => void = async (data) => {
    const res: any = await CarePlanApi.ConsentPDFDownload(
      { id1: data.enrolledProgramId },
      "Enrolled"
    );
    const binaryResponse = res.data;
    const blob = new Blob([binaryResponse], {
      type: "application/pdf",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${data.name}_Consent.pdf`;
    link.click();
  };

  const handlUnenrollDownload: (data: ConsentType) => void = async (data) => {
    const res: any = await CarePlanApi.ConsentPDFDownload(
      { id1: data.enrolledProgramId },
      "Unenrolled"
    );
    const binaryResponse = res.data;
    const blob = new Blob([binaryResponse], {
      type: "application/pdf",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${data.name}_Cancellation_Form.pdf`;
    link.click();
  };

  const downloadExport: () => void = async () => {
    setDownloading(true);
    const newFilterPayload = CreateFilter2Payload(filterData);
    const payload = {
      ...(newFilterPayload || {}),
    };
    const res: any = await PatientApi.DownloadAllConsent({ data: payload });
    if (res?.data) {
      const binaryResponse = res?.data;
      const blob = new Blob([binaryResponse], {
        type: "application/ms-excel",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "consent_list.xls";
      link.click();
    }
    setDownloading(false);
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={rows}
      searchText={searchText}
      tableAction={(data) =>
        actionArr(
          () => handleChart(data),
          () => handlePatientDetails(data),
          () => handlEnrollDownload(data),
          () => handlUnenrollDownload(data),
          data
        )
      }
    />
  );

  return (
    <Fragment>
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => setFilterData(data)}
        changeSearchText={(val) => setSearchText(val)}
        setFilterHeight={(val) => setFilterHeight(val)}
      />
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol)}
        height={tableHeight}
        loading={loading}
        Pagination={
          <ControlledTable.Pagination
            mainPage={page}
            limit={limit}
            total={totalRecord}
            handleClick={handleSetData}
            tableStyle={true}
          />
        }
      />
      {permission?.download && (
        <Box
          id="download-id"
          display={"flex"}
          justifyContent={"flex-end"}
          py={1}
        >
          <CstmBtn
            label={downloading ? "Downloading..." : "Download"}
            onClick={() => downloadExport()}
            disable={downloading}
            width={"10%"}
          />
        </Box>
      )}
    </Fragment>
  );
};
export default Consent;
