import { ReduxLoadType, ScreenActionReturnType } from "DataTypes/Redux";
import {
  SET_ALERT,
  CLEAR_ALERT,
  START_SCREEN_LOAD,
  STOP_SCREEN_LOAD,
  CLEAR_SCREEN,
} from "./type";
import { AlertProps } from "DataTypes/Common";

export const setAlertData: (data: AlertProps) => ScreenActionReturnType = (
  data
) => {
  return {
    type: SET_ALERT,
    payload: data,
  };
};

export const clearAlertData: () => ScreenActionReturnType = () => {
  return {
    type: CLEAR_ALERT,
  };
};

export const startLoading: (data: ReduxLoadType) => ScreenActionReturnType = (
  data
) => {
  return {
    type: START_SCREEN_LOAD,
    payload: data,
  };
};

export const stopLoading: () => ScreenActionReturnType = () => {
  return {
    type: STOP_SCREEN_LOAD,
  };
};

export const clearScreenData: () => ScreenActionReturnType = () => {
  return {
    type: CLEAR_SCREEN,
  };
};
