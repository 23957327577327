import { FC } from "react";
import { Typography } from "@mui/material";

type InputLabelProps = {
  label: string;
  required: boolean;
};

const InputLabel:FC<InputLabelProps> = (props: InputLabelProps) => {
  const { label, required } = props;
  return (
    <Typography
      variant="subtitle1"
      sx={{
        mt: 0.5,
        "&>span": {
          color: "error.main",
        },
      }}
    >
      {label} {required ? <span>*</span> : ""}
    </Typography>
  );
};
export default InputLabel;
