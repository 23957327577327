import { NotificationType } from "DataTypes/Notification";
import DateTime from "Utils/DateTime";
import {
  CheckArr,
  CheckBoolean,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "./common";

const Obj: (data: any) => NotificationType = (data) => {
  return {
    id: CheckNumber(data?.id),
    receiverUserId: CheckNumber(data?.receiverUserId),
    patientId: CheckStringId(data?.patientId),
    patientName: CheckStringEmpty(data?.patientName),
    title: CheckStringEmpty(data?.title),
    action: CheckStringEmpty(data?.action),
    actionId: CheckNumber(data?.actionId),
    message: CheckStringEmpty(data?.message),
    datetime: DateTime.ValidDate(data?.createdAt),
    isNotificationRead: CheckBoolean(data?.isNotificationRead),
    showColorRow: !CheckBoolean(data?.isNotificationRead),
  };
};

const Arr: (data: any) => NotificationType[] = (data) => {
  const newArr = CheckArr(data);
  return newArr.map((el) => Obj(el));
};

export default { Obj, Arr };
