import { label } from "Constant/En";
import { FilterDataType } from "DataTypes/Common";

const FilterData: FilterDataType = {
  //Input Type
  patientName: {
    id: "patientName",
    label: "Patient Name",
    type: "input",
    payType: "string",
    value: [],
  },
  practiceName: {
    id: "name",
    label: label.practicename,
    type: "input",
    payType: "string",
    value: [],
  },
  mobile: {
    id: "mobile",
    label: "Mobile Number",
    type: "input",
    payType: "mobile",
    value: [],
  },
  homeNo: {
    id: "homeNumber",
    label: "Home Number",
    type: "input",
    payType: "mobile",
    value: [],
  },
  orgName: {
    id: "organizationName",
    label: "Organization Name",
    type: "input",
    payType: "string",
    value: [],
  },
  orgLocation: {
    id: "organizationLocationName",
    label: "Organization Location Name",
    type: "input",
    payType: "string",
    value: [],
  },
  primIns: {
    id: "primaryInsurance",
    label: "Primary Insurance",
    type: "input",
    payType: "string",
    value: [],
  },
  secIns: {
    id: "secondaryInsurance",
    label: "Secondary Insurance",
    type: "input",
    payType: "string",
    value: [],
  },
  activityType: {
    id: "activityType",
    label: "Action Type",
    type: "input",
    payType: "string",
    value: [],
  },
  actionType: {
    id: "actionType",
    label: "Action Type",
    type: "input",
    payType: "string",
    value: [],
  },
  activityOn: {
    id: "activityOn",
    label: "Action On",
    type: "input",
    payType: "string",
    value: [],
  },
  patientTask: {
    id: "Patient",
    label: "Patient's Name",
    type: "input",
    payType: "string",
    value: [],
  },
  patientFirstName: {
    id: "firstName",
    label: "First Name",
    type: "input",
    payType: "string",
    value: [],
  },
  patientMiddleName: {
    id: "middleName",
    label: "Middle Name",
    type: "input",
    payType: "string",
    value: [],
  },
  patientLastName: {
    id: "lastName",
    label: "Last Name",
    type: "input",
    payType: "string",
    value: [],
  },

  //Input Type With Designations
  provider: {
    id: "providerName",
    label: "Provider Name",
    type: "input",
    payType: "array",
    value: [],
  },
  providerDesig: {
    id: "providerNameDesignation",
    label: "Provider Designation",
    type: "input",
    payType: "string",
    value: [],
  },
  clinicalStaff: {
    id: "clinicalStaff",
    label: "Clinical Staff",
    type: "input",
    payType: "array",
    value: [],
  },
  clinicalStaffDesig: {
    id: "clinicalStaffDesignation",
    label: "Clinical Staff Designation",
    type: "input",
    payType: "string",
    value: [],
  },
  consentGainedBy: {
    id: "consentGainedBy",
    label: "Consent Gained By",
    type: "input",
    payType: "array",
    value: [],
  },
  consentGainedByDesig: {
    id: "consentGainedByDesignation",
    label: "Consent Gained By Designation",
    type: "input",
    payType: "string",
    value: [],
  },
  primCoordinator: {
    id: "primaryCareCoordinator",
    label: "Primary Care Coordinator",
    type: "input",
    payType: "array",
    value: [],
  },
  primCoordinatorDesig: {
    id: "primaryCareCoordinatorDesignation",
    label: "Primary Care Coordinator Designation",
    type: "input",
    payType: "string",
    value: [],
  },
  requestedBy: {
    id: "requestedByName",
    label: "Action By",
    type: "input",
    payType: "array",
    value: [],
  },
  requestedByDesig: {
    id: "requestedByDesignation",
    label: "Action By Designation",
    type: "input",
    payType: "string",
    value: [],
  },
  taskCreatedBy: {
    id: "Created By",
    label: "Created By",
    type: "input",
    payType: "array",
    value: [],
  },
  taskCreatedByDesig: {
    id: "Created By Designation",
    label: "Created By Designation",
    type: "input",
    payType: "string",
    value: [],
  },
  taskAssignee: {
    id: "Assignee",
    label: "Assignee",
    type: "input",
    payType: "array",
    value: [],
  },
  taskAssigneeDesig: {
    id: "Assignee Designation",
    label: "Assignee Designation",
    type: "input",
    payType: "string",
    value: [],
  },

  //Multi select Types
  prgmName: {
    id: "abbrevation",
    label: "Program Name",
    type: "multi-select",
    payType: "array",
    dropArr: [],
    value: [],
  },
  consentType: {
    id: "consentType",
    label: "Consent Type",
    type: "multi-select",
    payType: "array",
    dropArr: [],
    value: [],
  },
  model: {
    id: "businessModelId",
    label: "Model",
    type: "multi-select",
    payType: "array",
    dropArr: [],
    value: [],
  },
  orgPrgm: {
    id: "programId",
    label: "Program Name",
    type: "multi-select",
    payType: "array",
    dropArr: [],
    value: [],
  },
  orgStatus: {
    id: "status",
    label: "Status",
    type: "multi-select",
    payType: "array",
    dropArr: [],
    value: [],
  },
  taskType: {
    id: "Task Type",
    label: "Task Type",
    type: "multi-select",
    payType: "array",
    dropArr: [],
    value: [],
  },
  taskStatus: {
    id: "Status",
    label: "Status",
    type: "multi-select",
    payType: "array",
    dropArr: [],
    value: [],
  },
  taskPriority: {
    id: "Priority",
    label: "Priority",
    type: "multi-select",
    payType: "array",
    dropArr: [],
    value: [],
  },
  notificationType: {
    id: "notificationType",
    label: "Notifications Type",
    type: "multi-select",
    payType: "array",
    dropArr: [],
    value: [],
  },

  //Date Types
  dob: {
    id: "dob",
    label: "Date Of Birth",
    type: "date",
    payType: "string",
    value: "",
  },
  enrollFrom: {
    id: "enrollmentDateFrom",
    label: "Enrollment Start Date",
    type: "date",
    payType: "string",
    value: "",
  },
  enrollTo: {
    id: "enrollmentDateTo",
    label: "Enrollment End Date",
    type: "date",
    payType: "string",
    value: "",
  },
  cpInitiationFrom: {
    id: "cpInitiationDateFrom",
    label: "CP Initiation Start Date",
    type: "date",
    payType: "string",
    value: "",
  },
  cpInitiationTo: {
    id: "cpInitiationDateTo",
    label: "CP Initiation End Date",
    type: "date",
    payType: "string",
    value: "",
  },
  careplanCompletionFrom: {
    id: "careplanCompletionDateFrom",
    label: "CP Completion Date From",
    type: "date",
    payType: "string",
    value: "",
  },
  careplanCompletionTo: {
    id: "careplanCompletionDateTo",
    label: "CP Completion Date To",
    type: "date",
    payType: "string",
    value: "",
  },
  completionDateFrom: {
    id: "dateOfCompletionFrom",
    label: "Completion Date From",
    type: "date",
    payType: "string",
    value: "",
  },
  completionDateTo: {
    id: "dateOfCompletionTo",
    label: "Completion Date To",
    type: "date",
    payType: "string",
    value: "",
  },
  lastCallFrom: {
    id: "lastCallFrom",
    label: "Last Call From",
    type: "date",
    payType: "string",
    value: "",
  },
  lastCallTo: {
    id: "lastCallTo",
    label: "Last Call To",
    type: "date",
    payType: "string",
    value: "",
  },
  startDate: {
    id: "startDate",
    label: "Start Date",
    type: "date",
    payType: "string",
    value: "",
  },
  endDate: {
    id: "endDate",
    label: "End Date",
    type: "date",
    payType: "string",
    value: "",
  },
  taskDate: {
    id: "Date",
    label: "Date",
    type: "date",
    payType: "string",
    value: "",
  },
};

export default FilterData;
