import { Box } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";

const OrgMgmt: FC = () => {
  return (
    <Box height="91%">
      <Outlet />
    </Box>
  );
};
export default OrgMgmt;
