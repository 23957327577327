import { ListActionType, ListInitialStateType } from "DataTypes/Redux";
import {
  CLEAR_LIST,
  STORE_CALLRESULT_LIST,
  STORE_FREQUENCY_LIST,
  STORE_GENDER_LIST,
  STORE_MODEL_DROP_LIST,
  STORE_NOTIFICATION_LIST,
  STORE_PROGRAM_DROP_LIST,
  STORE_PROGRAM_LIST,
  STORE_QUALITY_LIST,
  STORE_ROLE_DROP_LIST,
} from "./type";

const INITIAL_STATE: ListInitialStateType = {
  modelDropList: [],
  prgDropList: [],
  roleDropList: [],
  programList: [],
  notificationList: [],
  genderList: [],
  qualityList: [],
  callResultList: [],
  frequencyList: [],
};

const reducer = (
  state = JSON.parse(JSON.stringify(INITIAL_STATE)),
  action: ListActionType
) => {
  switch (action.type) {
    case STORE_MODEL_DROP_LIST:
      return {
        ...state,
        modelDropList: [...action.payload],
      };
    case STORE_PROGRAM_DROP_LIST:
      return {
        ...state,
        prgDropList: [...action.payload],
      };
    case STORE_PROGRAM_LIST:
      return {
        ...state,
        programList: [...action.payload],
      };
    case STORE_ROLE_DROP_LIST:
      return {
        ...state,
        roleDropList: [...action.payload],
      };
    case STORE_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: [...action.payload],
      };
    case STORE_GENDER_LIST:
      return {
        ...state,
        genderList: [...action.payload],
      };
    case STORE_QUALITY_LIST:
      return {
        ...state,
        qualityList: [...action.payload],
      };
    case STORE_CALLRESULT_LIST:
      return {
        ...state,
        callResultList: [...action.payload],
      };
    case STORE_FREQUENCY_LIST:
      return {
        ...state,
        frequencyList: [...action.payload],
      };
    case CLEAR_LIST:
      return {
        ...state,
        modelDropList: [],
        prgDropList: [],
        roleDropList: [],
        programList: [],
        outgoingTaskList: [],
        notificationList: [],
        genderList: [],
        qualityList: [],
        callResultList: [],
        frequencyList: [],
      };
    default:
      return state;
  }
};

export default reducer;
