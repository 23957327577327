import { actionArrType, columnType } from "DataTypes/Table";
import { ControlledTable } from "Components";
import { BreadArrType, Filter2DataProps } from "DataTypes/Common";
import { FormatDropdownData } from "Utils/DataFormatter";
import { store } from "Redux/Store";
import FilterData from "Components/Filter2/Data";

export const actionArr: (
  handleChart: () => void,
  handlePatientDetails: () => void,
  handleTask: () => void,
  handleViewCare: () => void,
  prgmName: string
) => actionArrType[] = (
  handleChart,
  handlePatientDetails,
  handleTask,
  handleViewCare,
  prgmName
) => {
  return [
    ControlledTable.ActionData.userAction(handlePatientDetails),
    ControlledTable.ActionData.chartAction(handleChart),
    ControlledTable.ActionData.taskAction(handleTask),
    ControlledTable.ActionData.ViewCarePlanAction(
      handleViewCare,
      prgmName == "TCM" ? "View TCM" : "View Care Plan"
    ),
  ];
};

export const TableCol: () => columnType[] = () => {
  return [
    ControlledTable.ColumnData.PicCol(),
    ControlledTable.ColumnData.PatientInfoCol(),
    ControlledTable.ColumnData.PracticeCol(),
    ControlledTable.ColumnData.ClinicalStaffCol(),
    ControlledTable.ColumnData.ProviderCol(),
    ControlledTable.ColumnData.ConsentGainedByCol(),
    ControlledTable.ColumnData.ProgramCol("", "text"),
    ControlledTable.ColumnData.EnrollmentDateCol(),
    ControlledTable.ColumnData.CPICol(),
    ControlledTable.ColumnData.StatusCol(),
    ControlledTable.ColumnData.ActionCol(),
  ];
};

export const EnrolledBreadArr: BreadArrType[] = [
  {
    id: 2,
    label: "Enrolled",
    path: "",
  },
];

export const FilterArr: () => Filter2DataProps[] = () => {
  const { prgDropList } = store.getState().list;
  const Arr: Filter2DataProps[] = [
    FilterData.patientName,
    FilterData.dob,
    FilterData.homeNo,
    FilterData.mobile,
    FilterData.orgName,
    FilterData.orgLocation,
    FilterData.clinicalStaff,
    FilterData.clinicalStaffDesig,
    FilterData.provider,
    FilterData.consentGainedBy,
    FilterData.consentGainedByDesig,
    {
      ...FilterData.prgmName,
      dropArr: FormatDropdownData(prgDropList, "id", "label", "label"),
    },
    FilterData.enrollFrom,
    FilterData.enrollTo,
    FilterData.cpInitiationFrom,
    FilterData.cpInitiationTo,
  ];
  return Arr;
};
