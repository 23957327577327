import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import ImgPath from "Constant/Imgs";
import CstmBtn from "Components/CstmBtn";
import { DataArr } from "./Data";
import { FormDataType } from "DataTypes/Form";
import { LoginLayout } from "Layouts";
import { ControlledBoxSizeSwitch, commonFormSwitch } from "Utils/SwitchCases";
import { AlertProps, HandleChangeProps } from "DataTypes/Common";
import AuthApi from "Service/Auth.api";
import { HandleFormChange, ValidateFormData } from "Utils/common";

const SignUp: FC = () => {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const attempId = queryParameters.get("passwordAttemptId");
  const userName = queryParameters.get("userName") || "";
  const [formData, setFormData] = useState<FormDataType[] | []>(
    JSON.parse(JSON.stringify(DataArr))
  );
  const [alertData, setalertData] = useState<AlertProps>({
    alertMessage: "",
    openAlert: false,
    alertVariant: "",
  });

  useEffect(() => {
    handleChange({ value: userName, id: "login" });
  }, []);

  const handleChange: (props: HandleChangeProps) => void = ({ value, id }) => {
    const newArr = formData.map((el) => {
      if (el.id == id) el.value = value;
      return el;
    });
    setFormData(newArr);
  };

  const handleSubmit = async () => {
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData || formData);
    if (!newArr.errorCheck) {
      if (newArr.formData[1].value == newArr.formData[2].value) {
        const payload = {
          data: {
            userName: userName,
            newPassword: formData[1].value,
            confirmPassword: formData[2].value,
            passwordAttemptId: attempId,
          },
        };
        const res: any = await AuthApi.reset(payload);
        if (res?.success) {
          setalertData(res?.alert);
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        }
      } else {
        setalertData({
          alertMessage: "Both passwords are different.",
          openAlert: true,
          alertVariant: "error",
        });
      }
    }
  };

  const rightBox = (
    <Box sx={{ ...sxRight }}>
      <img src={ImgPath.CV_Logo} alt="" />
      <Typography fontWeight={"bold"} variant="h6">
        Sign Up
      </Typography>
      <Grid container spacing={2} width="80%" sx={{ my: 2 }}>
        {formData.map((el: FormDataType) => {
          return (
            <Grid key={el.id} item {...ControlledBoxSizeSwitch(el.boxSize)}>
              {commonFormSwitch({
                formObj: el,
                onChange: (data) =>
                  setFormData(HandleFormChange(data, formData)),
              })}
            </Grid>
          );
        })}
      </Grid>
      <CstmBtn
        label={"Sign Up"}
        onClick={handleSubmit}
        width="30%"
        py={1.5}
        fontSize="1rem"
      />
    </Box>
  );

  return <LoginLayout rightBox={rightBox} />;
};
export default SignUp;

const sxRight = {
  width: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "& >img:first-of-type": {
    width: "40%",
  },
  "& >button:nth-of-type(1)": {
    mb: 1,
  },
  "& >a:last-child": {
    textDecoration: "none",
    cursor: "pointer",
  },
};
