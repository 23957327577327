import { Box, Typography } from "@mui/material";
import { ControlledForm } from "Components";
import { ReasonArr } from "Constant/Arr";
import DateTime from "Utils/DateTime";
import { Fragment, useState } from "react";
type ModalProps = {
  programName: string;
  patientName?: string;
  onReasonselect?: (reason: string | number) => void;
};

const UnenrollConsent = (props: ModalProps) => {
  const [reason, setReason] = useState("");
  return (
    <Fragment>
      <Box>
        <Box>
          <Typography sx={{ fontWeight: 600, textAlign: "center" }}>
            Why do you want to unenroll patient {props?.patientName} from{" "}
            {props?.programName} program?
          </Typography>
          <Box mt={2} mb={2}>
            <ControlledForm.SingleSelectDrop
              id="unenrollNotes"
              placeholder={"Select Reason"}
              required={false}
              width={"10%"}
              dropArr={ReasonArr}
              onClick={(value) => {
                setReason(typeof value == "string" ? value : "");
                props?.onReasonselect &&
                  props?.onReasonselect(
                    typeof value == "string" || typeof value == "number"
                      ? value
                      : ""
                  );
              }}
            />
          </Box>
          {reason && (
            <Fragment>
              <Box sx={{ ...confirmationBox }}>
                <Typography variant="body1" textAlign={"center"}>
                  Cancellation Form
                </Typography>
                <Typography variant="body2" sx={{ textAlign: "center" }}>
                  As of {DateTime.CurrentDate()}, {props?.patientName} has
                  discontinued service in the {props?.programName} program due
                  to the following reason: {reason}
                </Typography>
              </Box>
              <Typography variant="subtitle1">
                {
                  "Note: Also, previously created appointments for this program will get closed."
                }
              </Typography>
            </Fragment>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default UnenrollConsent;

const confirmationBox = {
  border: "1px solid #cecece",
  p: 1,
};
