import ImgPath from "Constant/Imgs";
import { label } from "Constant/En";
import { SidebarNavProps } from "DataTypes/Common";
import { PatientInfoType } from "DataTypes/Redux";

export const navBarArr: (data: PatientInfoType) => SidebarNavProps[] = (
  data
) => {
  const fullName = data?.name
    ? data.name +
      (data.age || data.gender
        ? " (" +
          (data.age ? data.age : "") +
          (data.gender ? ", " + data.gender : "") +
          ")"
        : "")
    : "";
  return [
    {
      id: "profile",
      icon: <ImgPath.UserIcon color="secondary" fontSize="large" />,
      label: label.profile,
      heading: label.profile,
      iconType: "mui",
      width: "25%",
    },
    {
      id: "find",
      icon: <ImgPath.SearchIcon color="secondary" fontSize="large" />,
      label: label.find,
      heading: label.find,
      iconType: "mui",
      width: "25%",
      twoLayers: true,
    },
    {
      id: "cm",
      icon: <ImgPath.GroupsOutlinedIcon color="secondary" fontSize="large" />,
      label: label.cm,
      width: "90%",
      heading: label.cm + " - " + fullName,
      iconType: "mui",
    },
    {
      id: "probs",
      icon: <ImgPath.Problems width="50%" />,
      label: label.problems,
      heading: label.problems,
      iconType: "flati",
      width: "25%",
    },
    {
      id: "t&v",
      icon: <ImgPath.TV width="50%" />,
      label: label.taskvisit,
      width: "90%",
      heading: label.taskvisit,
      iconType: "flati",
      twoLayers: true,
    },
    {
      id: "meds",
      icon: <ImgPath.Meds width="50%" />,
      label: label.meds,
      heading: label.medications,
      iconType: "flati",
      width: "40%",
      twoLayers: true,
    },
    {
      id: "allergies",
      icon: <ImgPath.Allergies width="80%" />,
      label: label.allergies,
      heading: label.allergies,
      iconType: "flati",
      width: "25%",
    },
    {
      id: "vaccines",
      icon: <ImgPath.syringImage color="secondary" fontSize="large" />,
      label: label.vaccines,
      heading: label.vaccines,
      iconType: "mui",
      width: "25%",
    },
    {
      id: "vitals",
      icon: <ImgPath.Vitals width="50%" />,
      label: label.vitals,
      heading: label.vitals,
      iconType: "flati",
      width: "25%",
    },
    {
      id: "results",
      icon: <ImgPath.Result width="40%" />,
      label: label.result,
      heading: label.result,
      iconType: "flati",
      width: "25%",
    },
    {
      id: "quality",
      icon: <ImgPath.Quality width="50%" />,
      label: label.quality,
      heading: label.quality,
      iconType: "flati",
      width: "25%",
      twoLayers: true,
    },
  ];
};
