import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { BreadProps } from "DataTypes/Common";

const Breadcrumb: FC<BreadProps> = (props) => {
  const navigate = useNavigate();
  const { breadArr } = props;

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ ...BreadcrumbList }}
      >
        {breadArr.map((el, index, breadObj) => {
          if (index + 1 == breadObj.length || el.path.length == 0) {
            return [
              <Typography key={el.id} variant="body2" fontWeight="bold">
                {el.label}
              </Typography>,
            ];
          }
          return [
            <Typography
              key={el.id}
              variant="body2"
              fontWeight="bold"
              sx={{ cursor: "pointer" }}
            >
              <Link
                underline="hover"
                color="inherit"
                onClick={() => navigate(el.path, { replace: true })}
              >
                {el.label}
              </Link>
            </Typography>,
          ];
        })}
      </Breadcrumbs>
    </Stack>
  );
};
export default Breadcrumb;

const BreadcrumbList = {
  "& > ol": {
    "& > li[aria-hidden='true']": {
      margin: "unset",
    },
  },
};
