import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { RedoxSettingModalProps } from "DataTypes/Modal";
import { commonFormSwitch, ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { FormDataType } from "DataTypes/Form";

const RedoxSettingsModal = ({
  formArr,
  handleChange,
  formArr2,
  handleChange1,
}: RedoxSettingModalProps) => {
  const [formData, setFormData] = useState<FormDataType[]>([]);
  const [formData2, setFormData2] = useState<FormDataType[]>([]);

  useEffect(() => {
    formArr && setFormData(formArr);
    formArr2 && setFormData2(formArr2);
  }, [formArr]);
  return (
    <Grid container columnSpacing={3} rowSpacing={3}>
      {formData2 &&
        handleChange1 &&
        formData2.length > 0 &&
        formData2.map((el) => {
          return (
            <Grid
              item
              key={el.id}
              {...(el.boxSize && ControlledBoxSizeSwitch(el.boxSize))}
            >
              {commonFormSwitch({
                formObj: el,
                onChange: (data) => handleChange1(data, [el]),
              })}
            </Grid>
          );
        })}
      {formData &&
        handleChange &&
        formData.length > 0 &&
        formData.map((el) => {
          return (
            <Grid
              item
              key={el.id}
              {...(el.boxSize && ControlledBoxSizeSwitch(el.boxSize))}
            >
              {commonFormSwitch({
                formObj: el,
                onChange: (data) => handleChange(data, [el]),
              })}
            </Grid>
          );
        })}
    </Grid>
  );
};
export default RedoxSettingsModal;
