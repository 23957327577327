import {
  PatientActionReturnType,
  PatientInfoType,
  PatientInitialStateType,
  PatientOrgInfoType,
} from "DataTypes/Redux";
import {
  ADD_PATIENT_INFO,
  ADD_PATIENT_ORG_INFO,
  ADD_SELECTED_PRGM_INFO,
  CLEAR_PATIENT,
  CLEAR_PATIENT_INFO,
  CLEAR_PATIENT_ORG_INFO,
  CLEAR_SELECTED_PRGM_INFO,
} from "./type";
import { FormatEligiblePrgmType } from "DataTypes/PatientManagement.type";

export const PatientInfoInitialState: PatientInfoType = {
  address: "",
  age: "",
  city: "",
  country: "",
  dob: "",
  email: "",
  firstName: "",
  gender: "",
  home: "",
  id: null,
  lastName: "",
  middleName: "",
  mobile: "",
  name: "",
  sex: "",
  state: "",
  streetAddress: "",
  zip: "",
};

export const PatientOrgInfoInitialState: PatientOrgInfoType = {
  practiceId: null,
  practiceName: "",
  practiceLocationId: null,
  practiceLocationName: "",
  practiceLocationTimezone: "",
};

export const selectedPrgmInitialState: FormatEligiblePrgmType = {
  abbrevation: "",
  activity: "",
  appointment_date: "",
  appointment_id: null,
  billableproviderId: null,
  caremanagerId: null,
  careplanCreatedInEhr: "",
  careplanFrequency: "",
  careplanId: null,
  careplanInitDateInEhr: "",
  careplanRecurringId: null,
  careplanState: "",
  careplanStage: 0,
  dischargeDate: "",
  eligibleProgramId: null,
  enrollmentId: null,
  isCareplanComprehensive: false,
  nextSchedule: "",
  patientId: null,
  planningMonth: null,
  planningYear: null,
  practiceId: null,
  practiceLocationId: null,
  practiceLocationName: "",
  practiceLocationTimezone: "",
  practiceName: "",
  primaryccId: null,
  programId: null,
  providerId: null,
  providerName: "",
  providerNameDes: "",
  status: null,
  secondaryccId: null,
  teamInitiatorRoleId: null,
  teamInitiatedBy: null,
  careplanActivity: null,
  showLogTime: false,
  enrollmentDate: "",
  recurringPlanningMonth: null,
  recurringPlanningYear: null,
  readOnlyArr: [],
  callDefaultRoute: "",
  showSaveBtn: false,
  showSubmitBtn: false,
};

const INITIAL_STATE: PatientInitialStateType = {
  patientInfo: PatientInfoInitialState,
  selectedPrgm: selectedPrgmInitialState,
  patientOrg: PatientOrgInfoInitialState,
};

const reducer = (state = INITIAL_STATE, action: PatientActionReturnType) => {
  switch (action.type) {
    case ADD_PATIENT_INFO:
      return {
        ...state,
        patientInfo: action.payload,
      };
    case CLEAR_PATIENT_INFO:
      return {
        ...state,
        patientInfo: PatientInfoInitialState,
      };
    case ADD_PATIENT_ORG_INFO:
      return {
        ...state,
        patientOrg: action.payload,
      };
    case CLEAR_PATIENT_ORG_INFO:
      return {
        ...state,
        patientOrg: PatientOrgInfoInitialState,
      };
    case ADD_SELECTED_PRGM_INFO:
      return {
        ...state,
        selectedPrgm: action.payload,
      };
    case CLEAR_SELECTED_PRGM_INFO:
      return {
        ...state,
        selectedPrgm: null,
      };
    case CLEAR_PATIENT:
      return {
        ...state,
        patientInfo: PatientInfoInitialState,
        selectedPrgm: selectedPrgmInitialState,
        patientOrg: PatientOrgInfoInitialState,
      };
    default:
      return state;
  }
};

export default reducer;
