import { FC, Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { ModalLayout, TableLayout } from "Layouts";
import { ControlledTable, Filter2 } from "Components";
import { actionArr, BreadArr, FilterArr, TableCol } from "./Data";
import PatientApi from "Service/Patient.api";
import { useDispatch } from "react-redux";
import { DeleteButtonArray } from "Components/Modal/Data";
import { setAlertData } from "Redux/Screen/Action";
import { RouteUrls } from "Constant/RouteUrls";
import {
  FetchListProps,
  Filter2DataProps,
  HeadContextType,
} from "DataTypes/Common";
import { CreateFilterPayload1 } from "Utils/common";
import { HeadContext } from "Context/HeadContext";
import TaskFormatter from "Utils/Task.Formatter";
import { TaskListType } from "DataTypes/Services/Tasks";

const OutgoingTasks: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<TaskListType | null>(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [rows, setRows] = useState<TaskListType[]>([]);
  const [searchText, setSearchText] = useState("");
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>(
    JSON.parse(JSON.stringify(FilterArr()))
  );
  const tableHeight = `calc(99% - ${filterHeight}px)`;

  useEffect(() => {
    setBreadArr(BreadArr);
  }, []);

  useEffect(() => {
    handleSetData({ page: 1, limit });
  }, [filterData]);

  const handleSetData: (props: FetchListProps) => void = async (props) => {
    const { page: currentPage, limit: currentLimit } = props;
    const payload = {
      category: "outgoing",
      expectedColumnPair: CreateFilterPayload1(filterData),
    };
    setLoading(true);
    const res = await PatientApi.fetchTaskList({
      data: payload,
      limit: currentLimit,
      offset: (currentPage - 1) * currentLimit,
    });
    if (res?.success) {
      setRows(TaskFormatter.list(res?.data));
      setPage(res.criterion.page);
      setTotalRecord(res?.criterion?.total);
      setLimit(res?.criterion?.limit);
    } else {
      setRows([]);
      setPage(1);
      setTotalRecord(0);
      setLimit(10);
    }
    setLoading(false);
  };

  const handlePatientDetails: (data: TaskListType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.task}/${data.id}/${RouteUrls.View}`
    );
  };

  const handleEdit: (data: TaskListType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.tasking}/${RouteUrls.outgoing}/${data.id}/${RouteUrls.update}`
    );
  };

  useEffect(() => {
    setDeleteModal(selectedRow ? true : false);
  }, [selectedRow]);

  const ConfirmHandler: () => void = async () => {
    const res = await PatientApi.deleteTask({ id1: selectedRow?.id });
    if (res?.success) {
      handleSetData({ page, limit });
      dispatch(setAlertData(res?.alert));
      setSelectedRow(null);
    }
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={rows}
      tableAction={(data) =>
        actionArr(
          () => handlePatientDetails(data),
          () => handleEdit(data),
          () => setSelectedRow(data),
          data
        )
      }
      searchText={searchText}
    />
  );

  return (
    <Fragment>
      <ModalLayout
        modwidth="30%"
        heading={"Warning"}
        content={
          <Typography textAlign={"center"}>
            Do you want to delete this Task?
          </Typography>
        }
        open={deleteModal}
        buttonArr={DeleteButtonArray(ConfirmHandler, () =>
          setSelectedRow(null)
        )}
      />
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => setFilterData(data)}
        changeSearchText={(val) => setSearchText(val)}
        setFilterHeight={(val) => setFilterHeight(val)}
      />
      <TableLayout
        tblBody={TblBodyJSX}
        loading={loading}
        tblHead={ControlledTable.TblHead(TableCol)}
        height={tableHeight}
        Pagination={
          <ControlledTable.Pagination
            mainPage={page}
            limit={limit}
            total={totalRecord}
            handleClick={(currentPage, currentLimit) =>
              handleSetData({ page: currentPage, limit: currentLimit })
            }
            tableStyle={true}
          />
        }
      />
    </Fragment>
  );
};
export default OutgoingTasks;
