import { columnType } from "DataTypes/Table";
import { ControlledTable } from "Components";

export const TableCol: (rowClick: (data: any) => void) => columnType[] = (
  rowClick
) => [
  ControlledTable.ColumnData.DateTimeCol1("15%"),
  ControlledTable.ColumnData.TaskTypeCol("10%"),
  {
    id: "status",
    label: "Status",
    width: "10%",
    align: "center",
    colType: "text",
    rowType: "status",
    rowClick,
  },
  ControlledTable.ColumnData.PriorityCol("10%"),
  ControlledTable.ColumnData.AssigneeCol("15%"),
  ControlledTable.ColumnData.SubjectCol("20%"),
  ControlledTable.ColumnData.NoteCol("20%"),
];

export const VisitsTabelCol: columnType[] = [
  ControlledTable.ColumnData.VisitNumCol(),
  ControlledTable.ColumnData.FacilityCol(),
  ControlledTable.ColumnData.ReasonCol(),
  ControlledTable.ColumnData.DateTimeCol1(),
];
