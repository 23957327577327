import {
  AppSettingListType,
  RedoxSettingListType,
} from "DataTypes/Services/Settings.type";
import {
  CheckStringEmpty,
  CheckNumber,
  CheckArr,
  CheckBoolean,
  CheckTypeId,
} from "Utils/common";

const formateRedoxSettingSearch: (data: any) => RedoxSettingListType[] = (
  data
) => {
  const newArr = CheckArr(data);
  return newArr.map((el) => {
    return {
      id: CheckNumber(el.id),
      version: CheckNumber(el.version),
      praticeLocationId: CheckNumber(el.praticeLocationId),
      type: CheckStringEmpty(el.type),
      data_model: CheckStringEmpty(el.dataModel),
      sourceId: CheckStringEmpty(el.sourceId),
      sourceName: CheckStringEmpty(el.sourceName),
      destinationId: CheckStringEmpty(el.destinationId),
      destinationName: CheckStringEmpty(el.destinationName),
      webhookToken: CheckStringEmpty(el.webhookToken),
      webhookTokenExpireAt: CheckStringEmpty(el.webhookTokenExpireAt),
      apiKey: CheckStringEmpty(el.apiKey),
      apiSecret: CheckStringEmpty(el.apiSecret),
      apiKID: CheckStringEmpty(el.apikid),
      apiExpiry: CheckStringEmpty(el.apiExpiry),
      patientUniqueIdtype: CheckStringEmpty(el.patientUniqueIdtype),
      createdBy: CheckNumber(el.createdBy),
      updated_by: CheckNumber(el.updatedBy),
      createdAt: CheckStringEmpty(el.createdAt),
      updatedAt: CheckStringEmpty(el.updatedAt),
      isDeleted: CheckBoolean(el.isDeleted),
      status: CheckStringEmpty(el.status),
      deletedAt: CheckStringEmpty(el.deletedAt),
      deletedBy: CheckNumber(el.deletedBy),
      authType: CheckStringEmpty(el.authType),
      urlEndPoint: CheckStringEmpty(el.urlEndPoint),
      practiceId: CheckNumber(el?.practiceId),
    };
  });
};

const List: (data: any) => AppSettingListType[] = (data) => {
  const newArr = CheckArr(data);
  return newArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      groupLabel: CheckStringEmpty(el?.groupLabel),
      groupSlug: CheckStringEmpty(el?.groupSlug),
      keyLable: CheckStringEmpty(el?.keyLable),
      keySlug: CheckStringEmpty(el?.keySlug),
      value: CheckStringEmpty(el?.value),
      label: CheckStringEmpty(el?.label),
      typeId: CheckTypeId(el?.typeId),
    };
  });
};

export default { formateRedoxSettingSearch, List };
