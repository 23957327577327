import { useState, useEffect, FC } from "react";
import { Box, Typography } from "@mui/material";
import { CommonStyle, ControlledForm } from "Components";
import ImgPath from "Constant/Imgs";
import { DropdownArrPropsType } from "DataTypes/Form";
import { Fragment } from "react";
import { ChipDropDownProps } from "DataTypes/Master";

const ChipDropDown: FC<ChipDropDownProps> = ({
  placeholder,
  label,
  dropArr,
  selectedVals,
  chipLabel,
  width,
  handleSelect,
  handleRemoveChip,
  readOnly,
  errorText,
  error,
  required,
}) => {
  const [selectedArr, setSelectedArr] = useState<DropdownArrPropsType[]>([]);

  const handleSelectArr = () => {
    const newArr: DropdownArrPropsType[] = [];
    selectedVals.forEach((el) => {
      const newObj: DropdownArrPropsType | undefined = dropArr.find(
        (el1) => el1.value == el
      );
      if (newObj) newArr.push(newObj);
    });
    setSelectedArr(newArr);
  };

  useEffect(() => {
    handleSelectArr();
  }, [selectedVals]);

  return (
    <Fragment>
      <ControlledForm.MultiSelectDrop
        id="program"
        label={label}
        placeholder={placeholder}
        dropArr={dropArr}
        dropValue={selectedVals}
        onClick={(arr) => handleSelect(arr)}
        errorText={errorText || ""}
        error={error || false}
        readOnly={readOnly ? true : false}
        required={required}
        width={width}
      />
      {selectedVals.length > 0 && (
        <Typography variant="subtitle1" fontWeight={"bold"} sx={{ my: 1 }}>
          {`${chipLabel} :`}
        </Typography>
      )}
      {selectedArr.length > 0 && (
        <Box flexWrap={"wrap"} sx={{ ...CommonStyle.sxRow }}>
          {selectedArr.map((el) => {
            return (
              <Typography
                key={el.id}
                sx={{ ...sxChip, ...CommonStyle.sxRow }}
                variant="subtitle1"
                onClick={() =>
                  handleSelect(
                    selectedVals.filter((selEl) => selEl != el.value)
                  )
                }
              >
                {el.label}
                <ImgPath.CloseRound />
              </Typography>
            );
          })}
        </Box>
      )}
    </Fragment>
  );
};
export default ChipDropDown;

const sxChip = {
  backgroundColor: "roundiconbg.main",
  py: 0.75,
  px: 1,
  mr: 1,
  mb: 1,
  borderRadius: 5,
  color: "common.white",
  cursor: "pointer",
  ": hover": {
    backgroundColor: "roundiconbghover.main",
  },
  "& >svg": {
    ml: 1,
    fontSize: "1.1rem",
  },
};
