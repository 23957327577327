import { columnType, actionArrType } from "DataTypes/Table";
import { ControlledTable } from "Components";
import { FormatOrgListType } from "DataTypes/PracticeMangement.type";
import { BreadArrType, Filter2DataProps } from "DataTypes/Common";
import { label } from "Constant/En";
import FilterData from "Components/Filter2/Data";
import { store } from "Redux/Store";

export const TableCol: (handleStaff: (row: any) => void) => columnType[] = (
  handleStaff
) => {
  const Arr: columnType[] = [
    ControlledTable.ColumnData.NameCol("20%", label.practicename),
    ControlledTable.ColumnData.ModelCol("20%"),
    ControlledTable.ColumnData.ProgramCol("20%"),
    ControlledTable.ColumnData.StatusCol("20%"),
    ControlledTable.ColumnData.StaffIconCol(handleStaff, "20%"),
    ControlledTable.ColumnData.ActionCol(),
  ];
  return Arr;
};

export const actionArr: (
  handleEdit: () => void,
  handleClick: () => void,
  handleImportFile: () => void,
  rowData: FormatOrgListType
) => actionArrType[] = (handleEdit, handleClick, handleImportFile, rowData) => {
  const Arr: actionArrType[] = [
    ControlledTable.ActionData.editAction({ handleClick: handleEdit }),
    ControlledTable.ActionData.enableDisableAction(
      handleClick,
      rowData.status == "ACTIVE" ? true : false,
      rowData.masterPractice
    ),
    ControlledTable.ActionData.ImportFileAction(handleImportFile),
  ];
  return Arr;
};
export const statusFilterArr = [
  {
    id: 1,
    label: "ACTIVE",
    value: "ACTIVE",
  },
  {
    id: 2,
    label: "DISABLED",
    value: "DISABLED",
  },
];

export const FilterArr: () => Filter2DataProps[] = () => {
  const { modelDropList, prgDropList } = store.getState().list;
  return [
    FilterData.practiceName,
    { ...FilterData.model, dropArr: modelDropList },
    { ...FilterData.orgPrgm, dropArr: prgDropList },
    { ...FilterData.orgStatus, dropArr: statusFilterArr },
  ];
};

export const BreadData: () => BreadArrType[] = () => {
  return [
    {
      id: 1,
      label: "Home",
      path: "/",
    },
    {
      id: 2,
      label: "Organization List",
      path: "",
    },
  ];
};
