import { FC, useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import CstmBtn from "Components/CstmBtn";
import ImgPath from "Constant/Imgs";
import { LogtimeProps } from "DataTypes/PatientManagement.type";
import { label } from "Constant/En";

const StartStopLogTime: FC<LogtimeProps> = (props) => {
  const {
    time,
    setTime,
    setStartTime,
    setStopTime,
    activity,
    openLogModal,
    openTaskModal,
    status,
    myInfo,
  } = props;
  const [interv, setInterv] = useState<
    ReturnType<typeof setInterval> | undefined
  >();
  let updatedS = time.s,
    updatedM = time.m,
    updatedH = time.h;

  useEffect(() => {
    if (time.h == 0 && time.m == 0 && time.s == 0) clearInterval(interv);
  }, [time]);

  const start: () => void = () => {
    run();
    setInterv(setInterval(run, 1015));
    setStartTime();
  };

  const run: () => void = () => {
    if (updatedM === 59 && updatedS === 59) {
      updatedH++;
      updatedM = 0;
      updatedS = 0;
    } else if (updatedS === 59) {
      updatedM++;
      updatedS = 0;
    } else {
      updatedS++;
    }
    return setTime({ s: updatedS, m: updatedM, h: updatedH });
  };

  const stop: () => void = () => {
    setTime({ s: 0, m: 0, h: 0 });
    setStopTime();
  };

  return (
    <Box
      sx={{ ...CommonStyle.sxRow, "& >button": { ml: 1 }, "& >h6": { ml: 1 } }}
    >
      <Typography
        sx={{ ...CommonStyle.sxWhiteBox, p: 0.5 }}
        variant="subtitle1"
      >
        {activity}
      </Typography>
      <Typography variant="subtitle1" width={100} textAlign="center">
        Time:{" "}
        {`${time.h < 10 ? `0${time.h}` : time.h}` +
          ":" +
          `${time.m < 10 ? `0${time.m}` : time.m}` +
          ":" +
          `${time.s < 10 ? `0${time.s}` : time.s}`}
      </Typography>
      <CstmBtn
        onClick={start}
        label="Start"
        disable={myInfo.roleId == 1 || myInfo.roleId == 2 ? true : status != 1}
      />
      <CstmBtn
        onClick={stop}
        label="Stop"
        disable={myInfo.roleId == 1 || myInfo.roleId == 2 ? true : status != 2}
      />
      <IconButton
        sx={{ ...CommonStyle.sxRoundIconsMainMd }}
        title={label.ct}
        onClick={openTaskModal}
      >
        <ImgPath.CreateTaskIcon />
      </IconButton>
      <IconButton
        sx={{
          ...(myInfo.roleId == 1 || myInfo.roleId == 2
            ? CommonStyle.sxRoundIconsDisabledMd
            : status == 1
              ? CommonStyle.sxRoundIconsMainMd
              : CommonStyle.sxRoundIconsDisabledMd),
        }}
        title="Log Time"
        {...(myInfo.roleId != 1 &&
          myInfo.roleId != 2 &&
          status == 1 && {
          onClick: () => openLogModal(),
        })}
      >
        <ImgPath.AddTimeLogIcon />
      </IconButton>
    </Box>
  );
};
export default StartStopLogTime;
