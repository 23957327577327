import { FC, useState, useEffect, useContext, Fragment } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { CommonStyle, ControlledTable } from "Components";
import { ModalLayout, TableLayout } from "Layouts";
import CstmBtn from "Components/CstmBtn";
import { useNavigate } from "react-router-dom";
import PatientApi from "Service/Patient.api";
import { FormDataType } from "DataTypes/Form";
import { commonFormSwitch } from "Utils/SwitchCases";
import { useSelector } from "react-redux";
import { HandleFormChange, ValidateFormData } from "Utils/common";
import { HeadContextType } from "DataTypes/Common";
import { CallLogsBreadArr, FormDataArr, TableCol } from "./Data";
import { HeadContext } from "Context/HeadContext";
import { RouteUrls } from "Constant/RouteUrls";
import { useDispatch } from "react-redux";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import {
  ListInitialStateType,
  PatientInitialStateType,
  UserInitialStateType,
} from "DataTypes/Redux";
import DateTime from "Utils/DateTime";
import { CommonButtonArray } from "Components/Modal/Data";
import PatientFormatter from "Utils/Patient.Formatter";
import { CallLogListType } from "DataTypes/Services/Patient.type";

const CallLogs: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { patientInfo, selectedPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const newPatientId = patientInfo.id;
  const readOnly = selectedPrgm.readOnlyArr.some((el) => el == "call-log");
  const { callResultList }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const { setBreadArr, setHeadRightUI } = useContext(
    HeadContext
  ) as HeadContextType;
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const [rowData, setRowData] = useState<CallLogListType[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [formArr, setFormArr] = useState<FormDataType[]>([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setBreadArr(CallLogsBreadArr);
    setHeadRightUI(HeadUI);
    handleSetFormData();
  }, []);

  const HeadUI = (
    <CstmBtn
      onClick={() => navigate(`../${RouteUrls.clinicalSummary}`)}
      label="Clinical Summary"
    />
  );

  const handleSetFormData: () => void = () => {
    const UserName = `${myInfo?.firstname} ${myInfo?.lastname}`;
    const newFormData: FormDataType[] = JSON.parse(
      JSON.stringify(FormDataArr())
    );
    const newArr: FormDataType[] = newFormData?.map((el) => {
      if (el.id == "call_name") {
        el.value = UserName;
        el.readOnly = true;
      }
      if (el.id == "call_result") el.dropArr = callResultList;
      if (el.id == "next_call") el.minVal = DateTime.CurrentDateTime();
      return el;
    });
    setFormArr(newArr);
  };

  useEffect(() => {
    handleSetData(page, limit);
  }, [newPatientId]);

  const handleSetData: (
    currentPage: number,
    currentLimit: number
  ) => void = async (currentPage, currentLimit) => {
    setLoading(true);
    const res: any = await PatientApi.callLogList({
      offset: (currentPage - 1) * currentLimit,
      limit: currentLimit,
      id1: newPatientId,
    });
    if (res?.success) {
      setRowData(PatientFormatter.CallLogList(res?.data, callResultList));
      setTotalRecord(res?.criterion?.total);
      setPage(res?.criterion?.page);
      setLimit(res?.criterion?.limit);
    }
    setLoading(false);
  };

  const handleSubmit: (btnText: string) => void = async (btnText) => {
    const newArr = ValidateFormData(formArr);
    setFormArr(newArr.formData);
    if (!newArr.errorCheck) {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      const payload = {
        notes: newArr.formData[3].value,
        callResult: newArr.formData[1].dropValue,
        nextCallDate: DateTime.ToUTCDateTime(newArr.formData[2].value),
        programId: selectedPrgm?.programId,
        careplanId: selectedPrgm?.careplanId,
        careplanRecurringId: selectedPrgm?.careplanRecurringId,
      };
      const res = await PatientApi.callLogCreate({
        id1: newPatientId,
        data: payload,
      });
      if (res?.success) {
        handleSetData(page, limit);
        handleSetFormData();
        dispatch(setAlertData(res?.alert));
        if (btnText == "save-next") {
          navigate(
            `${selectedPrgm?.callDefaultRoute || "../" + RouteUrls.program}`
          );
        }
        dispatch(stopLoading());
      }
    }
  };

  const handleUnResponsiveBtn: () => void = async () => {
    const res = await PatientApi.callLogUnresponsive({
      id1: newPatientId,
    });
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      setOpenModal(false);
    }
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol()}
      tableRow={rowData}
    />
  );

  return (
    <Box width="91%" sx={{ ...CommonStyle.sxWhiteCard }} overflow="auto">
      <ModalLayout
        modwidth="33%"
        heading={"Unresponsive"}
        content={
          <Typography>
            {`Are you sure you want to mark '${patientInfo.name}' as Unresponsive? Patient will get Unenrolled from all previously enrolled programs if marked as Unresponsive. Also, previously created all appointments for Care Plan, Recurring follow up or Call Reminder if any will get closed. Confirm?`}
          </Typography>
        }
        open={openModal}
        onClose={() => setOpenModal(false)}
        buttonArr={CommonButtonArray(
          handleUnResponsiveBtn,
          () => setOpenModal(false),
          "Yes",
          "No"
        )}
        warning={true}
      />
      {!readOnly && (
        <Fragment>
          <Grid container rowSpacing={0} columnSpacing={1} height="14%">
            {formArr?.map((el: FormDataType) => {
              return (
                <Grid key={el.id} item sm={3}>
                  {commonFormSwitch({
                    formObj: el,
                    onChange: (props) =>
                      setFormArr(HandleFormChange(props, formArr)),
                  })}
                </Grid>
              );
            })}
          </Grid>
          <Box
            height={"8%"}
            display="flex"
            flexDirection="row"
            justifyContent={"flex-end"}
            sx={{ "& >button": { ml: 1 } }}
          >
            <CstmBtn
              label="UNRESPONSIVE"
              onClick={() => setOpenModal(true)}
              btnType="border"
              title="Unresponsive-Never Answered"
            />
            <CstmBtn label={"Save"} onClick={() => handleSubmit("save")} />
            {(formArr[1]?.dropValue == "answered" ||
              formArr[1]?.dropValue == "in_person") && (
              <CstmBtn
                label={"Save & Next"}
                onClick={() => handleSubmit("save-next")}
              />
            )}
            <CstmBtn
              label="Cancel"
              onClick={() => navigate(`../${RouteUrls.details}`)}
              btnType="border"
              title="Cancel"
            />
          </Box>
        </Fragment>
      )}
      <Typography
        sx={{
          height: "6%",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          ...CommonStyle.sxHeading,
        }}
      >
        Log History
      </Typography>
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol())}
        height={readOnly ? "92" : "72%"}
        loading={loading}
        Pagination={
          <ControlledTable.Pagination
            mainPage={page}
            limit={limit}
            total={totalRecord}
            handleClick={handleSetData}
            tableStyle={true}
          />
        }
      />
    </Box>
  );
};
export default CallLogs;
