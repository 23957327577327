import ControlledTable from "Components/ControlledTable";
import { columnType } from "DataTypes/Table";

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.CompanyNameCol("15%"),
  ControlledTable.ColumnData.PlanNameCol("15%"),
  ControlledTable.ColumnData.PolicyNumCol("15%"),
  ControlledTable.ColumnData.PriorityCol("15%"),
  ControlledTable.ColumnData.EffectiveDateCol("15%"),
  ControlledTable.ColumnData.ExpirationDateCol("15%"),
];

export const EmergencyTabelCol: columnType[] = [
  ControlledTable.ColumnData.NameCol("15%"),
  ControlledTable.ColumnData.RelationCol("15%"),
  ControlledTable.ColumnData.EmailCol("15%"),
  ControlledTable.ColumnData.MobileNoCol("15%"),
  ControlledTable.ColumnData.OfficeNoCol("15%"),
  ControlledTable.ColumnData.HomeNumberCol("15%"),
];

export const AppointmentsTabelCol: columnType[] = [
  ControlledTable.ColumnData.VisitNumCol("25%"),
  ControlledTable.ColumnData.FacilityCol("25%"),
  ControlledTable.ColumnData.ReasonCol("25%"),
  ControlledTable.ColumnData.DateTimeCol("25%"),
]