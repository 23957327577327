import { BreadArrType } from "DataTypes/Common";

export const PrgmInsBreadArr = () => {
  const Arr: BreadArrType[] = [
    {
      id: 1,
      label: "Program Insurance Mapping",
      path: "",
    },
  ];
  return Arr;
};
