import { clearMaster } from "Redux/Header/Action";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

const QuestionnaireMaster = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearMaster());
    };
  }, []);
  return <Outlet />;
};
export default QuestionnaireMaster;
