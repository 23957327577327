import { FC, useState, useEffect, Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TableLayout } from "Layouts";
import { ControlledTable, Filter2 } from "Components";
import {
  actionArr,
  FilterArr,
  PendingApprovalBreadArr,
  TableCol,
} from "./Data";
import { RouteUrls } from "Constant/RouteUrls";
import { useDispatch, useSelector } from "react-redux";
import Formate from "./Formate";
import PatientApi from "Service/Patient.api";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { UserInitialStateType } from "DataTypes/Redux";
import { HeadContext } from "Context/HeadContext";
import { Filter2DataProps, HeadContextType } from "DataTypes/Common";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import { PendingProviderType } from "DataTypes/Services/Patient.type";
import { CreateFilter2Payload } from "Utils/common";

const PendingProviderApproval: FC = () => {
  const permission: any = useSelector(
    (state: any) => state?.user?.myPermission?.pendingapprovepatient
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const dispatch = useDispatch();
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const navigate = useNavigate();
  const [rows, setRows] = useState<PendingProviderType[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>(
    JSON.parse(JSON.stringify(FilterArr()))
  );
  const tableHeight = `calc(99% - ${filterHeight}px)`;

  useEffect(() => {
    setBreadArr(PendingApprovalBreadArr);
  }, []);

  useEffect(() => {
    handleSetData(1, limit);
  }, [filterData]);

  const handleSetData: (
    currentPage: number,
    currentLimit: number
  ) => void = async (currentPage, currentLimit) => {
    setLoading(true);
    const newFilterPayload = CreateFilter2Payload(filterData);
    const payload = {
      ...(newFilterPayload || {}),
    };
    const res = await PatientApi.PendingApproval({
      offset: (currentPage - 1) * currentLimit,
      limit: currentLimit,
      data: payload,
    });
    if (res?.success) {
      const formattedData = Formate.PendingApprovalList(res?.data);
      setRows(formattedData);
      setPage(res.criterion.page);
      setTotalRecord(res.criterion.total);
      setLimit(res.criterion.limit);
    } else {
      setRows([]);
      setPage(1);
      setTotalRecord(0);
      setLimit(10);
    }
    setLoading(false);
  };

  const handlePatientDetails: (data: PendingProviderType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.details}`
    );
  };

  const handleChart: (data: PendingProviderType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.clinicalSummary}`
    );
  };

  const handleTask: (data: PendingProviderType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.task}/${RouteUrls.add}`
    );
  };

  const handleCarePlan: (data: PendingProviderType) => void = (data) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        readOnlyArr: ["uptoDate"],
        ...(myInfo.roleId == 3 ? { showSaveBtn: true } : {}),
        ...(myInfo.roleId == 5 ? { showSubmitBtn: true } : {}),
      })
    );
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.carePlan}/${data.careplanId}/${RouteUrls.careSummary}`
    );
  };

  const handleViewCare: (data: PendingProviderType) => void = (data) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        readOnlyArr: [
          "uptoDate",
          "problem",
          "question",
          "team",
          "addNotes",
          "frequency",
        ],
      })
    );
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.carePlan}/${data.careplanId}/${RouteUrls.careSummary}`
    );
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={rows}
      tableAction={(data: PendingProviderType) =>
        actionArr(
          () => handleChart(data),
          () => handlePatientDetails(data),
          () => handleTask(data),
          () => handleCarePlan(data),
          () => handleViewCare(data),
          myInfo.roleId
        )
      }
      searchText={searchText}
    />
  );

  return (
    <Fragment>
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => setFilterData(data)}
        changeSearchText={(val) => setSearchText(val)}
        setFilterHeight={(val) => setFilterHeight(val)}
      />
      {permission?.search && (
        <TableLayout
          tblBody={TblBodyJSX}
          tblHead={ControlledTable.TblHead(TableCol)}
          height={tableHeight}
          loading={loading}
          Pagination={
            <ControlledTable.Pagination
              mainPage={page}
              limit={limit}
              total={totalRecord}
              handleClick={handleSetData}
              tableStyle={true}
            />
          }
        />
      )}
    </Fragment>
  );
};
export default PendingProviderApproval;
