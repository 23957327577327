import { Modal } from "Components";
import { HeadContext } from "Context/HeadContext";
import {
  HandleChangeProps,
  HeadContextType,
  TimerContextType,
} from "DataTypes/Common";
import { FC, useContext, useState, useEffect, Fragment, useRef } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import PatientApi from "Service/Patient.api";
import { ModalLayout } from "Layouts";
import { CommonButtonArray } from "Components/Modal/Data";
import { PatientInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useDispatch, useSelector } from "react-redux";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { FormDataType } from "DataTypes/Form";
import {
  CheckStringEmpty,
  ConvertStringToNum,
  HandleFormChange,
  ValidateFormData,
} from "Utils/common";
import { TimeModalFormData } from "./Data";
import { setAlertData } from "Redux/Screen/Action";
import PatientFormatter from "Utils/Patient.Formatter";
import StartStopLogTime from "Components/StartStopLogTime";
import DateTime from "Utils/DateTime";
import { TimerContext } from "Context/TimerContext";
import { FormatEligiblePrgmType } from "DataTypes/PatientManagement.type";

const CarePlan: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { status, setStatus } = useContext(TimerContext) as TimerContextType;
  const { patientInfo, selectedPrgm, patientOrg }: PatientInitialStateType =
    useSelector((state: any) => state?.patient);
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const pathname = location.pathname;
  const { careplanId } = useParams();
  const newPatientId = patientInfo.id;
  const newCPId = ConvertStringToNum(careplanId);
  const { setHeadRightUI, setSpaceBtw } = useContext(
    HeadContext
  ) as HeadContextType;
  const [formData, setFormData] = useState<FormDataType[]>([]);
  const [time, setTime] = useState({ s: 0, m: 0, h: 0 });
  const [startDate, setStartDate] = useState("");
  const [openTimeLogModal, setOpenTimeLogModal] = useState(false);
  const [openCreateTaskModal, SetOpenCreateTaskModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const componentWillUnmount = useRef(false);

  const handleOpenFormData: (activity: string) => FormDataType[] = (
    activity
  ) => {
    const newFormData: FormDataType[] = JSON.parse(
      JSON.stringify(TimeModalFormData)
    );
    newFormData[0].required = true;
    newFormData[1].required = true;
    newFormData[2].value = activity;
    newFormData[2].readOnly = true;
    newFormData[2].required = true;
    newFormData[3].readOnly = true;
    newFormData[3].value = "0";
    newFormData[3].required = false;
    return newFormData;
  };

  useEffect(() => {
    setSpaceBtw(true);
    setStatus(1);
    return () => {
      setSpaceBtw(false);
      componentWillUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    status == 1 && startDate && handleTimeLog();
    return () => {
      componentWillUnmount.current &&
        status == 2 &&
        startDate &&
        handleTimeLog();
    };
  }, [status, startDate]);

  useEffect(() => {
    handleHistoryPrgmCall();
  }, [newCPId]);

  const handleHistoryPrgmCall: () => void = async () => {
    const res: any = await PatientApi.enrollPrgmHistory({
      id1: newPatientId,
    });
    if (res.success) {
      const formatArr = PatientFormatter.CheckEligiblePrgmRes(res?.data);
      const find = formatArr.find((el) => el.careplanId == newCPId);
      if (find && selectedPrgm) {
        const newObj: FormatEligiblePrgmType = {
          ...selectedPrgm,
          ...find,
          careplanRecurringId:
            selectedPrgm.careplanRecurringId ||
            find.careplanRecurringId ||
            null,
        };
        if (!newObj.careplanRecurringId && myInfo.roleId == 4) {
          dispatch(
            setAlertData({
              alertMessage: "This appointment is either closed or invaid",
              alertVariant: "error",
              openAlert: true,
            })
          );
          navigate(-1);
        } else {
          dispatch(addSelectedPrgm(newObj));
          setFormData(handleOpenFormData(newObj.careplanActivity || ""));
        }
      } else {
        dispatch(
          setAlertData({
            alertMessage: "This appointment is either closed or invaid",
            alertVariant: "error",
            openAlert: true,
          })
        );
        navigate(-1);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    selectedPrgm?.showLogTime &&
      setHeadRightUI(
        <StartStopLogTime
          activity={selectedPrgm?.careplanActivity || ""}
          openLogModal={() => setOpenTimeLogModal(true)}
          openTaskModal={() => SetOpenCreateTaskModal(true)}
          setStartTime={() => {
            setStatus(2);
            setStartDate(DateTime.CurrentUTCDateTimeSec());
          }}
          setStopTime={() => setStatus(1)}
          setTime={(val) => setTime(val)}
          time={time}
          status={status}
          myInfo={myInfo}
        />
      );
  }, [time, pathname, selectedPrgm, status]);

  const handleTimeLog: () => void = async () => {
    setTime({ s: 0, m: 0, h: 0 });
    const endTime = DateTime.CurrentUTCDateTimeSec();
    const payload = {
      careplanId: selectedPrgm?.careplanId,
      careplanRecurringId: selectedPrgm?.careplanRecurringId || null,
      startDateTime: startDate,
      endDateTime: endTime,
      notes: "",
      reasons: selectedPrgm?.careplanActivity || null,
      status: "ACTIVE",
      date: endTime,
      billingMonth: parseInt(DateTime.CurrentMonth()),
      billingYear: parseInt(DateTime.CurrentYear()),
    };
    const res = await PatientApi.timeLog({ data: payload, id1: careplanId });
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      setStartDate("");
    }
  };

  const handleModalTimeLog: () => void = async () => {
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData);
    if (!newArr.errorCheck) {
      const payload = {
        careplanId: selectedPrgm?.careplanId,
        careplanRecurringId: selectedPrgm?.careplanRecurringId || null,
        startDateTime: DateTime.ToUTCDateTime(formData[0].dropValue),
        endDateTime: DateTime.ToUTCDateTime(formData[1].dropValue),
        notes: CheckStringEmpty(formData[4].value),
        reasons: selectedPrgm?.careplanActivity || null,
        status: "ACTIVE",
        date: DateTime.CurrentUTCDateTime(),
        billingMonth: parseInt(DateTime.CurrentMonth()),
        billingYear: parseInt(DateTime.CurrentYear()),
      };
      const res = await PatientApi.timeLog({ data: payload, id1: careplanId });
      if (res.success) {
        dispatch(setAlertData(res?.alert));
        handleTimeModalClose();
      }
    }
  };

  const handleChange: (props: HandleChangeProps) => void = (props) => {
    const { value, id } = props;
    const newFormData: FormDataType[] = JSON.parse(JSON.stringify(formData));
    if (id == "start-date-time") {
      newFormData[1].minVal = CheckStringEmpty(value);
      newFormData[3].value = DateTime.CalculateDuration(
        CheckStringEmpty(value),
        CheckStringEmpty(newFormData[1].value)
      ).toString();
    }
    if (id == "end-date-time") {
      newFormData[0].maxVal = CheckStringEmpty(value);
      newFormData[3].value = DateTime.CalculateDuration(
        CheckStringEmpty(newFormData[0].value),
        CheckStringEmpty(value)
      ).toString();
    }
    setFormData(HandleFormChange(props, newFormData));
  };

  const handleTimeModalClose: () => void = () => {
    setOpenTimeLogModal(false);
    setFormData(handleOpenFormData(selectedPrgm.careplanActivity || ""));
  };

  return (
    <Fragment>
      {!loading && selectedPrgm && (
        <Fragment>
          <ModalLayout
            modwidth="50%"
            heading={"Log Time"}
            content={
              <Modal.LogTimeModal
                formData={formData}
                onChange={handleChange}
                patientName={patientInfo.name}
                practiceName={patientOrg.practiceName}
                billingMonth={`${DateTime.CurrentMonthName()}-${DateTime.CurrentYear()}`}
              />
            }
            open={openTimeLogModal}
            onClose={handleTimeModalClose}
            buttonArr={CommonButtonArray(
              () => handleModalTimeLog(),
              handleTimeModalClose
            )}
          />
          <ModalLayout
            modwidth="55%"
            heading={"Create Task"}
            content={
              <Modal.Task
                from="care plan"
                SetOpenCreateTaskModal={SetOpenCreateTaskModal}
              />
            }
            open={openCreateTaskModal}
            onClose={() => {
              SetOpenCreateTaskModal(false);
            }}
          />
          <Outlet />
        </Fragment>
      )}
    </Fragment>
  );
};

export default CarePlan;
