import Stages from "Constant/Stages";
import {
  ActionIdType,
  PrgmDetailsType,
} from "DataTypes/PatientManagement.type";
import PatientFormatter from "Utils/Patient.Formatter";
import { AscendSortByNumber, CheckArr } from "Utils/common";

const PrgmDetails: (
  res: any,
  roleId: number | null,
  userId: number | null
) => PrgmDetailsType[] = (res, roleId, userId) => {
  const CheckedArr = CheckArr(res);
  const newArr = CheckedArr.map((el) => {
    const Obj = PatientFormatter.EligiblePrgmResObj(el);
    let teamMember = false; //Check for login user is team member or not
    const actionArr: ActionIdType[] = [];

    //Check for login user is team member or not
    if (
      Obj.billableproviderId == userId ||
      Obj.caremanagerId == userId ||
      Obj.providerId == userId ||
      Obj.primaryccId == userId ||
      Obj.secondaryccId == userId
    ) {
      teamMember = true;
    }

    if (Obj.programId == 3) {
      //for tcm
      if (Obj.status == "Not Enrolled") {
        //status == not-enroll
        if (roleId == 3 || roleId == 4 || roleId == 5)
          actionArr.push("tcm-enroll");
        else actionArr.push("view-call-logs");
      } else if (Obj.status == "Enrolled") {
        //status == enroll
        if (roleId != 4) {
          if (Obj.careplanState == Stages.Recurring) {
            if (teamMember) actionArr.push("un-enroll");
            actionArr.push("view-call-logs");
            actionArr.push("view-tcm");
          } else if (Obj.careplanState == Stages.CPAssignment) {
            actionArr.push("view-call-logs");
            actionArr.push("view-discharge");
          } else {
            if (teamMember) {
              actionArr.push("un-enroll");
              actionArr.push("tcm-call");
            } else {
              actionArr.push("view-call-logs");
              actionArr.push("view-tcm");
            }
          }
        } else {
          actionArr.push("view-call-logs");
          actionArr.push("view-discharge");
        }
      } else {
        //status == unenroll, decline, pending
        if (roleId == 3 || roleId == 4 || roleId == 5)
          actionArr.push("tcm-re-enroll");
        else actionArr.push("view-call-logs");
      }
    } else {
      //for other programs
      if (Obj.status == "Not Enrolled") {
        //status == not-enroll
        if (roleId == 1 || roleId == 2) {
          actionArr.push("view-call-logs");
        } else actionArr.push("enroll");
      } else if (Obj.status == "Enrolled") {
        //status == enroll
        if (Obj.careplanState == Stages.Recurring) {
          if (teamMember) {
            actionArr.push("un-enroll");
            actionArr.push("recurring-call");
          } else {
            actionArr.push("view-call-logs");
            actionArr.push("view-recurring-plan");
          }
        } else if (Obj.careplanState == Stages.CPSignOff) {
          ///Changing conditions for coordinator role since coordinator can view care plan #9985
          if (roleId != 4) {
            if (teamMember) {
              actionArr.push("un-enroll");
              actionArr.push("edit-care-plan");
            } else actionArr.push("view-care-plan");
          }
          actionArr.push("view-call-logs");
        } else if (Obj.careplanState == Stages.CPAssignment) {
          actionArr.push("view-call-logs");
        } else {
          ///Changing conditions for coordinator role since coordinator can view care plan #9985
          if (roleId != 4) {
            if (teamMember) {
              actionArr.push("un-enroll");
              actionArr.push("care-plan-call");
            } else actionArr.push("view-call-logs");
            actionArr.push("view-care-plan");
          } else actionArr.push("view-call-logs");
        }
      } else {
        //status == unenroll, decline, pending
        if (roleId == 1 || roleId == 2) actionArr.push("view-call-logs");
        else actionArr.push("re-enroll");
      }
    }

    return {
      ...Obj,
      actionArr,
      careplanId: Obj.status == "Enrolled" ? Obj.careplanId : null,
      careplanRecurringId:
        Obj.status == "Enrolled" ? Obj.careplanRecurringId : null,
    };
  });
  return AscendSortByNumber(newArr, "programId");
};

export default { PrgmDetails };
