import { FC, useState, useEffect, Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { actionArr, EnrolledBreadArr, FilterArr, TableCol } from "./Data";
import { TableLayout } from "Layouts";
import { ControlledTable, Filter2 } from "Components";
import { RouteUrls } from "Constant/RouteUrls";
import PatientApi from "Service/Patient.api";
import Formate from "./Formate";
import { HeadContext } from "Context/HeadContext";
import { Filter2DataProps, HeadContextType } from "DataTypes/Common";
import { useDispatch } from "react-redux";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import PatientFormatter from "Utils/Patient.Formatter";
import { EnrolledPatientType } from "DataTypes/Services/Patient.type";
import { CreateFilter2Payload } from "Utils/common";

const Enrolled: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const [limit, setLimit] = useState<number>(10);
  const [loading, setLoading] = useState(true);
  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<EnrolledPatientType[]>([]);
  const [searchText, setSearchText] = useState("");
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>(
    JSON.parse(JSON.stringify(FilterArr()))
  );
  const tableHeight = `calc(99% - ${filterHeight}px)`;

  useEffect(() => {
    setBreadArr(EnrolledBreadArr);
  }, []);

  useEffect(() => {
    handleSetData(1, limit);
  }, [filterData]);

  const handleSetData: (
    currentPage: number,
    currentLimit: number
  ) => void = async (currentPage, currentLimit) => {
    setLoading(true);
    const newFilterPayload = CreateFilter2Payload(filterData);
    const payload = {
      ...(newFilterPayload || {}),
    };
    const res = await PatientApi.Enrolled({
      offset: (currentPage - 1) * currentLimit,
      limit: currentLimit,
      data: payload,
    });
    if (res?.success) {
      setRows(Formate.EnrolledPatientList(res?.data));
      setPage(res.criterion.page);
      setTotalRecord(res.criterion.total);
      setLimit(res.criterion.limit);
    } else {
      setRows([]);
      setPage(1);
      setTotalRecord(0);
      setLimit(10);
    }
    setLoading(false);
  };

  const handlePatientDetails: (data: EnrolledPatientType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.details}`
    );
  };

  const handleChart: (data: EnrolledPatientType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.clinicalSummary}`
    );
  };

  const handleTask: (data: EnrolledPatientType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.task}/${RouteUrls.add}`
    );
  };

  const handleViewCare: (data: EnrolledPatientType) => void = (data) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        readOnlyArr: [
          "uptoDate",
          "problem",
          "question",
          "team",
          "addNotes",
          "frequency",
        ],
      })
    );
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${
        data.program == "TCM" ? RouteUrls.tcm : RouteUrls.carePlan
      }/${data.careplanId}/${PatientFormatter.StateRouteSwitch(
        data.careplanState
      )}`
    );
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol()}
      tableRow={rows}
      tableAction={(data: EnrolledPatientType) =>
        actionArr(
          () => handleChart(data),
          () => handlePatientDetails(data),
          () => handleTask(data),
          () => handleViewCare(data),
          data.program
        )
      }
      searchText={searchText}
    />
  );

  return (
    <Fragment>
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => setFilterData(data)}
        changeSearchText={(val) => setSearchText(val)}
        setFilterHeight={(val) => setFilterHeight(val)}
      />
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol())}
        height={tableHeight}
        loading={loading}
        Pagination={
          <ControlledTable.Pagination
            mainPage={page}
            limit={limit}
            total={totalRecord}
            handleClick={handleSetData}
            tableStyle={true}
          />
        }
      />
    </Fragment>
  );
};
export default Enrolled;
