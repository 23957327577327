import { PendingProviderType } from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";
import PatientFormatter from "Utils/Patient.Formatter";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "Utils/common";

const PendingApprovalList: (data: any) => PendingProviderType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: PendingProviderType[] = CheckedArr.map((el, index) => {
    const provider = CheckStringEmpty(el?.teamProviderName);
    const providerDes = CheckStringEmpty(el?.teamProviderNameDesignation);
    const user = CheckStringEmpty(el?.consentGainedBy);
    const userDes = CheckStringEmpty(el?.consentGainedByDesignation);
    const staff = CheckStringEmpty(el?.clinicalStaff);
    const staffDes = CheckStringEmpty(el?.clinicalStaffDesignation);
    return {
      ...PatientFormatter.ListPatientInfo1(el),
      careplancompletion: DateTime.ValidDate(el?.careplanCompletionDate),
      careplanId: CheckNumber(el?.careplanId),
      careplanState: CheckStringEmpty(el?.careplanState),
      clinicalStaff: staff ? `${staff}${staffDes ? ", " + staffDes : ""}` : "",
      consentgainedby: user ? `${user}${userDes ? ", " + userDes : ""}` : "",
      enrolledProgramId: CheckNumber(el?.enrolledProgramId),
      enrollmentdate: DateTime.ValidDate(el?.enrolmentDate),
      id: index,
      program: CheckStringEmpty(el?.programAbbrevation),
      patientId: CheckStringId(el?.id),
      programId: CheckNumber(el?.programId),
      providerId: CheckNumber(el?.teamProviderId),
      provider: provider
        ? `${provider}${providerDes ? ", " + providerDes : ""}`
        : "",
      status: CheckStringEmpty(el?.status),
    };
  });
  return newArr;
};

export default { PendingApprovalList };
