import { ApiServiceProps } from "DataTypes/Services/Index.type";
import { post } from ".";

const PatientList: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "appointment/patient/search",
    data: {
      data: {},
      criterion: {
        offset: payload.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const Search: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "appointment/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const Create: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "appointment/create",
    data: { data: payload.data },
  };
  return post(request);
};

const Read: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `appointment/${payload.id1}/read`,
  };
  return post(request);
};

const Update: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `appointment/${payload.id1}/update`,
    data: { data: payload.data },
  };
  return post(request);
};

export default { PatientList, Search, Create, Read, Update };
