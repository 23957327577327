import { Box, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import { useEffect, useContext } from "react";
import { BreadArr } from "./Data";
import { HeadContext } from "Context/HeadContext";
import { HeadContextType } from "DataTypes/Common";
import ImgPath from "Constant/Imgs";

const AboutUs = () => {
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;

  useEffect(() => {
    setBreadArr(BreadArr);
  }, []);

  return (
    <Box sx={{ ...sxMain }}>
      <img src={ImgPath.CV_Logo} alt="" />
      <Typography variant="body1">
        <b>Version:</b> 1.0.2024.04.15
      </Typography>
    </Box>
  );
};
export default AboutUs;

const sxMain = {
  ...CommonStyle.sxWhiteBox,
  textAlign: "end",
  height: "91%",
  overflow: "auto",
  px: 2,
  "& >img:first-of-type": {
    width: "7%",
    mt: 2,
  },
};
