import BreadUrls from "Constant/BreadUrls";
import { BreadArrType } from "DataTypes/Common";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import { PatientInfoType } from "DataTypes/Redux";

export const BreadData: (
  patient: PatientInfoType,
  isTCM: boolean,
  careplanId: number | null,
  isCareplanComprehensive: boolean
) => BreadArrType[] = (patient, isTCM, careplanId, isCareplanComprehensive) => {
  if (isTCM) {
    return [
      {
        id: 1,
        label: `${patient.name}`,
        path: BreadUrls.ClinicalSummary(patient.id),
      },
      {
        id: 2,
        label: "Questionnaire",
        path: BreadUrls.TCMCPQues(patient.id, careplanId),
      },
      {
        id: 3,
        label: "Team",
        path: "",
      },
    ];
  } else {
    return [
      {
        id: 1,
        label: `${patient.name}`,
        path: BreadUrls.ClinicalSummary(patient.id),
      },
      {
        id: 2,
        label: "Problems",
        path: BreadUrls.CPProbs(patient.id, careplanId),
      },
      ...(!isCareplanComprehensive
        ? [
          {
            id: 3,
            label: "Questionnaire",
            path: BreadUrls.CPQues(patient.id, careplanId),
          },
        ]
        : []),
      {
        id: 4,
        label: "Team",
        path: "",
      },
    ];
  }
};

export const PrimaryInfoArr = (
  teamInitiatorRoleId: number | null,
  ProviderDropData: DropdownArrPropsType[],
  primeCareCoordinatorDropData: DropdownArrPropsType[],
  secCareCoordinatorDropData: DropdownArrPropsType[],
  CareManagerDropData: DropdownArrPropsType[],
  prgm: string
) => {
  const newObj: FormDataType = {
    id: "cp-billable-provider",
    label:
      prgm == "TCM"
        ? "Treatment Plan Billable Provider"
        : "Care Plan Billable Provider",
    placeholder: "Select Care Plan Billable Provider",
    boxType: "single-select-drop",
    boxSize: 3,
    validatetype: "dropdown",
    required: true,
    dropArr: ProviderDropData ? ProviderDropData : [],
    readOnly: true,
  };
  const nonTCM: FormDataType[] = [
    {
      id: "primary-care-coordinator",
      label: "Primary Care Coordinator",
      placeholder: "Select Primary Care Coordinator",
      boxType: "single-select-drop",
      boxSize: 3,
      validatetype: "dropdown",
      required: true,
      dropArr: primeCareCoordinatorDropData,
    },
    {
      id: "secondary-care-coordinator",
      label: "Secondary Care Coordinator",
      placeholder: "Select Secondary Care Coordinator",
      boxType: "single-select-drop",
      boxSize: 3,
      validatetype: "dropdown",
      required: false,
      dropArr: secCareCoordinatorDropData,
    },
  ];
  const arr: FormDataType[] = [
    {
      id: "care-manager",
      label: "Care Manager",
      placeholder: "Enter Care Manager Name",
      boxType: "single-select-drop",
      boxSize: 3,
      validatetype: "dropdown",
      required: true,
      dropArr: CareManagerDropData ? CareManagerDropData : [],
    },
    ...(teamInitiatorRoleId == 5 ? [newObj] : []),
    {
      id: "billable-provider",
      label: "Billable Provider",
      placeholder: "Select Billable Provider",
      boxType: "single-select-drop",
      boxSize: 3,
      validatetype: "dropdown",
      required: true,
      dropArr: ProviderDropData ? ProviderDropData : [],
    },
    {
      id: "speciality",
      label: "Billable Provider's Speciality",
      placeholder: "Enter Billable Provider's Speciality",
      boxType: "input-box",
      boxSize: 3,
      validatetype: "alphanumericName",
      required: false,
      readOnly: true,
    },
    ...(prgm == "TCM" ? [] : [...nonTCM]),
    {
      id: "authorized-representative1",
      label: "Authorized Representative 1",
      placeholder: "Enter Authorized Representative",
      boxType: "input-box",
      boxSize: 3,
      validatetype: "authRepresent",
      required: false,
    },
    {
      id: "authorized-representative2",
      label: "Authorized Representative 2",
      placeholder: "Enter Authorized Representative",
      boxType: "input-box",
      boxSize: 3,
      validatetype: "authRepresent",
      required: false,
    },
    {
      id: "authorized-representative3",
      label: "Authorized Representative 3",
      placeholder: "Enter Authorized Representative",
      boxType: "input-box",
      boxSize: 3,
      validatetype: "authRepresent",
      required: false,
    },
  ];
  return arr;
};

export const AdditionalProvidarArr: FormDataType[] = [
  {
    id: "additional-provider",
    label: "Additional Provider",
    placeholder: "Enter Provider Name",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "alphanumericPeriod",
    required: true,
  },
  {
    id: "add-speciality",
    label: "Additional Provider's Speciality",
    placeholder: "Enter Provider Speciality",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "alphanumericName",
  },
  {
    id: "organization-name",
    label: "Organization Name",
    placeholder: "Enter Organization Name",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "org-name",
  },
  {
    id: "phone-number",
    label: "Phone Number",
    placeholder: "Enter Phone Number",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "mobile no.",
  },
  {
    id: "fax-number",
    label: "Fax Number",
    placeholder: "Enter Fax Number",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "fax-number",
  },
];
