import { MdmArr } from "Components/SidebarModal/CM/DischargeSummary/Data";
import { label } from "Constant/En";
import { FormDataObjProps, FormDataType } from "DataTypes/Form";

const LoginData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "login",
    placeholder: label.userId,
    boxType: "login-input-box",
    boxSize: 3,
    validatetype: "userid",
    iconId: "login-icon",
    required: true,
    requiredSign: false,
  };
};

const PasswordData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "password",
    placeholder: label.password,
    boxType: "login-input-box",
    type: "password",
    validatetype: "login-password",
    iconId: "password-icon",
    iconClick: true,
    required: true,
    requiredSign: false,
  };
};

const OtpData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "otp",
    placeholder: "Enter Verification Code",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "otp",
    required: true,
    requiredSign: false,
  };
};

const PracticeNpiData: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "npi",
    label: "Organization NPI",
    placeholder: "Enter Organization NPI",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "npi no.",
    required: props?.required ? true : false,
  };
};

const PracticeNameData: (props?: FormDataObjProps) => FormDataType = (
  props
) => {
  return {
    id: "name",
    label: "Organization Name",
    placeholder: "Enter Organization Name",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "org-name",
    required: props?.required ? true : false,
    uniqueArr: props?.uniqueArr || [],
  };
};

const BusinessModelData: (props?: FormDataObjProps) => FormDataType = (
  props
) => {
  return {
    id: "model-id",
    label: "Model",
    placeholder: "Select Model",
    boxType: "single-select-drop",
    dropArr: props?.dropArr,
    boxSize: 3,
    validatetype: "dropdown",
    required: props?.required ? true : false,
  };
};

const ProgramData: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "program-id",
    label: "Select Program",
    placeholder: "Select Program",
    boxType: "multi-select-drop",
    dropArr: props?.dropArr,
    boxSize: 3,
    validatetype: "multi-dropdown",
    required: props?.required ? true : false,
    disableSelectAll: props?.disableSelectAll,
    width: props?.width || "100%",
  };
};

const ProgramName: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "program-name",
    // label: "Program Name",
    placeholder: "",
    boxType: "input-box",
    boxSize: 1,
    validatetype: "name",
    readOnly: props?.readonly ? true : false,
  };
};

const ConsentDrop: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "consent-drop",
    // label: "Consent",
    placeholder: "Select Consent",
    boxType: "single-select-drop",
    dropArr: props?.dropArr,
    boxSize: 2,
    validatetype: "dropdown",
    required: props?.required ? true : false,
  };
};

const DOEDrop: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "doe",
    boxType: "mui-date",
    boxSize: 2,
    validatetype: "date",
    readOnly: props?.readonly ? true : false,
    required: props?.required ? true : false,
  };
};

const DOIDrop: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "doi",
    boxType: "mui-date",
    boxSize: 2,
    validatetype: "date",
    readOnly: true,
  };
};

const ConsentTypeDrop: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "consent-type",
    // label: "Consent",
    placeholder: "Select Consent Type",
    boxType: "single-select-drop",
    dropArr: props?.dropArr,
    boxSize: 2,
    validatetype: "dropdown",
    required: props?.required ? true : false,
    readOnly: props?.readonly ? true : false,
  };
};

const ConsentCreatedDrop: (props?: FormDataObjProps) => FormDataType = (
  props
) => {
  return {
    id: "consent-create",
    // label: "Consent",
    placeholder: "Select Option",
    boxType: "single-select-drop",
    dropArr: props?.dropArr,
    boxSize: 2,
    validatetype: "dropdown",
    required: props?.required ? true : false,
    readOnly: props?.readonly ? true : false,
  };
};

const ReasonDrop: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "reason",
    // label: "Consent",
    placeholder: "Select Reason",
    boxType: "single-select-drop",
    dropArr: props?.dropArr,
    boxSize: 3,
    validatetype: "dropdown",
    readOnly: props?.readonly ? true : false,
  };
};

const PracticeAdminNameData: (props?: FormDataObjProps) => FormDataType = (
  props
) => {
  return {
    id: "practice-admin-name",
    label: "Organization Admin",
    placeholder: "Enter Organization Admin Name",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "alphanumericPeriod",
    required: props?.required ? true : false,
  };
};

const PrimEmailData: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "prim-email-id",
    label: "Email ID",
    placeholder: "Enter Email ID",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "email",
    required: props?.required ? true : false,
  };
};

const SecEmailData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "sec-email-id",
    label: "Secondary Email ID",
    placeholder: "Enter Secondary Email ID",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "email",
  };
};

const LocationNameData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "location1",
    label: "Location Name",
    placeholder: "Enter Location Name",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "org-name",
    required: true,
  };
};

const AddressData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "address",
    label: "Address",
    placeholder: "Enter Address",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "address",
    required: true,
  };
};

const Address1Data: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "address1",
    label: "Address 1",
    placeholder: "Enter Address 1",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "address",
    required: true,
  };
};

const Address2Data: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "address2",
    label: "Address 2",
    placeholder: "Enter Address 2",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "address",
    required: props?.required ? props?.required : false,
  };
};

const CityNameData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "city",
    label: "City",
    placeholder: "Enter City",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "alphanumericPeriod",
    required: true,
  };
};

const StateNameData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "state",
    label: "State",
    placeholder: "Enter State",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "alphanumericPeriod",
    required: true,
  };
};

const ZipcodeData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "zipCode",
    label: "ZIP Code",
    placeholder: "Enter ZIP Code",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "zipcode",
    required: true,
  };
};

const CountryNameData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "country",
    label: "Country",
    placeholder: "Enter Country",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "alphanumericPeriod",
    required: true,
  };
};

const OfficeNoData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "officeNumber",
    label: "Office Number",
    placeholder: "Enter Office Number",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "mobile no.",
    required: true,
  };
};

const DirectNoData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "direct-number",
    label: "Direct Phone Number",
    placeholder: "Enter Direct Phone Number",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "mobile no.",
    required: true,
  };
};

const DirectNo2Data: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "direct-number-2",
    label: "Direct Phone Number 2",
    placeholder: "Enter Direct Phone Number 2",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "mobile no.",
  };
};

const DirectNo3Data: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "direct-number-3",
    label: "Direct Phone Number 3",
    placeholder: "Enter Direct Phone Number 3",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "mobile no.",
  };
};

const DirectNo4Data: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "direct-number-4",
    label: "Direct Phone Number 4",
    placeholder: "Enter Direct Phone Number 4",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "mobile no.",
  };
};

const ServiceLocationData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "serviceLocation",
    label: "Place of Service",
    placeholder: "Enter Place of Service",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "address",
    required: true,
  };
};

const TimezoneData: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "timezone",
    label: "Time Zone",
    placeholder: "Select Time Zone",
    boxType: "single-select-drop",
    dropArr: props?.dropArr,
    boxSize: props?.boxSize ? props?.boxSize : 3,
    validatetype: "dropdown",
  };
};

const UserIdData: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "userId",
    label: "User ID",
    placeholder: "Enter User ID",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "userid",
    required: true,
    readOnly: props?.readonly ? true : false,
    uniqueArr: props?.uniqueArr || [],
  };
};

const FirstNameData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "first-name",
    label: "First Name",
    placeholder: "Enter First Name",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "alphanumericPeriod",
    required: true,
  };
};

const LastNameData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "last-name",
    label: "Last Name",
    placeholder: "Enter Last Name",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "alphanumericPeriod",
    required: true,
  };
};

const GenderData: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "gender",
    label: "Gender Identity",
    placeholder: "Select Gender Identity",
    boxType: "single-select-drop",
    dropArr: props?.dropArr,
    boxSize: 3,
    validatetype: "dropdown",
    required: true,
    readOnly: props?.readonly ? props?.readonly : false,
  };
};

const SpecialityData: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "speciality",
    label: "Speciality",
    placeholder: "Enter Speciality",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "alphanumericName",
    required: props?.required ? props?.required : false,
  };
};

const DesignationData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "designation",
    label: "Designation",
    placeholder: "Enter Designation",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "alphanumericName",
    required: false,
  };
};

const RoleData: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "role",
    label: "Role",
    placeholder: "Select Role",
    boxType: "single-select-drop",
    dropArr: props?.dropArr,
    boxSize: 3,
    validatetype: "dropdown",
    required: true,
    readOnly: props?.readonly ? true : false,
  };
};

const LicenseIdData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "licenseID",
    label: "License ID / Certificate #",
    placeholder: "Enter License ID / Certificate",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "alphanumericName",
  };
};

const ProviderNpiData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "providerNPI",
    label: "Provider NPI",
    placeholder: "Enter Provider NPI",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "npi no.",
  };
};

const MobNoData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "mobile-no.",
    label: "Mobile Number",
    placeholder: "Enter Mobile Number",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "mobile no.",
    required: true,
  };
};

const DobData: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "dob",
    label: "Date of Birth",
    boxType: "mui-date",
    boxSize: 3,
    validatetype: "date",
    required: false,
  };
};

const CallName: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "call_name",
    label: "Staff Member Name",
    placeholder: "Enter Caller Name",
    boxType: "input-box",
    type: "text",
    boxSize: 3,
    validatetype: "alphanumericPeriod",
    required: false,
    readOnly: props?.readonly ? true : false,
  };
};

const CallResult: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "call_result",
    label: "Result",
    placeholder: "Select any value",
    boxType: "single-select-drop",
    type: "text",
    boxSize: 3,
    validatetype: "dropdown",
    required: true,
  };
};

const CallNotes: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "notes",
    label: "Notes",
    placeholder: "Enter Notes",
    boxType: "input-box",
    type: "text",
    boxSize: 3,
    validatetype: "callnotes",
    required: false,
  };
};

const Notes: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "note",
    label: "Notes",
    placeholder: "Enter Notes",
    boxType: "input-box",
    boxSize: props?.boxSize || 12,
    validatetype: "notes",
    multiline: true,
    multilineRows: 5,
  };
};

const AddNotes: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "addnotes",
    label: "Additional Notes",
    placeholder: "Add Additional Notes",
    boxType: "input-box",
    type: "text",
    boxSize: 3,
    validatetype: "notes",
    required: false,
    multiline: true,
    multilineRows: 5,
  };
};

const Callfreq: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "call-freq",
    label: "Call Frequency",
    placeholder: "Select Call Frequency",
    boxType: "single-select-drop",
    boxSize: 3,
    validatetype: "dropdown",
    dropArr: props?.dropArr || [],
    required: true,
  };
};

const CompletionDate: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "completion-date",
    label: "Completion Date & Time",
    boxType: "mui-datetime",
    boxSize: 3,
    validatetype: "date",
    required: true,
  };
};

const NextCall: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "next_call",
    label: "Call Reminder (Date & Time)",
    boxType: "mui-datetime",
    boxSize: 3,
    validatetype: "date",
    required: false,
  };
};

const FacilityName: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "facility-name",
    label: "Facility Name",
    placeholder: "Enter Facility Name",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "alphanumericName",
    required: true,
    readOnly: props?.readonly ? props?.readonly : false,
  };
};
const InitialContact: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "initial-contact",
    label: "Initial Attempt to Contact",
    boxType: "mui-date",
    boxSize: 3,
    validatetype: "date",
    required: true,
    readOnly: props?.readonly ? props?.readonly : false,
  };
};
const FormOfContact: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "form-of-contact",
    label: "Form of Contact",
    placeholder: "Enter Form of Contact",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "name",
    required: false,
    readOnly: props?.readonly ? props?.readonly : false,
  };
};
const DateOfContact: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "date-of-contact",
    label: "Date of Contact",
    boxType: "mui-date",
    boxSize: 3,
    validatetype: "date",
    required: false,
    readOnly: props?.readonly ? props?.readonly : false,
  };
};
const TcmAppointment: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "tcm-appointment",
    label: "TCM Appointment",
    boxType: "mui-date",
    boxSize: 3,
    validatetype: "date",
    required: true,
    readOnly: props?.readonly ? props?.readonly : false,
  };
};
const DateOfAdmission: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "date-of-admission",
    label: "Date of Admission",
    boxType: "mui-date",
    boxSize: 3,
    validatetype: "date",
    required: true,
    readOnly: props?.readonly ? props?.readonly : false,
  };
};
const DateOfDischarge: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "date-of-discharge",
    label: "Date of Discharge",
    boxType: "mui-date",
    boxSize: 3,
    validatetype: "date",
    required: true,
    readOnly: props?.readonly ? props?.readonly : false,
  };
};
const MedicalDecisionMaking: (props?: FormDataObjProps) => FormDataType = (
  props
) => {
  return {
    id: "medical-decision-making",
    label: "Medical Decision Making",
    placeholder: "Select Medical Decision Making",
    boxType: "single-select-drop",
    dropArr: MdmArr,
    boxSize: 3,
    validatetype: "dropdown",
    required: true,
    readOnly: props?.readonly ? props?.readonly : false,
  };
};
const PrimaryDiagnosis: (props?: FormDataObjProps) => FormDataType = (
  props
) => {
  return {
    id: "primary-diagnosis",
    label: "Primary Diagnosis",
    placeholder: "Enter Primary Diagnosis",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "alphanumeric",
    required: true,
    readOnly: props?.readonly ? props?.readonly : false,
  };
};
const SecondaryDiagnosis: (props?: FormDataObjProps) => FormDataType = (
  props
) => {
  return {
    id: "secondary-diagnosis",
    label: "Secondary Diagnosis",
    placeholder: "Enter Secondary Diagnosis",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "name",
    required: false,
    readOnly: props?.readonly ? props?.readonly : false,
  };
};
const TertiaryDiagnosis: (props?: FormDataObjProps) => FormDataType = (
  props
) => {
  return {
    id: "tertiary-diagnosis",
    label: "Tertiary Diagnosis",
    placeholder: "Enter Tertiary Diagnosis",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "name",
    required: false,
    readOnly: props?.readonly ? props?.readonly : false,
  };
};
const DischargeDisposition: (props?: FormDataObjProps) => FormDataType = (
  props
) => {
  return {
    id: "discharge-disposition",
    label: "Discharge Disposition",
    placeholder: "Enter Discharge Disposition",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "notes",
    required: true,
    multiline: true,
    multilineRows: 5,
    readOnly: props?.readonly ? props?.readonly : false,
  };
};

const ReasonForHospitalization: (props?: FormDataObjProps) => FormDataType = (
  props
) => {
  return {
    id: "reason-for-hospitalization",
    label: "Reason for Hospitalization",
    placeholder: "Enter Reason for Hospitalization",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "notes",
    required: true,
    multiline: true,
    multilineRows: 5,
    readOnly: props?.readonly ? props?.readonly : false,
  };
};

const TcmNotes: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "notes",
    label: "Notes",
    placeholder: "Enter Notes",
    boxType: "input-box",
    boxSize: 24,
    validatetype: "notes",
    required: false,
    multiline: true,
    multilineRows: 5,
    readOnly: props?.readonly ? props?.readonly : false,
  };
};

const Question: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "questions",
    label: "Enter Question",
    placeholder: "Enter Question",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "question",
    required: true,
  };
};

const ProblemChip: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "problem-chip",
    label: "Select Problems",
    placeholder: "Select Problems",
    boxType: "chip-drop",
    dropArr: props?.dropArr || [],
    boxSize: 6,
    validatetype: "multi-dropdown",
    required: props?.required,
    readOnly: false,
    chipLabel: "Selected Problems",
    width: "40%",
  };
};

const VisitChip: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "visit-chip",
    label: "Select Visit Type",
    placeholder: "Select Visit Type",
    boxType: "chip-drop",
    dropArr: props?.dropArr || [],
    boxSize: 6,
    validatetype: "multi-dropdown",
    required: props?.required,
    readOnly: props?.readonly || false,
    chipLabel: "Selected Visit Type",
    width: "40%",
  };
};

const AnswerType: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "ans-type",
    label: "Select Answer Type",
    placeholder: "Select Answer Type",
    boxType: "single-select-drop",
    dropArr: props?.dropArr || [],
    boxSize: 6,
    validatetype: "dropdown",
    required: true,
  };
};

const StartDateTime: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "start-date-time",
    label: "Start Date & Time",
    boxType: "mui-datetime",
    boxSize: 6,
    validatetype: "date",
    required: true,
  };
};

const EndDateTime: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "end-date-time",
    label: "End Date & Time",
    boxType: "mui-datetime",
    boxSize: 6,
    validatetype: "date",
    required: true,
  };
};

const Reason: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "reason",
    label: "Reason",
    placeholder: "Enter Reason",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "alphanumeric",
  };
};

const PatientSearch: () => FormDataType = () => {
  return {
    id: "patient",
    label: "Patient",
    placeholder: "Select Patient",
    boxType: "single-select-drop",
    boxSize: 6,
    validatetype: "dropdown",
    required: true,
    iconId: "search",
  };
};

const Duration: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "duration",
    label: "Duration (mins)",
    readOnly: true,
    boxType: "input-box",
    type: "text",
    boxSize: 6,
    validatetype: "duration",
  };
};

const AppointmentType: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "appointment-type",
    label: "Appointment Type",
    placeholder: "Select Appointment Type",
    boxType: "single-select-drop",
    dropArr: [],
    boxSize: 6,
    validatetype: "dropdown",
    required: true,
  };
};

const AppointmentTypeName: (props?: FormDataObjProps) => FormDataType = (
  props
) => {
  return {
    id: "appointment-type",
    label: "Appointment Type",
    placeholder: "Select Appointment Type",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "alphanumeric",
    required: true,
  };
};

const OrgName: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "practice",
    label: "Organization",
    placeholder: "Organization",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "org-name",
    readOnly: true,
  };
};

const ProviderName: (props?: FormDataObjProps) => FormDataType = (props) => {
  return {
    id: "provider",
    label: "Provider",
    placeholder: "Provider's Name",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "alphanumericPeriod",
    readOnly: true,
  };
};

const AppointmentDateTime: (props?: FormDataObjProps) => FormDataType = (
  props
) => {
  return {
    id: "start-date-time",
    label: "Appointment Date & Time",
    boxType: "mui-datetime",
    boxSize: 6,
    validatetype: "date",
    required: true,
  };
};

const AppointmentNotes: (props?: FormDataObjProps) => FormDataType = () => {
  return {
    id: "notes",
    label: "Notes",
    placeholder: "Enter Notes",
    boxType: "input-box",
    type: "text",
    boxSize: 12,
    validatetype: "notes",
    multiline: true,
    multilineRows: 5,
    required: true,
  };
};

export default {
  LoginData,
  PasswordData,
  OtpData,
  PracticeNpiData,
  PracticeNameData,
  BusinessModelData,
  ProgramData,
  PracticeAdminNameData,
  PrimEmailData,
  SecEmailData,
  LocationNameData,
  AddressData,
  Address1Data,
  Address2Data,
  CityNameData,
  StateNameData,
  ZipcodeData,
  CountryNameData,
  OfficeNoData,
  DirectNoData,
  DirectNo2Data,
  DirectNo3Data,
  DirectNo4Data,
  ServiceLocationData,
  TimezoneData,
  UserIdData,
  FirstNameData,
  LastNameData,
  GenderData,
  SpecialityData,
  DesignationData,
  RoleData,
  ProviderNpiData,
  LicenseIdData,
  MobNoData,
  DobData,
  NextCall,
  CallNotes,
  Notes,
  AddNotes,
  CallResult,
  CallName,
  ProgramName,
  ConsentDrop,
  DOEDrop,
  DOIDrop,
  ConsentCreatedDrop,
  ConsentTypeDrop,
  ReasonDrop,
  FacilityName,
  InitialContact,
  FormOfContact,
  DateOfContact,
  TcmAppointment,
  DateOfAdmission,
  DateOfDischarge,
  MedicalDecisionMaking,
  PrimaryDiagnosis,
  SecondaryDiagnosis,
  TertiaryDiagnosis,
  DischargeDisposition,
  ReasonForHospitalization,
  TcmNotes,
  Question,
  ProblemChip,
  VisitChip,
  AnswerType,
  CompletionDate,
  Callfreq,
  StartDateTime,
  EndDateTime,
  Reason,
  Duration,
  PatientSearch,
  AppointmentType,
  OrgName,
  ProviderName,
  AppointmentDateTime,
  AppointmentNotes,
  AppointmentTypeName,
};
