import { Fragment, FC, useContext } from "react";
import { Box, IconButton } from "@mui/material";
import { CommonStyle } from "Components";
import { label } from "Constant/En";
import ImgPath from "Constant/Imgs";
import { Outlet, useNavigate } from "react-router-dom";
import { HeadContextType } from "DataTypes/Common";
import Breadcrumb from "Components/Breadcrumb";
import { HeadContext } from "Context/HeadContext";

const HeadScreen: FC = () => {
  const { breadArr, headRightUI, spaceBtw, headLeftUI } = useContext(
    HeadContext
  ) as HeadContextType;
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box
        height="8%"
        sx={{
          ...CommonStyle.sxRow,
          justifyContent: spaceBtw ? "space-between" : "flex-start",
        }}
      >
        <Box sx={{ ...CommonStyle.sxRow, mr: 2 }}>
          <IconButton
            title={label.goback}
            onClick={() =>
              breadArr.length > 1
                ? navigate(breadArr[breadArr.length - 2].path)
                : navigate(-1)
            }
            sx={{ p: 0, ...BackIconBox }}
          >
            <ImgPath.BreadcrumbIcon />
          </IconButton>
          <Breadcrumb breadArr={breadArr} />
          {headLeftUI && <Box sx={{ ...CommonStyle.sxRow }}>{headLeftUI}</Box>}
        </Box>
        <Box sx={{ ...CommonStyle.sxRow }}>{headRightUI}</Box>
      </Box>
      <Outlet />
    </Fragment>
  );
};
export default HeadScreen;

const BackIconBox = {
  backgroundColor: "common.white",
  padding: 0.8,
  borderRadius: 2,
  mr: 1.2,
  transition: "all 0.6s",
  "&:hover": {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 14px 0px #c7c7c7",
    transition: "all 0.6s",
  },
  svg: {
    width: "0.9em",
    height: "0.9em",
  },
};
