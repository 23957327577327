import { store } from "Redux/Store";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
const utcFormat = "YYYY-MM-DDTHH:mm";
const utcDateFormat = "YYYY-MM-DD";
const utcFormat1 = "YYYY-MM-DDTHH:mm:ss";

const ValidDate: (date: any) => string = (date) => {
  if (date && dayjs(date).isValid() && typeof date == "string") {
    const dateArr = date?.split(".");
    if (dateArr.length > 0) return dateArr[0];
    else return date;
  } else return "";
};
const CurrentTime: () => string = () => dayjs().format("HH:mm");
const CurrentDate: () => string = () => {
  const { dateFormat } = store.getState().user.myInfo;
  return dayjs().format(dateFormat);
};
const CurrentDateTime: () => string = () => {
  const { dateTimeFormat } = store.getState().user.myInfo;
  return dayjs().format(dateTimeFormat);
};
const CurrentDateTimeSec: () => string = () => {
  const { dateTimeSecFormat } = store.getState().user.myInfo;
  return dayjs().format(dateTimeSecFormat);
};
const CurrentMonth: () => string = () => dayjs().format("MM");
const CurrentMonthName: () => string = () => dayjs().format("MMMM");
const CurrentYear: () => string = () => dayjs().format("YYYY");
const CurrentUTCDateTime: () => string = () => {
  const { utcDiff } = store.getState().user.myInfo;
  if (utcDiff) {
    const newDate = dayjs(CurrentDateTime())
      .add(-utcDiff, "hour")
      .format(utcFormat);
    return newDate + ":00.000Z";
  } else return new Date().toISOString();
};
const CurrentUTCDateTimeSec: () => string = () => {
  const { utcDiff } = store.getState().user.myInfo;
  if (utcDiff) {
    const newDate = dayjs(CurrentDateTimeSec())
      .add(-utcDiff, "hour")
      .format(utcFormat1);
    return newDate + "Z";
  } else return new Date().toISOString();
};
const TomorrowDateTime: () => string = () => {
  return dayjs().add(+1, "day").format(utcFormat);
};
const PreviousMonthDateTime: () => string = () => {
  const { dateTimeFormat } = store.getState().user.myInfo;
  return dayjs().add(-31, "day").format(dateTimeFormat);
};
const ToLocalDate: (data: any) => string = (data) => {
  const { dateFormat } = store.getState().user.myInfo;
  const valid = ValidDate(data);
  if (valid) return dayjs(valid).format(dateFormat);
  else return "";
};
const ToLocalDateTime: (data: any) => string = (data) => {
  const { dateTimeFormat } = store.getState().user.myInfo;
  const valid = ValidDate(data);
  if (valid) return dayjs(valid).format(dateTimeFormat);
  else return "";
};
const UTCToLocalDateTime: (data: any) => string = (data) => {
  const { utcDiff, dateTimeFormat } = store.getState().user.myInfo;
  const newDate = ValidDate(data);
  if (newDate && dayjs.utc(newDate).isUTC()) {
    if (utcDiff) {
      return dayjs(newDate).add(utcDiff, "hour").format(dateTimeFormat);
    } else return dayjs.utc(newDate).local().format(dateTimeFormat);
  } else return "";
};
const MonthName: (month: number | null) => string = (month) => {
  if (month && month > 0)
    return dayjs()
      .month(month - 1)
      .format("MMMM");
  else return "";
};
const ToUTCDate: (data: any) => string = (data) => {
  const valid = ValidDate(data);
  if (valid) return dayjs(valid).format(utcDateFormat);
  else return "";
};
const ToUTCDateTime: (data: any) => string = (data) => {
  const { utcDiff } = store.getState().user.myInfo;
  const valid = ValidDate(data);
  if (valid) {
    const newDate = dayjs(valid).format(utcFormat);
    if (utcDiff) {
      const newDate2 = dayjs(newDate).add(-utcDiff, "hour").format(utcFormat);
      return newDate2 + ":00.000Z";
    } else return new Date(newDate).toISOString();
  } else return "";
};
const GetMonth: (data: any) => string = (data) => {
  const valid = ValidDate(data);
  if (valid) return dayjs(valid).format("MM") || "";
  else return "";
};
const GetYear: (data: any) => string = (data) => {
  const valid = ValidDate(data);
  if (valid) return dayjs(valid).format("YYYY") || "";
  else return "";
};

const GetTimeStamp: (date: any) => number = (date) => {
  return dayjs(date).valueOf();
};

const AddSubDay: (date: string, days: number) => string = (date, days) => {
  const valid = ValidDate(date);
  if (valid) return dayjs(date).add(days, "d").format(utcDateFormat);
  else return "";
};
const AddSubMin: (date: string, min: number) => string = (date, min) => {
  const valid = ValidDate(date);
  if (valid) return dayjs(valid).add(min, "m").format(utcFormat);
  else return "";
};
const ToDbUtcFormat: (date: string) => string = (date) => {
  const valid = ValidDate(date);
  if (valid) return valid + ":00.000Z";
  else return "";
};
const AddHoursToUTCDateTime: (number: number, date: any) => string = (
  number,
  date
) => {
  const valid = ValidDate(date);
  if (valid) {
    const newDate = dayjs(valid).add(-number, "hour").format(utcFormat);
    return newDate + ":00.000Z";
  } else return "";
};
const AddHoursUTCToLocalDateTime: (number: number, date: any) => string = (
  number,
  date
) => {
  const { dateTimeFormat } = store.getState().user.myInfo;
  return dayjs(date).add(number, "hour").format(dateTimeFormat);
};

const AgeCalculator = (data: string) => {
  const currentDate = CurrentDate();
  const currentYear = parseInt(dayjs(currentDate).format("YYYY"));
  const dobYear = parseInt(dayjs(data).format("YYYY"));
  return `${currentYear - dobYear}Y`;
};

const CalculateDuration: (start: string, end: string) => number = (
  start,
  end
) => {
  if (ValidDate(start) && ValidDate(end)) {
    const startDate = dayjs(start);
    const endDate = dayjs(end);
    const diff = endDate.diff(startDate, "minutes");
    return diff ? diff : 0;
  } else return 0;
};

const HoursTimeZoneSwitch: (timezone: string) => number = (timezone) => {
  switch (timezone) {
    case "EST":
      return -4;
    case "CST":
      return -5;
    case "MST":
      return -6;
    case "PST":
      return -7;
    default:
      return 0;
  }
};

const TimeZoneRegionSwitch: (timezone: string) => {
  date: string;
  datetime: string;
  datetimesec: string;
} = (timezone) => {
  switch (timezone) {
    case "EST":
      return {
        date: "MM/DD/YYYY",
        datetime: "MM/DD/YYYY hh:mm A",
        datetimesec: "MM/DD/YYYY hh:mm:ss A",
      };
    case "CST":
      return {
        date: "MM/DD/YYYY",
        datetime: "MM/DD/YYYY hh:mm A",
        datetimesec: "MM/DD/YYYY hh:mm:ss A",
      };
    case "MST":
      return {
        date: "MM/DD/YYYY",
        datetime: "MM/DD/YYYY hh:mm A",
        datetimesec: "MM/DD/YYYY hh:mm:ss A",
      };
    case "PST":
      return {
        date: "MM/DD/YYYY",
        datetime: "MM/DD/YYYY hh:mm A",
        datetimesec: "MM/DD/YYYY hh:mm:ss A",
      };
    default:
      return {
        date: "MM/DD/YYYY",
        datetime: "MM/DD/YYYY hh:mm A",
        datetimesec: "MM/DD/YYYY hh:mm:ss A",
      };
  }
};

export default {
  CurrentDate,
  CurrentDateTime,
  CurrentMonth,
  CurrentMonthName,
  CurrentYear,
  CurrentUTCDateTime,
  CurrentUTCDateTimeSec,
  TomorrowDateTime,
  PreviousMonthDateTime,
  CurrentTime,
  ValidDate,
  ToUTCDate,
  ToUTCDateTime,
  UTCToLocalDateTime,
  GetMonth,
  GetYear,
  GetTimeStamp,
  AgeCalculator,

  ToDbUtcFormat,

  HoursTimeZoneSwitch,
  ToLocalDate,
  ToLocalDateTime,
  CalculateDuration,
  AddHoursToUTCDateTime,
  AddHoursUTCToLocalDateTime,
  TimeZoneRegionSwitch,
  MonthName,

  AddSubDay,
  AddSubMin,
};
