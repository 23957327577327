import { actionArrType, columnType } from "DataTypes/Table";
import { ControlledTable } from "Components";
import { BreadArrType, Filter2DataProps } from "DataTypes/Common";
import { FormatDropdownData } from "Utils/DataFormatter";
import { store } from "Redux/Store";
import FilterData from "Components/Filter2/Data";

export const actionArr: (
  handleChart: () => void,
  handlePatientDetails: () => void,
  handleTask: () => void,
  handleAssignPatient: () => void,
  roleId: number | null
) => actionArrType[] = (
  handleChart,
  handlePatientDetails,
  handleTask,
  handleAssignPatient,
  roleId
) => {
  const Arr: actionArrType[] = [
    ControlledTable.ActionData.userAction(handlePatientDetails),
    ControlledTable.ActionData.chartAction(handleChart),
    ControlledTable.ActionData.taskAction(handleTask),
    ...(roleId == 1 || roleId == 2
      ? []
      : [ControlledTable.ActionData.MoveToAction(handleAssignPatient)]),
  ];
  return Arr;
};

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.PicCol(),
  ControlledTable.ColumnData.PatientInfoCol(),
  ControlledTable.ColumnData.PracticeCol(),
  ControlledTable.ColumnData.ProviderCol(),
  ControlledTable.ColumnData.ConsentGainedByCol(),
  ControlledTable.ColumnData.EnrollmentDateCol(),
  ControlledTable.ColumnData.ProgramCol("", "text"),
  ControlledTable.ColumnData.StatusCol(),
  ControlledTable.ColumnData.ActionCol(),
];

export const PendingAssignBreadArr: BreadArrType[] = [
  {
    id: 2,
    label: "Pending Assignment",
    path: "",
  },
];

export const FilterArr: () => Filter2DataProps[] = () => {
  const { prgDropList } = store.getState().list;
  const Arr: Filter2DataProps[] = [
    FilterData.patientName,
    FilterData.dob,
    FilterData.homeNo,
    FilterData.mobile,
    FilterData.orgName,
    FilterData.orgLocation,
    FilterData.provider,
    FilterData.consentGainedBy,
    FilterData.consentGainedByDesig,
    FilterData.enrollFrom,
    FilterData.enrollTo,
    {
      ...FilterData.prgmName,
      dropArr: FormatDropdownData(prgDropList, "id", "label", "label"),
    },
  ];
  return Arr;
};
