import { columnType, actionArrType } from "DataTypes/Table";
import { ControlledTable } from "Components";
import { BreadArrType } from "DataTypes/Common";
import BreadUrls from "Constant/BreadUrls";
import { MyInfoType } from "DataTypes/Redux";

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.PicCol("8%"),
  ControlledTable.ColumnData.NameCol("11%"),
  ControlledTable.ColumnData.DesignationCol("11%"),
  ControlledTable.ColumnData.RoleCol("12%"),
  ControlledTable.ColumnData.PracticeColWithoutInfo("12%"),
  ControlledTable.ColumnData.MobileNoCol("12%"),
  ControlledTable.ColumnData.OfficeNoCol("12%"),
  ControlledTable.ColumnData.DirectNoCol("12%"),
  ControlledTable.ColumnData.ActionCol("10%"),
];
export const actionArr: (
  handleEdit: (data: any) => void,
  handleClick: (data: any) => void,
  handleDownload: (data: any) => void,
  data: any,
  permission: any,
  loginUser: MyInfoType
) => actionArrType[] = (
  handleEdit,
  handleClick,
  handleDownload,
  data,
  permission,
  loginUser
) => {
  return [
    ControlledTable.ActionData.editAction({
      handleClick: handleEdit,
      disable: permission?.allStaffEdit
        ? false
        : loginUser.practiceId == data?.practiceId
          ? false
          : true,
    }),
    ControlledTable.ActionData.enableDisableAction(
      handleClick,
      data?.status == "INACTIVE" ? false : true,
      loginUser.id == data?.id
        ? true
        : permission?.allStaffEdit
          ? false
          : loginUser.practiceId == data?.practiceId
            ? false
            : true
    ),
    ControlledTable.ActionData.downloadAction(handleDownload),
  ];
};

export const BreadData: (
  roleId: number | null,
  name: string
) => BreadArrType[] = (roleId, name) => {
  if (roleId == 1) {
    return [
      {
        id: 1,
        label: "Home",
        path: "/",
      },
      {
        id: 2,
        label: "Organization List",
        path: BreadUrls.OrgList(),
      },
      {
        id: 3,
        label: `${name} Staff List`,
        path: "",
      },
    ];
  } else {
    return [
      {
        id: 1,
        label: "Home",
        path: "/",
      },
      {
        id: 2,
        label: `${name} Staff List`,
        path: "",
      },
    ];
  }
};
