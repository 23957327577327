import { actionArrType, columnType } from "DataTypes/Table";
import { ControlledTable } from "Components";
import { BreadArrType, Filter2DataProps } from "DataTypes/Common";
import { InactiveType } from "DataTypes/Services/Patient.type";
import { FormatDropdownData } from "Utils/DataFormatter";
import { store } from "Redux/Store";
import FilterData from "Components/Filter2/Data";

export const actionArr: (
  handleChart: () => void,
  handlePatientDetails: () => void,
  handleTask: () => void,
  handleCall: (data: any) => void,
  data: InactiveType
) => actionArrType[] = (
  handleChart,
  handlePatientDetails,
  handleTask,
  handleCall,
  data
) => {
  return [
    ControlledTable.ActionData.userAction(handlePatientDetails),
    ControlledTable.ActionData.chartAction(handleChart),
    ...(data.reason == "declined"
      ? [ControlledTable.ActionData.callAction(handleCall)]
      : []),
    ControlledTable.ActionData.taskAction(handleTask),
  ];
};

export const inactiveBeadArr: BreadArrType[] = [
  {
    id: 2,
    label: "Inactive",
    path: "",
  },
];

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.PicCol(),
  ControlledTable.ColumnData.PatientInfoCol(),
  ControlledTable.ColumnData.PracticeCol(),
  ControlledTable.ColumnData.ProviderCol(),
  ControlledTable.ColumnData.ProgramCol("5%", "text"),
  ControlledTable.ColumnData.PrimeInsCol(),
  ControlledTable.ColumnData.SecInsCol(),
  ControlledTable.ColumnData.LastCallCol(),
  ControlledTable.ColumnData.ReasonCol(),
  ControlledTable.ColumnData.ActionCol(),
];

export const FilterArr: () => Filter2DataProps[] = () => {
  const { prgDropList } = store.getState().list;
  const Arr: Filter2DataProps[] = [
    FilterData.patientName,
    FilterData.dob,
    FilterData.homeNo,
    FilterData.mobile,
    FilterData.orgName,
    FilterData.orgLocation,
    FilterData.provider,
    FilterData.providerDesig,
    {
      ...FilterData.prgmName,
      dropArr: FormatDropdownData(prgDropList, "id", "label", "label"),
    },
    FilterData.primIns,
    FilterData.secIns,
    FilterData.lastCallFrom,
    FilterData.lastCallTo,
  ];
  return Arr;
};
