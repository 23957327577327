import { ApiServiceProps } from "DataTypes/Services/Index.type";
import { post } from ".";

const list: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "practice/search",
    isAuth: true,
    data: {
      data: payload?.data,
      criterion: {
        offset: payload.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const create: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "practice/create",
    data: { data: payload.data },
    isAuth: true,
  };
  return post(request);
};

const update: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `practice/${payload.id1}/update`,
    data: { data: payload.data },
    isAuth: true,
  };
  return post(request);
};

const read: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `practice/${data.id1}/read`,
    isAuth: true,
  };
  return post(request);
};

const changeStatus: (payload: ApiServiceProps) => any = ({ id1, status }) => {
  const request = {
    subUrl: `practice/${id1}/status/${status}/changed`,
    isAuth: true,
  };
  return post(request);
};

const practiceLocation: (payload: ApiServiceProps) => any = ({ id1 }) => {
  const request = {
    subUrl: `practice/${id1}/practice-location`,
    isAuth: true,
  };
  return post(request);
};

const practiceUpload: (payload: ApiServiceProps) => any = ({ id1, data }) => {
  const request = {
    subUrl: `patient/ptacticeLocation/${id1}/upload`,
    isRedox: true,
    data,
    excel: true,
  };
  return post(request);
};

export default {
  list,
  create,
  update,
  read,
  changeStatus,
  practiceUpload,
  practiceLocation,
};
