import { FC, Fragment, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import { Loader, SideBarNav } from "Components";
import TimerProvider from "Context/TimerContext";
import { useParams } from "react-router-dom";
import PatientApi from "Service/Patient.api";
import { CheckStringId } from "Utils/common";
import { useNavigate } from "react-router-dom";
import PatientFormatter from "Utils/Patient.Formatter";
import { useDispatch } from "react-redux";
import {
  addPatientInfo,
  addPatientOrgInfo,
  clearPatientInfo,
  clearPatientOrgInfo,
} from "Redux/Patient/Action";
import { HeadContext } from "Context/HeadContext";
import { HeadContextType } from "DataTypes/Common";

const PatientManagement: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { patientId } = useParams();
  const newPatientId = CheckStringId(patientId);
  const { setHeadLeftUI, setSpaceBtw, setHeadRightUI } = useContext(
    HeadContext
  ) as HeadContextType;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setHeadRightUI(<></>);
    setHeadLeftUI(<></>);
    setSpaceBtw(false);
    () => {
      dispatch(clearPatientInfo());
      dispatch(clearPatientOrgInfo());
      setHeadRightUI(<></>);
      setHeadLeftUI(<></>);
      setSpaceBtw(false);
    };
  }, []);

  useEffect(() => {
    fetchPatientInfo();
  }, [patientId]);

  const fetchPatientInfo: () => void = async () => {
    setLoading(true);
    const patientInfoRes = await PatientApi.demographic({ id1: newPatientId });
    if (patientInfoRes?.success) {
      const formateRes = PatientFormatter.FormatePatientInfo(
        patientInfoRes?.data?.patients
      );
      dispatch(addPatientInfo(formateRes));
    } else navigate(-1);
    const patientOrgInfoRes = await PatientApi.practice({ id1: newPatientId });
    if (patientOrgInfoRes?.success) {
      const formateRes = PatientFormatter.FormatPatientOrg(
        patientOrgInfoRes?.data?.practiceLocations
      );
      dispatch(addPatientOrgInfo(formateRes));
    } else navigate(-1);
    setLoading(false);
  };

  return (
    <Box display="flex" flexDirection="row" height="91%">
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <SideBarNav />
          <TimerProvider>
            <Outlet />
          </TimerProvider>
        </Fragment>
      )}
    </Box>
  );
};
export default PatientManagement;
