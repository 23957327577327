import { ControlledTable } from "Components";
import BreadUrls from "Constant/BreadUrls";
import { BreadArrType } from "DataTypes/Common";
import { FormDataType } from "DataTypes/Form";
import { PatientInfoType } from "DataTypes/Redux";

export const CpProblemCol = [
  ControlledTable.ColumnData.ProblemCol("25%"),
  ControlledTable.ColumnData.IcdCol("15%"),
  ControlledTable.ColumnData.DescriptionCol("60%"),
];

export const EnterProblemBreadArr: (
  patient: PatientInfoType
) => BreadArrType[] = (patient) => {
  return [
    {
      id: 1,
      label: `${patient.name}`,
      path: BreadUrls.ClinicalSummary(patient.id),
    },
    {
      id: 2,
      label: "Problems",
      path: "",
    },
  ];
};

export const FormArr: FormDataType[] = [
  {
    id: "cp-problem",
    label: "Select Problems",
    placeholder: "Select Care Plan Problems",
    boxType: "single-select-drop",
    boxSize: 3,
    validatetype: "dropdown",
  },
  {
    id: "cp-icdCodes",
    label: "ICD Codes",
    placeholder: "Select ICD Codes",
    boxType: "multi-select-search-drop",
    boxSize: 3,
    validatetype: "dropdown",
  },
];
