// import { FC, useEffect } from "react";
import { FC } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
// import { PatientInitialStateType } from "DataTypes/Redux";
// import { ConvertStringToNum } from "Utils/common";
// import { setAlertData } from "Redux/Screen/Action";

const Recurring: FC = () => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const { recurringId } = useParams();
  // const newRecurringId = ConvertStringToNum(recurringId);
  // const { selectedPrgm }: PatientInitialStateType = useSelector(
  //   (state: any) => state?.patient
  // );

  // useEffect(() => {
  //   checkRecurringId();
  // }, []);

  // const checkRecurringId: () => void = () => {
  //   if (!selectedPrgm || selectedPrgm.careplanRecurringId != newRecurringId) {
  //     dispatch(
  //       setAlertData({
  //         alertMessage: "This appointment is either closed or invalid",
  //         alertVariant: "error",
  //         openAlert: true,
  //       })
  //     );
  //     navigate(-1);
  //   }
  // };

  return <Outlet />;
};

export default Recurring;
