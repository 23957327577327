import { ControlledForm } from "Components";
import { FormDataType } from "DataTypes/Form";

export const TimeModalFormData: FormDataType[] = [
  ControlledForm.FormData.StartDateTime(),
  ControlledForm.FormData.EndDateTime(),
  ControlledForm.FormData.Reason(),
  ControlledForm.FormData.Duration(),
  ControlledForm.FormData.Notes()
];
