import { FormDataType } from "DataTypes/Form";

export const FormArr: (prgmId: number | null) => FormDataType[] = (prgmId) => {
  return [
    {
      id: "care-manager",
      label: "Care Manager",
      placeholder: "Enter Care Manager Name",
      boxType: "single-select-drop",
      boxSize: 3,
      validatetype: "dropdown",
    },
    {
      id: "cp-billable-provider",
      label: "Care Plan Billable Provider",
      placeholder: "Select Care Plan Billable Provider",
      boxType: "single-select-drop",
      boxSize: 3,
      validatetype: "dropdown",
    },
    {
      id: "billable-provider",
      label: "Billable Provider",
      placeholder: "Select Billable Provider",
      boxType: "single-select-drop",
      boxSize: 3,
      validatetype: "dropdown",
    },
    ...(prgmId == 3 ? [] : [...nonTCM]),
  ];
};

const nonTCM: FormDataType[] = [
  {
    id: "primary-care-coordinator",
    label: "Primary Care Coordinator",
    placeholder: "Select Primary Care Coordinator",
    boxType: "single-select-drop",
    boxSize: 3,
    validatetype: "dropdown",
  },
  {
    id: "secondary-care-coordinator",
    label: "Secondary Care Coordinator",
    placeholder: "Select Secondary Care Coordinator",
    boxType: "single-select-drop",
    boxSize: 3,
    validatetype: "dropdown",
  },
];
