import { ApiServiceProps } from "DataTypes/Services/Index.type";
import { post } from "Service";

const list: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "permission/search",
    isAuth: true,
    data: { data: payload.data },
  };
  return post(request);
};

export default { list };
