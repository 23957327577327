import { ChangeEvent, Fragment, FC } from "react";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import { InputDataType } from "DataTypes/Form";
import { InputImgSwitch } from "Utils/SwitchCases";
import { CommonStyle } from "Components";
import InputLabel from "./Label";
import { CheckStringEmpty } from "Utils/common";

const Input: FC<InputDataType> = (props: InputDataType) => {
  const {
    id,
    label,
    placeholder,
    type,
    defaultValue,
    value,
    error,
    errorText,
    onChange,
    iconId,
    required,
    iconClickHandler,
    multiline,
    sxProps,
    startAdornmentText,
    readOnly,
    multilineRows,
    minVal,
    maxVal,
    maxLength,
    handleEnter,
    onBlurChange,
    onFocusChange,
    infoText,
  } = props;

  return (
    <Fragment>
      {label && (
        <InputLabel label={label} required={required ? required : false} />
      )}
      <OutlinedInput
        name={id}
        {...(handleEnter && { onKeyDown: (e) => handleEnter(e) })}
        {...(placeholder && { placeholder: placeholder })}
        {...(defaultValue && { defaultValue: defaultValue })}
        {...(maxLength && {
          onInput: (e: any) => {
            if (e.target.value) {
              e.target.value = e.target.value.slice(0, maxLength);
            }
          },
        })}
        {...(onChange && {
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            onChange(
              type == "file"
                ? e.target.files
                  ? e.target.files[0]
                  : ""
                : e.target.value
            );
          },
        })}
        {...(onBlurChange && { onBlur: (e) => onBlurChange(e.target.value) })}
        {...(onFocusChange && {
          onFocus: (e) => onFocusChange(e.target.value),
        })}
        readOnly={readOnly ? readOnly : false}
        {...(value != undefined && { value: value })}
        {...(type && { type: type })}
        inputProps={{
          ...(type == "file" && { accept: "application/pdf" }),
          ...(minVal && { min: minVal }),
          ...(maxVal && { max: maxVal }),
        }}
        fullWidth
        sx={{
          ...(readOnly ? { ...sxReadOnly } : { ...CommonStyle.sxWhiteBox }),
          ...sxInput,
          ...sxProps,
        }}
        multiline={multiline ? multiline : false}
        {...(multilineRows && { rows: multilineRows })}
        {...(startAdornmentText && {
          startAdornment: (
            <InputAdornment position="start">
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                sx={{ pl: 0.5 }}
              >
                {startAdornmentText}
              </Typography>
            </InputAdornment>
          ),
        })}
        {...(!readOnly && {
          endAdornment:
            iconId &&
            (iconClickHandler ? (
              <IconButton onClick={() => iconClickHandler(id)} sx={{ p: 0 }}>
                <InputAdornment position="end">
                  {InputImgSwitch(iconId)}
                </InputAdornment>
              </IconButton>
            ) : CheckStringEmpty(infoText) ? (
              <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                <Tooltip title={infoText} arrow>
                  {InputImgSwitch(iconId)}
                </Tooltip>
              </InputAdornment>
            ) : (
              <InputAdornment position="end">
                {InputImgSwitch(iconId)}
              </InputAdornment>
            )),
        })}
        error={error ? error : false}
      />
      {error && (
        <Typography variant="subtitle2" color="red">
          {errorText}
        </Typography>
      )}
    </Fragment>
  );
};
export default Input;

const sxInput = {
  fontSize: "0.85rem",
  px: 0.5,
  py: 0,
  "& >input": {
    px: 0.5,
    py: 1,
  },
  "& >textarea": {
    px: 0.5,
    py: 1,
  },
  "& >fieldset": {
    borderWidth: 0,
  },
};

const sxReadOnly = {
  backgroundColor: "custom.grey",
  boxShadow: "0px 0px 6px #00000029",
  borderRadius: 1.25,
  " >fieldset": {
    display: "none",
  },
};
