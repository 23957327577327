import BreadUrls from "Constant/BreadUrls";
import { BreadArrType } from "DataTypes/Common";
import { PatientInfoType } from "DataTypes/Redux";

export const BreadData: (
  patient: PatientInfoType,
  isRecurring: boolean,
  isTCM: boolean,
  careplanId: number | null
) => BreadArrType[] = (patient, isRecurring, isTCM, careplanId) => {
  if (isRecurring) {
    return [
      {
        id: 1,
        label: `${patient.name}`,
        path: BreadUrls.ClinicalSummary(patient.id),
      },
      {
        id: 2,
        label: "Questionnaire",
        path: "",
      },
    ];
  } else if (isTCM) {
    return [
      {
        id: 1,
        label: `${patient.name}`,
        path: BreadUrls.ClinicalSummary(patient.id),
      },
      {
        id: 2,
        label: "Questionnaire",
        path: "",
      },
    ];
  } else {
    return [
      {
        id: 1,
        label: `${patient.name}`,
        path: BreadUrls.ClinicalSummary(patient.id),
      },
      {
        id: 2,
        label: "Problems",
        path: BreadUrls.CPProbs(patient.id, careplanId),
      },
      {
        id: 2,
        label: "Questionnaire",
        path: "",
      },
    ];
  }
};
