import { ControlledForm } from "Components";
import { BreadArrType } from "DataTypes/Common";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import DateTime from "Utils/DateTime";

export const ActivityArr = [
  {
    id: 1,
    label: "CCM Care Plan",
    value: "CCM Care Plan",
  },
  {
    id: 2,
    label: "CCM Recurring",
    value: "CCM Recurring",
  },
];

export const BreadArr: BreadArrType[] = [
  {
    id: 2,
    label: "Add Time Log",
    path: "",
  },
];

export const ProgramArr = [
  {
    id: 1,
    label: "CCM",
    value: "CCM",
  },
  {
    id: 2,
    label: "PCM",
    value: "PCM",
  },
  {
    id: 3,
    label: "TCM",
    value: "TCM",
  },
  {
    id: 4,
    label: "RPM",
    value: "RPM",
  },
  {
    id: 5,
    label: "BHI",
    value: "BHI",
  },
  {
    id: 6,
    label: "GCM",
    value: "GCM",
  },
];
export const TypeArr = [
  {
    id: 1,
    label: "Administrative",
    value: "Administrative",
  },
  {
    id: 2,
    label: "Clinical Question",
    value: "Clinical Question",
  },
  {
    id: 3,
    label: "Chart Review",
    value: "Chart Review",
  },
  {
    id: 4,
    label: "Full Scope of Service",
    value: "Full Scope of Service",
  },
  {
    id: 5,
    label: "Medication",
    value: "Medication",
  },
  {
    id: 6,
    label: "Medication Refill",
    value: "Medication Refill",
  },
  {
    id: 7,
    label: "Medical Record - Electronic",
    value: "Medical Record - Electronic",
  },
  {
    id: 8,
    label: "Medical Record - Paper",
    value: "Medical Record - Paper",
  },
  {
    id: 9,
    label: "Print Care Plan",
    value: "Print Care Plan",
  },
  {
    id: 10,
    label: "Electronically Send Care Plan",
    value: "Electronically Send Care Plan",
  },
  {
    id: 11,
    label: "Referral",
    value: "Referral",
  },
  {
    id: 12,
    label: "Scheduling",
    value: "Scheduling",
  },
  {
    id: 13,
    label: "Test Results",
    value: "Test Results",
  },
  {
    id: 14,
    label: "Other",
    value: "Other",
  },
];
export const DataArr = (prgDropData: DropdownArrPropsType[]) => {
  const arr: FormDataType[] = [
    {
      id: "programName",
      label: "Program Name",
      dropArr: prgDropData,
      placeholder: "Select Program Name",
      boxType: "single-select-drop",
      boxSize: 6,
      validatetype: "dropdown",
      required: true,
    },
    {
      id: "activity",
      label: "Activity",
      placeholder: "Select Activity",
      boxType: "input-box",
      boxSize: 6,
      validatetype: "name",
      readOnly: true,
      required: true,
    },
    ControlledForm.FormData.StartDateTime(),
    ControlledForm.FormData.EndDateTime(),
    {
      id: "type",
      label: "Type",
      placeholder: "Select Type",
      boxType: "single-select-drop",
      dropArr: TypeArr,
      boxSize: 6,
      validatetype: "dropdown",
      required: true,
    },
    ControlledForm.FormData.Duration(),
    ControlledForm.FormData.Notes(),
  ];
  return arr;
};
