import { actionArrType, columnType } from "DataTypes/Table";
import { ControlledTable } from "Components";
import { BreadArrType, Filter2DataProps } from "DataTypes/Common";
import FilterData from "Components/Filter2/Data";
import { store } from "Redux/Store";
import { FormatDropdownData } from "Utils/DataFormatter";

export const actionArr: (
  handleChart: () => void,
  handlePatientDetails: () => void,
  handleTask: () => void
) => actionArrType[] = (handleChart, handlePatientDetails, handleTask) => {
  const Arr: actionArrType[] = [
    ControlledTable.ActionData.userAction(handlePatientDetails),
    ControlledTable.ActionData.chartAction(handleChart),
    ControlledTable.ActionData.taskAction(handleTask),
  ];
  return Arr;
};

export const OngoingBreadArr: BreadArrType[] = [
  {
    id: 2,
    label: "Ongoing",
    path: "",
  },
];

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.PicCol(),
  ControlledTable.ColumnData.PatientInfoCol(),
  ControlledTable.ColumnData.ClinicalStaffCol(),
  ControlledTable.ColumnData.ImportanceCol(),
  ControlledTable.ColumnData.PracticeCol(),
  ControlledTable.ColumnData.ProviderCol(),
  ControlledTable.ColumnData.PrimeInsCol(),
  ControlledTable.ColumnData.SecInsCol(),
  ControlledTable.ColumnData.CPICol(),
  ControlledTable.ColumnData.CareCoordinationCol(),
  ControlledTable.ColumnData.ActionCol(),
];

export const FilterArr: () => Filter2DataProps[] = () => {
  const { prgDropList } = store.getState().list;
  const Arr: Filter2DataProps[] = [
    FilterData.patientName,
    FilterData.dob,
    FilterData.homeNo,
    FilterData.mobile,
    FilterData.orgName,
    FilterData.orgLocation,
    FilterData.clinicalStaff,
    FilterData.clinicalStaffDesig,
    FilterData.provider,
    FilterData.providerDesig,
    FilterData.primIns,
    FilterData.secIns,
    FilterData.cpInitiationFrom,
    FilterData.cpInitiationTo,
    {
      ...FilterData.prgmName,
      dropArr: FormatDropdownData(prgDropList, "id", "label", "label"),
    },
  ];
  return Arr;
};
