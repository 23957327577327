import { FC, useState, useEffect, Fragment, useContext } from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import { TableCol, actionArr, FilterArr, BreadArr } from "./Data";
import { CommonStyle, ControlledTable, Filter2 } from "Components";
import { TableLayout } from "Layouts";
import CstmBtn from "Components/CstmBtn";
import { useSelector } from "react-redux";
import PatientApi from "Service/Patient.api";
import Format from "./Format";
import { Filter2DataProps, HeadContextType } from "DataTypes/Common";
import { CheckNumber, CreateFilterPayload1 } from "Utils/common";
import { setAlertData } from "Redux/Screen/Action";
import { useDispatch } from "react-redux";
import { HeadContext } from "Context/HeadContext";
import { BillingType } from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";
import dayjs from "dayjs";

const Billing: FC = () => {
  const dispatch = useDispatch();
  const permission: any = useSelector(
    (state: any) => state?.user?.myPermission?.billingpatient
  );
  const downloadPermission = permission?.download || false;
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const [rows, setRows] = useState<BillingType[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIdArr, setSelectedArr] = useState<(number | null)[]>([]);
  const [month, setMonth] = useState(DateTime.CurrentMonthName());
  const [year, setYear] = useState(DateTime.CurrentYear());
  const [searchText, setSearchText] = useState("");
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [donwloadHeight, setDownloadHeight] = useState<number>(0);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>(
    JSON.parse(JSON.stringify(FilterArr()))
  );
  const offsetDownload = document?.getElementById("download-id")?.offsetHeight;
  const showLastRow =
    (selectedIdArr.length > 0 && permission?.sendtobilling) ||
    downloadPermission;
  const tableHeight = showLastRow
    ? `calc(99% - ${filterHeight + donwloadHeight}px)`
    : `calc(99% - ${filterHeight}px)`;

  useEffect(() => {
    setDownloadHeight(offsetDownload || 0);
  }, [offsetDownload]);

  useEffect(() => {
    setBreadArr(BreadArr);
  }, []);

  useEffect(() => {
    handleSetData();
  }, [filterData, month, year]);

  const handleSetData: () => void = async () => {
    setLoading(true);
    const payload = {
      ...(month ? { billingMonth: dayjs(month, "MMMM").month() + 1 } : {}),
      ...(year ? { billingYear: dayjs(year).year() } : {}),
      expectedColumnPair: CreateFilterPayload1(filterData),
    };
    const res = await PatientApi.billingList({ data: payload });
    if (res?.success) {
      setRows(Format.BillingList(res?.data));
    } else setRows([]);
    setLoading(false);
  };

  useEffect(() => {
    if (rows.length > 0) {
      const newWholeArr = rows
        .filter((el) => el?.status == "Billable")
        .map((el) => el.id);
      const difference = newWholeArr
        .filter((x) => !selectedIdArr.includes(x))
        .concat(selectedIdArr.filter((x) => !newWholeArr.includes(x)));
      setSelectAll(
        difference.length == 0 && selectedIdArr.length > 0 ? true : false
      );
    }
  }, [selectedIdArr]);

  const columnClick: () => void = () => {
    const totalSelectableRows = rows.filter((el) => el.status == "Billable");
    setSelectedArr(
      selectedIdArr.length == totalSelectableRows.length
        ? []
        : totalSelectableRows.map((el) => el.id)
    );
  };

  const rowClick: (rowId: string | number) => void = (rowId) => {
    const newRowId = CheckNumber(rowId);
    if (newRowId) {
      const find = selectedIdArr.find((el) => el == newRowId);
      if (find) setSelectedArr(selectedIdArr.filter((el) => el != newRowId));
      else setSelectedArr([...selectedIdArr, newRowId]);
    }
  };

  const sumPropertyValue: (
    arr: BillingType[],
    key:
      | "careteamtime"
      | "careteamclinictime"
      | "providerclinictime"
      | "totaltime"
  ) => number = (arr, key) => {
    return arr.reduce((sum, obj) => sum + (obj[key] ? Number(obj[key]) : 0), 0);
  };

  const sendToBolling: () => void = async () => {
    const payload = { billingIds: selectedIdArr };
    const res = await PatientApi.sendToBilling({ data: payload });
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      handleSetData();
      setSelectedArr([]);
    }
  };

  const downloadExport: () => void = async () => {
    const payload = {
      ...(month ? { billingMonth: dayjs(month, "MMMM").month() + 1 } : {}),
      ...(year ? { billingYear: dayjs(year).year() } : {}),
      expectedColumnPair: CreateFilterPayload1(filterData),
    };
    const res = await PatientApi.billingExport({ data: payload });
    if (res?.data) {
      const binaryResponse = res?.data;
      const blob = new Blob([binaryResponse], {
        type: "application/ms-excel",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Billing.xls";
      link.click();
    } else {
      dispatch(
        setAlertData({
          alertMessage: "Error fetching or downloading Excel file",
          alertVariant: "error",
          openAlert: true,
        })
      );
    }
  };

  const ExtraRow = (
    <TableRow
      sx={{
        position: "sticky",
        bottom: "0",
        "& >td": {
          fontWeight: "Bold",
          fontSize: "0.8rem",
          zIndex: -1,
          padding: 0,
          p: 1,
          backgroundColor: "custom.main",
        },
      }}
    >
      <TableCell colSpan={downloadPermission ? 6 : 5}>Total</TableCell>
      <TableCell align="left">
        {sumPropertyValue(rows, "careteamtime")}
      </TableCell>
      <TableCell align="left">
        {sumPropertyValue(rows, "careteamclinictime")}
      </TableCell>
      <TableCell align="left">
        {sumPropertyValue(rows, "providerclinictime")}
      </TableCell>
      <TableCell align="left">{sumPropertyValue(rows, "totaltime")}</TableCell>
      <TableCell colSpan={4} />
    </TableRow>
  );

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={(currentRow: any) => {
        const find = selectedIdArr.find((el) => el == currentRow?.id);
        const disbleCheckBox = currentRow?.status != "Billable" ? true : false;
        return TableCol(
          columnClick,
          rowClick,
          selectAll,
          find ? true : false,
          disbleCheckBox,
          downloadPermission
        );
      }}
      tableRow={rows}
      tableAction={(data) => actionArr(data, permission)}
      searchText={searchText}
    />
  );

  return (
    <Fragment>
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => setFilterData(data)}
        changeSearchText={(val) => setSearchText(val)}
        setFilterHeight={(val) => setFilterHeight(val)}
        monthChange={(val) => setMonth(val)}
        monthFilter={true}
        monthVal={month}
        yearChange={(val) => setYear(val)}
        yearFilter={true}
        yearVal={year}
      />
      {permission?.search && (
        <TableLayout
          tblBody={TblBodyJSX}
          extraRow={ExtraRow}
          tblHead={ControlledTable.TblHead(
            TableCol(
              columnClick,
              rowClick,
              selectAll,
              false,
              false,
              downloadPermission
            )
          )}
          height={tableHeight}
          loading={loading}
        />
      )}
      {showLastRow && (
        <Box
          id="download-id"
          sx={{
            ...CommonStyle.sxRow,
            justifyContent: "flex-end",
            "& >button": {
              ml: 1,
            },
          }}
        >
          {selectedIdArr.length > 0 && permission?.sendtobilling && (
            <CstmBtn label="Send to billing" onClick={() => sendToBolling()} />
          )}
          {downloadPermission && (
            <CstmBtn label="Download" onClick={() => downloadExport()} />
          )}
        </Box>
      )}
    </Fragment>
  );
};
export default Billing;
