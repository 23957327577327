import { DeclinedReason, PendingReason } from "Constant/Arr";
import { HandleChangeProps } from "DataTypes/Common";
import { FormDataType } from "DataTypes/Form";
import { EligibleProgramFormArr } from "DataTypes/PatientManagement.type";
import { EligiblePrgmListType } from "DataTypes/Services/Prgm.type";
import DateTime from "Utils/DateTime";
import PrgmFormatter from "Utils/Prgm.Formatter";
import { AscendSortByNumber, CheckArr, HandleFormChange } from "Utils/common";

const FormateEligibleRes: (
  data: any,
  formArr: FormDataType[]
) => EligibleProgramFormArr[] = (data, formArr) => {
  const CheckEligiblePrgmArr = CheckArr(data);
  const newArr = CheckEligiblePrgmArr.map((prgmEl) =>
    PrgmFormatter.EligiblePrgmList(prgmEl)
  );
  const newArr1: EligiblePrgmListType[] = AscendSortByNumber(
    newArr,
    "program_id"
  );
  const filterArr = newArr1.filter(
    (el) => el.status != "Not Applicable" && el.status != "Enrolled"
  );
  const assignArr = filterArr.map((filEl, index) => {
    const newFormArr: FormDataType[] = JSON.parse(JSON.stringify(formArr));
    newFormArr[0].value = newFormArr[0].dropValue = filEl.abbrevation;
    newFormArr[2].minVal = DateTime.CurrentDate();
    return {
      id: index + 1,
      ...filEl,
      formArr: newFormArr,
    };
  });
  return assignArr;
};

const HandleChange: (
  props: HandleChangeProps,
  rowId: number | null,
  programArr: EligibleProgramFormArr[]
) => EligibleProgramFormArr[] = (props, rowId, programArr) => {
  const { value, id } = props;
  let newArr: EligibleProgramFormArr[] = JSON.parse(JSON.stringify(programArr));
  newArr = newArr.map((prgmEl) => {
    if (prgmEl.id == rowId) {
      const newFormArr = prgmEl.formArr;
      if (id == "consent-drop") {
        if (value == "Pending" || value == "Declined") {
          newFormArr[2].readOnly = true;
          newFormArr[2].required = false;
          newFormArr[2].error = false;
          newFormArr[2].errorText = "";
          newFormArr[2].dropValue = newFormArr[2].value = "";
          newFormArr[3].readOnly = true;
          newFormArr[3].required = false;
          newFormArr[3].error = false;
          newFormArr[3].errorText = "";
          newFormArr[3].dropValue = newFormArr[3].value = "";
          newFormArr[4].readOnly = true;
          newFormArr[4].required = false;
          newFormArr[4].error = false;
          newFormArr[4].errorText = "";
          newFormArr[4].dropValue = newFormArr[4].value = "";
          newFormArr[5].readOnly = true;
          newFormArr[5].required = false;
          newFormArr[5].error = false;
          newFormArr[5].errorText = "";
          newFormArr[5].dropValue = newFormArr[5].value = "";
          newFormArr[6].readOnly = false;
          newFormArr[6].required = true;
          newFormArr[6].dropValue = newFormArr[6].value = "";
          newFormArr[6].dropArr =
            value == "Pending" ? PendingReason : DeclinedReason;
        } else if (value == "Enrolled") {
          newFormArr[2].dropValue = newFormArr[2].value =
            DateTime.CurrentDate();
          newFormArr[2].readOnly = false;
          newFormArr[2].required = true;
          newFormArr[3].readOnly = false;
          newFormArr[3].required = true;
          newFormArr[3].dropValue = "Verbal";
          newFormArr[4].readOnly = false;
          newFormArr[4].required = true;
          newFormArr[5].readOnly = true;
          newFormArr[5].required = false;
          newFormArr[6].readOnly = true;
          newFormArr[6].required = false;
          newFormArr[6].error = false;
          newFormArr[6].errorText = "";
          newFormArr[6].dropArr = [];
          newFormArr[6].dropValue = newFormArr[6].value = "";
        } else {
          newFormArr[2].readOnly = true;
          newFormArr[2].required = false;
          newFormArr[2].error = false;
          newFormArr[2].errorText = "";
          newFormArr[2].dropValue = newFormArr[2].value = "";
          newFormArr[3].readOnly = true;
          newFormArr[3].required = false;
          newFormArr[3].error = false;
          newFormArr[3].errorText = "";
          newFormArr[3].dropValue = newFormArr[3].value = "";
          newFormArr[4].readOnly = true;
          newFormArr[4].required = false;
          newFormArr[4].error = false;
          newFormArr[4].errorText = "";
          newFormArr[4].dropValue = newFormArr[4].value = "";
          newFormArr[5].readOnly = true;
          newFormArr[5].required = false;
          newFormArr[5].error = false;
          newFormArr[5].errorText = "";
          newFormArr[5].dropValue = newFormArr[5].value = "";
          newFormArr[6].readOnly = true;
          newFormArr[6].required = false;
          newFormArr[6].error = false;
          newFormArr[6].errorText = "";
          newFormArr[6].dropValue = newFormArr[6].value = "";
        }
      }
      if (id == "consent-create") {
        if (value == "Yes") {
          newFormArr[5].readOnly = false;
          newFormArr[5].required = true;
        } else {
          newFormArr[5].readOnly = true;
          newFormArr[5].required = false;
          newFormArr[5].error = false;
          newFormArr[5].errorText = "";
          newFormArr[5].value = newFormArr[5].dropValue = "";
        }
      }
      prgmEl.formArr = HandleFormChange(props, newFormArr);
    }
    return prgmEl;
  });
  return newArr;
};

export default { FormateEligibleRes, HandleChange };
