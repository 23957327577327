import { BreadArrType } from "DataTypes/Common";
import { columnType } from "DataTypes/Table";

export const TableCol: columnType[] = [
  {
    id: "icdCode",
    label: "ICD-10 code",
    width: "20%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  {
    id: "description",
    label: "Description",
    width: "80%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
];

export const MasterProblemICDBreadArr = (problemName: string) => {
  const Arr: BreadArrType[] = [
    {
      id: 1,
      label: `${problemName} > ICD-10 Codes`,
      path: "",
    },
  ];
  return Arr;
} 