import { FC, Fragment } from "react";
import { Box, Typography } from "@mui/material";
import Cstmtip from "Components/Tooltip";
import { QuestionTooltipBoxProps } from "DataTypes/Master";
import { CommonStyle } from "Components";
import ImgPath from "Constant/Imgs";

const TooltipBox: FC<QuestionTooltipBoxProps> = ({
  dataArr,
  noDataText,
  label,
}) => {
  return (
    <Cstmtip
      titleUI={
        dataArr?.length > 0 ? (
          <Box maxHeight={200} overflow={"auto"}>
            {dataArr?.map((dataEl) => {
              return (
                <Typography key={dataEl.id} variant="subtitle1" pr={0.5}>
                  • {dataEl.label}
                </Typography>
              );
            })}
          </Box>
        ) : (
          <Typography variant="subtitle1">{noDataText}</Typography>
        )
      }
      textUI={
        <Box sx={{ ...CommonStyle.sxRow, cursor: "pointer" }}>
          <Typography variant="subtitle1" fontWeight="bold" sx={{ mr: 0.5 }}>
            {label}
          </Typography>
          <ImgPath.InfoOutlined sx={{ width: 18, color: "primary.main" }} />
        </Box>
      }
    />
  );
};

export default TooltipBox;
