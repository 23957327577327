import { ControlledTable } from "Components";
import FilterData from "Components/Filter2/Data";
import { BreadArrType, Filter2DataProps } from "DataTypes/Common";
import { columnType, actionArrType } from "DataTypes/Table";
// import { store } from "Redux/Store";
// import { FormatDropdownData } from "Utils/DataFormatter";

export const BreadArr: BreadArrType[] = [
  {
    id: 2,
    label: "Eligible Patients",
    path: "",
  },
];

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.PicCol("8%"),
  ControlledTable.ColumnData.PatientInfoCol("13%"),
  ControlledTable.ColumnData.PracticeCol("14%"),
  ControlledTable.ColumnData.ProviderCol("13%"),
  ControlledTable.ColumnData.PrimeInsCol("12%"),
  ControlledTable.ColumnData.SecInsCol("14%"),
  ControlledTable.ColumnData.ProgramCol("17%"),
  ControlledTable.ColumnData.ActionCol("14%"),
];

export const actionArr: (
  handleChart: () => void,
  handleCallDetails: () => void,
  handlePatientDetails: () => void,
  handleTask: () => void,
  roleId: number | null
) => actionArrType[] = (
  handleChart,
  handleCallDetails,
  handlePatientDetails,
  handleTask,
  roleId
) => {
  return [
    ControlledTable.ActionData.userAction(handlePatientDetails),
    ControlledTable.ActionData.chartAction(handleChart),
    ...(roleId == 1 || roleId == 2
      ? []
      : [ControlledTable.ActionData.callAction(handleCallDetails)]),
    ControlledTable.ActionData.taskAction(handleTask),
  ];
};

export const FilterArr: () => Filter2DataProps[] = () => {
  // const { prgDropList } = store.getState().list;
  const Arr: Filter2DataProps[] = [
    FilterData.patientName,
    FilterData.dob,
    FilterData.homeNo,
    FilterData.mobile,
    FilterData.orgName,
    FilterData.orgLocation,
    FilterData.primIns,
    FilterData.secIns,
    // {
    //   ...FilterData.prgmName,
    //   dropArr: FormatDropdownData(prgDropList, "id", "label", "label"),
    // },
  ];
  return Arr;
};
