import { BreadArrType } from "DataTypes/Common";

export const programProblemBreadArr = () => {
  const Arr: BreadArrType[] = [
    {
      id: 1,
      label: "Program Problem Mapping",
      path: "",
    },
  ];
  return Arr;
} 