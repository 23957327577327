import { ConsentType } from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";
import PatientFormatter from "Utils/Patient.Formatter";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "Utils/common";

const ConsentList: (data: any) => ConsentType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: ConsentType[] = CheckedArr.map((el, index) => {
    const user = CheckStringEmpty(el?.consentGainedBy);
    const userDes = CheckStringEmpty(el?.consentGainedByDesignation);
    return {
      ...PatientFormatter.ListPatientInfo1(el),
      careplanId: CheckNumber(el?.careplanId),
      consent: CheckStringEmpty(el?.concent),
      consentgainedby: `${user ? user + (userDes ? ", " + userDes : "") : ""}`,
      typeconsent: CheckStringEmpty(el?.concentType),
      currentConsent: CheckStringEmpty(el?.currentConcent),
      enrolledProgramId: CheckNumber(el?.enrolledProgramId),
      enrollmentdate: DateTime.ValidDate(el?.enrolmentDate),
      id: index,
      program: CheckStringEmpty(el?.programAbbrevation),
      patientId: CheckStringId(el?.id),
      programId: CheckNumber(el?.programId),
    };
  });
  return newArr;
};

export default { ConsentList };
