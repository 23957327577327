import { BreadArrType } from "DataTypes/Common";
import { FormDataType } from "DataTypes/Form";

export const TypeArr = [
  {
    id: 1,
    label: "Administrative",
    value: "Administrative",
  },
  {
    id: 2,
    label: "Clinical Question",
    value: "Clinical Question",
  },
  {
    id: 3,
    label: "Medication",
    value: "Medication",
  },
  {
    id: 4,
    label: "Medication Refill",
    value: "Medication Refill",
  },
  {
    id: 5,
    label: "Medical Record - Electronic",
    value: "Medical Record - Electronic",
  },
  {
    id: 6,
    label: "Medical Record – Paper",
    value: "Medical Record – Paper",
  },
  {
    id: 7,
    label: "Mail Care Plan",
    value: "Mail Care Plan",
  },
  {
    id: 8,
    label: "Print Care Plan",
    value: "Print Care Plan",
  },
  {
    id: 9,
    label: "Electronically Send Care Plan",
    value: "Electronically Send Care Plan",
  },
  {
    id: 10,
    label: "Referral",
    value: "Referral",
  },
  {
    id: 11,
    label: "Scheduling",
    value: "Scheduling",
  },
  {
    id: 12,
    label: "Test Results",
    value: "Test Results",
  },
  {
    id: 13,
    label: "Other",
    value: "Other",
  },
];

export const TargetDaysArr = [
  {
    id: 1,
    label: "1 Day",
    value: "1 Day",
  },
  {
    id: 2,
    label: "2 Days",
    value: "2 Days",
  },
  {
    id: 3,
    label: "3 Days",
    value: "3 Days",
  },
  {
    id: 4,
    label: "4 Days",
    value: "4 Days",
  },
  {
    id: 5,
    label: "5 Days",
    value: "5 Days",
  },
  {
    id: 6,
    label: "6 Days",
    value: "6 Days",
  },
  {
    id: 7,
    label: "1 week",
    value: "1 week",
  },
  {
    id: 8,
    label: "2 weeks",
    value: "2 weeks",
  },
];

export const NameArr = [
  {
    id: 1,
    label: "Care Manager Group",
    value: "CMgroup",
  },
  {
    id: 2,
    label: "Care Coordinator Group",
    value: "CCgroup",
  },
  {
    id: 3,
    label: "Provider Group",
    value: "CCgroup",
  },
  {
    id: 4,
    label: "Louis Mcdonalds",
    value: "CCgroup",
  },
  {
    id: 5,
    label: "Johnny Reynolds",
    value: "CCgroup",
  },
];
export const BreadArr: BreadArrType[] = [
  {
    id: 1,
    label: "Home",
    path: "/",
  },
  {
    id: 2,
    label: "Task",
    path: "",
  },
];

export const TargetDateArr: FormDataType[] = [
  {
    id: "filter",
    boxType: "mui-datetime",
    boxSize: 3,
    validatetype: "date",
    required: true,
  },
];

export const TaskFormDataArr: FormDataType[] = [
  {
    id: "source",
    label: "Source",
    required: true,
    placeholder: "Select Source",
    boxType: "single-select-drop",
    dropArr: [
      {
        id: 1,
        label: "Patient",
        value: "Patient",
      },
      {
        id: 2,
        label: "Authorized Representative",
        value: "Authorized Representative",
      },
      {
        id: 3,
        label: "Provider",
        value: "Provider",
      },
    ],
    boxSize: 6,
    validatetype: "dropdown",
  },
  {
    id: "type",
    label: "Task Type",
    placeholder: "Select Task Type",
    required: true,
    boxType: "single-select-drop",
    dropArr: TypeArr,
    boxSize: 6,
    validatetype: "dropdown",
  },
  {
    id: "task-assign-to",
    label: "Assignee",
    placeholder: "Select Assignee",
    boxType: "single-select-drop",
    dropArr: NameArr,
    required: true,
    boxSize: 6,
    validatetype: "dropdown",
  },
  {
    id: "subject",
    label: "Subject",
    placeholder: "Enter Subject",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "name",
  },
  {
    id: "task-description",
    label: "Task Description",
    required: true,
    placeholder: "Enter Task Description",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "description",
    multiline: true,
    multilineRows: 3,
  },
  {
    id: "note",
    label: "Notes",
    placeholder: "Enter Notes",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "name",
    multiline: true,
    multilineRows: 3,
  },
  {
    id: "status",
    label: "Status",
    placeholder: "Select Status",
    boxType: "single-select-drop",
    dropValue: {
      id: 1,
      label: "Open",
      value: "Open",
    },
    dropArr: [
      {
        id: 1,
        label: "Open",
        value: "Open",
      },
      {
        id: 2,
        label: "Completed",
        value: "Completed",
      },
    ],
    boxSize: 6,
    validatetype: "dropdown",
  },
];
