import { ControlledTable } from "Components";
import FilterData from "Components/Filter2/Data";
import { BreadArrType, Filter2DataProps } from "DataTypes/Common";
import { columnType } from "DataTypes/Table";

export const TableCol: () => columnType[] = () => {
  const Arr: columnType[] = [
    {
      id: "actionType",
      label: "Action Type",
      width: "13%",
      align: "left",
      colType: "text",
      rowType: "text",
    },
    {
      id: "actionOn",
      label: "Action On",
      width: "13%",
      align: "left",
      colType: "text",
      rowType: "text",
    },
    {
      id: "actionBy",
      label: "Action By",
      width: "14%",
      align: "left",
      colType: "text",
      rowType: "text",
    },
    {
      id: "description",
      label: "Description",
      width: "30%",
      align: "left",
      colType: "text",
      rowType: "text",
    },
    {
      id: "ipAddress",
      label: "IP Address",
      width: "10%",
      align: "left",
      colType: "text",
      rowType: "text",
    },
    ControlledTable.ColumnData.DateTimeCol1("20%"),
  ];
  return Arr;
};

export const BreadArr: BreadArrType[] = [
  {
    id: 1,
    label: "User Audit Trail",
    path: "",
  },
];

export const FilterArr: () => Filter2DataProps[] = () => {
  return [
    FilterData.activityType,
    FilterData.activityOn,
    FilterData.requestedBy,
    FilterData.requestedByDesig,
    FilterData.startDate,
    FilterData.endDate,
  ];
};
