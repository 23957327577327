import { actionArrType, columnType } from "DataTypes/Table";
import { ControlledTable } from "Components";
import { BreadArrType, Filter2DataProps } from "DataTypes/Common";
import { TypeArr } from "Pages/Home/HeadScreen/PatientMgmt/Task/Data";
import { TaskPriorityArr, TaskStatusArr } from "Constant/Arr";
import { TaskListType } from "DataTypes/Services/Tasks";
import FilterData from "Components/Filter2/Data";

export const actionArr: (
  handleView: () => void,
  handleSelfAssign: () => void,
  rowData: TaskListType
) => actionArrType[] = (handleView, handleSelfAssign, rowData) => {
  const OpenArr: actionArrType[] = [
    ControlledTable.ActionData.viewTitleAction(handleView),
    ControlledTable.ActionData.selfAssignAction(handleSelfAssign),
  ];
  const ClosedArr: actionArrType[] = [
    ControlledTable.ActionData.viewTitleAction(handleView),
  ];
  return rowData?.status == "Open" || rowData?.status == "New"
    ? OpenArr
    : ClosedArr;
};

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.ExclaimationCol("3%"),
  ControlledTable.ColumnData.DateTimeCol1("10%"),
  ControlledTable.ColumnData.TaskTypeCol("10%"),
  ControlledTable.ColumnData.StatusCol("10%"),
  ControlledTable.ColumnData.PriorityCol("10%"),
  ControlledTable.ColumnData.PatientCol("10%"),
  ControlledTable.ColumnData.CreatedByCol("10%"),
  ControlledTable.ColumnData.SubjectCol("13%"),
  ControlledTable.ColumnData.NoteCol("13%"),
  ControlledTable.ColumnData.ActionCol("10%"),
];

export const BreadArr: BreadArrType[] = [
  {
    id: 1,
    label: "Group Tasks",
    path: "",
  },
];

export const FilterArr: () => Filter2DataProps[] = () => {
  return [
    FilterData.taskDate,
    { ...FilterData.taskType, dropArr: TypeArr },
    { ...FilterData.taskStatus, dropArr: TaskStatusArr },
    { ...FilterData.taskPriority, dropArr: TaskPriorityArr },
    FilterData.taskCreatedBy,
    FilterData.taskCreatedByDesig,
    FilterData.taskAssignee,
    FilterData.taskAssigneeDesig,
    FilterData.patientTask,
  ];
};
