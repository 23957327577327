import { ApiServiceProps } from "DataTypes/Services/Index.type";
import { post } from ".";

const Search: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient/${payload.id1}/quality-measure/search`,
    data: {
      data: payload.data,
      criterion: {
        offset: payload.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const Create: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient/${payload.id1}/quality-measure/create`,
    data: {
      data: payload.data,
    },
  };
  return post(request);
};

const Update: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient/${payload.id1}/quality-measure/${payload.id2}/update`,
    data: {
      data: payload.data,
    },
  };
  return post(request);
};

const NameExist: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient/${payload.id1}/quality-exist`,
    data: { data: payload.data },
  };
  return post(request);
};

export default { Search, Create, Update, NameExist };
