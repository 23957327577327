import { Box, Typography } from "@mui/material";
import { Fragment } from "react";
type ModalType = {
  content: string;
};
const AssignPatient = ({ content }: ModalType) => {
  return (
    <Fragment>
      <Box textAlign="center">
        <Typography mb={2}>{content}</Typography>
        <Typography>Do you wish to proceed?</Typography>
      </Box>
    </Fragment>
  );
};
export default AssignPatient;
