import { DropdownArrPropsType } from "DataTypes/Form";
import {
  CLEAR_LIST,
  STORE_MODEL_DROP_LIST,
  STORE_PROGRAM_DROP_LIST,
  STORE_PROGRAM_LIST,
  STORE_ROLE_DROP_LIST,
  STORE_NOTIFICATION_LIST,
  STORE_GENDER_LIST,
  STORE_QUALITY_LIST,
  STORE_CALLRESULT_LIST,
  STORE_FREQUENCY_LIST,
} from "./type";
import { PrgmListResType } from "DataTypes/Services/Prgm.type";
import { NotificationType } from "DataTypes/Notification";

export const addModelDropList = (modelList: DropdownArrPropsType[]) => {
  return {
    type: STORE_MODEL_DROP_LIST,
    payload: modelList,
  };
};

export const addNotificationList = (list: NotificationType[]) => {
  return {
    type: STORE_NOTIFICATION_LIST,
    payload: list,
  };
};

export const addPrgDropList = (prgList: DropdownArrPropsType[]) => {
  return {
    type: STORE_PROGRAM_DROP_LIST,
    payload: prgList,
  };
};

export const addProgramList = (prgList: PrgmListResType[]) => {
  return {
    type: STORE_PROGRAM_LIST,
    payload: prgList,
  };
};

export const addRoleDropList = (roleList: DropdownArrPropsType[]) => {
  return {
    type: STORE_ROLE_DROP_LIST,
    payload: roleList,
  };
};

export const addGenderDropList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_GENDER_LIST,
    payload: list,
  };
};

export const addQualityDropList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_QUALITY_LIST,
    payload: list,
  };
};

export const addCallDropList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_CALLRESULT_LIST,
    payload: list,
  };
};

export const addFreqDropList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_FREQUENCY_LIST,
    payload: list,
  };
};

export const clearListData = () => {
  return {
    type: CLEAR_LIST,
  };
};
