//@flow
import { setAlertData, stopLoading } from "Redux/Screen/Action";
import axios from "axios";
import { store } from "Redux/Store";
import {
  CheckPaginateNumber,
  CheckStringEmpty,
  Logout,
  getPaginationPage,
} from "Utils/common";
export const authTokenKey = "Authorization";

const apiBaseUrls = {
  prod: process.env.REACT_APP_BASE_URL,
  dev: process.env.REACT_APP_BASE_URL,
  stage: process.env.REACT_APP_BASE_URL,
}; /*  */

const apiBaseUrlsAuth = {
  prod: process.env.REACT_APP_BASE_AUTH_URL,
  dev: process.env.REACT_APP_BASE_AUTH_URL,
  stage: process.env.REACT_APP_BASE_AUTH_URL,
};

const apiBaseUrlsRedox = {
  prod: process.env.REACT_APP_BASE_REDOX_URL,
  dev: process.env.REACT_APP_BASE_REDOX_URL,
  stage: process.env.REACT_APP_BASE_REDOX_URL,
};

const react_app_env = {
  prod: "production",
  dev: "development",
};

let baseUrl = apiBaseUrls.dev;
if (process.env.NODE_ENV === react_app_env.prod) {
  baseUrl = apiBaseUrls.prod;
}

let baseUrlAuth = apiBaseUrlsAuth.dev;
if (process.env.NODE_ENV === react_app_env.prod) {
  baseUrlAuth = apiBaseUrlsAuth.prod;
}

let baseUrlRedox = apiBaseUrlsRedox.dev;
if (process.env.NODE_ENV === react_app_env.prod) {
  baseUrlRedox = apiBaseUrlsRedox.prod;
}

export const get = (request: any) => {
  return commonFetch({ method: "get", ...request });
};

export const post = (request: any) => {
  return commonFetch({ method: "post", ...request });
};

export const patch = (request: any) => {
  return commonFetch({ method: "patch", ...request });
};

export const put = (request: any) => {
  return commonFetch({ method: "put", ...request });
};

export const deletee = (request: any) => {
  return commonFetch({ method: "delete", ...request });
};

export const abortSignal = axios.CancelToken.source();

const commonFetch = (request: any) => {
  const dispatch = store.dispatch;
  try {
    const {
      subUrl,
      method,
      data = {},
      params = {},
      headers = {},
      isAuth,
      isRedox,
      excel,
    } = request;

    let url;
    if (isAuth) url = `${baseUrlAuth}/auth/${subUrl}`;
    else if (isRedox) url = `${baseUrlRedox}/redox/${subUrl}`;
    else url = `${baseUrl}/api/${subUrl}`;

    const passedHeaders = headers;
    const commonHeaders = getCommonHeaders(excel);
    const responseType = excel ? "blob" : "json";
    return axios({
      method,
      url,
      // data: { data },
      responseType,
      data,
      withCredentials: false,
      headers: excel
        ? { ...commonHeaders }
        : { ...commonHeaders, ...passedHeaders },
      params,
    })
      .then((response) => {
        if (response?.data?.status == 1) {
          return {
            success: true,
            data: response?.data?.data,
            criterion: {
              limit: CheckPaginateNumber(response?.data?.criterion?.limit),
              page: getPaginationPage(
                CheckPaginateNumber(response?.data?.criterion?.offset),
                CheckPaginateNumber(response?.data?.criterion?.limit)
              ),
              total: CheckPaginateNumber(response?.data?.criterion?.total),
            },
            alert: {
              alertMessage: CheckStringEmpty(
                response?.data?.msg || response?.data?.error || "Save Changes"
              ),
              openAlert: true,
              alertVariant: "success",
            },
          };
        } else if (excel) {
          return { success: true, data: response?.data };
        } else {
          dispatch(
            setAlertData({
              alertMessage: CheckStringEmpty(
                response?.data?.error ||
                  response?.data?.msg ||
                  "Something went wrong!"
              ),
              openAlert: true,
              alertVariant: "error",
            })
          );
          dispatch(stopLoading());
          return {
            success: false,
          };
        }
      })
      .catch((error) => {
        if (error?.response?.status == 400) {
          dispatch(
            setAlertData({
              alertMessage:
                "Unable to perform this action. Please try after sometime.",
              openAlert: true,
              alertVariant: "error",
            })
          );
        } else if (error?.response?.status == 401) {
          dispatch(
            setAlertData({
              alertMessage: "Session has expired. Please log in again.",
              openAlert: true,
              alertVariant: "error",
            })
          );
          Logout();
        } else if (error?.response?.status == 403) {
          dispatch(
            setAlertData({
              alertMessage: "You are not authorized to perform this action.",
              openAlert: true,
              alertVariant: "error",
            })
          );
        } else if (error?.response?.status == 404) {
          dispatch(
            setAlertData({
              alertMessage: "Resource not found. Please try after sometime.",
              openAlert: true,
              alertVariant: "error",
            })
          );
        } else if (error?.response?.status == 409) {
          dispatch(
            setAlertData({
              alertMessage:
                "User login to another system with these credentials.",
              openAlert: true,
              alertVariant: "error",
            })
          );
          Logout();
        } else if (error?.response?.status == 500) {
          dispatch(
            setAlertData({
              alertMessage:
                "Something went wrong. Please login after sometime",
              openAlert: true,
              alertVariant: "error",
            })
          );
          Logout();
        } else if (error?.response?.status == 503) {
          dispatch(
            setAlertData({
              alertMessage: "Service Unavailable. Please login after sometime",
              openAlert: true,
              alertVariant: "error",
            })
          );
          Logout();
        } else {
          dispatch(
            setAlertData({
              alertMessage:
                "Something went wrong. Please login after sometime.",
              openAlert: true,
              alertVariant: "error",
            })
          );
          Logout();
        }
        dispatch(stopLoading());
        return {
          success: false,
        };
        // if (
        //   (error?.response?.status == 401 || error?.response?.status == 500) &&
        //   commonHeaders?.Authorization
        // ) {
        //   console.log({ error });
        //   dispatch(
        //     setAlertData({
        //       alertMessage: "Something went wrong! Please log in again.",
        //       openAlert: true,
        //       alertVariant: "error",
        //     })
        //   );
        //   // Logout();
        // } else if (
        //   error?.response?.status == 403 &&
        //   commonHeaders?.Authorization
        // ) {
        //   dispatch(
        //     setAlertData({
        //       alertMessage: "Access Denied",
        //       openAlert: true,
        //       alertVariant: "error",
        //     })
        //   );
        // } else {
        //   dispatch(
        //     setAlertData({
        //       alertMessage: CheckStringEmpty(
        //         error?.response?.data?.error ||
        //           error?.message ||
        //           "Something went wrong!"
        //       ),
        //       openAlert: true,
        //       alertVariant: "error",
        //     })
        //   );
        // }
        // dispatch(stopLoading());
        // return {
        //   success: false,
        // };
      });
  } catch (err) {
    dispatch(
      setAlertData({
        openAlert: true,
        alertMessage: JSON.stringify(err),
        alertVariant: "error",
      })
    );
    dispatch(stopLoading());
    return {
      success: false,
    };
  }
};

const getCommonHeaders = (excel?: boolean) => {
  const localToken = localStorage.getItem("cm-token") || "";
  const tokenStorage = localToken ? JSON.parse(localToken) : "";

  const headerObj: any = {
    "Content-Type":
      // excel ? "application/vnd.ms-excel" :
      "application/json",
    // Accept: excel
    //   ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    //   : "",
    "Access-Control-Allow-Origin": true,
    [authTokenKey]: tokenStorage ? `Bearer ${tokenStorage}` : "",
  };
  const headerObjForExcel: any = {
    [authTokenKey]: tokenStorage ? `Bearer ${tokenStorage}` : "",
  };
  return excel ? headerObjForExcel : headerObj;
};
