import { Box, Typography } from "@mui/material";
import { Fragment, useEffect, useState, FC, useContext } from "react";
import { CommonStyle, ControlledTable, Modal } from "Components";
import CstmBtn from "Components/CstmBtn";
import { ModalLayout, TableLayout } from "Layouts";
import { actionArr, CategoryMasterBreadArr, DataArr1, TableCol } from "./Data";
import { HandleChangeProps, HeadContextType } from "DataTypes/Common";
import { useSelector } from "react-redux";
import CategoryApi from "Service/Category.api";
import { CommonButtonArray, DeleteButtonArray } from "Components/Modal/Data";
import { ValidateFormData } from "Utils/common";
import SwitchCase from "Utils/SwitchCase";
import { HeadContext } from "Context/HeadContext";
import { useDispatch } from "react-redux";
import { setAlertData } from "Redux/Screen/Action";
import { FormDataType } from "DataTypes/Form";
import CategoryFormatter from "Utils/Category.Formatter";
import { CategoryApiObj } from "DataTypes/Services/Category.type";

const QuesCategory: FC = () => {
  const dispatch = useDispatch();
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const permission: any = useSelector(
    (state: any) => state?.user?.myPermission?.categories
  );
  const [categoryList, setCategoryList] = useState<CategoryApiObj[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [editRow, setEditRow] = useState<CategoryApiObj | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteRow, setDeleteRow] = useState<CategoryApiObj | null>(null);
  const [loading, setLoading] = useState(true);
  const [uniqueCategory, setUniqueCategory] = useState<string[]>([]);
  const [formData1, setFormData1] = useState<FormDataType[]>([]);

  useEffect(() => {
    setBreadArr(CategoryMasterBreadArr());
    getCategoryListFn();
  }, []);

  const getCategoryListFn = async () => {
    setLoading(true);
    const payload = { isDeleted: false };
    const res: any = await CategoryApi.list({ data: payload });
    if (res?.success) {
      const formatedArr = CategoryFormatter.CheckCategoryArr(res?.data);
      setUniqueCategory(formatedArr.map((el) => el.name.toLowerCase()));
      setCategoryList(formatedArr);
    }
    setLoading(false);
  };

  useEffect(() => {
    !openModal && setFormData1(JSON.parse(JSON.stringify(DataArr1)));
  }, [openModal]);

  useEffect(() => {
    editRow && handleEditButton();
  }, [editRow]);

  const handleEditButton: () => void = () => {
    const newArr: FormDataType[] = JSON.parse(JSON.stringify(formData1));
    newArr[0].value = newArr[0].dropValue = editRow?.name;
    newArr[1].value = newArr[1].dropValue = editRow?.sortOrder
      ? editRow?.sortOrder.toString()
      : "";
    newArr[2].value = newArr[2].dropValue = editRow?.type;
    setFormData1(newArr);
    setOpenModal(true);
  };

  const HandleFormChange: (data: HandleChangeProps) => void = ({
    value,
    id,
  }) => {
    const newArr = formData1.map((el: FormDataType) => {
      if (el.id == "category") {
        el.uniqueArr = editRow
          ? uniqueCategory.filter(
            (unEl) => unEl != editRow?.name?.toLowerCase()
          )
          : uniqueCategory;
      }
      if (el.id == id) {
        el.value = el.dropValue = value;
        const temp = SwitchCase.FormValidation(el);
        el.error = temp.error;
        el.errorText = temp.errorText;
      }
      return el;
    });
    setFormData1(newArr);
  };

  const handleAddEdit: () => void = async () => {
    const temp = ValidateFormData(formData1);
    setFormData1(temp.formData);
    if (!temp.errorCheck) {
      const payload = {
        name: formData1[0].value,
        status: "ACTIVE",
        sortOrder: formData1[1].value,
        type: formData1[2].value,
      };
      let res: any;
      if (editRow) {
        res = await CategoryApi.update({
          id1: editRow?.id,
          data: payload,
        });
      } else {
        res = await CategoryApi.create({ data: payload });
      }
      if (res?.success) {
        dispatch(setAlertData(res?.alert));
        getCategoryListFn();
        setEditRow(null);
        setOpenModal(false);
      }
    }
  };

  useEffect(() => {
    deleteRow ? setOpenDeleteModal(true) : setOpenDeleteModal(false);
  }, [deleteRow]);

  const handleDeleteItem = async () => {
    const res: any = await CategoryApi.updateDelete({ id1: deleteRow?.id });
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      getCategoryListFn();
      setDeleteRow(null);
    }
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={categoryList}
      tableAction={(data: CategoryApiObj) =>
        actionArr(
          () => setEditRow(data),
          () => setDeleteRow(data)
        )
      }
    />
  );

  return (
    <Fragment>
      <ModalLayout
        modwidth="30%"
        heading={"Warning"}
        content={
          deleteRow?.questioncount && deleteRow.questioncount > 0 ? (
            <Box textAlign={"center"}>
              <Typography mb={2}>
                Deleting <b>{deleteRow?.name}</b> category will delete all the
                associated ({deleteRow?.questioncount}) questions.
              </Typography>
              <Typography>Do you want to proceed?</Typography>
            </Box>
          ) : (
            <Typography textAlign={"center"}>
              Do you want to delete <b>{deleteRow?.name}</b> category from the
              list ?
            </Typography>
          )
        }
        open={openDeleteModal}
        onClose={() => setDeleteRow(null)}
        buttonArr={DeleteButtonArray(handleDeleteItem, () =>
          setDeleteRow(null)
        )}
      />
      <ModalLayout
        modwidth="30%"
        heading={editRow == null ? "Add New Category" : "Edit Category"}
        content={
          <Modal.AddCategoryModal
            formArr={formData1}
            handleChange={HandleFormChange}
          />
        }
        open={openModal}
        onClose={() => {
          setEditRow(null);
          setOpenModal(false);
        }}
        buttonArr={CommonButtonArray(
          handleAddEdit,
          () => {
            setEditRow(null), setOpenModal(false);
          },
          editRow == null ? "Add" : "Save"
        )}
      />
      <Box
        height="100%"
        sx={{ ...CommonStyle.sxWhiteBox, px: 2 }}
        overflow={"auto"}
      >
        <Box
          height={"10%"}
          justifyContent={"space-between"}
          sx={{ ...CommonStyle.sxRow }}
        >
          <Typography sx={{ ...CommonStyle.sxHeading }}>
            {"Question Category Master"}
          </Typography>
          {permission?.create && (
            <CstmBtn
              label="Add New Category"
              onClick={() => setOpenModal(true)}
            />
          )}
        </Box>
        {permission?.search && (
          <Box height="85%">
            <TableLayout
              tblBody={TblBodyJSX}
              loading={loading}
              tblHead={ControlledTable.TblHead(TableCol)}
              height={"100%"}
            />
          </Box>
        )}
      </Box>
    </Fragment>
  );
};
export default QuesCategory;
