import Stages from "Constant/Stages";
import { MyPatientType } from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";
import PatientFormatter from "Utils/Patient.Formatter";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "Utils/common";

const MyPatientList: (data: any) => MyPatientType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: MyPatientType[] = CheckedArr.map((el, index) => {
    const teamInitiatorRoleId = CheckNumber(el?.teamInitiatorRoleId);
    const newStatus = CheckStringEmpty(el?.careplanState);
    const program = CheckStringEmpty(el?.programAbbrevation);
    const status =
      program == "TCM"
        ? newStatus == Stages.CPShare
          ? "Pending Completion"
          : newStatus
        : newStatus == Stages.CPShare && teamInitiatorRoleId == 5
        ? Stages.CPSignOff
        : newStatus;
    const user = CheckStringEmpty(el?.consentGainedBy);
    const userDes = CheckStringEmpty(el?.consentGainedByDesignation);
    return {
      ...PatientFormatter.ListPatientInfo1(el),
      careplanId: CheckNumber(el?.careplanId),
      cpinitiation: DateTime.ValidDate(el?.careplanInitDate),
      careplanState: CheckStringEmpty(el?.careplanState),
      consentCreatedBy: CheckNumber(el?.consentCreatedBy),
      consentgainedby: user ? `${user}${userDes ? ", " + userDes : ""}` : "",
      enrolledProgramId: CheckNumber(el?.enrolledProgramId),
      enrollmentdate: DateTime.ValidDate(el?.enrolmentDate),
      id: index,
      program,
      patientId: CheckStringId(el?.id),
      programId: CheckNumber(el?.programId),
      providerId: CheckNumber(el?.providerId),
      provider: CheckStringEmpty(el?.providerName),
      status,
      teamInitiatedBy: CheckNumber(el?.teamInitiatedBy),
      teamInitiatorRoleId,
    };
  });
  return newArr;
};

export default { MyPatientList };
