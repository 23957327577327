import { Fragment, useEffect, useState, FC } from "react";
import { Box, Typography } from "@mui/material";
import CarePlan from "./CarePlan";
import PreNotes from "./PreNotes";
import CareTeam from "./CareTeam";
import DischargeSummary from "./DischargeSummary";
import { CommonStyle, CstmAccordion, Loader } from "Components";
import ImgPath from "Constant/Imgs";
import { useNavigate, useParams } from "react-router-dom";
import AddonLogTime from "./Addon Log Time";
import PatientApi from "Service/Patient.api";
import { PatientInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import PatientFormatter from "Utils/Patient.Formatter";
import NoData from "Components/NoData";
import { RouteUrls } from "Constant/RouteUrls";
import { label } from "Constant/En";
import DateTime from "Utils/DateTime";
import CarePlanApi from "Service/CarePlan.api";
import { SideModalProps } from "DataTypes/Modal";
import { EligiblePrgmResType } from "DataTypes/Services/Patient.type";
import { useDispatch } from "react-redux";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import Stages from "Constant/Stages";
import { ReadOnlyType } from "DataTypes/PatientManagement.type";

const CM: FC<SideModalProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const [prgList, setPrgList] = useState<EligiblePrgmResType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData();
  }, []);

  const setData = async () => {
    const res: any = await PatientApi.enrollPrgmHistory({
      id1: patientInfo.id,
    });
    if (res.success) {
      const formatArr = PatientFormatter.CheckEligiblePrgmRes(res?.data);
      setPrgList(formatArr);
    }
    setLoading(false);
  };

  const callMeth = async (careplanId: number | null, careplanName: string) => {
    const res: any = await CarePlanApi.CPPDFDownload(
      { id1: careplanId },
      "CareplanSummary"
    );
    const binaryResponse = res.data;
    const blob = new Blob([binaryResponse], {
      type: "application/pdf",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${patientInfo.name}_${
      careplanName == "TCM" ? "TCM" : "Careplan"
    }_Summary.pdf`;
    link.click();
  };

  const consentPdfDownload = async (
    enrollId: number | null,
    fileType: string
  ) => {
    const res: any = await CarePlanApi.ConsentPDFDownload(
      { id1: enrollId },
      fileType
    );
    const binaryResponse = res.data;
    const blob = new Blob([binaryResponse], {
      type: "application/pdf",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${patientInfo.name}_${
      fileType == "Enrolled" ? "Consent" : "Cancellation_Form"
    }.pdf`;
    link.click();
  };

  const handleEditCarePlan: (data: EligiblePrgmResType) => void = (data) => {
    onClose();
    const defaultArr: ReadOnlyType[] = [
      "uptoDate",
      "problem",
      "question",
      "team",
    ];
    data.careplanState == Stages.Recurring && defaultArr.push("addNotes");
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...data,
        readOnlyArr: defaultArr,
        showSaveBtn: true,
      })
    );
    navigate(
      `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.carePlan}/${data.careplanId}/${RouteUrls.careSummary}`
    );
  };

  const handleEditTeam: (data: EligiblePrgmResType) => void = (data) => {
    onClose();
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        careplanId: data.careplanId,
      })
    );
    navigate(
      `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.carePlan}/${data.careplanId}/${RouteUrls.editTeam}`
    );
  };

  const MainBody: FC<{ data: EligiblePrgmResType }> = ({ data }) => {
    return (
      <Box sx={{ ...CommonStyle.sxRow }}>
        <Typography variant="body1" fontWeight="bold" width={"80%"}>
          {data.abbrevation}
        </Typography>
        <Box sx={{ ...CommonStyle.sxRow, width: "20%" }}>
          <Box>
            <Typography variant="subtitle1">
              {`${DateTime.MonthName(data.planningMonth) || label.naText} ${
                data.planningYear || label.naText
              }`}
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              {data.status}
            </Typography>
          </Box>
          <Box
            sx={{
              ...CommonStyle.sxRow,
              "&>div": {
                ...CommonStyle.sxRoundIconsMainMd,
                ml: 1,
              },
            }}
          >
            {data.status == "Enrolled" &&
              (myInfo.roleId == 1 || myInfo.roleId == 2) && (
              <Box onClick={() => handleEditTeam(data)}>
                <ImgPath.EditOutlined titleAccess="Edit Team" />
              </Box>
            )}
            {data.programId != 3 && (
              <Box
                onClick={() =>
                  consentPdfDownload(data.enrollmentId, "Enrolled")
                }
              >
                <ImgPath.DownloadIcon titleAccess="Download Consent PDF" />
              </Box>
            )}
            {data.status == "Unenrolled" && (
              <Box
                onClick={() =>
                  consentPdfDownload(data.enrollmentId, "Unenrolled")
                }
              >
                <ImgPath.DownloadIcon titleAccess="Download Cancellation Form PDF" />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const CarePlanMainBody: FC<{ data: EligiblePrgmResType }> = ({ data }) => {
    return (
      <Box sx={{ ...CommonStyle.sxRow, justifyContent: "space-between" }}>
        <Typography variant="body2" fontWeight="bold">
          {data.abbrevation != "TCM" ? "Care Plan" : "TCM"}
        </Typography>
        <Box
          sx={{
            ...CommonStyle.sxRow,
            "&>div": { ml: 1 },
          }}
        >
          {!data.isCareplanComprehensive && (
            <Fragment>
              {data.abbrevation == "TCM" && (
                <Box
                  sx={{ ...CommonStyle.sxRoundIconsMainMd }}
                  onClick={() => {
                    callMeth(data.careplanId, data.abbrevation);
                  }}
                >
                  <ImgPath.DownloadIcon
                    titleAccess={"Download TCM Summary PDF"}
                  />
                </Box>
              )}
              {data.abbrevation != "TCM" &&
                data.careplanState == Stages.Recurring && (
                <Box
                  sx={{ ...CommonStyle.sxRoundIconsMainMd }}
                  onClick={() => {
                    callMeth(data.careplanId, data.abbrevation);
                  }}
                >
                  <ImgPath.DownloadIcon
                    titleAccess={"Download Careplan PDF"}
                  />
                </Box>
              )}
            </Fragment>
            // <Box
            //   sx={{ ...CommonStyle.sxRoundIconsMainMd }}
            //   onClick={() => {
            //     callMeth(data.careplanId, data.abbrevation);
            //   }}
            // >
            //   <ImgPath.DownloadIcon
            //     titleAccess={
            //       data.abbrevation == "TCM"
            //         ? "Download TCM Summary PDF"
            //         : "Download Careplan PDF"
            //     }
            //   />
            // </Box>
          )}
          {data.abbrevation != "TCM" &&
            data.status == "Enrolled" &&
            (data.billableproviderId == myInfo.id ||
              data.caremanagerId == myInfo.id ||
              data.providerId == myInfo.id) && (
            <Box
              sx={{
                ...CommonStyle.sxRoundIconsMainMd,
              }}
              onClick={() => handleEditCarePlan(data)}
            >
              <ImgPath.EditOutlined titleAccess="Expand & Edit Frequency" />
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          {prgList.length ? (
            prgList.map((el) => {
              return (
                <Fragment key={el.careplanId}>
                  <CstmAccordion
                    mainContent={<MainBody data={el} />}
                    subContent={
                      <Box
                        sx={{
                          "& >div:last-of-type": { borderColor: "transparent" },
                        }}
                      >
                        {el.careplanStage >
                          PatientFormatter.GetCPStage(Stages.CPShare) && (
                          <CstmAccordion
                            mainContent={<CarePlanMainBody data={el} />}
                            subContent={<CarePlan data={el} />}
                          />
                        )}
                        {el.careplanStage >
                          PatientFormatter.GetCPStage(Stages.CPTeam) && (
                          <CstmAccordion
                            mainContent={
                              <Typography variant="body2" fontWeight="bold">
                                {el.abbrevation == "TCM"
                                  ? "TCM Team"
                                  : "Care Team"}
                              </Typography>
                            }
                            subContent={<CareTeam data={el} />}
                          />
                        )}
                        {el.careplanStage >
                          PatientFormatter.GetCPStage(Stages.CPSignOff) &&
                          el.abbrevation !== "TCM" && (
                          <CstmAccordion
                            mainContent={
                              <Typography variant="body2" fontWeight="bold">
                                  Previous Notes
                              </Typography>
                            }
                            subContent={
                              <PreNotes data={el} onClose={onClose} />
                            }
                          />
                        )}
                        <CstmAccordion
                          mainContent={
                            <Typography variant="body2" fontWeight="bold">
                              Additional Time Logged
                            </Typography>
                          }
                          subContent={<AddonLogTime data={el} />}
                        />
                        {el.careplanStage >
                          PatientFormatter.GetCPStage(Stages.CPDischarge) &&
                          el.abbrevation == "TCM" && (
                          <CstmAccordion
                            mainContent={
                              <Typography variant="body2" fontWeight="bold">
                                {"Discharge Summary"}
                              </Typography>
                            }
                            subContent={<DischargeSummary data={el} />}
                          />
                        )}
                      </Box>
                    }
                  />
                </Fragment>
              );
            })
          ) : (
            <NoData />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
export default CM;
