import {
  CatSearchDocType,
  CategorySearchType,
  MedSearchType,
  VisitSearchType,
} from "DataTypes/Services/Redox.type";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "./common";
import DateTime from "./DateTime";
import {
  RedoxAllergyType,
  RedoxAppointmentType,
  RedoxContactType,
  RedoxInsuranceType,
  RedoxProblemType,
  RedoxResultType,
  RedoxVaccineType,
  RedoxVitalType,
} from "DataTypes/Services/Patient.type";

const CategorySearch: (data: any) => CategorySearchType = (data) => {
  const docType = data?.facet_counts?.facet_fields?.documentType;
  return {
    response: CatSearchDoc(data?.response),
    facet_counts: {
      facet_fields: {
        documentType: {
          "lab result": CheckNumber(docType["lab result"]),
          "consent form": CheckNumber(docType["consent form"]),
          "clinical document": CheckNumber(docType["clinical document"]),
          "treatment plan": CheckNumber(docType["treatment plan"]),
        },
      },
    },
  };
};

const CatSearchDoc: (data: any) => CatSearchDocType[] = (data) => {
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => {
    return {
      documentDescription: CheckStringEmpty(el?.documentDescription),
      documentType: CheckStringEmpty(el?.documentType),
      documentName: CheckStringEmpty(el?.documentName),
      availability: CheckStringEmpty(el?.availability),
      indexedDate: CheckStringEmpty(el?.indexedDate),
      documentationDatetime: CheckStringEmpty(el?.documentationDatetime),
      path: CheckStringEmpty(el?.path),
      id: CheckStringEmpty(el?.id),
      contentType: CheckStringEmpty(el?.contentType),
      fileType: CheckStringEmpty(el?.fileType),
      url: "",
    };
  });
};

const MedSearch: (data: any) => MedSearchType[] = (data) => {
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      productName: CheckStringEmpty(el?.productName),
      stopDate: DateTime.ValidDate(el?.endDate),
      doseQuantity: CheckStringEmpty(el?.doseQuantity),
      doseUnits: CheckStringEmpty(el?.doseUnits),
      frequencyPeriod: CheckStringEmpty(el?.frequencyPeriod),
      frequencyUnit: CheckStringEmpty(el?.frequencyUnit),
      routeName: CheckStringEmpty(el?.routeName),
    };
  });
};

const VisitSearch: (data: any) => VisitSearchType[] = (data) => {
  const newArr = CheckArr(data);
  return newArr.map((el) => {
    return {
      id: CheckNumber(el?.visitNumber),
      visitNumber: CheckStringEmpty(el?.visitNumber),
      facility: CheckStringEmpty(el?.Facility),
      reason: CheckStringEmpty(el?.Reason),
      datetime: DateTime.ValidDate(el?.visitDateTime),
    };
  });
};

const ProblemList: (data: any) => RedoxProblemType[] = (data) => {
  const newArr = Array.isArray(data) ? data : [];
  return newArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      name: CheckStringEmpty(el?.name),
      code: CheckStringEmpty(el?.code),
      codeSystemName: CheckStringEmpty(el?.codeSystemName),
    };
  });
};

const AllergiesList: (data: any) => RedoxAllergyType[] = (data) => {
  const newArr = CheckArr(data);
  return newArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      name: CheckStringEmpty(el?.substanceName),
    };
  });
};

const ResultList: (data: any) => RedoxResultType[] = (data) => {
  const newArr = CheckArr(data);
  const tempArr: RedoxResultType[] = [];
  let temp: RedoxResultType;
  newArr.map((el) => {
    el?.observations.map((item: any) => {
      temp = {
        id: CheckNumber(item?.id),
        resultName: CheckStringEmpty(el?.name),
        observationName: CheckStringEmpty(item?.name),
        units: CheckStringEmpty(item?.units),
        value: CheckStringEmpty(item?.value),
      };
      tempArr.push(temp);
    });
  });
  return tempArr;
};

const VaccineList: (data: any) => RedoxVaccineType[] = (data) => {
  const newArr = CheckArr(data);
  return newArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      name: CheckStringEmpty(el?.productName),
      dateTime: DateTime.ValidDate(el?.dateTime),
    };
  });
};

const VitalList: (data: any) => RedoxVitalType[] = (data) => {
  const newArr = CheckArr(data);
  return newArr.map((el) => {
    const units = CheckStringEmpty(el?.units);
    const value = CheckStringEmpty(el?.value);
    return {
      id: CheckNumber(el?.id),
      name: CheckStringEmpty(el?.name),
      dateTime: DateTime.ValidDate(el?.vitalSignDatetime),
      value: value ? value + (units ? " " + units : "") : "",
    };
  });
};

const InsuranceList: (data: any) => RedoxInsuranceType[] = (data) => {
  const newArr = CheckArr(data);
  return newArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      planName: CheckStringEmpty(el?.planName),
      companyName: CheckStringEmpty(el?.companyName),
      policyNumber: CheckStringEmpty(el?.policyNumber),
      priority: CheckStringEmpty(el?.priority),
      effectiveDate: DateTime.ValidDate(el?.effectiveDate),
      expirationDate: DateTime.ValidDate(el?.expirationDate),
    };
  });
};

export const ContactList: (data: any) => RedoxContactType[] = (data) => {
  const newArr = CheckArr(data);
  return newArr.map((el) => {
    const firstName = CheckStringEmpty(el?.firstName);
    const middleName = CheckStringEmpty(el?.middleName);
    const lastName = CheckStringEmpty(el?.lastName);
    const name = firstName
      ? firstName +
        (middleName ? ` ${middleName}` : "") +
        (lastName ? ` ${lastName}` : "")
      : "";
    const homeAddress = CheckStringEmpty(el?.patientAdminContactStreetAddress);
    const city = CheckStringEmpty(el?.patientAdminContactAddresscity);
    const state = CheckStringEmpty(el?.patientAdminContactAddressState);
    const country = CheckStringEmpty(el?.patientAdminContactAddressCountry);
    const zip = CheckStringEmpty(el?.patientAdminContactAddressZIP);
    const address = homeAddress
      ? homeAddress
      : "" + city
      ? ` ${city}`
      : "" + state
      ? ` ${state}`
      : "" + country
      ? ` ${country}`
      : "" + zip
      ? ` ${zip}`
      : "";
    const emailArr = CheckArr(el?.emailAddresses);
    return {
      id: CheckNumber(el?.id),
      name,
      email: emailArr.length > 0 ? CheckStringEmpty(emailArr[0]) : "",
      relation: CheckStringEmpty(el?.relationToPatient),
      address,
      mobileNo: CheckStringEmpty(el?.patientAdminContactPhoneNumberMobile),
      homeNumber: CheckStringEmpty(el?.patientAdminContactPhoneNumberHome),
      officeNo: CheckStringEmpty(el?.patientAdminContactPhoneNumberOffice),
    };
  });
};

const AppointmentList: (data: any) => RedoxAppointmentType[] = (data: any) => {
  const newArr = Array.isArray(data) ? data : [];
  return newArr.map((el) => {
    return {
      id: CheckStringId(el?.visitNumber),
      visitNumber: CheckStringEmpty(el?.visitNumber),
      facility: CheckStringEmpty(el?.Facility),
      reason: CheckStringEmpty(el?.Reason),
      datetime: DateTime.ValidDate(el?.visitDateTime),
    };
  });
};

export default {
  CategorySearch,
  CatSearchDoc,
  MedSearch,
  VisitSearch,
  ProblemList,
  AllergiesList,
  ResultList,
  VaccineList,
  VitalList,
  InsuranceList,
  ContactList,
  AppointmentList,
};
