import { FC, useEffect, useState } from "react";
import { EligiblePrgmResType } from "DataTypes/Services/Patient.type";
import { FormDataType } from "DataTypes/Form";
import { PersonalDataArr } from "./Data";
import PatientApi from "Service/Patient.api";
import PatientFormatter from "Utils/Patient.Formatter";
import { Box, Grid } from "@mui/material";
import { ControlledBoxSizeSwitch, commonFormSwitch } from "Utils/SwitchCases";
import { HandleFormChange } from "Utils/common";
import NoData from "Components/NoData";
import Loader from "Components/Loader";

const DischargeSummary: FC<{ data: EligiblePrgmResType }> = ({ data }) => {
  const { careplanId } = data;
  const [formArr, setFormArr] = useState<FormDataType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData();
  }, []);

  const setData = async () => {
    setLoading(true);
    const newArr1 = PersonalDataArr();
    const res = await PatientApi.DischargeSummarySearch({
      id1: careplanId,
    });
    if (res?.success) {
      if (res.data.length) {
        const formattedData = PatientFormatter.DischargeSummary(res?.data[0]);
        const newFormattedData = PatientFormatter.DischargeSummaryForm(
          formattedData,
          newArr1
        );
        setFormArr(newFormattedData);
      } else {
        setFormArr([]);
      }
    }
    setLoading(false);
  };

  if (loading) return <Loader />;

  return (
    <Box height="100%">
      {formArr.length ? (
        <Grid container rowSpacing={1} columnSpacing={1} mb={1}>
          {formArr &&
            formArr.map((el: FormDataType) => {
              return (
                <Grid key={el.id} item {...ControlledBoxSizeSwitch(el.boxSize)}>
                  {commonFormSwitch({
                    formObj: el,
                    onChange: (data) => HandleFormChange(data, formArr),
                  })}
                </Grid>
              );
            })}
        </Grid>
      ) : (
        <NoData />
      )}
    </Box>
  );
};
export default DischargeSummary;
