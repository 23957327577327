import ImgPath from "Constant/Imgs";
import { actionArrType, columnType } from "DataTypes/Table";
import { ControlledTable } from "Components";
import { label } from "Constant/En";
import { RouteUrls } from "Constant/RouteUrls";
import { NavArrType } from "DataTypes/Common";
import { objectValues } from "DataTypes/Services/Index.type";

export const NavArr = (
  permission: any,
  tilesCount: objectValues | undefined
) => {
  const Arr: NavArrType[] = [
    ...(permission?.eligible
      ? [
        {
          id: 1,
          label: label.el,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.eligible}`,
          type: "text",
          data: tilesCount?.eligible ?? "",
          sublabel: label.subel,
          Icon: <ImgPath.EligibleIcon />,
        },
      ]
      : []),
    ...(permission?.pendingassign
      ? [
        {
          id: 2,
          label: label.pendingAssignment,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.pendingAssign}`,
          type: "text",
          data: tilesCount?.pendingAssignment ?? "",
          sublabel: label.subpend,
          Icon: <ImgPath.PccIcon />,
        },
      ]
      : []),
    ...(permission?.enrolled
      ? [
        {
          id: 3,
          label: label.enrolled,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.enrolled}`,
          type: "text",
          data: tilesCount?.enrolledPatient ?? "",
          sublabel: label.submypt,
          lablesuper: label?.submyptSuper,
          Icon: <ImgPath.myptIcon />,
        },
      ]
      : []),
    ...(permission?.mypatient
      ? [
        {
          id: 4,
          label: label.mp,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.myPatients}`,
          type: "text",
          data: tilesCount?.myPatient ?? "",
          sublabel: label.submypt,
          lablesuper: label?.submyptSuper,
          Icon: <ImgPath.myptIcon />,
        },
      ]
      : []),
    ...(permission?.pendingapproval
      ? [
        {
          id: 5,
          label: label.pendpr,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.pendingApproval}`,
          type: "text",
          data: tilesCount?.pendingPlanApproval ?? "",
          sublabel: label.subpendpr,
          Icon: <ImgPath.ppaIcon />,
        },
      ]
      : []),
    ...(permission?.pending_care
      ? [
        {
          id: 6,
          label: label.continuousCare,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.pendingCare}`,
          type: "text",
          data: tilesCount?.pendingContinuousCare ?? "",
          sublabel: label.subpendcc,
          Icon: <ImgPath.pcmcIcon />,
        },
      ]
      : []),
    ...(permission?.completedcare
      ? [
        {
          id: 7,
          label: label.comcc,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.completedCare}`,
          type: "text",
          data: tilesCount?.completedContinuousCare ?? "",
          sublabel: label.subcomcc,
          Icon: <ImgPath.ccmcIcon />,
        },
      ]
      : []),
    ...(permission?.inactive
      ? [
        {
          id: 8,
          label: label.inactive,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.inactive}`,
          type: "text",
          data: tilesCount?.patientInactive ?? "",
          sublabel: label.subcomcc,
          Icon: <ImgPath.clIcon />,
        },
      ]
      : []),
    ...(permission?.ongoing
      ? [
        {
          id: 9,
          label: label.ongpt,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.ongoing}`,
          type: "text",
          data: tilesCount?.onGoing ?? "",
          sublabel: label.subongpt,
          Icon: <ImgPath.ongIcon />,
        },
      ]
      : []),
    ...(permission?.billing
      ? [
        {
          id: 10,
          label: label.billing,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.billing}`,
          type: "img",
          imgUrl: ImgPath.Billing,
          data: tilesCount?.billing ?? "",
          sublabel: "",
          Icon: <ImgPath.billingIcon />,
        },
      ]
      : []),
    ...(permission?.tasking
      ? [
        {
          id: 11,
          label: label.myTask,
          link: `${RouteUrls.hs}/${RouteUrls.tasking}/${RouteUrls.incoming}`,
          type: "text",
          data: tilesCount?.tasking ?? "",
          sublabel: "",
          Icon: <ImgPath.mtIcon />,
        },
      ]
      : []),
  ];
  return Arr;
};

export const TableCol: (role: string) => columnType[] = (role: string) => {
  return [
    ControlledTable.ColumnData.PicCol("8%"),
    ControlledTable.ColumnData.PatientInfoCol("13%"),
    ...(role == "Super Admin"
      ? [ControlledTable.ColumnData.ClinicalStaffCol("10%")]
      : []),
    ControlledTable.ColumnData.SepDateTimeCol("11%"),
    ControlledTable.ColumnData.ProviderCol("10%"),
    ...(role != "TRAINER"
      ? [ControlledTable.ColumnData.PracticeCol("13%")]
      : []),
    ControlledTable.ColumnData.ActivityCol("10%"),
    ...(role != "TRAINER"
      ? [ControlledTable.ColumnData.ImportanceCol("10%")]
      : []),
    ControlledTable.ColumnData.ActionCol("15%"),
  ];
};

export const actionArr: (
  handleChart: () => void,
  handleCallDetails: () => void,
  handlePatientDetails: () => void,
  handleTask: () => void
) => actionArrType[] = (
  handleChart: () => void,
  handleCallDetails: () => void,
  handlePatientDetails: () => void,
  handleTask: () => void
) => {
  return [
    ControlledTable.ActionData.userAction(handlePatientDetails),
    ControlledTable.ActionData.chartAction(handleChart),
    ControlledTable.ActionData.callAction(handleCallDetails),
    ControlledTable.ActionData.taskAction(handleTask),
  ];
};
