import React, { useContext, useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { HeaderInitialStateType } from "DataTypes/Redux";
import { useDispatch, useSelector } from "react-redux";
import PracticeApi from "Service/Practice.api";
import DataFormatter from "Utils/DataFormatter";
import { FormDataType } from "DataTypes/Form";
import { setAlertData } from "Redux/Screen/Action";
import { CommonStyle, Modal } from "Components";
import { HeadContextType } from "DataTypes/Common";
import { HeadContext } from "Context/HeadContext";
import { BreadArr, FormDataArr } from "./Data";
import CstmBtn from "Components/CstmBtn";
import OrganizationFormatter from "Utils/Organization.Formatter";
import { ControlledBoxSizeSwitch, commonFormSwitch } from "Utils/SwitchCases";
import { HandleFormChange } from "Utils/common";
import { ModalLayout } from "Layouts";
import { useUploadForm } from "Hooks/useUploadFile";

const PatientImport = () => {
  const { organization }: HeaderInitialStateType = useSelector(
    (state: any) => state?.header
  );
  const dispatch = useDispatch();
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const [formArr, setFormArr] = useState<FormDataType[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const { uploadForm, progress, setProgress, inProgress } = useUploadForm(
    `${process.env.REACT_APP_BASE_REDOX_URL}/redox/patient/practicelocation/${formArr[1]?.dropValue}/upload`
  );

  useEffect(() => {
    setBreadArr(BreadArr(organization.name || ""));
    setData();
  }, []);

  useEffect(() => {
    setProgress(0);
  }, [selectedFile]);

  const setData = async () => {
    const newFormData: FormDataType[] = JSON.parse(JSON.stringify(FormDataArr));
    const practiceRes = await PracticeApi.read({
      id1: organization.id || null,
    });
    if (practiceRes?.success) {
      const verifiedRes = OrganizationFormatter.VerifyReadResponse(
        practiceRes?.data
      );
      const formatePracticeLocation = DataFormatter.FormatDropdownData(
        verifiedRes.practiceLocations,
        "id",
        "location1",
        "id"
      );
      newFormData[0].value = verifiedRes.name;
      newFormData[1].dropArr = formatePracticeLocation;
      setFormArr(newFormData);
    }
  };

  const hanldeImportFile = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      const res = await uploadForm({
        data: formData,
      });
      if (res?.success) {
        res?.alert && dispatch(setAlertData(res.alert));
      }
    }
  };

  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox, overflow: "auto" }}>
      <ModalLayout
        modwidth="35%"
        heading={"Upload File"}
        content={
          <Modal.UploadFileModal
            changeFile={(data) => setSelectedFile(data)}
            fileName={selectedFile?.name || ""}
            progress={progress}
            inProgress={inProgress}
          />
        }
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          !inProgress && setSelectedFile(null);
        }}
        buttonArr={[
          {
            id: "1",
            label: inProgress
              ? "Uploading"
              : progress == 100
                ? "Uploaded"
                : "Upload",
            clickHandler: hanldeImportFile,
            disable:
              inProgress || progress == 100 || !selectedFile ? true : false,
          },
          {
            id: "2",
            label: "Close",
            clickHandler: () => {
              setOpenModal(false);
              !inProgress && setSelectedFile(null);
            },
            type: "border",
          },
        ]}
      />
      <Box sx={{ p: 2 }}>
        <Box display={"flex"} flexDirection={"row"} alignItems={"flex-end"}>
          <Box width="50%" mr={2}>
            <Grid container rowSpacing={1} columnSpacing={2}>
              {formArr && formArr.length > 0
                ? formArr.map((el: FormDataType) => {
                  return (
                    <Grid
                      key={el.id}
                      item
                      {...ControlledBoxSizeSwitch(el.boxSize)}
                    >
                      {commonFormSwitch({
                        formObj: el,
                        onChange: (props) => {
                          if (props.id == "location") setSelectedFile(null);
                          setFormArr(HandleFormChange(props, formArr));
                        },
                      })}
                    </Grid>
                  );
                })
                : null}
            </Grid>
          </Box>
          <CstmBtn
            label="Upload Patient Data"
            onClick={() => setOpenModal(true)}
            disable={!formArr[1]?.dropValue}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default PatientImport;
