import { useState, FC, useEffect, Fragment } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
  Typography,
} from "@mui/material";
import { RadioProps } from "DataTypes/Form";

const RadioGroup: FC<RadioProps> = (props: RadioProps) => {
  const {
    name,
    label,
    onChange,
    items,
    labelVariant,
    iconSize,
    optionFontSize,
    body,
  } = props;

  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    const itemIndex = items.findIndex((el) => el.checked == true);
    if (itemIndex != -1) setValue(items[itemIndex].id);
  }, [items]);

  return (
    <FormControl>
      {label && (
        <Typography variant={labelVariant ? labelVariant : "body1"}>
          {label}
        </Typography>
      )}
      <MuiRadioGroup
        row={false}
        name={name}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {items.map((el) => {
          return (
            <Fragment key={el.id}>
              <FormControlLabel
                value={el.id}
                sx={{
                  ...sxCheckBox,
                  "& >span:last-of-type": {
                    fontSize: optionFontSize ? optionFontSize : "0.8rem",
                  },
                }}
                control={
                  <Radio
                    color="secondary"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: iconSize ? iconSize : "1rem",
                      },
                    }}
                  />
                }
                label={el.title}
              />
              {value == el.id && body && body(value)}
            </Fragment>
          );
        })}
      </MuiRadioGroup>
    </FormControl>
  );
};
export default RadioGroup;

const sxCheckBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  ml: 0,
  mb: 1.5,
  "& >span:first-of-type": {
    p: 0,
    mr: 1,
    mt: 0.25,
  },
};
