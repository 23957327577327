import { FC, Fragment, useEffect, useState } from "react";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { CommonStyle, ControlledForm } from "Components";
import ImgPath from "Constant/Imgs";
import { FormDataType } from "DataTypes/Form";
import { commonFormSwitch, ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { addProviderProps } from "DataTypes/CarePlanProblems";

const ProviderBox: FC<addProviderProps> = (props: addProviderProps) => {
  const { addProvider, handleremove, handleAddChange } = props;
  return (
    <Box>
      {addProvider?.length > 0 && <Divider />}
      {addProvider.length > 0 &&
        addProvider?.map((currentProvider: any) => {
          return (
            <Fragment key={currentProvider?.id}>
              <Box sx={{ ...CommonStyle.sxRow }} position="relative">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={2}
                  sx={{ mb: 2, mt: 1, pr: 3 }}
                >
                  {currentProvider?.additionalProvidarData &&
                  currentProvider?.additionalProvidarData?.length ? (
                      currentProvider?.additionalProvidarData?.map(
                        (assignField: FormDataType) => {
                          return (
                            <Grid
                              key={assignField.id}
                              item
                              {...ControlledBoxSizeSwitch(assignField.boxSize)}
                            >
                              {commonFormSwitch({
                                formObj: assignField,
                                onChange: (data) =>
                                  handleAddChange(data, currentProvider?.id),
                              })}
                            </Grid>
                          );
                        }
                      )
                    ) : (
                      <></>
                    )}
                </Grid>
                <IconButton
                  onClick={() => {
                    handleremove(
                      currentProvider?.id,
                      currentProvider?.additionalProvidarArrId
                    );
                  }}
                  title="Delete"
                  sx={{ ...IconRemove, ...CommonStyle.sxRoundIconsMainSm }}
                >
                  <ImgPath.CloseIcon />
                </IconButton>
              </Box>
            </Fragment>
          );
        })}
    </Box>
  );
};
export default ProviderBox;
const IconRemove = {
  position: "absolute",
  top: 50,
  right: -12,
};
