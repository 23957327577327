import { ApiServiceProps, objectValues } from "DataTypes/Services/Index.type";
import { post } from ".";

const billingList: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "billing/search",
    data: { data: payload.data },
  };
  return post(request);
};

const billingExport: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "billing/export",
    data: { data: payload.data },
    excel: true,
  };
  return post(request);
};

const sendToBilling: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "billing/sendToBilling",
    data: { data: payload.data },
  };
  return post(request);
};
const fetchProviderName: (careplanId: number | undefined) => any = (
  careplanId
) => {
  const request = {
    subUrl: `careplan/${careplanId}/team/search`,
  };
  return post(request);
};

const createTask: (patientId: string | null, data: objectValues) => any = (
  patientId,
  data
) => {
  const request = {
    subUrl: `patient/${patientId}/task/create`,
    data: data,
  };
  return post(request);
};

const syncData: () => any = () => {
  const request = {
    subUrl: "billing/sync",
  };
  return post(request);
};

const eligiblePatientCount: () => any = () => {
  const request = {
    subUrl: "patient/eligible/count",
  };
  return post(request);
};
const taskCount: () => any = () => {
  const request = {
    subUrl: "patient/task/count",
  };
  return post(request);
};
const pendingAssignmentCount: () => any = () => {
  const request = {
    subUrl: "patient/pending-assignment/count",
  };
  return post(request);
};
const billingCount: () => any = () => {
  const request = {
    subUrl: "billing/count",
  };
  return post(request);
};
const myPatientCount: () => any = () => {
  const request = {
    subUrl: "patient/my-patient/count",
  };
  return post(request);
};
const onGoingCount: () => any = () => {
  const request = {
    subUrl: "patient/ongoing/count",
  };
  return post(request);
};
const patientInactiveCount: () => any = () => {
  const request = {
    subUrl: "patient/inactive/count",
  };
  return post(request);
};
const completedContinuousCareCount: () => any = () => {
  const request = {
    subUrl: "patient/completed-continuous-care/count",
  };
  return post(request);
};
const pendingPlanApprovalCount: () => any = () => {
  const request = {
    subUrl: "patient/pending-plan-approval/count",
  };
  return post(request);
};
const pendingContinuousCareCount: () => any = () => {
  const request = {
    subUrl: "patient/pending-continuous-care/count",
  };
  return post(request);
};
const enrolledPatientCount: () => any = () => {
  const request = {
    subUrl: "patient/enrolled-patient/count",
  };
  return post(request);
};

const updateTask: (patientId: number, data: objectValues) => any = (
  patientId,
  data
) => {
  const request = {
    subUrl: `patient/${patientId}/task/update`,
    data: data,
  };
  return post(request);
};
const readTask: (taskId: number) => any = (taskId) => {
  const request = {
    subUrl: `patient/${taskId}/task/read`,
  };
  return post(request);
};

const fetchTaskList: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "patient/task/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const timeSeries: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient/${payload.id1}/find/time-series`,
    data: { data: payload.data, criterion: { offset: payload.offset } },
  };
  return post(request);
};

const callLogList: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient/${payload.id1}/calllog/search`,
    data: {
      data: {},
      criterion: {
        offset: payload.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const callLogCreate: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `patient/${props.id1}/calllog/create`,
    data: {
      data: props.data,
    },
  };
  return post(request);
};

const callLogUnresponsive: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `patient/${props.id1}/calllog/unreponsive`,
    data: {},
  };
  return post(request);
};

const eligiblePrgmList: (data: ApiServiceProps) => any = ({ id1 }) => {
  const request = {
    subUrl: `patient/${id1}/eligible-program/search`,
  };
  return post(request);
};

const enrollPrgmHistory: (data: ApiServiceProps) => any = ({ id1 }) => {
  const request = {
    subUrl: `patient/${id1}/enrolled-program/history`,
  };
  return post(request);
};

const enrolledPrgmUserList: (data: ApiServiceProps) => any = ({ id1 }) => {
  const request = {
    subUrl: `patient/${id1}/enrolled-program/search`,
  };
  return post(request);
};

const timeLog: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `timelog/careplan/${payload.id1}/create`,
    data: { data: payload.data },
  };
  return post(request);
};
const fetchGroupList: () => any = () => {
  const request = {
    subUrl: "patient/group/search",
  };
  return post(request);
};
const fetchUserList: (data: ApiServiceProps) => any = ({ data }) => {
  const request = {
    subUrl: "user/search",
    data,
    isAuth: true,
  };
  return post(request);
};

const DischargeSummarySearch: (data: ApiServiceProps) => any = ({ id1 }) => {
  const request = {
    subUrl: `careplan/${id1}/dischargesummary/search`,
  };
  return post(request);
};

const DischargeSummarySave: (payload: ApiServiceProps) => any = ({
  id1,
  data,
}) => {
  const request = {
    subUrl: `careplan/${id1}/dischargesummary/update`,
    data: {
      data: data,
    },
  };
  return post(request);
};

const consentGained: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "consent/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const DownloadAllConsent: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "consent/download",
    data: { data: payload.data },
    excel: true,
  };
  return post(request);
};

const AssignToMe: (payload: ApiServiceProps) => any = ({ id1, data }) => {
  const request = {
    subUrl: `enrolledProgram/${id1}/assign-to-me`,
    data: { data },
  };
  return post(request);
};
const Unassign: (payload: ApiServiceProps) => any = ({ id1, data }) => {
  const request = {
    subUrl: `enrolledProgram/${id1}/unassign`,
    data: { data },
  };
  return post(request);
};

const GlobalPatientSearch: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "patient/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};
const timeLogSearch: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "timelog/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

////////Checked
const demographic: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient/${payload.id1}/demographic/search`,
    isRedox: true,
  };
  return post(request);
};

const practice: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient/${payload.id1}/practice/search`,
  };
  return post(request);
};

const deleteTask: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient/${payload.id1}/task/delete`,
  };
  return post(request);
};

const updateTask1: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient/${payload.id1}/task/update`,
    data: { data: payload.data },
  };
  return post(request);
};

const PatientTaskSearch: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient/${payload.id1}/task/search`,
    data: {
      data: {},
      criterion: {
        offset: payload.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const CheckTimeLogged: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "timelog/checktimelog",
    data: {
      data: payload.data,
    },
  };
  return post(request);
};

const RedoxInsurance: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `patient/${data.id1}/insurance/search`,
    isRedox: true,
  };
  return post(request);
};

const RedoxContact: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `patient/${data.id1}/contact/search`,
    isRedox: true,
  };
  return post(request);
};

const RedoxAppointments: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `patient/${data?.id1}/appointment/search`,
    isRedox: true,
  };
  return post(request);
};

const RedoxAllergy: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `patient/${data.id1}/allergies/search`,
    isRedox: true,
  };
  return post(request);
};

const RedoxProblems: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `patient/${data.id1}/problem/search`,
    isRedox: true,
  };
  return post(request);
};

const RedoxVitals: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `patient/${data.id1}/vitalsign/search`,
    isRedox: true,
  };
  return post(request);
};

const RedoxResults: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `patient/${data.id1}/result/search`,
    isRedox: true,
  };
  return post(request);
};

const RedoxVaccines: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `patient/${data.id1}/immunization/search`,
    isRedox: true,
  };
  return post(request);
};

const PendingApproval: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "patient/pending-plan-approval/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload?.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const PendingContinuous: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "patient/pending-continuous-care/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload?.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const Ongoing: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "patient/ongoing/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload?.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const MyPatient: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "patient/my-patient/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload?.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const Inactive: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "patient/inactive/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload?.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const Enrolled: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "patient/enrolled-patient/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload?.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const CompletedContinuous: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "patient/completed-continuous-care/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload?.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const PendingAssignment: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "patient/pending-assignment/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload?.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const Eligible: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "patient/eligible/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

export default {
  billingList,
  billingExport,
  sendToBilling,
  syncData,
  fetchProviderName,
  taskCount,
  readTask,
  eligiblePatientCount,
  pendingAssignmentCount,
  billingCount,
  myPatientCount,
  onGoingCount,
  patientInactiveCount,
  completedContinuousCareCount,
  pendingContinuousCareCount,
  enrolledPatientCount,
  pendingPlanApprovalCount,
  createTask,
  updateTask,
  fetchTaskList,

  timeSeries,

  fetchGroupList,
  fetchUserList,

  callLogList,
  callLogCreate,
  callLogUnresponsive,
  enrolledPrgmUserList,
  eligiblePrgmList,

  timeLog,
  DischargeSummarySearch,
  DischargeSummarySave,
  consentGained,
  DownloadAllConsent,

  AssignToMe,
  Unassign,

  enrollPrgmHistory,
  GlobalPatientSearch,
  timeLogSearch,

  ///Checked
  demographic,
  practice,
  deleteTask,
  updateTask1,
  PatientTaskSearch,
  CheckTimeLogged,
  RedoxInsurance,
  RedoxContact,
  RedoxAppointments,
  RedoxAllergy,
  RedoxProblems,
  RedoxVitals,
  RedoxResults,
  RedoxVaccines,
  PendingApproval,
  PendingContinuous,
  Ongoing,
  MyPatient,
  Inactive,
  Enrolled,
  CompletedContinuous,
  PendingAssignment,
  Eligible,
};
