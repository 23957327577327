import { Box, Checkbox, Divider, Typography } from "@mui/material";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import { CommonStyle } from "Components";
import { useNavigate, useParams } from "react-router-dom";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import {
  AddQuestionBreadArr,
  FormArr1,
  FormArr2,
  SampleInputObject,
} from "./Data";
import { commonFormSwitch } from "Utils/SwitchCases";
import {
  AscendSort,
  AscendSortByNumber,
  CheckAnsType,
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  ConvertObjArrToIdArr,
  ConvertStringToNum,
  HandleFormChange,
  ValidateFormData,
} from "Utils/common";
import Format from "./Format";
import { MasterPrgmPrblmType, OptionObjType } from "DataTypes/Master";
import AnsBox from "./AnsBox";
import VisitApi from "Service/Visit.api";
import VisitFormatter from "Utils/Visit.Formatter";
import { VisitPrgmType } from "DataTypes/Services/Visit.type";
import { useSelector } from "react-redux";
import CategoryApi from "Service/Category.api";
import CategoryFormatter from "Utils/Category.Formatter";
import { useDispatch } from "react-redux";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import { HeadContext } from "Context/HeadContext";
import { HeadContextType } from "DataTypes/Common";
import QuestionApi from "Service/Question.api";
import QuestionFormatter from "Utils/Question.Formatter";
import { QuestionPrgmType } from "DataTypes/Services/Prgm.type";
import { FormatDropdownData } from "Utils/DataFormatter";
import ProgramApi from "Service/Program.api";
import ProbFormatter from "Utils/Prob.Formatter";
import { ProblemListType } from "DataTypes/Services/Problem.type";
import { MasterQuestionType } from "DataTypes/Services/Question.type";
import TooltipBox from "../List/TooltipBox";
import { ModalLayout } from "Layouts";
import { RouteUrls } from "Constant/RouteUrls";
import CstmBtn from "Components/CstmBtn";

const QuestionUpdate: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { parentId, parentOptionId, quesId, categoryId } = useParams();
  const programList: DropdownArrPropsType[] = useSelector(
    (state: any) => state?.list?.prgDropList
  );
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const [formData, setFormData] = useState<FormDataType[]>(
    JSON.parse(JSON.stringify(FormArr1))
  );
  const [formData1, setFormData1] = useState<FormDataType[]>(
    JSON.parse(JSON.stringify(FormArr2))
  );
  const [optionArr, setOptionArr] = useState<OptionObjType[]>([]);
  const [deleteOption, setDeleteOption] = useState<OptionObjType | null>(null);
  const [masterVisit, setMasterVisit] = useState<VisitPrgmType[]>([]);
  const [masterPrblm, setMasterPrblm] = useState<MasterPrgmPrblmType[]>([]);
  const [parentObj, setParentObj] = useState<MasterQuestionType | null>(null);
  const [deleteOptionModal, setDeleteOptionModal] = useState(false);
  const [isQuestionReq, setIsQuestionReq] = useState(false);

  useEffect(() => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    ReadApiCall();
  }, []);

  const ReadApiCall: () => void = async () => {
    const newForm1: FormDataType[] = JSON.parse(JSON.stringify(FormArr1));
    const newForm2: FormDataType[] = JSON.parse(JSON.stringify(FormArr2));
    let localMasterVisit: VisitPrgmType[] = [];
    let localMasterProblem: MasterPrgmPrblmType[] = [];

    //Without category read api it cannot proceed
    if (categoryId) {
      const res = await CategoryApi.read({
        id1: ConvertStringToNum(categoryId),
      });
      //category read api
      if (res?.success) {
        const formatedCategory = CategoryFormatter.CheckCategoryObj(res?.data);
        setBreadArr(
          AddQuestionBreadArr(formatedCategory?.name, quesId ? true : false)
        );
      } else window.history.back();
    } else {
      dispatch(
        setAlertData({
          alertMessage: "Category not found",
          alertVariant: "error",
          openAlert: true,
        })
      );
      window.history.back();
    }

    //parent question is only used to display parent question and ans type
    if (parentId) {
      const res = await QuestionApi.read({ id1: ConvertStringToNum(parentId) });
      if (res.success) {
        setParentObj(QuestionFormatter.CheckMasterQuestion(res?.data));
      } else window.history.back();
    } else {
      //if parent is not exist then we must call visit api
      const res = await VisitApi.list();
      if (res?.success) {
        const formattedVisitRes = VisitFormatter.QuestionVisitArr(res?.data);
        localMasterVisit = VisitFormatter.VisitPrgmArr(
          formattedVisitRes,
          programList
        );
      }
      //if parent is not exist then we must call program problem api
      await Promise.all(
        programList.map(async (el) => {
          let arr: ProblemListType[] = [];
          const res = await ProgramApi.mappedProblemList({
            id1: el.id,
            limit: 10000,
            offset: 0,
          });
          if (res?.success) arr = ProbFormatter.MappedProblems(res?.data);
          return {
            id: CheckNumber(el.id),
            problemArr: arr,
          };
        })
      ).then((data: MasterPrgmPrblmType[]) => (localMasterProblem = data));
      newForm2[0].dropArr = programList;
    }

    ///Question in editable state
    if (quesId) {
      //Load question read api
      const res = await QuestionApi.read({ id1: ConvertStringToNum(quesId) });
      if (res.success) {
        //formate and assign result to local form data
        const formattedRes = QuestionFormatter.CheckMasterQuestion(res?.data);
        setIsQuestionReq(formattedRes.questionRequired);
        newForm1[0].value = formattedRes.question;
        newForm1[1].dropValue = formattedRes.type;
        const prgms = ConvertObjArrToIdArr(formattedRes.programs, "id");
        const prbm = ConvertObjArrToIdArr(formattedRes.problems, "id");
        const visit = ConvertObjArrToIdArr(formattedRes.visittypes, "id");
        newForm2[0].dropValue = newForm2[0].value = prgms;
        newForm2[1].dropValue = newForm2[1].value = prbm;
        newForm2[2].dropValue = newForm2[2].value = visit;
        if (prgms.find((newEl) => newEl == 3)) {
          newForm2[0].disableArr = [1, 2, 4, 6];
          newForm2[1].readOnly = true;
          newForm2[2].readOnly = false;
        } else if (prgms.length == 0) {
          newForm2[0].disableArr = [];
          newForm2[1].readOnly = false;
          newForm2[2].readOnly = false;
        } else {
          newForm2[0].disableArr = [3];
          newForm2[1].readOnly = false;
          newForm2[2].readOnly = false;
        }

        //formate option according to requirement
        setOptionArr(
          formattedRes.options.map((el, index) => {
            return {
              id: index + 1,
              required: el.requiredOption || false,
              optionId: el?.id || null,
              formObj: SampleInputObject(CheckStringEmpty(el?.option), true),
              lastVal: "",
            };
          })
        );

        //Parent question in editable state
        if (!parentId) {
          //filter visits for dropdown according to selected programs
          const filterVisit = formattedRes.programs.reduce(
            (total: VisitPrgmType[], current: QuestionPrgmType) => {
              const filterArr = localMasterVisit.filter(
                (el) => el.prgmId == current.id
              );
              return [...total, ...filterArr];
            },
            []
          );
          //Convert filter visits to dropdown type & assign to formdata
          newForm2[2].dropArr = AscendSortByNumber(
            FormatDropdownData(filterVisit, "id", "name", "id"),
            "id"
          );

          //filter problems for dropdown according to selected programs
          const filterPrblm = formattedRes.programs.reduce(
            (total: ProblemListType[], current: QuestionPrgmType) => {
              const find = localMasterProblem.find((el) => el.id == current.id);
              let arr: ProblemListType[] = [];
              if (find) {
                arr = find.problemArr.filter((filEl) => {
                  if (!total.some((soEl) => soEl.id == filEl.id)) return filEl;
                });
              }
              return [...total, ...arr];
            },
            []
          );
          //Convert filter visits to dropdown type & assign to formdata
          newForm2[1].dropArr = AscendSort(
            FormatDropdownData(filterPrblm, "id", "name", "id"),
            "label"
          );
        }
      } else window.history.back();
    }
    setMasterVisit(localMasterVisit);
    setMasterPrblm(localMasterProblem);
    setFormData(newForm1);
    setFormData1(newForm2);
    dispatch(stopLoading());
  };

  useEffect(() => {
    deleteOption ? setDeleteOptionModal(true) : setDeleteOptionModal(false);
  }, [deleteOption]);

  const confirmOptionDelete: () => void = async () => {
    if (deleteOption) {
      setOptionArr(optionArr.filter((el) => el.id != deleteOption.id));
      setDeleteOption(null);
    }
  };

  const handleSaveQuestion: () => void = async () => {
    const foundNotSave = optionArr.some((el) => !el.formObj.readOnly);
    if (foundNotSave) {
      dispatch(
        setAlertData({
          alertMessage: "All options are not saved",
          alertVariant: "error",
          openAlert: true,
        })
      );
    } else {
      let errorCheck = false;
      const newArr = ValidateFormData(formData);
      if (newArr.errorCheck) errorCheck = true;
      setFormData(newArr.formData);
      //if parent id exist then we are not going to check form1 data because it is irrelevant
      if (!parentId) {
        const newArr1 = ValidateFormData(formData1);
        if (newArr1.errorCheck) errorCheck = true;
        setFormData1(newArr1.formData);
      }
      const newArr2 = Format.ValidateOptionData(optionArr);
      if (newArr2.errorCheck) errorCheck = true;
      setOptionArr(newArr2.formData);
      if (!errorCheck) {
        dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
        let programs: { id: number | null }[] = [];
        let problems: { id: number | null }[] = [];
        let visittypes: { id: number | null }[] = [];
        if (parentId) {
          programs = parentObj?.programs.map((el) => ({ id: el.id })) || [];
          problems = parentObj?.problems.map((el) => ({ id: el.id })) || [];
          visittypes = parentObj?.visittypes.map((el) => ({ id: el.id })) || [];
        } else {
          const probVal = CheckArr(formData1[1]?.dropValue);
          const visitVal = CheckArr(formData1[2]?.dropValue);
          let payProb = [];
          let payVisit = [];
          if (probVal.length < 1) {
            payProb =
              formData1[1]?.dropArr?.map((el) => ({ id: el.value })) || [];
          } else {
            payProb = probVal.map((el: any) => ({ id: el }));
          }
          if (visitVal.length < 1) {
            payVisit =
              formData1[2]?.dropArr?.map((el) => ({ id: el.value })) || [];
          } else {
            payVisit = visitVal.map((el: any) => ({ id: el }));
          }
          programs =
            formData1[0]?.dropValue?.map((el: any) => ({ id: el })) || [];
          problems = payProb;
          visittypes = payVisit;
        }
        const options = optionArr.map((el) => {
          return {
            ...(el?.optionId && { id: el.optionId }),
            option: CheckStringEmpty(el.formObj.value),
            requiredOption: el.required,
            status: "ACTIVE",
          };
        });
        const type = CheckAnsType(formData[1].dropValue);
        const payload = {
          categoryId: ConvertStringToNum(categoryId),
          parentQuestionId: ConvertStringToNum(parentId),
          parentOptionId: ConvertStringToNum(parentOptionId),
          question: formData[0].value,
          status: "ACTIVE",
          type,
          programs,
          problems,
          visittypes,
          options,
          required_question: isQuestionReq,
        };
        let res: any;
        if (quesId) {
          res = await QuestionApi.update({
            id1: ConvertStringToNum(quesId),
            data: payload,
          });
        } else {
          res = await QuestionApi.create({ data: payload });
        }
        if (res?.success) {
          dispatch(setAlertData(res?.alert));
          navigate(`../${RouteUrls.list}`);
        }
        dispatch(stopLoading());
      }
    }
  };

  return (
    <Box
      height="100%"
      sx={{ ...CommonStyle.sxWhiteBox, px: 2 }}
      overflow="auto"
    >
      <ModalLayout
        modwidth="30%"
        heading={"Warning"}
        content={
          <Box textAlign={"center"}>
            <Typography mb={2}>
              Deleting this option will delete any linked questions under this
              option.
            </Typography>
            <Typography>Do you want to continue?</Typography>
          </Box>
        }
        onClose={() => setDeleteOption(null)}
        open={deleteOptionModal}
        buttonArr={[
          {
            id: "1",
            label: "Yes",
            clickHandler: confirmOptionDelete,
          },
          {
            id: "2",
            label: "No",
            clickHandler: () => setDeleteOption(null),
            type: "border",
          },
        ]}
      />
      <Box py={2}>
        {parentId && (
          ///When parent question exist
          <Fragment>
            <Box sx={{ ...CommonStyle.sxRow, pb: 1, flexWrap: "wrap" }}>
              <Typography width="15%" variant="subtitle1" fontWeight="bold">
                Parent Question:
              </Typography>
              <Typography variant="subtitle1" sx={{ wordBreak: "break-all" }}>
                {parentObj?.question}
              </Typography>
            </Box>
            <Box sx={{ ...CommonStyle.sxRow, pb: 1 }}>
              <Typography width="15%" variant="subtitle1" fontWeight="bold">
                Parent Question Type:
              </Typography>
              <Typography variant="subtitle1">
                {parentObj?.type == "singleSelect"
                  ? "Single Select"
                  : parentObj?.type == "multiSelect"
                    ? "Multi Select"
                    : parentObj?.type == "freeText"
                      ? "Free Text"
                      : ""}
              </Typography>
            </Box>
            <Divider sx={{ mb: 1 }} />
            <Box
              sx={{
                ...CommonStyle.sxRow,
                justifyContent: "space-around",
              }}
            >
              <TooltipBox
                dataArr={
                  parentObj?.programs
                    ? FormatDropdownData(
                      parentObj?.programs,
                      "id",
                      "name",
                      "id"
                    )
                    : []
                }
                label={"Programs"}
                noDataText="No programs are available"
              />
              <TooltipBox
                dataArr={
                  parentObj?.problems
                    ? FormatDropdownData(
                      parentObj?.problems,
                      "id",
                      "name",
                      "id"
                    )
                    : []
                }
                label={"Problems"}
                noDataText="No problems are available"
              />
              <TooltipBox
                dataArr={
                  parentObj?.visittypes
                    ? FormatDropdownData(
                      parentObj?.visittypes,
                      "id",
                      "name",
                      "id"
                    )
                    : []
                }
                label={"Visit Types"}
                noDataText="No visit types are available"
              />
            </Box>
          </Fragment>
        )}
        <Box display={"flex"} alignItems={"center"}>
          {formData
            .filter((el) => el.id == "questions")
            .map((formEl) => {
              return (
                <Box key={formEl.id} width="40%">
                  {commonFormSwitch({
                    formObj: formEl,
                    onChange: (props) =>
                      setFormData(HandleFormChange(props, formData)),
                  })}
                </Box>
              );
            })}
          <Box display="flex" alignItems={"center"} mt={1} ml={2}>
            <Typography variant="subtitle1">Question Required :</Typography>
            <Checkbox
              checked={isQuestionReq}
              onClick={() => setIsQuestionReq(!isQuestionReq)}
              color="secondary"
              title={"Required"}
              sx={{ p: 0, ml: 2 }}
              disabled={optionArr.some((el) => el.required)}
            />
          </Box>
        </Box>
        {!parentId &&
          formData1.map((formEl) => {
            return (
              ///When parent question not exist
              <Fragment key={formEl.id}>
                <Divider sx={{ mt: 2 }} />
                {commonFormSwitch({
                  formObj: formEl,
                  onChange: (props) =>
                    setFormData1(
                      Format.HandleOnChange(
                        formData1,
                        masterVisit,
                        masterPrblm,
                        props
                      )
                    ),
                })}
              </Fragment>
            );
          })}
        <Divider sx={{ mt: 2 }} />
        {formData
          .filter((el) => el.id == "ans-type")
          .map((formEl) => {
            return (
              <Box key={formEl.id} width="40%" mb={1}>
                {commonFormSwitch({
                  formObj: formEl,
                  onChange: (props) => {
                    setFormData(HandleFormChange(props, formData));
                    setOptionArr(
                      Format.SetOptionArr(CheckAnsType(props.value))
                    );
                  },
                })}
              </Box>
            );
          })}
        {optionArr.length > 0 && (
          <AnsBox
            ansType={formData[1]?.dropValue}
            optionArr={optionArr}
            handleChangeOptionType={(arr) => {
              const find = arr.some((el) => el.required);
              setOptionArr(arr);
              find && !isQuestionReq && setIsQuestionReq(!isQuestionReq);
            }}
            handleDelete={(obj) => setDeleteOption(obj)}
          />
        )}
        <Box
          sx={{
            ...CommonStyle.sxRow,
            justifyContent: "flex-end",
            mt: 2,
            "& >button": {
              mr: 1,
            },
          }}
        >
          <CstmBtn label="Save" onClick={handleSaveQuestion} />
          <CstmBtn
            label="Cancel"
            onClick={() => navigate("../list")}
            btnType="border"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionUpdate;
