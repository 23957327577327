import { ReactComponent as ChartIcon } from "../Assets/Svgs/chart1.svg";
import { ReactComponent as CallLogsIcon } from "../Assets/Svgs/ActionIcons/call-logs.svg";
import { ReactComponent as CreateTaskIcon } from "../Assets/Svgs/ActionIcons/create-task2.svg";
import { ReactComponent as PatientDetailsIcon } from "../Assets/Svgs/ActionIcons/patient-details.svg";
import { ReactComponent as StartCareIcon } from "../Assets/Svgs/ActionIcons/start-care.svg";
import { ReactComponent as ClinicalSummaryIcon } from "../Assets/Svgs/ActionIcons/clinical-summary1.svg";
import { ReactComponent as TaskIcon } from "../Assets/Svgs/task.svg";
import { ReactComponent as PointOutIcon } from "../Assets/Svgs/point-out.svg";
import { ReactComponent as RoundTickIcon } from "../Assets/Svgs/round-tick.svg";
import { ReactComponent as ForwardArrowIcon } from "../Assets/Svgs/forward-arrow.svg";
import { ReactComponent as CMIcon } from "../Assets/Svgs/cm.svg";
import { ReactComponent as Problems } from "../Assets/Svgs/problems1.svg";
import { ReactComponent as TV } from "../Assets/Svgs/t&v1.svg";
import { ReactComponent as Meds } from "../Assets/Svgs/meds1.svg";
import { ReactComponent as Allergies } from "../Assets/Svgs/allergies.svg";
import { ReactComponent as Vitals } from "../Assets/Svgs/vitals1.svg";
import { ReactComponent as Result } from "../Assets/Svgs/results1.svg";
import { ReactComponent as Quality } from "../Assets/Svgs/quality1.svg";
import { ReactComponent as CarePlanIcon } from "../Assets/Svgs/care-plan.svg";
import { ReactComponent as StartCarePlanIcon } from "../Assets/Svgs/ST-care-plane.svg";
import { ReactComponent as TileIcon } from "../Assets/Svgs/tileicon.svg";
import { ReactComponent as EligibleIcon } from "../Assets/Svgs/tilesIcon/eligiblePatients.svg";
import { ReactComponent as PccIcon } from "../Assets/Svgs/tilesIcon/pendingCareCoordination.svg";
import { ReactComponent as myptIcon } from "../Assets/Svgs/tilesIcon/myPatients.svg";
import { ReactComponent as ppaIcon } from "../Assets/Svgs/tilesIcon/pendingProviderApproval.svg";
import { ReactComponent as unrIcon } from "../Assets/Svgs/tilesIcon/unResponsive.svg";
import { ReactComponent as ongIcon } from "../Assets/Svgs/tilesIcon/ongoingPatients.svg";
import { ReactComponent as clIcon } from "../Assets/Svgs/tilesIcon/closedPatients.svg";
import { ReactComponent as decIcon } from "../Assets/Svgs/tilesIcon/declinedPatients.svg";
import { ReactComponent as pcmcIcon } from "../Assets/Svgs/tilesIcon/pendingCareCoordination.svg";
import { ReactComponent as ccmcIcon } from "../Assets/Svgs/tilesIcon/completedCareCoordination.svg";
import { ReactComponent as billingIcon } from "../Assets/Svgs/tilesIcon/billingIcon.svg";
import { ReactComponent as mtIcon } from "../Assets/Svgs/tilesIcon/myTask.svg";
import { ReactComponent as cptIcon } from "../Assets/Svgs/tilesIcon/completedTraining.svg";
import { ReactComponent as ReenrollIcon } from "../Assets/Svgs/Reenroll1.svg";
import { ReactComponent as UnenrollIcon } from "../Assets/Svgs/Unenroll1.svg";
import { ReactComponent as EnrollIcon } from "../Assets/Svgs/enroll1.svg";

import IDCard from "../Assets/Svgs/id.svg";
import Lock from "../Assets/Svgs/password.svg";
import Breadcrumb from "../Assets/Svgs/breadcrum.svg";

import User from "../Assets/Svgs/user.svg";
import Search from "../Assets/Svgs/search.svg";
import Pie from "../Assets/Svgs/pie.svg";
import Notification from "../Assets/Svgs/notification.svg";
import Chat from "../Assets/Svgs/chat.svg";
import Profile from "../Assets/Svgs/profile.svg";
import Backarrow from "../Assets/Svgs/backarrow.svg";
import CMColor from "../Assets/Svgs/cm-color.svg";
import Search2 from "../Assets/Svgs/search2.svg";
import User2 from "../Assets/Svgs/user2.svg";
import Vaccines from "../Assets/Svgs/vaccines.svg";
import AddRound from "../Assets/Svgs/add-round.svg";
import MinusRound from "../Assets/Svgs/minus-round.svg";
import DropDownArrow from "../Assets/Svgs/dropdown-arrow.svg";
import Calendar from "../Assets/Svgs/calendar.svg";
import Close from "../Assets/Svgs/close.svg";
import CloseGray from "../Assets/Svgs/close-gray.svg";
import StarFilled from "../Assets/Svgs/star-filled.svg";
import MyPatient from "../Assets/Svgs/my-patient.svg";
import Billing from "../Assets/Svgs/billing.svg";
import GoalSheet from "../Assets/Svgs/goal-sheet.svg";
import Watch from "../Assets/Svgs/watch.svg";
import Drop from "../Assets/Svgs/drop.svg";
import Group from "../Assets/Images/group.png";
import Group2x from "../Assets/Images/group2x.png";
import CV_Logo from "../Assets/Images/cv_logo.png";
import Profilepic1 from "../Assets/Images/profilepic1.png";
import Profilepic2 from "../Assets/Images/profilepic2.png";
import Profilepic3 from "../Assets/Images/profilepic3.png";
import Profilepic4 from "../Assets/Images/profilepic4.png";
import Profilepic5 from "../Assets/Images/profilepic5.png";
import Profilepic6 from "../Assets/Images/profilepic6.png";
import Profilepic7 from "../Assets/Images/profilepic7.png";
import CV_Logo_Mid from "../Assets/Images/cv_logo_mid.png";
import Login_Img from "Assets/Images/login_img.jpg";
import Login_Img1 from "Assets/Images/login_img1.png";

import {
  EditOutlined,
  DeleteOutline,
  WatchLaterOutlined as AddTimeLogIcon,
  PersonOutlineOutlined as UserIcon,
  ArrowDropDown,
  ArrowDropUp,
  West as BackArrowIcon,
  GroupsOutlined,
  HighlightOffRounded as CloseRound,
  Close as CloseIcon,
  CalendarMonthOutlined as CalendarIcon,
  Search as SearchIcon,
  Person as PersonIcon,
  SettingsOutlined as SettingsIcon,
  Notifications as NotificationIcon,
  Add as PlusIcon,
  Remove as MinusIcon,
  // LocationOn as LocationIcon,
  Call as CallIcon,
  Email as EmailIcon,
  Smartphone as SmartphoneIcon,
  Create as PencilIcon,
  Schedule as ClockIcon,
  GroupsOutlined as GroupsOutlinedIcon,
  VaccinesOutlined as syringImage,
  AddOutlined as PlusOutlinedIcon,
  KeyboardBackspaceOutlined as PaginationArrowIcon,
  Done as SaveIcon,
  TrendingFlat as AnsIcon,
  StarBorderOutlined as QuestionStarIcon,
  RemoveRedEyeOutlined as RemoveRedEyeOutlined,
  InfoOutlined as InfoOutlined,
  HouseOutlined as HomeIcon,
  TableRowsOutlined as MasterIcon,
  AddBusinessOutlined as OrganisationIcon,
  FiberManualRecord as DotIcon,
  ArrowBackIosOutlined as BreadcrumbIcon,
  Warning as WarnIcon,
  Leaderboard as rpmchartIcon,
  ReplyOutlined as assignIcon,
  FileUploadOutlined as ImportIcon,
  FileDownloadOutlined as DownloadIcon,
  InputOutlined as MoveToIcon,
  LocationOnOutlined as LocationIcon,
  Cached as RefreshIcon,
  KeyboardArrowRightOutlined as StartIcon,
  ExpandCircleDown as CPDropIcon,
  WarningAmberOutlined as WarningIcon,
  PreviewOutlined as ViewCarePlan,
  MarkChatReadOutlined as MarkReadIcon,
} from "@mui/icons-material";

const ImgPath = {
  InfoOutlined,
  EditOutlined,
  DeleteOutline,
  AddTimeLogIcon,
  UserIcon,
  ChartIcon,
  CallLogsIcon,
  CreateTaskIcon,
  StartCareIcon,
  PatientDetailsIcon,
  ClinicalSummaryIcon,
  TaskIcon,
  PointOutIcon,
  RoundTickIcon,
  ForwardArrowIcon,
  CMIcon,
  IDCard,
  Lock,
  Breadcrumb,
  User,
  Search,
  Pie,
  Notification,
  Chat,
  Profile,
  Backarrow,
  Allergies,
  Meds,
  Problems,
  CMColor,
  Vitals,
  Search2,
  User2,
  Vaccines,
  Quality,
  Result,
  TV,
  AddRound,
  MinusRound,
  DropDownArrow,
  Calendar,
  Close,
  RemoveRedEyeOutlined,
  QuestionStarIcon,
  StarFilled,
  MyPatient,
  Billing,
  GoalSheet,
  CloseGray,
  Watch,
  Drop,
  HomeIcon,
  MasterIcon,
  OrganisationIcon,
  ArrowDropDown,
  ArrowDropUp,
  BackArrowIcon,
  GroupsOutlined,
  CloseRound,
  CloseIcon,
  CalendarIcon,
  SearchIcon,
  SettingsIcon,
  PersonIcon,
  NotificationIcon,
  StartCarePlanIcon,
  CarePlanIcon,
  PaginationArrowIcon,
  SaveIcon,
  AnsIcon,
  PlusIcon,
  MinusIcon,
  LocationIcon,
  CallIcon,
  EmailIcon,
  SmartphoneIcon,
  PencilIcon,
  ClockIcon,
  GroupsOutlinedIcon,
  syringImage,
  PlusOutlinedIcon,
  Group,
  Group2x,
  CV_Logo,
  Profilepic1,
  Profilepic2,
  Profilepic3,
  Profilepic4,
  Profilepic5,
  Profilepic6,
  Profilepic7,
  CV_Logo_Mid,
  TileIcon,
  WarnIcon,
  rpmchartIcon,
  Login_Img,
  Login_Img1,
  // Tiles Icon
  EligibleIcon,
  PccIcon,
  myptIcon,
  ppaIcon,
  unrIcon,
  ongIcon,
  clIcon,
  decIcon,
  pcmcIcon,
  ccmcIcon,
  billingIcon,
  mtIcon,
  cptIcon,
  DotIcon,
  BreadcrumbIcon,
  assignIcon,
  ImportIcon,
  DownloadIcon,
  MoveToIcon,
  RefreshIcon,
  StartIcon,
  ReenrollIcon,
  UnenrollIcon,
  EnrollIcon,
  CPDropIcon,
  WarningIcon,
  ViewCarePlan,
  MarkReadIcon,
};

export default ImgPath;
