import { Box, Checkbox, Typography } from "@mui/material";
import { DropdownArrPropsType } from "DataTypes/Form";
import { FC, Fragment } from "react";

type BoxType = {
  dropArr: DropdownArrPropsType[];
  selectedItems: (string | number)[];
  handleSelect: (el: DropdownArrPropsType) => void;
  disableItems: (string | number)[];
};

const MultiSelectDrop: FC<BoxType> = (props: BoxType) => {
  const { dropArr, selectedItems, handleSelect, disableItems } = props;
  return (
    <Box sx={{ ...sxDrop }}>
      {dropArr.length > 0 ? (
        <Fragment>
          {dropArr.map((el: DropdownArrPropsType) => {
            const found = selectedItems.find((el1) => el.id == el1);
            const isDisable = disableItems.find((disEl) => disEl == el.value);
            return (
              <Box
                key={el.id}
                {...(!isDisable && { onClick: () => handleSelect(el) })}
                sx={{
                  ...(isDisable ? sxDisable : sxNonDisable),
                  ...sxBox,
                }}
              >
                <Checkbox checked={found ? true : false} size="small" />
                <Typography variant="subtitle1">{el.label}</Typography>
              </Box>
            );
          })}
        </Fragment>
      ) : (
        <Typography variant="body2" p={1}>
          No Data Found
        </Typography>
      )}
    </Box>
  );
};
export default MultiSelectDrop;

const sxBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  p: 1,
  "& >span": {
    p: 0,
    mr: 1,
    "&.Mui-checked": {
      color: "checkbox.main",
    },
  },
};

const sxDisable = {
  opacity: 0.5,
  cursor: "default",
};

const sxNonDisable = {
  cursor: "pointer",
  ": hover": {
    backgroundColor: "custom.light",
  },
};

const sxDrop = {
  width: "100%",
  position: "absolute",
  zIndex: "10",
  maxHeight: "300px",
  overflow: "auto",
  backgroundColor: "common.white",
  boxShadow: "0px 3px 6px #00000029",
  borderBottomLeftRadius: "5px",
  borderBottomRightRadius: "5px",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  top: "100%",
  py: 1,
};