import { FC, Fragment, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import MainHeader from "Components/Header";
import HeadProvider from "Context/HeadContext";
import Notification from "Service/Notification";
import { ListInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useSelector, useDispatch } from "react-redux";
import { Client } from "stompjs";
import NotificationFormatter from "Utils/Notification.Formatter";
import { addNotificationList } from "Redux/List/Action";
import { NotificationType } from "DataTypes/Notification";
import { Logout } from "Utils/common";

const Home: FC = () => {
  const dispatch = useDispatch();
  let stompClient: Client;
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { notificationList }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const [notification, setNotification] = useState<NotificationType | null>(
    null
  );
  const headers = { Authorization: myInfo.id };

  useEffect(() => {
    ConnectClient();
    return () => {
      DisconnectClient();
    };
  }, []);

  const ConnectClient: () => void = () => {
    if (!stompClient?.connected) {
      stompClient = Notification.list();
      stompClient.connect(headers, SubscribeClient, (error: any) => {
        console.error("WebSocket connection error:", error);
      });
    }
  };

  const SubscribeClient: () => void = () => {
    if (stompClient?.connected) {
      stompClient.subscribe("/user/specific", async (msg) => {
        const receivedMsg = JSON.parse(msg?.body);
        const formattedMsg = NotificationFormatter.Obj(receivedMsg);
        setNotification(formattedMsg);
      });
    } else DisconnectClient();
  };

  useEffect(() => {
    notification && setData(notification);
  }, [notification]);

  const setData = (notification: NotificationType) => {
    const newArr: NotificationType[] = JSON.parse(
      JSON.stringify(notificationList)
    );
    newArr.push(notification);
    dispatch(addNotificationList(newArr));
  };

  const DisconnectClient: () => void = () => {
    if (stompClient?.connected) {
      stompClient?.disconnect(() => {
        console.log("Client disconnected", headers);
      });
    }
    Logout();
  };

  return (
    <Fragment>
      <HeadProvider>
        <MainHeader />
        <Box sx={{ ...sxMain, px: 1 }}>
          <Outlet />
        </Box>
      </HeadProvider>
    </Fragment>
  );
};
export default Home;

const sxMain = {
  height: "92%",
  overflow: "auto",
  backgroundColor: "bg.main",
};
