import { FC, Fragment, useEffect, useState } from "react";
import { EligiblePrgmResType } from "DataTypes/Services/Patient.type";
import { FormDataType } from "DataTypes/Form";
import { Divider, Grid } from "@mui/material";
import Loader from "Components/Loader";
import { ControlledBoxSizeSwitch, commonFormSwitch } from "Utils/SwitchCases";
import NoData from "Components/NoData";
import CarePlanApi from "Service/CarePlan.api";
import { FormData1, FormData2 } from "./Data";
import CPFormatter from "Utils/CP.Formatter";
import { AddProviderFormType } from "DataTypes/PatientManagement.type";
import Format from "./Format";

const CareTeam: FC<{ data: EligiblePrgmResType }> = ({ data }) => {
  const { careplanId, teamInitiatorRoleId } = data;
  const [formArr1, setFormArr1] = useState<FormDataType[]>([]);
  const [formArr2, setFormArr2] = useState<AddProviderFormType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setData();
  }, []);

  const setData = async () => {
    const res: any = await CarePlanApi.careplanTeamSearch({ id1: careplanId });
    if (res.success) {
      const checkedRes = CPFormatter.CheckCPTeam(res?.data);
      setFormArr1(Format.MainForm(checkedRes, FormData1));
      setFormArr2(Format.AddForm(checkedRes, FormData2));
    }
    setLoading(false);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : formArr1.length > 0 ? (
        <Fragment>
          <Grid container rowSpacing={1} columnSpacing={1} mb={1}>
            {formArr1.map((el) => {
              if (el.id == "cp-billable-provider" && teamInitiatorRoleId == 3)
                return <Fragment key={el.id} />;
              return (
                <Grid key={el.id} item {...ControlledBoxSizeSwitch(el.boxSize)}>
                  {commonFormSwitch({ formObj: el })}
                </Grid>
              );
            })}
          </Grid>
          {formArr2.map((el) => {
            return (
              <Fragment key={el.id}>
                <Divider sx={{ mt: 2, mb: 1 }} />
                <Grid container rowSpacing={1} columnSpacing={1} mb={1}>
                  {el.formArr.map((el1: FormDataType) => {
                    return (
                      <Grid
                        key={el1.id}
                        item
                        {...ControlledBoxSizeSwitch(el1.boxSize)}
                      >
                        {commonFormSwitch({ formObj: el1 })}
                      </Grid>
                    );
                  })}
                </Grid>
              </Fragment>
            );
          })}
        </Fragment>
      ) : (
        <NoData />
      )}
    </Fragment>
  );
};
export default CareTeam;
