import { BreadArrType } from "DataTypes/Common";

export const OptionArr = [
  {
    id: "1",
    label: "Yes",
    value: "Yes",
  },
  {
    id: "2",
    label: "No",
    value: "No",
  },
  {
    id: "3",
    label: "I don't remember",
    value: "I don't remember",
  },
];

export const ProblemArr = [
  {
    id: "1",
    label: "Diabetes",
    value: "Diabetes",
  },
  {
    id: "2",
    label: "Hypertension",
    value: "Hypertension",
  },
];

export const ProgramArr = [
  {
    id: "1",
    label: "CCM",
    value: "CCM",
  },
  {
    id: "2",
    label: "PCM",
    value: "PCM",
  },
];

export const VisitArr = [
  {
    id: "1",
    label: "CCM Care Plan (Provider)",
    value: "CCM Care Plan (Provider)",
  },
  {
    id: "2",
    label: "CCM Care Plan (Care Manager)",
    value: "CCM Care Plan (Care Manager)",
  },
];

export const QuestionArr = [
  {
    id: "1",
    question: "Have you been hospitalized since your last visit?",
    type: "Single Select",
    programs: [
      {
        id: "1",
        label: "CCM",
        value: "CCM",
      },
      {
        id: "2",
        label: "PCM",
        value: "PCM",
      },
    ],
    problems: [
      {
        id: "1",
        label: "Diabetes",
        value: "Diabetes",
      },
      {
        id: "2",
        label: "Hypertension",
        value: "Hypertension",
      },
    ],
    visits: [
      {
        id: "1",
        label: "CCM Care Plan (Provider)",
        value: "CCM Care Plan (Provider)",
      },
      {
        id: "2",
        label: "CCM Care Plan (Care Manager)",
        value: "CCM Care Plan (Care Manager)",
      },
    ],
    options: [
      {
        id: "1.1",
        optionLabel: "Yes",
        question: "Have you seen a different provider since your last visit?",
        type: "Multi Select",
        options: [
          {
            id: "1.1.1",
            optionLabel: "Yes",
          },
          {
            id: "1.1.2",
            optionLabel: "No",
          },
          {
            id: "1.1.3",
            optionLabel: "I don't remember",
          },
        ],
      },
      {
        id: "1.2",
        optionLabel: "No",
      },
    ],
  },
  {
    id: "2",
    question:
      " Have you been to the emergency room or urgent care clinic since your last visit?",
    type: "Multi Select",
    problems: [
      {
        id: "1",
        label: "Diabetes",
        value: "Diabetes",
      },
      {
        id: "2",
        label: "Hypertension",
        value: "Hypertension",
      },
    ],
    options: [
      {
        id: "2.1",
        optionLabel: "Yes",
        question: "Have you had your thyroid function tested (T4, TSH)?",
        type: "Single Select",
        options: [
          {
            id: "2.1.1",
            optionLabel: "Yes",
          },
          {
            id: "2.1.2",
            optionLabel: "No",
          },
        ],
      },
      {
        id: "2.2",
        optionLabel: "No",
      },
      {
        id: "2.3",
        optionLabel: "I don't remember",
      },
    ],
  },
];

export const QuestioniarMasterBreadArr = () => {
  const Arr: BreadArrType[] = [
    {
      id: 1,
      label: "Questionnaire Master",
      path: "",
    },
  ];
  return Arr;
} 