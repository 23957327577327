import ControlledTable from "Components/ControlledTable";
import { columnType } from "DataTypes/Table";

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.StartDateTimeCol(),
  ControlledTable.ColumnData.EndDateTimeCol(),
  ControlledTable.ColumnData.ActivityCol(),
  ControlledTable.ColumnData.DurationCol(),
  ControlledTable.ColumnData.ActivityTypeCol(),
];
