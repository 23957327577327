import { FC, useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ControlledTable, CommonStyle } from "Components";
import { TableLayout } from "Layouts";
import { MasterProgramBreadArr, TableCol } from "./Data";
import ProgramApi from "Service/Program.api";
import { useSelector } from "react-redux";
import { AscendSort } from "Utils/common";
import { HeadContext } from "Context/HeadContext";
import { HeadContextType } from "DataTypes/Common";

const ProgramMaster: FC = () => {
  const permission: any = useSelector(
    (state: any) => state?.user?.myPermission?.program
  );
  const { setBreadArr } = useContext(
    HeadContext
  ) as HeadContextType;
  const [programList, setProgramList] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setBreadArr(MasterProgramBreadArr());
    getProgramList();
  }, []);

  const getProgramList = async () => {
    const res: any = await ProgramApi.list();
    if (res?.success) setProgramList(res?.data);
    setLoading(false);
  };
  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={(currentRow: any) => TableCol}
      tableRow={programList}
    />
  );

  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox, px: 2 }}>
      <Box
        height={"10%"}
        justifyContent={"space-between"}
        sx={{ ...CommonStyle.sxRow }}
      >
        <Typography sx={{ ...CommonStyle.sxHeading }}>
          {"Program Master"}
        </Typography>
      </Box>
      {permission?.search && (
        <Box width="50%" height="88%">
          <TableLayout
            tblBody={TblBodyJSX}
            tblHead={ControlledTable.TblHead(TableCol)}
            loading={loading}
            height={"100%"}
          />
        </Box>
      )}
    </Box>
  );
};
export default ProgramMaster;
