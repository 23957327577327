import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { TableCol } from "./Data";
import PatientApi from "Service/Patient.api";
import PatientFormatter from "Utils/Patient.Formatter";
import {
  EligiblePrgmResType,
  LoggedTimeType,
} from "DataTypes/Services/Patient.type";
import ControlledTable from "Components/ControlledTable";
import { TableLayout } from "Layouts";
import { PatientInitialStateType, UserInitialStateType } from "DataTypes/Redux";

const AddonLogTime: FC<{ data: EligiblePrgmResType }> = ({ data }) => {
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const [rows, setRows] = useState<LoggedTimeType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData();
  }, []);

  const setData: () => void = async () => {
    const payload = {
      patientId: patientInfo.id,
      userId: myInfo.id,
      state: "addon time",
      programId: data.programId,
      careplanId: data.careplanId,
    };
    const res = await PatientApi.timeLogSearch({ data: payload });
    if (res.success) {
      setRows(PatientFormatter.LoggedTimeList(res?.data));
    }
    setLoading(false);
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody tableColumn={() => TableCol} tableRow={rows} />
  );

  return (
    <Box width="100%">
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol)}
        height={"100%"}
        loading={loading}
      />
    </Box>
  );
};
export default AddonLogTime;
