import { useEffect, useState, FC } from "react";
import { Box, Typography } from "@mui/material";
import { CommonStyle, Loader } from "Components";
import PatientApi from "Service/Patient.api";
import {
  RedoxAllergyType,
  RedoxProblemType,
} from "DataTypes/Services/Patient.type";
import RedoxApi from "Service/Redox.api";
import RedoxFormatter from "Utils/Redox.Formatter";
import { MedSearchType } from "DataTypes/Services/Redox.type";
import { label } from "Constant/En";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

const HistoryCard: FC = () => {
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [allergiesList, setAllergiesList] = useState<RedoxAllergyType[]>([]);
  const [medicationList, setMedicationList] = useState<MedSearchType[]>([]);
  const [problemsList, setProblemsList] = useState<RedoxProblemType[]>([]);
  const [loading, setLoading] = useState(true);

  const getCardData = async () => {
    const payload = { medicationsActive: "active" };
    const medRes = await RedoxApi.MedSearch({
      id1: patientInfo.id,
      data: payload,
    });
    const problemsRes = await PatientApi.RedoxProblems({ id1: patientInfo.id });
    const allergiesRes = await PatientApi.RedoxAllergy({ id1: patientInfo.id });
    if (medRes?.success) {
      setMedicationList(RedoxFormatter.MedSearch(medRes?.data?.medications));
    }
    if (problemsRes?.success) {
      setProblemsList(RedoxFormatter.ProblemList(problemsRes?.data?.problem));
    }
    if (allergiesRes?.success) {
      setAllergiesList(
        RedoxFormatter.AllergiesList(allergiesRes?.data?.allergies)
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getCardData();
  }, []);

  const NoData = <Typography variant="subtitle1">No Data Found</Typography>;

  return (
    <Box
      height="100%"
      sx={{ ...CommonStyle.sxWhiteBox, mr: 2, overflow: "auto" }}
    >
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ p: 2 }}>
          <Box
            display="flex"
            flexDirection="row"
            sx={{ "& >div": { width: "50%" } }}
            mb={2}
          >
            <Box>
              <Typography variant="body2" fontWeight="bold">
                Allergies
              </Typography>
              {allergiesList.length > 0
                ? allergiesList.map((obj) => {
                  return (
                    <Typography key={obj?.id} variant="subtitle1">
                        • {obj?.name}
                    </Typography>
                  );
                })
                : NoData}
            </Box>
            <Box>
              <Typography variant="body2" fontWeight="bold">
                Medication
              </Typography>
              {medicationList.length > 0
                ? medicationList.map((obj) => {
                  return (
                    <Typography key={obj.id} variant="subtitle1">
                        • {obj.productName || label.naText}
                    </Typography>
                  );
                })
                : NoData}
            </Box>
          </Box>
          <Box>
            <Typography variant="body2" fontWeight="bold">
              Problems
            </Typography>
            {problemsList.length > 0
              ? problemsList.map((obj) => {
                return (
                  <Typography variant="subtitle1" key={obj?.id}>{`• ${
                    obj.codeSystemName == "ICD-10-CM" ? obj.code + " -" : ""
                  } ${obj.name}`}</Typography>
                );
              })
              : NoData}
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default HistoryCard;
