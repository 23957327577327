import { InactiveType } from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";
import PatientFormatter from "Utils/Patient.Formatter";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "Utils/common";

const InactiveList: (data: any) => InactiveType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: InactiveType[] = CheckedArr.map((el, index) => {
    const proName = CheckStringEmpty(el?.teamProviderName);
    const proDes = CheckStringEmpty(el?.teamProviderDesignation);
    const provider = proName ? proName + (proDes ? ", " + proDes : "") : "";
    return {
      ...PatientFormatter.ListPatientInfo1(el),
      careplanState: CheckStringEmpty(el?.careplanState),
      id: index,
      lastcall: DateTime.ValidDate(el?.lastCall),
      patientId: CheckStringId(el?.id),
      primaryins: CheckStringEmpty(el?.primaryInsurance),
      program: CheckStringEmpty(el?.programAbbrevation),
      provider,
      providerId: CheckNumber(el?.teamProviderId),
      secondaryins: CheckStringEmpty(el?.secondaryInsurance),
      reason: CheckStringEmpty(el?.reason),
    };
  });
  return newArr;
};

export default { InactiveList };
