import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { CommonStyle, ControlledAccord } from "Components";
import { PrgmPrbmProgrmBox } from "DataTypes/Master";

const ProgramBox: FC<PrgmPrbmProgrmBox> = (props) => {
  const { programList, selectedPrgm, handleChangePrgm } = props;

  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox }}>
      <Typography variant="subtitle1" sx={{ ...CommonStyle.sxRow, ...sxHead }}>
        {"Program Name"}
      </Typography>
      <Box sx={{ ...sxBody }}>
        {programList.map((el) => {
          return (
            <ControlledAccord
              key={el.id}
              handleClick={() =>
                handleChangePrgm(selectedPrgm?.id == el.id ? null : el)
              }
              open={selectedPrgm?.id == el.id ? true : false}
              head={
                <Typography
                  variant="subtitle1"
                  sx={{ ml: 1, ...(selectedPrgm?.id == el.id && sxSelected) }}
                >
                  {el.name}
                </Typography>
              }
            />
          );
        })}
      </Box>
    </Box>
  );
};
export default ProgramBox;

const sxHead = {
  ...CommonStyle.sxRow,
  height: "10%",
  width: "100%",
  fontWeight: "bold",
  px: 2,
};

const sxBody = {
  height: "90%",
  overflow: "auto",
  px: 2,
};

const sxSelected = {
  fontWeight: "bold",
};
