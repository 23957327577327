import { Fragment, useEffect, useState, FC } from "react";
import { Box, IconButton, Link, Typography } from "@mui/material";
import { label } from "Constant/En";
import { CommonStyle, Loader, Modal } from "Components";
import { ModalLayout } from "Layouts";
import { CommonButtonArray } from "Components/Modal/Data";
import { useNavigate } from "react-router-dom";
import ImgPath from "Constant/Imgs";
import PatientApi from "Service/Patient.api";
import NoData from "Components/NoData";
import ProgramApi from "Service/Program.api";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import { useDispatch } from "react-redux";
import { PatientInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { RouteUrls } from "Constant/RouteUrls";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { columnArr } from "./Data";
import {
  ActionIdType,
  FormatEligiblePrgmType,
  PrgmDetailsType,
} from "DataTypes/PatientManagement.type";
import DateTime from "Utils/DateTime";
import AppointmentApi from "Service/Appointment.api";
import AppointmentFormatter from "Utils/Appointment.Formatter";
import Format from "./Format";
import { FormDataType } from "DataTypes/Form";
import {
  CheckStringEmpty,
  CheckStringId,
  ValidateFormData,
} from "Utils/common";
import {
  AppointmentPatientType,
  AppointmentType,
  EligiblePrgmResType,
} from "DataTypes/Services/Patient.type";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import PatientFormatter from "Utils/Patient.Formatter";

const ProgramCard: FC = () => {
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openUnenrollModal, setOpenUnenrollModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [unenrollReason, setUnenrollReason] = useState<string | number>("");
  const [clickedElement, setClickedElement] =
    useState<FormatEligiblePrgmType | null>(null);
  const [dataArr, setDataArr] = useState<PrgmDetailsType[]>([]);
  const [editAppointment, setEditAppointment] =
    useState<AppointmentType | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedPrgm, setSelectedPrgm] =
    useState<FormatEligiblePrgmType | null>(null);
  const [selectedPatient, setSelectedPatient] =
    useState<AppointmentPatientType | null>(null);
  const [formData, setFormData] = useState<FormDataType[]>([]);

  useEffect(() => {
    setOpen(editAppointment ? true : false);
  }, [editAppointment]);

  useEffect(() => {
    fetchPatientProgramsData();
  }, []);

  const fetchPatientProgramsData = async () => {
    const res: any = await PatientApi.eligiblePrgmList({
      id1: CheckStringId(patientInfo.id),
    });
    if (res?.success) {
      const formattedRes = Format.PrgmDetails(
        res?.data,
        myInfo.roleId,
        myInfo.id
      );
      setDataArr(formattedRes);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (clickedElement) setOpenUnenrollModal(true);
    else setOpenUnenrollModal(false);
  }, [clickedElement]);

  const handleUnenroll: (
    payloadPrgm: FormatEligiblePrgmType | null,
    reason: string | number
  ) => void = async (payloadPrgm, reason) => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    const payload = {
      concent: "Unenrolled",
      unenrollmentReason: reason,
    };
    const res = await ProgramApi.UnenrollProgram({
      id1: CheckStringId(patientInfo.id),
      id2: payloadPrgm?.eligibleProgramId,
      data: payload,
    });
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      fetchPatientProgramsData();
      dispatch(stopLoading());
    }
  };

  const handleEditAppointment: (id: number | null) => void = async (id) => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    const readRes = await AppointmentApi.Read({ id1: id });
    if (readRes?.success) {
      const editData = AppointmentFormatter.ReadAppointmemt(readRes?.data);
      setEditAppointment(editData);
    }
    dispatch(stopLoading());
  };

  const handleClose: () => void = () => {
    setEditAppointment(null);
    setOpen(false);
  };

  const handleSave = async () => {
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData);
    if (!newArr.errorCheck) {
      const timezone = CheckStringEmpty(formData[5]?.dropValue);
      const dateTime = DateTime.ValidDate(formData[4]?.value);
      const payload = {
        patientId: formData[0]?.dropValue,
        appointmentType: formData[1]?.dropValue,
        organization: selectedPatient?.practiceId,
        providerId: selectedPatient?.providerId,
        appointmentDate: timezone
          ? DateTime.AddHoursToUTCDateTime(
            DateTime.HoursTimeZoneSwitch(timezone),
            dateTime
          )
          : DateTime.ToUTCDateTime(dateTime),
        timeZone: timezone,
        notes: formData[6]?.value,
        status: "OPEN",
        careplanId: selectedPrgm?.careplanId,
        careplanRecurringId: selectedPrgm?.careplanRecurringId,
      };
      const res: any = await AppointmentApi.Update({
        data: payload,
        id1: editAppointment?.id,
      });
      if (res?.success) {
        dispatch(setAlertData(res?.alert));
        handleClose();
        fetchPatientProgramsData();
      }
    }
  };

  const dispatchPrgm: (prgm: PrgmDetailsType) => EligiblePrgmResType = (
    prgm
  ) => {
    const newObj = JSON.parse(JSON.stringify(prgm));
    delete newObj?.actionArr;
    const newObj1: EligiblePrgmResType = JSON.parse(JSON.stringify(newObj));
    return newObj1;
  };

  const handleViewCallLogs: (prgm: PrgmDetailsType) => void = (prgm) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...dispatchPrgm(prgm),
        readOnlyArr: ["call-log"],
      })
    );
    navigate(`../${RouteUrls.callLog}`);
  };

  const handleUnEnroll: (prgm: PrgmDetailsType) => void = (prgm) => {
    setClickedElement({
      ...selectedPrgmInitialState,
      ...dispatchPrgm(prgm),
    });
  };

  const handleTcmEnroll: (prgm: PrgmDetailsType) => void = (prgm) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...dispatchPrgm(prgm),
      })
    );
    navigate(`../${RouteUrls.dischargeSummary}`);
  };

  const handleViewDischarge: (prgm: PrgmDetailsType) => void = (prgm) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...dispatchPrgm(prgm),
      })
    );
    navigate(`../${RouteUrls.dischargeSummary}`);
  };

  const handleTcmCall: (prgm: PrgmDetailsType) => void = (prgm) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...dispatchPrgm(prgm),
        callDefaultRoute: `../${RouteUrls.tcm}/${
          prgm.careplanId
        }/${PatientFormatter.StateRouteSwitch(prgm.careplanState)}`,
        showLogTime: true,
        showSaveBtn: true,
        showSubmitBtn: true,
      })
    );
    navigate(`../${RouteUrls.callLog}`);
  };

  const handleViewTcm: (prgm: PrgmDetailsType) => void = (prgm) => {
    const newObj: FormatEligiblePrgmType = {
      ...selectedPrgmInitialState,
      ...dispatchPrgm(prgm),
      readOnlyArr: ["question", "team", "addNotes", "frequency"],
    };
    dispatch(addSelectedPrgm(newObj));
    navigate(
      `../${RouteUrls.tcm}/${
        prgm.careplanId
      }/${PatientFormatter.StateRouteSwitch(prgm.careplanState)}`
    );
  };

  const handleCareplanEnroll: (prgm: PrgmDetailsType) => void = (prgm) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...dispatchPrgm(prgm),
        callDefaultRoute: `../${RouteUrls.program}`,
      })
    );
    navigate(`../${RouteUrls.callLog}`);
  };

  const handleCareplanCall: (prgm: PrgmDetailsType) => void = (prgm) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...dispatchPrgm(prgm),
        callDefaultRoute: `../${RouteUrls.carePlan}/${
          prgm.careplanId
        }/${PatientFormatter.StateRouteSwitch(prgm.careplanState)}`,
        showLogTime: true,
        showSaveBtn: true,
        showSubmitBtn: true,
      })
    );
    navigate(`../${RouteUrls.callLog}`);
  };

  const handleViewCareplan: (prgm: PrgmDetailsType) => void = (prgm) => {
    const newObj: FormatEligiblePrgmType = {
      ...selectedPrgmInitialState,
      ...dispatchPrgm(prgm),
      readOnlyArr: [
        "uptoDate",
        "problem",
        "question",
        "team",
        "addNotes",
        "frequency",
      ],
    };
    dispatch(addSelectedPrgm(newObj));
    navigate(
      `../${RouteUrls.carePlan}/${
        prgm.careplanId
      }/${PatientFormatter.StateRouteSwitch(prgm.careplanState)}`
    );
  };

  const handleEditCareplan: (prgm: PrgmDetailsType) => void = (prgm) => {
    const newObj: FormatEligiblePrgmType = {
      ...selectedPrgmInitialState,
      ...dispatchPrgm(prgm),
      ...(myInfo.roleId == 3 ? { showSaveBtn: true } : {}),
      ...(myInfo.roleId == 5 ? { showSubmitBtn: true } : {}),
    };
    dispatch(addSelectedPrgm(newObj));
    navigate(
      `../${RouteUrls.carePlan}/${
        prgm.careplanId
      }/${PatientFormatter.StateRouteSwitch(prgm.careplanState)}`
    );
  };

  const handleRecurringCall: (prgm: PrgmDetailsType) => void = (prgm) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...dispatchPrgm(prgm),
        callDefaultRoute: `../${RouteUrls.carePlan}/${prgm.careplanId}/${RouteUrls.recurring}/${prgm.careplanRecurringId}/${RouteUrls.careQuestion}`,
        showLogTime: true,
        showSubmitBtn: true,
      })
    );
    navigate(`../${RouteUrls.callLog}`);
  };

  const handleViewRecurringplan: (prgm: PrgmDetailsType) => void = (prgm) => {
    const newObj: FormatEligiblePrgmType = {
      ...selectedPrgmInitialState,
      ...dispatchPrgm(prgm),
      readOnlyArr: ["question", "addNotes", "frequency"],
    };
    dispatch(addSelectedPrgm(newObj));
    navigate(
      `../${RouteUrls.carePlan}/${prgm.careplanId}/${RouteUrls.recurring}/${prgm.careplanRecurringId}/${RouteUrls.careQuestion}`
    );
  };

  const IconSwitch: (
    actionId: ActionIdType,
    prgm: PrgmDetailsType
  ) => JSX.Element = (actionId, prgm) => {
    switch (actionId) {
      case "view-call-logs":
        return (
          <IconButton
            title={"View Call Logs"}
            onClick={() => handleViewCallLogs(prgm)}
          >
            <ImgPath.CallLogsIcon />
          </IconButton>
        );
      case "un-enroll":
        return (
          <IconButton title={"Unenroll"} onClick={() => handleUnEnroll(prgm)}>
            <ImgPath.UnenrollIcon />
          </IconButton>
        );
      case "tcm-enroll":
        return (
          <IconButton
            title={"Discharge Summary"}
            onClick={() => handleTcmEnroll(prgm)}
          >
            <ImgPath.EnrollIcon />
          </IconButton>
        );
      case "view-discharge":
        return (
          <IconButton
            title={"View Discharge Summary"}
            onClick={() => handleViewDischarge(prgm)}
          >
            <ImgPath.ViewCarePlan />
          </IconButton>
        );
      case "tcm-call":
        return (
          <IconButton
            title={"Call Details"}
            onClick={() => handleTcmCall(prgm)}
          >
            <ImgPath.CallLogsIcon />
          </IconButton>
        );
      case "view-tcm":
        return (
          <IconButton title={"View TCM"} onClick={() => handleViewTcm(prgm)}>
            <ImgPath.ViewCarePlan />
          </IconButton>
        );
      case "tcm-re-enroll":
        return (
          <IconButton
            title={"Reenroll TCM"}
            onClick={() => handleTcmEnroll(prgm)}
          >
            <ImgPath.ReenrollIcon />
          </IconButton>
        );
      case "enroll":
        return (
          <IconButton
            title={"Enroll"}
            onClick={() => handleCareplanEnroll(prgm)}
          >
            <ImgPath.EnrollIcon />
          </IconButton>
        );
      case "care-plan-call":
        return (
          <IconButton
            title={"Call Details"}
            onClick={() => handleCareplanCall(prgm)}
          >
            <ImgPath.CallLogsIcon />
          </IconButton>
        );
      case "view-care-plan":
        return (
          <IconButton
            title={"View Careplan"}
            onClick={() => handleViewCareplan(prgm)}
          >
            <ImgPath.ViewCarePlan />
          </IconButton>
        );
      case "edit-care-plan":
        return (
          <IconButton
            title={"Care Plan"}
            onClick={() => handleEditCareplan(prgm)}
          >
            <ImgPath.CarePlanIcon />
          </IconButton>
        );
      case "re-enroll":
        return (
          <IconButton
            title={"Reenroll"}
            onClick={() => handleCareplanEnroll(prgm)}
          >
            <ImgPath.ReenrollIcon />
          </IconButton>
        );
      case "recurring-call":
        return (
          <IconButton
            title={"Call Details"}
            onClick={() => handleRecurringCall(prgm)}
          >
            <ImgPath.CallLogsIcon />
          </IconButton>
        );
      case "view-recurring-plan":
        return (
          <IconButton
            title={"View Recurring Plan"}
            onClick={() => handleViewRecurringplan(prgm)}
          >
            <ImgPath.ViewCarePlan />
          </IconButton>
        );
      default:
        return (
          <IconButton
            title={"Call Details"}
            onClick={() => handleCareplanEnroll(prgm)}
          >
            <ImgPath.CallLogsIcon />
          </IconButton>
        );
    }
  };

  return (
    <Box height="100%" overflow="auto" sx={{ ...CommonStyle.sxWhiteBox }}>
      <ModalLayout
        modwidth="55%"
        heading={"Edit Appointment"}
        content={
          <Modal.AppointmentModal
            setForm={(data) => setFormData(data)}
            roleId={myInfo.roleId}
            editAppointment={editAppointment}
            handleSelectedPrgm={(data) => {
              setSelectedPrgm(data);
            }}
            handleSelectedPatient={(data) => {
              setSelectedPatient(data);
            }}
          />
        }
        open={open}
        onClose={handleClose}
        buttonArr={CommonButtonArray(handleSave, handleClose, "Save")}
      />
      <ModalLayout
        modwidth="520px"
        heading={"Unenroll"}
        content={
          <Modal.UnenrollConsent
            onReasonselect={(reason: string | number) => {
              setUnenrollReason(reason);
            }}
            programName={clickedElement?.abbrevation || ""}
            patientName={patientInfo.name}
          />
        }
        buttonArr={CommonButtonArray(
          () => {
            handleUnenroll(clickedElement, unenrollReason);
            setClickedElement(null);
            setUnenrollReason("");
          },
          () => {
            setClickedElement(null);
            setUnenrollReason("");
          },
          "Confirm",
          "",
          unenrollReason ? false : true
        )}
        open={openUnenrollModal}
        onClose={() => {
          setClickedElement(null);
          setUnenrollReason("");
        }}
      />
      <Box p={2}>
        <Typography variant="body2" fontWeight="bold" mb={1}>
          {label.prgmoverview}
        </Typography>
        {/* according to this task 10931  */}
        {/* <Typography variant="subtitle1" sx={{ mb: 1, mt: 1 }}>
          Note: Following are the status of the Programs Patient is eligible for
          :
        </Typography> */}
        <Box>
          {loading ? (
            <Loader />
          ) : dataArr.length ? (
            <Fragment>
              <Box sx={{ ...sxHead }}>
                {columnArr.map((el) => {
                  return (
                    <Typography
                      key={el.id}
                      variant="subtitle1"
                      width={el.width}
                    >
                      {el.label}
                    </Typography>
                  );
                })}
              </Box>
              {dataArr.map((el) => {
                const date = DateTime.UTCToLocalDateTime(el.appointment_date);
                return (
                  <Box key={el.programId} sx={{ ...sxContainer }}>
                    <Typography variant="subtitle1" width={columnArr[0].width}>
                      {el.abbrevation}
                    </Typography>
                    <Typography variant="subtitle1" width={columnArr[1].width}>
                      {el.status}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      width={columnArr[2].width}
                      sx={{ "& >button": { ...sxIcons } }}
                    >
                      {el.actionArr.length
                        ? el.actionArr.map((el1) => {
                          return (
                            <Fragment key={el1}>
                              {IconSwitch(el1, el)}
                            </Fragment>
                          );
                        })
                        : label.naText}
                    </Typography>
                    <Typography variant="subtitle1" width={columnArr[3].width}>
                      {el.status == "Enrolled"
                        ? el.abbrevation == "TCM"
                          ? "TCM"
                          : el.nextSchedule
                        : label.naText}
                    </Typography>
                    <Box width={columnArr[4].width}>
                      {date ? (
                        <Link
                          sx={{ cursor: "pointer" }}
                          variant="subtitle1"
                          onClick={() =>
                            handleEditAppointment(el.appointment_id)
                          }
                        >
                          {date}
                        </Link>
                      ) : (
                        <Typography variant="subtitle1">
                          {label.naText}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Fragment>
          ) : (
            <NoData />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default ProgramCard;

const sxHead = {
  display: "flex",
  "& >h6": {
    fontWeight: "bold",
  },
};

const sxContainer = {
  display: "flex",
  mb: 1,
};

const sxIcons = {
  p: 0.5,
  mr: 0.5,
  backgroundColor: "roundiconbg.main",
  color: "common.white",
  ":hover": {
    backgroundColor: "roundiconbghover.main",
  },
  "& >svg": {
    width: 20,
    height: 20,
    "& path": {
      fill: "#fff",
    },
  },
};
