import { FormDataType } from "DataTypes/Form";

export const FormData1: FormDataType[] = [
  {
    id: "Care-Manager",
    label: "Care Manager",
    placeholder: "Enter Care Manager Name",
    boxType: "input-box",
    readOnly: true,
    boxSize: 3,
    validatetype: "name",
  },
  {
    id: "cp-billable-provider",
    label: "Care Plan Billable Provider",
    boxType: "input-box",
    readOnly: true,
    boxSize: 3,
    validatetype: "name",
  },
  {
    id: "Billable-Provider",
    label: "Billable Provider",
    boxType: "input-box",
    readOnly: true,
    boxSize: 3,
    validatetype: "name",
  },
  {
    id: "Speciality",
    label: "Billable Provider's Speciality",
    placeholder: "Enter Billable Provider's Speciality",
    boxType: "input-box",
    readOnly: true,
    boxSize: 3,
    validatetype: "name",
  },
  {
    id: "Primary-Care-Coordinator",
    label: "Primary Care Coordinator",
    boxType: "input-box",
    readOnly: true,
    boxSize: 3,
    validatetype: "name",
  },
  {
    id: "Secondary-Care-Coordinator",
    label: "Secondary Care Coordinator",
    boxType: "input-box",
    readOnly: true,
    boxSize: 3,
    validatetype: "name",
  },
  {
    id: "Authorized-Representative1",
    label: "Authorized Representative 1",
    boxType: "input-box",
    readOnly: true,
    boxSize: 3,
    validatetype: "name",
  },
  {
    id: "authorized-representative2",
    label: "Authorized Representative 2",
    boxType: "input-box",
    readOnly: true,
    boxSize: 3,
    validatetype: "name",
  },
  {
    id: "authorized-representative3",
    label: "Authorized Representative 3",
    boxType: "input-box",
    readOnly: true,
    boxSize: 3,
    validatetype: "name",
  },
];

export const FormData2: FormDataType[] = [
  {
    id: "Additional Provider",
    label: "Additional Provider",
    boxType: "input-box",
    readOnly: true,
    boxSize: 3,
    validatetype: "name",
  },
  {
    id: "Add Speciality",
    label: "Additional Provider's Speciality",
    boxType: "input-box",
    readOnly: true,
    boxSize: 3,
    validatetype: "name",
  },
  {
    id: "Organization Name",
    label: "Organization Name",
    boxType: "input-box",
    readOnly: true,
    boxSize: 3,
    validatetype: "name",
  },
  {
    id: "Phone Number",
    label: "Phone Number",
    boxType: "input-box",
    readOnly: true,
    boxSize: 3,
    validatetype: "name",
  },
  {
    id: "Fax Number",
    label: "Fax Number",
    boxType: "input-box",
    readOnly: true,
    boxSize: 3,
    validatetype: "name",
  },
];
