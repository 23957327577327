import { FC, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { CommonStyle, ControlledTable, Filter2 } from "Components";
import { TableLayout } from "Layouts";
import { FilterArr, TableCol } from "./Data";
import AuditApi from "Service/Audit.api";
import { PatientAuditType } from "DataTypes/Services/Patient.type";
import { HeadContext } from "Context/HeadContext";
import {
  FetchListProps,
  Filter2DataProps,
  HeadContextType,
} from "DataTypes/Common";
import { BreadArr } from "./Data";
import { CreateFilter2Payload } from "Utils/common";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import PatientFormatter from "Utils/Patient.Formatter";

const PatientAuditTrail: FC = () => {
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const newPatientId = patientInfo.id;
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const [limit, setLimit] = useState<number>(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<PatientAuditType[]>([]);
  const [searchText, setSearchText] = useState("");
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>(
    JSON.parse(JSON.stringify(FilterArr()))
  );
  const tableHeight = `calc(99% - ${filterHeight}px)`;

  useEffect(() => {
    setBreadArr(BreadArr);
  }, []);

  useEffect(() => {
    handleSetData({ page: 1, limit });
  }, [filterData]);

  const handleSetData: (props: FetchListProps) => void = async (props) => {
    const { page: currentPage, limit: currentLimit } = props;
    const newFilterPayload = CreateFilter2Payload(filterData);
    const payload = {
      ...(newFilterPayload || {}),
    };
    setLoading(true);
    const res = await AuditApi.patientAudit({
      data: payload,
      limit: currentLimit,
      offset: (currentPage - 1) * currentLimit,
      id1: newPatientId,
    });
    if (res?.success) {
      setRows(PatientFormatter.PatientAuditLog(res?.data));
      setPage(res.criterion.page);
      setTotalRecord(res.criterion.total);
      setLimit(res.criterion.limit);
    } else {
      setRows([]);
      setPage(1);
      setTotalRecord(0);
      setLimit(10);
    }
    setLoading(false);
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol()}
      tableRow={rows}
      searchText={searchText}
    />
  );

  return (
    <Box width="91%" sx={{ ...CommonStyle.sxWhiteBox, px: 2 }} overflow="auto">
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => setFilterData(data)}
        changeSearchText={(val) => setSearchText(val)}
        setFilterHeight={(val) => setFilterHeight(val)}
      />
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol(), true)}
        height={tableHeight}
        loading={loading}
        Pagination={
          <ControlledTable.Pagination
            mainPage={page}
            limit={limit}
            total={totalRecord}
            handleClick={(currentPage, currentLimit) =>
              handleSetData({ page: currentPage, limit: currentLimit })
            }
            tableStyle={true}
          />
        }
      />
    </Box>
  );
};
export default PatientAuditTrail;
