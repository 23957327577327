import { PatientInfoType, PatientOrgInfoType } from "DataTypes/Redux";
import {
  ADD_PATIENT_INFO,
  CLEAR_PATIENT_INFO,
  CLEAR_PATIENT,
  ADD_SELECTED_PRGM_INFO,
  CLEAR_SELECTED_PRGM_INFO,
  ADD_PATIENT_ORG_INFO,
  CLEAR_PATIENT_ORG_INFO,
} from "./type";
import { FormatEligiblePrgmType } from "DataTypes/PatientManagement.type";

export const addPatientInfo: (data: PatientInfoType) => {
  type: string;
  payload: PatientInfoType;
} = (data) => {
  return {
    type: ADD_PATIENT_INFO,
    payload: data,
  };
};

export const clearPatientInfo: () => {
  type: string;
} = () => {
  return {
    type: CLEAR_PATIENT_INFO,
  };
};

export const addPatientOrgInfo: (data: PatientOrgInfoType) => {
  type: string;
  payload: PatientOrgInfoType;
} = (data) => {
  return {
    type: ADD_PATIENT_ORG_INFO,
    payload: data,
  };
};

export const clearPatientOrgInfo: () => {
  type: string;
} = () => {
  return {
    type: CLEAR_PATIENT_ORG_INFO,
  };
};

export const addSelectedPrgm: (data: FormatEligiblePrgmType) => {
  type: string;
  payload: FormatEligiblePrgmType;
} = (data) => {
  return {
    type: ADD_SELECTED_PRGM_INFO,
    payload: data,
  };
};

export const clearSelectedPrgm: () => {
  type: string;
} = () => {
  return {
    type: CLEAR_SELECTED_PRGM_INFO,
  };
};

export const clearPatient: () => {
  type: string;
} = () => {
  return {
    type: CLEAR_PATIENT,
  };
};
