import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "Constant/RouteUrls";
import { PatientAuditType } from "DataTypes/Services/Patient.type";
import AuditApi from "Service/Audit.api";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import CstmBtn from "Components/CstmBtn";
import { CommonStyle } from "Components";
import PatientFormatter from "Utils/Patient.Formatter";
import DateTime from "Utils/DateTime";

const AuditCard = () => {
  const navigate = useNavigate();
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [data, setData] = useState<PatientAuditType[]>([]);

  useEffect(() => {
    getPatientAuditLog();
  }, []);

  const getPatientAuditLog = async () => {
    const payload = {
      data: {},
      id1: patientInfo.id,
      offset: 0,
      limit: 5,
    };
    const res = await AuditApi.patientAudit(payload);
    if (res.success) {
      setData(PatientFormatter.PatientAuditLog(res?.data));
    }
  };

  return (
    <Box sx={{ ...CommonStyle.sxWhiteBox, overflow: "auto" }} height="100%">
      <Box sx={{ p: 2 }}>
        <Box
          sx={{ ...CommonStyle.sxRow, mb: 3 }}
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Typography variant="body2" fontWeight="bold" sx={{ mb: 0 }}>
            {"Recent Activity"}
          </Typography>
          <CstmBtn
            label="Audit Trail"
            onClick={() => navigate(`../${RouteUrls.patientAudit}`)}
            width="25%"
          />
        </Box>
        {data.map((el) => {
          return (
            <Box
              sx={{
                mb: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              key={el.id}
            >
              <Typography width="72%" variant="subtitle1">
                {el.description}
              </Typography>
              <Typography width="23%" variant="subtitle1">
                {DateTime.UTCToLocalDateTime(el.datetime)}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
export default AuditCard;
