import { FC, useState, useEffect, Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ModalLayout, TableLayout } from "Layouts";
import { ControlledTable, Modal, Filter2 } from "Components";
import { actionArr, FilterArr, PendingAssignBreadArr, TableCol } from "./Data";
import { RouteUrls } from "Constant/RouteUrls";
import { useDispatch, useSelector } from "react-redux";
import { CommonButtonArray } from "Components/Modal/Data";
import { setAlertData, stopLoading } from "Redux/Screen/Action";
import Formate from "./Formate";
import PatientApi from "Service/Patient.api";
import { UserInitialStateType } from "DataTypes/Redux";
import { HeadContext } from "Context/HeadContext";
import { Filter2DataProps, HeadContextType } from "DataTypes/Common";
import { PendingAssignType } from "DataTypes/Services/Patient.type";
import { CreateFilter2Payload } from "Utils/common";

const PendingAssignment: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const [rows, setRows] = useState<PendingAssignType[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedPatient, setSelectedPatient] =
    useState<PendingAssignType | null>(null);
  const [limit, setLimit] = useState<number>(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [searchText, setSearchText] = useState("");
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>(
    JSON.parse(JSON.stringify(FilterArr()))
  );
  const tableHeight = `calc(99% - ${filterHeight}px)`;

  useEffect(() => {
    setBreadArr(PendingAssignBreadArr);
  }, []);

  useEffect(() => {
    handleSetData(1, limit);
  }, [filterData]);

  const handleSetData: (
    currentPage: number,
    currentLimit: number
  ) => void = async (currentPage, currentLimit) => {
    setLoading(true);
    const newFilterPayload = CreateFilter2Payload(filterData);
    const payload = {
      ...(newFilterPayload || {}),
    };
    const res = await PatientApi.PendingAssignment({
      offset: (currentPage - 1) * currentLimit,
      limit: currentLimit,
      data: payload,
    });
    if (res?.success) {
      setRows(Formate.PendingAssignList(res?.data));
      setPage(res.criterion.page);
      setTotalRecord(res.criterion.total);
      setLimit(res.criterion.limit);
    } else {
      setRows([]);
      setPage(1);
      setTotalRecord(0);
      setLimit(10);
    }
    setLoading(false);
  };

  const handlePatientDetails: (data: PendingAssignType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.details}`
    );
  };

  const handleChart: (data: PendingAssignType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.clinicalSummary}`
    );
  };

  const handleTask: (data: PendingAssignType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.task}/${RouteUrls.add}`
    );
  };

  const handleAssign: (data: PendingAssignType) => void = (data) => {
    setSelectedPatient(data);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setSelectedPatient(null);
  };

  const handleSave = async () => {
    const cmpayload = {
      caremanagerId: myInfo.id,
    };
    const provpayload = {
      billableProviderId: myInfo.id,
    };
    const res = await PatientApi.AssignToMe({
      id1: selectedPatient && selectedPatient.enrolledProgramId,
      data:
        myInfo.roleId == 5 ? provpayload : myInfo.roleId == 3 ? cmpayload : {},
    });
    if (res.success) {
      dispatch(setAlertData(res.alert));
      setOpenModal(false);
      navigate(`../${RouteUrls.myPatients}`);
    }
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={rows}
      tableAction={(data: PendingAssignType) =>
        actionArr(
          () => handleChart(data),
          () => handlePatientDetails(data),
          () => handleTask(data),
          () => handleAssign(data),
          myInfo.roleId
        )
      }
      searchText={searchText}
    />
  );

  const handleSwitch: (prgm: string) => string = (prgm) => {
    let plan = "";
    switch (prgm) {
      case "CCM":
      case "PCM":
      case "GCM":
        plan = " to complete a Care Plan";
        break;
      case "BHI":
      case "RPM":
        plan = " to complete a Treatment Plan";
        break;
      case "TCM":
        plan = "";
        break;
      default:
        plan = "Care Plan";
    }
    return plan;
  };

  return (
    <Fragment>
      <ModalLayout
        modwidth="33%"
        heading={"Assign Patient"}
        content={
          <Modal.AssignPatient
            content={`${
              selectedPatient?.name
            } is being assigned to you${handleSwitch(
              selectedPatient?.program || ""
            )} to initiate ${selectedPatient?.program} services.`}
          />
        }
        open={openModal}
        onClose={() => setOpenModal(false)}
        buttonArr={CommonButtonArray(handleSave, handleClose, "Yes", "No")}
      />
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => setFilterData(data)}
        changeSearchText={(val) => setSearchText(val)}
        setFilterHeight={(val) => setFilterHeight(val)}
      />
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol)}
        height={tableHeight}
        loading={loading}
        Pagination={
          <ControlledTable.Pagination
            mainPage={page}
            limit={limit}
            total={totalRecord}
            handleClick={handleSetData}
            tableStyle={true}
          />
        }
      />
    </Fragment>
  );
};
export default PendingAssignment;
