import { ApiServiceProps } from "DataTypes/Services/Index.type";
import { post } from ".";

const CategorySearch: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient/${payload.id1}/category/search`,
    data: { data: payload.data },
    isRedox: true,
  };
  return post(request);
};

const DocDownload: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient/${payload.id1}/document/download`,
    data: { data: payload.data },
    isRedox: true,
    excel: true,
  };
  return post(request);
};

const DocIndex: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient/${payload.id1}/index/create`,
    isRedox: true,
  };
  return post(request);
};

const MedSearch: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient/${payload.id1}/medication/search`,
    data: { data: payload.data },
    isRedox: true,
  };
  return post(request);
};

const VisitSearch: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `patient/${data.id1}/visit/search`,
    isRedox: true,
  };
  return post(request);
};

export default {
  CategorySearch,
  DocDownload,
  DocIndex,
  MedSearch,
  VisitSearch,
};
