import {
  CPCatQuesOptType,
  CPQuesOptType,
} from "DataTypes/PatientManagement.type";
import { CPProblemArr } from "DataTypes/Services/Careplan.type";
import { ProblemListType } from "DataTypes/Services/Problem.type";
import CPFormatter from "Utils/CP.Formatter";
import { AscendSort, GetUniqueArr } from "Utils/common";

const QuestionList: (res: any) => CPCatQuesOptType[] = (res) => {
  const CheckArr = CPFormatter.CheckCarePlanQuesArr(res);
  const CatIdArr = CheckArr.map((el) => el.categoryId);
  const UniqueCatIdArr = GetUniqueArr(CatIdArr);
  const newArr: CPCatQuesOptType[] = [];
  UniqueCatIdArr.forEach((unEL) => {
    const findEl = CheckArr.find((checkEl) => checkEl.categoryId == unEL);
    if (findEl) {
      const filterQuestion = CheckArr.filter(
        (checkEl) => checkEl.categoryId == unEL
      );
      const QuestionArr: CPQuesOptType[] = filterQuestion.map((filEL) => {
        return {
          ...filEL,
          options: [],
          isChildRequiredOptAnswered: false,
          isChildAnswered: false,
        };
      });
      newArr.push({
        categoryId: findEl.categoryId,
        category: findEl.category,
        isAllRequiredQuestionAnswered: false,
        isAllRequiredQuesOptionAnswered: false,
        isQuestionAnsweringStart: false,
        questionList: QuestionArr,
      });
    }
  });
  return newArr;
};

const FormateUpdateQuestion: (
  res: any,
  question: CPQuesOptType
) => CPQuesOptType = (res, question) => {
  const verifyedres = CPFormatter.CheckCarePlanQuesUpdateObj(res);
  const newOptionArr = question.options.map((el) => {
    const find = verifyedres.careplanQuestionOption.find(
      (el1) => el1.optionId == el.id
    );
    return {
      ...el,
      careplanQuestionOptionId: !el.answered
        ? null
        : el.careplanQuestionOptionId
        ? el.careplanQuestionOptionId
        : find
        ? find.id
        : null,
    };
  });
  const newComment = question.questionComment.trim();
  const freeText = question.freeTextAnswer.trim();
  const isAnswered = freeText ? true : newOptionArr.some((el) => el.answered);
  question.isAnswered = isAnswered;
  question.careplanQuestionId = isAnswered ? verifyedres.id : null;
  question.options = newOptionArr;
  question.freeTextAnswer = freeText;
  question.questionComment = newComment;
  return question;
};

const SortCategoryQuestion: (
  originalArr: CPCatQuesOptType[],
  newArr: CPCatQuesOptType[]
) => CPCatQuesOptType[] = (originalArr, newArr) => {
  const lastArr: CPCatQuesOptType[] = originalArr.map((orEl) => {
    const find = newArr.find((newEl) => newEl.categoryId == orEl.categoryId);
    if (find) {
      orEl.questionList = [...orEl.questionList, ...find.questionList];
    }
    return orEl;
  });
  return lastArr;
};

const CheckCategory: (cat: CPCatQuesOptType) => CPCatQuesOptType = (cat) => {
  const newObj: CPCatQuesOptType = JSON.parse(JSON.stringify(cat));
  const find1 = newObj.questionList.find((el) => el.isAnswered);
  if (find1) newObj.isQuestionAnsweringStart = true;
  else newObj.isQuestionAnsweringStart = false;
  const filter1 = newObj.questionList.filter((el) => el.requiredQuestion);
  if (filter1.length > 0) {
    const filter2 = newObj.questionList.filter(
      (el) => el.requiredQuestion && el.isAnswered
    );
    if (filter1.length != filter2.length)
      newObj.isAllRequiredQuestionAnswered = false;
    else newObj.isAllRequiredQuestionAnswered = true;
    if (filter1.length > 0) {
      const filter3 = newObj.questionList.filter(
        (el) =>
          el.requiredQuestion && el.isAnswered && el.isRequiredOptionAnswered
      );
      if (filter1.length != filter3.length)
        newObj.isAllRequiredQuesOptionAnswered = false;
      else newObj.isAllRequiredQuesOptionAnswered = true;
    }
  } else {
    newObj.isAllRequiredQuestionAnswered = true;
    newObj.isAllRequiredQuesOptionAnswered = true;
  }
  return newObj;
};

const CheckSelectedCategory: (
  catArr: CPCatQuesOptType[],
  catId: number | null
) => CPCatQuesOptType[] = (catArr, catId) => {
  const newCatArr: CPCatQuesOptType[] = JSON.parse(JSON.stringify(catArr));
  const findCatIndex = newCatArr.findIndex((el) => el.categoryId == catId);
  if (findCatIndex != -1) {
    const newObj: CPCatQuesOptType = CheckCategory(newCatArr[findCatIndex]);
    newCatArr[findCatIndex] = newObj;
  }
  return newCatArr;
};

const CheckAllCategory: (catArr: CPCatQuesOptType[]) => {
  catArr: CPCatQuesOptType[];
  allCatSuccess: boolean;
} = (catArr) => {
  const newCatArr: CPCatQuesOptType[] = JSON.parse(JSON.stringify(catArr));
  let allCatSuccess = true;
  return {
    catArr: newCatArr.map((el) => {
      const newObj: CPCatQuesOptType = CheckCategory(el);
      if (
        !newObj.isAllRequiredQuestionAnswered ||
        !newObj.isAllRequiredQuesOptionAnswered
      )
        allCatSuccess = false;
      return newObj;
    }),
    allCatSuccess,
  };
};

const GetUniqueProblemArr: (
  problemArr: ProblemListType[],
  mappedArr: CPProblemArr[]
) => string[] = (problemArr, mappedArr) => {
  const newArr = AscendSort(problemArr, "name").map((el) => {
    const find = mappedArr.find((el1) => el1.problemId == el.id);
    return find ? el.name : "null";
  });
  return newArr.filter((el) => el != "null").sort();
};

///in testing
const FilterOutQuestions: (
  optArr: (number | null)[],
  arr: CPQuesOptType[],
  openIds: (number | null)[]
) => Promise<{
  quesList: CPQuesOptType[];
  openArr: (number | null)[];
}> = async (optArr, arr, openIds) => {
  const filterIdArr: (number | null)[] = [];
  let newQuesArr: CPQuesOptType[] = [];
  let newOpenIds: (number | null)[] = [];
  const Recursion: (
    optionArr: (number | null)[],
    tempIdArr: (number | null)[]
  ) => Promise<void> = async (optionArr, tempIdArr) => {
    if (optionArr.length > 0) {
      const filQuesIds = arr
        .filter((el) => {
          if (optionArr.some((el1) => el1 == el.parentOptionId)) return el;
        })
        .map((el) => {
          filterIdArr.push(el.questionsId);
          return el.questionsId;
        });
      await Recursion([], filQuesIds);
    } else if (tempIdArr.length > 0) {
      const filQuesIds = arr
        .filter((el) => {
          if (tempIdArr.some((el1) => el1 == el.parentQuestionId)) return el;
        })
        .map((el) => {
          filterIdArr.push(el.questionsId);
          return el.questionsId;
        });
      await Recursion([], filQuesIds);
    } else if (filterIdArr.length > 0) {
      newQuesArr = arr.filter((el) => {
        if (!filterIdArr.some((el1) => el1 == el.questionsId)) return el;
      });
      newOpenIds = openIds.filter((el) => {
        if (!filterIdArr.some((el1) => el1 == el)) return el;
      });
    } else {
      newQuesArr = arr;
      newOpenIds = openIds;
    }
  };
  await Recursion(optArr, []);
  return { quesList: newQuesArr, openArr: newOpenIds };
};

export default {
  QuestionList,
  FormateUpdateQuestion,
  SortCategoryQuestion,
  CheckSelectedCategory,
  CheckAllCategory,
  GetUniqueProblemArr,
  FilterOutQuestions,
};
