import { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import ImgPath from "Constant/Imgs";
import CstmBtn from "Components/CstmBtn";
import { DataArr } from "./Data";
import { FormDataType } from "DataTypes/Form";
import { LoginLayout, ModalLayout } from "Layouts";
import { commonFormSwitch } from "Utils/SwitchCases";
import { HandleChangeProps } from "DataTypes/Common";
import AuthApi from "Service/Auth.api";
import { SingleBtnArray } from "Components/Modal/Data";
import { CheckStringEmpty, ValidateFormData } from "Utils/common";
import { useDispatch } from "react-redux";
import { setAlertData } from "Redux/Screen/Action";

const Forgot: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState<FormDataType[] | []>(
    JSON.parse(JSON.stringify(DataArr))
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleChange: (props: HandleChangeProps) => void = ({ value, id }) => {
    const newArr: FormDataType[] = formData.map((el) => {
      if (el.id == id) el.value = value;
      return el;
    });
    setFormData(newArr ? newArr : []);
  };

  const handleSaveModal = () => {
    setOpenModal(false);
    navigate("/login");
  };

  const handleSubmit = async () => {
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData);
    if (!newArr.errorCheck) {
      setIsLoading(true);
      const payload = {
        userName: CheckStringEmpty(formData?.[0]?.value),
      };
      const res = await AuthApi.forget({ data: payload });
      if (res?.success) setOpenModal(true);
      setIsLoading(false);
    }
  };

  const rightBox = (
    <Box sx={{ ...sxRight }}>
      <img src={ImgPath.CV_Logo} alt="" />
      <Typography fontWeight={"bold"} variant="h6">
        Forget Password
      </Typography>
      {formData.map((el: FormDataType) => {
        return (
          <Box key={el.id}>
            {commonFormSwitch({ formObj: el, onChange: handleChange })}
          </Box>
        );
      })}
      <CstmBtn
        label={"Submit"}
        onClick={handleSubmit}
        width="30%"
        py={1.5}
        fontSize="1rem"
        loading={isLoading}
        loadingText="Loading..."
      />
      <Link onClick={() => navigate("/login")}>
        <ImgPath.BackArrowIcon color="primary" />
        <Typography fontWeight="bold">{"Back to Login"}</Typography>
      </Link>
    </Box>
  );

  return (
    <Fragment>
      <ModalLayout
        modwidth="30%"
        heading={""}
        content={
          <Typography>
            A password reset link was sent to your registered email. Click the
            link in the email to create a new password
          </Typography>
        }
        open={openModal}
        buttonArr={SingleBtnArray(handleSaveModal)}
      />

      <LoginLayout rightBox={rightBox} />
    </Fragment>
  );
};
export default Forgot;

const sxRight = {
  width: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "& >img:first-of-type": {
    width: "40%",
    mb: 2,
  },
  "& >div:nth-of-type(1)": {
    mt: 1,
    width: "60%",
    mb: 3.75,
  },
  "& >button:nth-of-type(1)": {
    mb: 1,
  },
  "& >a:last-child": {
    display: "flex",
    flexDirection: "row",
    textDecoration: "none",
    cursor: "pointer",
    "& >svg": {
      mr: 1,
    },
    ":hover": {
      "& >svg": {
        color: "textHover.main",
      },
      "& >p": {
        color: "textHover.main",
      },
    },
  },
};
