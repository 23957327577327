import { ControlledForm, ControlledTable } from "Components";
import BreadUrls from "Constant/BreadUrls";
import { BreadArrType } from "DataTypes/Common";
import { PatientInfoType } from "DataTypes/Redux";
import { columnType } from "DataTypes/Table";

export const Arr = [ControlledForm.FormData.AddNotes()];

export const Arr1 = [
  ControlledForm.FormData.Callfreq(),
  ControlledForm.FormData.CompletionDate(),
];

export const BreadData: (
  patient: PatientInfoType,
  isRecurring: boolean,
  isTCM: boolean,
  careplanId: number | null,
  recurringId: number | null,
  isCareplanComprehensive: boolean
) => BreadArrType[] = (
  patient,
  isRecurring,
  isTCM,
  careplanId,
  recurringId,
  isCareplanComprehensive
) => {
  if (isRecurring) {
    return [
      {
        id: 1,
        label: `${patient.name}`,
        path: BreadUrls.ClinicalSummary(patient.id),
      },
      {
        id: 2,
        label: "Questionnaire",
        path: BreadUrls.RecurringQues(patient.id, careplanId, recurringId),
      },
      {
        id: 3,
        label: "Summary",
        path: "",
      },
    ];
  }
  if (isTCM) {
    return [
      {
        id: 1,
        label: `${patient.name}`,
        path: BreadUrls.ClinicalSummary(patient.id),
      },
      {
        id: 2,
        label: "Questionnaire",
        path: BreadUrls.TCMCPQues(patient.id, careplanId),
      },
      {
        id: 3,
        label: "Team",
        path: BreadUrls.TCMCPTeam(patient.id, careplanId),
      },
      {
        id: 4,
        label: "Summary",
        path: "",
      },
    ];
  } else {
    return [
      {
        id: 1,
        label: `${patient.name}`,
        path: BreadUrls.ClinicalSummary(patient.id),
      },
      {
        id: 2,
        label: "Problems",
        path: BreadUrls.CPProbs(patient.id, careplanId),
      },
      ...(!isCareplanComprehensive
        ? [
            {
              id: 3,
              label: "Questionnaire",
              path: BreadUrls.CPQues(patient.id, careplanId),
            },
          ]
        : []),
      {
        id: 4,
        label: "Team",
        path: BreadUrls.CPTeam(patient.id, careplanId),
      },
      {
        id: 5,
        label: "Summary",
        path: "",
      },
    ];
  }
};

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.ProblemCol(),
  ControlledTable.ColumnData.GoalCol(),
  ControlledTable.ColumnData.InterventionCol(),
];
export const TCMTableCol: columnType[] = [
  ControlledTable.ColumnData.TcmProblemCol(),
  ControlledTable.ColumnData.TcmQuesCol(), //Separate column for tcm question in summary screen
];

export const RecurringCol: columnType[] = [
  ControlledTable.ColumnData.QuesAnsCol(),
];
