import { post } from ".";
import { ApiServiceProps } from "DataTypes/Services/Index.type";

const search: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/search`,
  };
  return post(request);
};

const tcmSummarySearch: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/tcm-summary`,
  };
  return post(request);
};

const careplanProblemUpdate: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `careplan/${payload.id1}/problem/update`,
    data: { data: payload.data },
  };
  return post(request);
};

const careplanProblemsRead: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `careplan/${payload.id1}/problem/search`,
  };
  return post(request);
};

const careplanTeamSave: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `careplan/${payload.id1}/team/update`,
    data: payload.data,
  };
  return post(request);
};

const careplanTeamSearch: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `careplan/${payload.id1}/team/search`,
  };
  return post(request);
};

const AdditionalProviderRemove: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/additionalprovider/${props.id2}/delete`,
  };
  return post(request);
};

const QuestionSearch: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `careplan/${data.id1}/question/search`,
    data: { data: data.data },
  };
  return post(request);
};

const QuesOptSearch: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `careplan/${data.id1}/question-option/search`,
    data: { data: data.data },
  };
  return post(request);
};

const QuesUpdate: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `careplan/${data.id1}/question/update`,
    data: { data: data.data },
  };
  return post(request);
};

const careplanSummary: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/summary`,
  };
  return post(request);
};

const careplanRecurringSummary: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/recurring-summary`,
  };
  return post(request);
};

const careplanRecurringsearch: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/recurring/${props?.id1}/search`,
  };
  return post(request);
};

const careplanSubmit: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/submit`,
    data: { data: props.data },
  };
  return post(request);
};

const SummarySave: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/save`,
    data: { data: props.data },
  };
  return post(request);
};

const CareplanPdfGenerate: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/summarypdf`,
    data: { data: props.data },
  };
  return post(request);
};

const TcmPdfGenerate: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/tcm-summarypdf`,
    data: { data: props.data },
  };
  return post(request);
};

const RecurringCPPdfGenerate: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/recurring-summarypdf`,
    data: { data: props.data },
  };
  return post(request);
};

const RecurringCPSubmit: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/recurring/${props.id1}/submit`,
    data: { data: props.data },
  };
  return post(request);
};

const CpNotes: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `careplan/${payload.id1}/recurringnotes/search`,
  };
  return post(request);
};

const CPPDFDownload: (payload: ApiServiceProps, fileType: string) => any = (
  payload,
  fileType
) => {
  const request = {
    subUrl: `careplan/${payload.id1}/getPDF/${fileType}`,
    excel: true,
  };
  return post(request);
};
const ConsentPDFDownload: (
  payload: ApiServiceProps,
  fileType: string
) => any = (payload, fileType) => {
  const request = {
    subUrl: `patient/${payload.id1}/getPDF/${fileType}`,
    excel: true,
  };
  return post(request);
};

const CPRecurringPDFDownload: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `careplan/${payload.id1}/recurring-summarypdf`,
    excel: true,
  };
  return post(request);
};

const CpQuestionStatusUpdate: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `careplan/${payload.id1}/questionarie-status/update`,
    data: { data: payload.data },
  };
  return post(request);
};

const RecurringCpQuestionStatusUpdate: (payload: ApiServiceProps) => any = (
  payload
) => {
  const request = {
    subUrl:
      `careplan/careplan-recurring/${payload.id1}` +
      "/questionarie-status/update",
    data: { data: payload.data },
  };
  return post(request);
};

export default {
  search,
  careplanProblemUpdate,
  careplanProblemsRead,
  careplanTeamSave,
  careplanTeamSearch,
  AdditionalProviderRemove,
  QuestionSearch,
  QuesOptSearch,
  QuesUpdate,
  careplanSubmit,
  careplanSummary,
  careplanRecurringSummary,
  CpNotes,
  CPPDFDownload,
  CpQuestionStatusUpdate,
  CPRecurringPDFDownload,
  RecurringCpQuestionStatusUpdate,
  RecurringCPSubmit,
  CareplanPdfGenerate,
  RecurringCPPdfGenerate,
  TcmPdfGenerate,
  ConsentPDFDownload,
  tcmSummarySearch,
  careplanRecurringsearch,

  //New Changes
  SummarySave,
};
