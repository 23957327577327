import { HeaderInitialStateType } from "DataTypes/Redux";
import { clearOrg } from "Redux/Header/Action";
import { ConvertStringToNum } from "Utils/common";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";

const OrganizationStaff: FC = () => {
  const { orgId } = useParams();
  const newOrgId = ConvertStringToNum(orgId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organization }: HeaderInitialStateType = useSelector(
    (state: any) => state?.header
  );
  useEffect(() => {
    return () => {
      dispatch(clearOrg());
    };
  }, []);
  newOrgId != organization.id && navigate(-1);
  return <Outlet />;
};
export default OrganizationStaff;
