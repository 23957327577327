import { Box } from "@mui/material";
import { CommonStyle, SubNavBar } from "Components";
import { label } from "Constant/En";
import { RouteUrls } from "Constant/RouteUrls";
import { SubNavDataType } from "DataTypes/Layout";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const ListManagement: FC = () => {
  const { myPermission }: any = useSelector((state: any) => state?.user);
  return (
    <Box height="91%">
      <SubNavBar data={TabArr(myPermission?.dashlinks)} />
      <Box
        height="92%"
        sx={{ ...CommonStyle.sxWhiteBoxWithNavbar, px: 2, overflow: "auto" }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
export default ListManagement;

const TabArr: (permission: any) => SubNavDataType[] = (permission) => {
  return [
    ...(permission?.eligible
      ? [
        {
          id: RouteUrls.eligible,
          label: label.eligible,
          link: `${RouteUrls.eligible}`,
        },
      ]
      : []),
    ...(permission?.pendingassign
      ? [
        {
          id: RouteUrls.pendingAssign,
          label: label.pcp,
          link: `${RouteUrls.pendingAssign}`,
        },
      ]
      : []),
    ...(permission?.enrolled
      ? [
        {
          id: RouteUrls.enrolled,
          label: label?.enrolledPatient,
          link: `${RouteUrls.enrolled}`,
        },
      ]
      : []),
    ...(permission?.mypatient
      ? [
        {
          id: RouteUrls.myPatients,
          label: label?.mp,
          link: `${RouteUrls.myPatients}`,
        },
      ]
      : []),
    ...(permission?.pendingapproval
      ? [
        {
          id: RouteUrls.pendingApproval,
          label: label.pendpr,
          link: `${RouteUrls.pendingApproval}`,
        },
      ]
      : []),
    ...(permission?.pending_care
      ? [
        {
          id: RouteUrls.pendingCare,
          label: label.continuousCare,
          link: `${RouteUrls.pendingCare}`,
        },
      ]
      : []),
    ...(permission?.completedcare
      ? [
        {
          id: RouteUrls.completedCare,
          label: label.ccc,
          link: `${RouteUrls.completedCare}`,
        },
      ]
      : []),
    ...(permission?.inactive
      ? [
        {
          id: RouteUrls.inactive,
          label: label.inactive,
          link: `${RouteUrls.inactive}`,
        },
      ]
      : []),
    ...(permission?.ongoing
      ? [
        {
          id: RouteUrls.ongoing,
          label: label.ongpt,
          link: `${RouteUrls.ongoing}`,
        },
      ]
      : []),
    ...(permission?.billing
      ? [
        {
          id: RouteUrls.billing,
          label: label.billing,
          link: `${RouteUrls.billing}`,
        },
      ]
      : []),
    ...(permission?.consent
      ? [
        {
          id: RouteUrls.consent,
          label: label.consent,
          link: `${RouteUrls.consent}`,
        },
      ]
      : []),
  ];
};
