import { useState, useEffect, Fragment, FC } from "react";
import { Box, Grid } from "@mui/material";
import ControlledTable from "Components/ControlledTable";
import { FormDataType } from "DataTypes/Form";
import {
  CPSummaryTableType,
  TCMSummaryTableType,
} from "DataTypes/Services/Careplan.type";
import { TableLayout } from "Layouts";
import {
  TableCol,
  TCMTableCol,
} from "Pages/Home/HeadScreen/PatientMgmt/CarePlan/Summary/Data";
import CarePlanApi from "Service/CarePlan.api";
import CPFormatter from "Utils/CP.Formatter";
import { Arr } from "./Data";
import { commonFormSwitch } from "Utils/SwitchCases";
import Loader from "Components/Loader";
import DateTime from "Utils/DateTime";
import { EligiblePrgmResType } from "DataTypes/Services/Patient.type";
import { ListInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

const CarePlan: FC<{ data: EligiblePrgmResType }> = ({ data }) => {
  const { frequencyList }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const { careplanId, abbrevation } = data;
  const [summary, setSummary] = useState<CPSummaryTableType[]>([]);
  const [formData, setFormData] = useState<FormDataType[]>(Arr);
  const [loading, setLoading] = useState(true);
  const [TcmSummary, setTcmSummary] = useState<TCMSummaryTableType[]>([]);

  useEffect(() => {
    handleReadApiCalls();
  }, []);

  const handleReadApiCalls: () => void = async () => {
    const newFormData: FormDataType[] = JSON.parse(JSON.stringify(formData));
    const searchRes = await CarePlanApi.search({ id1: careplanId });
    if (abbrevation == "TCM") {
      //TCM summary Implementation
      const TcmSearchRes = await CarePlanApi.tcmSummarySearch({
        id1: careplanId,
      });
      if (TcmSearchRes?.success) {
        const FormattedTcmRes = CPFormatter.FormateTcmSummary(
          TcmSearchRes?.data
        );
        setTcmSummary(FormattedTcmRes);
      }
    }
    if (searchRes?.success) {
      const FormatedRes = CPFormatter.CheckCPSearch(searchRes?.data);
      newFormData[0].value = FormatedRes.carePlanAdditionalNotes;
      newFormData[1].value = newFormData[1].dropValue =
        FormatedRes.careplan_frequency;
      newFormData[2].value = DateTime.UTCToLocalDateTime(
        FormatedRes.careplanCompletionDate
      );
    }
    const summaryRes = await CarePlanApi.careplanSummary({
      id1: careplanId,
    });
    if (summaryRes?.success) {
      const FormattedRes = CPFormatter.FormateCarePlanSummary(
        summaryRes?.data?.problem
      );
      setSummary(FormattedRes);
    }
    newFormData[1].dropArr = frequencyList;
    newFormData[0].readOnly =
      newFormData[1].readOnly =
      newFormData[2].readOnly =
        true;
    if (data.isCareplanComprehensive) newFormData[0].label = "Message";
    setFormData(newFormData);
    setLoading(false);
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={summary}
      verticalAlign="top"
    />
  );

  const TCMTblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TCMTableCol}
      tableRow={TcmSummary}
      verticalAlign="top"
    />
  );

  return (
    <Box display="flex" flexDirection="row">
      {loading ? (
        <Box width="100%">
          <Loader />
        </Box>
      ) : (
        <Fragment>
          {!data.isCareplanComprehensive && (
            <Box width={abbrevation != "TCM" ? "70%" : "100%"}>
              <TableLayout
                tblBody={abbrevation == "TCM" ? TCMTblBodyJSX : TblBodyJSX}
                tblHead={ControlledTable.TblHead(
                  abbrevation == "TCM" ? TCMTableCol : TableCol
                )}
                height={300}
              />
            </Box>
          )}
          {data.isCareplanComprehensive ? (
            <Grid container spacing={2}>
              {formData.map((formEl: FormDataType) => {
                return (
                  <Grid key={formEl.id} item sm={4}>
                    {commonFormSwitch({ formObj: formEl })}
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Box width="30%" sx={{ pl: 1, "&>div:first-of-type": { mb: 1 } }}>
              {formData.map((formEl) => {
                if (abbrevation == "TCM" && formEl.id == "call-freq") {
                  return <></>;
                }
                return (
                  <Fragment key={formEl.id}>
                    {commonFormSwitch({ formObj: formEl })}
                  </Fragment>
                );
              })}
            </Box>
          )}
        </Fragment>
      )}
    </Box>
  );
};
export default CarePlan;
