import ControlledForm from "Components/ControlledForm";
import { FormDataType } from "../../../../DataTypes/Form";

export const MdmArr = [
  {
    id: 1,
    label: "Low Complex",
    value: "low-complex",
    selected: false,
  },
  {
    id: 2,
    label: "Moderate Complex",
    value: "moderate-complex",
    selected: false,
  },
  {
    id: 3,
    label: "High Complex",
    value: "high-complex",
    selected: false,
  },
];

export const PersonalDataArr = () => {
  const Arr: FormDataType[] = [
    ControlledForm.FormData.FacilityName({readonly: true}),
    ControlledForm.FormData.InitialContact({readonly: true}),
    ControlledForm.FormData.FormOfContact({readonly: true}),
    ControlledForm.FormData.DateOfContact({readonly: true}),
    ControlledForm.FormData.TcmAppointment({readonly: true}),
    ControlledForm.FormData.DateOfAdmission({readonly: true}),
    ControlledForm.FormData.DateOfDischarge({readonly: true}),
    ControlledForm.FormData.MedicalDecisionMaking({readonly: true}),
    ControlledForm.FormData.PrimaryDiagnosis({readonly: true}),
    ControlledForm.FormData.SecondaryDiagnosis({readonly: true}),
    ControlledForm.FormData.TertiaryDiagnosis({readonly: true}),
    ControlledForm.FormData.DischargeDisposition({readonly: true}),
    ControlledForm.FormData.ReasonForHospitalization({readonly: true}),
    ControlledForm.FormData.TcmNotes({readonly: true}),
  ];
  return Arr;
};
