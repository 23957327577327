import { BreadArrType } from "DataTypes/Common";
import { ClickIconArrDataType, FormDataType } from "DataTypes/Form";

export const BreadArr: BreadArrType[] = [
  {
    id: 2,
    label: "Change Password",
    path: "",
  },
];

export const DataArr: FormDataType[] = [
  {
    id: "paa",
    label: "Old Password",
    placeholder: "Enter Old Password",
    boxType: "input-box",
    boxSize: 3,
    type: "password",
    validatetype: "description",
    iconId: "password-icon",
    iconClick: true,
    required: true,
  },
  {
    id: "new-paa",
    label: "New Password",
    placeholder: "Enter New Password",
    boxType: "input-box",
    boxSize: 3,
    type: "password",
    validatetype: "password",
    iconId: "password-icon",
    iconClick: true,
    required: true,
  },
  {
    id: "confirm-password",
    label: "Confirm Password",
    placeholder: "Confirm New Password",
    boxType: "input-box",
    boxSize: 3,
    type: "password",
    validatetype: "password",
    iconId: "password-icon",
    iconClick: true,
    required: true,
  },
];

export const IconClickArr = (handlePassClick: (id: string) => void) => {
  const arr: ClickIconArrDataType[] = [
    {
      iconId: "password-icon",
      onClick: (id: string) => handlePassClick(id),
    },
  ];
  return arr;
};
