import { FC, useEffect, useContext } from "react";
import { Box, IconButton } from "@mui/material";
import InfoCard from "Components/InfoCard";
import ProgramCard from "Components/ProgramCard";
import PatientViewCard from "Components/PatientViewCard";
import { BreadArr } from "./Data";
import CstmBtn from "Components/CstmBtn";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "Constant/RouteUrls";
import { HeadContext } from "Context/HeadContext";
import { HeadContextType } from "DataTypes/Common";
import { CommonStyle } from "Components";
import ImgPath from "Constant/Imgs";
import { UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { label } from "Constant/En";

const PatientDetails: FC = () => {
  const { setBreadArr, setHeadRightUI, setHeadLeftUI, setSpaceBtw } =
    useContext(HeadContext) as HeadContextType;
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const navigate = useNavigate();

  const HeadUI = (
    <Box display={"flex"} sx={{ "&>button": { ml: 1 } }}>
      <CstmBtn
        onClick={() => navigate(`../${RouteUrls.clinicalSummary}`)}
        label="Clinical Summary"
      />
    </Box>
  );

  const RightHeadUI = (
    <Box
      sx={{
        ...CommonStyle.sxRow,
        width: "100%",
      }}
    >
      <IconButton
        sx={{
          ...CommonStyle.sxRoundIconsMainMd,
          mr: 1,
        }}
        title={label.ct}
        onClick={() => navigate(`../${RouteUrls.task}/${RouteUrls.add}`)}
      >
        <ImgPath.CreateTaskIcon />
      </IconButton>
      <IconButton
        sx={{
          ...(myInfo.roleId == 1 || myInfo.roleId == 2
            ? CommonStyle.sxRoundIconsDisabledMd
            : CommonStyle.sxRoundIconsMainMd),
          mr: 1,
        }}
        title="Log Time"
        {...(myInfo.roleId != 1 &&
          myInfo.roleId != 2 && {
          onClick: () => navigate(`../${RouteUrls.addonTime}`),
        })}
      >
        <ImgPath.AddTimeLogIcon />
      </IconButton>
    </Box>
  );

  useEffect(() => {
    setBreadArr(BreadArr);
    setHeadRightUI(RightHeadUI);
    setHeadLeftUI(HeadUI);
    setSpaceBtw(true);
    return () => {
      setHeadLeftUI(<></>);
      setSpaceBtw(false);
    };
  }, []);

  return (
    <Box height="100%" width="100%">
      <Box display="flex" height={"40%"} flexDirection="row">
        <Box width={"50%"}>
          <InfoCard />
        </Box>
        <Box width={"50%"}>
          <ProgramCard />
        </Box>
      </Box>
      <Box height={"3%"}></Box>
      <Box height="57%" width="100%">
        <PatientViewCard />
      </Box>
    </Box>
  );
};
export default PatientDetails;
