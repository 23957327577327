import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import { SubNavDataType } from "DataTypes/Layout";

const SubNavBar: FC<{ data: SubNavDataType[] }> = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathArr = location.pathname.split("/");
  const childPath = pathArr[pathArr.length - 1];

  return (
    <Box height="8%" sx={{ ...sxMain }}>
      {data.map((el: SubNavDataType) => {
        return (
          <Typography
            variant="subtitle1"
            key={el.id}
            onClick={() => navigate(el.link)}
            sx={{
              ...(el.id == childPath && sxSelect),
              color: el.id == childPath ? "common.white" : "primary.main",
              backgroundColor:
                el.id == childPath ? "secondary.main" : "common.white",
            }}
          >
            {el.label}
          </Typography>
        );
      })}
    </Box>
  );
};
export default SubNavBar;

const sxMain = {
  display: "flex",
  flexDirection: "row",
  "& >h6": {
    ...CommonStyle.sxRowCenter,
    width: "9%",
    textAlign: "center",
    py: 1,
    px: 0.5,
    cursor: "pointer",
    borderRight: "0.5px solid",
    borderTop: "0.5px solid",
    borderBottom: "0.5px solid",
    borderColor: "custom.main",
    fontWeight: "bold",
    ":hover": {
      color: "textHover.main",
    },
    ":first-of-type": {
      borderLeft: "0.5px solid",
      borderColor: "custom.main",
      borderTopLeftRadius: 5,
    },
    ":last-of-type": {
      borderTopRightRadius: 5,
    },
  },
};

const sxSelect = {
  color: "select.main",
  borderColor: "select.main !important",
};
