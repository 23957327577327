import ControlledTable from "Components/ControlledTable";
import { TableLayout } from "Layouts";
import { Fragment } from "react";
import { TableCol } from "./Data";
import { RedoxInsuranceType } from "DataTypes/Services/Patient.type";

const Insurance = (InsuranceData: RedoxInsuranceType[]) => {
  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={InsuranceData}
    />
  );
  return (
    <Fragment>
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol)}
        height={""}
      />
    </Fragment>
  );
};
export default Insurance;
