import ControlledForm from "Components/ControlledForm";
import ControlledTable from "Components/ControlledTable";
import { BreadArrType } from "DataTypes/Common";
import { FormDataType } from "DataTypes/Form";
import { actionArrType, columnType } from "DataTypes/Table";

export const BreadArr: BreadArrType[] = [
  {
    id: 2,
    label: "Task",
    path: "",
  },
];

export const QualityDataArr: FormDataType[] = [
  {
    id: "canonicalName",
    label: "Measure Name",
    required: true,
    placeholder: "Enter Measure Name",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "org-name",
  },
  {
    id: "measureId",
    label: "Measure Id",
    placeholder: "Enter Measure Id",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "alphanumeric",
  },
  {
    id: "status",
    label: "Mark As",
    placeholder: "",
    boxSize: 6,
    boxType: "single-select-drop",
    validatetype: "dropdown",
    dropArr: [
      {
        id: 1,
        label: "Due",
        value: "due",
      },
      {
        id: 2,
        label: "Completed",
        value: "completed",
      },
    ],
    dropValue: "due",
    required: true,
  },
  {
    id: "dueDate",
    label: "Date",
    boxSize: 6,
    boxType: "mui-date",
    validatetype: "date",
    required: true,
  },
  ControlledForm.FormData.Notes(),
];

export const TableCol: columnType[] = [
  {
    id: "takenOn",
    label: "Date",
    width: "20%",
    align: "left",
    colType: "text",
    rowType: "date",
  },
  {
    id: "note",
    label: "Notes",
    width: "70%",
    align: "left",
    colType: "text",
    rowType: "qualitynotes",
  },
  {
    id: "action",
    label: "",
    width: "10%",
    align: "right",
    colType: "text",
    rowType: "action",
  },
];

export const actionArr: (handleEdit: () => void) => actionArrType[] = (
  handleEdit
) => {
  return [ControlledTable.ActionData.viewTitleAction(handleEdit)];
};
