// export const GET_USER_LIST = "GET_USER_LIST";
// export const STORE_USER_LIST = "STORE_USER_LIST";
// export const ADD_USER = "ADD_USER";
// export const EDIT_USER = "EDIT_USER";
// export const DELETE_USER = "DELETE_USER";
// export const LOGGED_USER = "LOGGED_USER";
// export const USER_PERMISSION = "USER_PERMISSION";
export const SET_MY_INFO = "SET_MY_INFO";
export const CLEAR_MY_INFO = "CLEAR_MY_INFO";
export const SET_MY_PERMISSION = "SET_MY_PERMISSION";
export const CLEAR_MY_PERMISSION = "CLEAR_MY_PERMISSION";
export const CLEAR_USER = "CLEAR_USER";
