import { FC, useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Badge, Box, ClickAwayListener, Typography } from "@mui/material";
import ImgPath from "Constant/Imgs";
import { ProfileArr, HeadArr } from "./Data";
import { CommonStyle, ControlledForm } from "Components";
import DropBox from "./DropBox";
import { useSelector } from "react-redux";
import { HeadContext } from "Context/HeadContext";
import { HeadContextType, HeaderType } from "DataTypes/Common";
import { ListInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { RouteUrls } from "Constant/RouteUrls";
import { useDispatch } from "react-redux";
import { addOrg } from "Redux/Header/Action";
import { addNotificationList } from "Redux/List/Action";
import { NotificationType } from "DataTypes/Notification";
import NotificationBox from "./NotificationDropBox";
import Notification from "Service/Notification";

const MainHeader: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const { myPermission, myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const name = myInfo.firstname
    ? `${myInfo.firstname}${myInfo.lastname ? " " + myInfo.lastname : ""}${
      myInfo.designation ? ", " + myInfo.designation : ""
    }`
    : "";
  const { notificationList }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const { setBreadArr, setHeadRightUI } = useContext(
    HeadContext
  ) as HeadContextType;
  const [selectId, setSelectId] = useState("");
  const [dropId, setDropId] = useState("");
  const [globalSearchValue, setGlobalSearchValue] = useState<any>("");
  const [data, setData] = useState<NotificationType[]>([]);

  useEffect(() => {
    handleNotification();
  }, [notificationList]);

  const handleNotification: () => void = () => {
    setData(JSON.parse(JSON.stringify(notificationList)));
  };

  const handleNotificationClick: (notifData: NotificationType) => void = async (
    notifData
  ) => {
    const res = await Notification.markAsRead({ id1: notifData.id });
    if (res?.success) {
      const newArr = data.filter((el) => el.id != notifData.id);
      dispatch(addNotificationList(newArr));
      if (notifData.patientId) {
        navigate(
          `/${RouteUrls.hs}/${RouteUrls.patient}/${notifData.patientId}/${RouteUrls.clinicalSummary}`
        );
      } else navigate(`${RouteUrls.hs}/${RouteUrls.notifications}`);
    }
  };

  const handleEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      navigate(`${RouteUrls.hs}/${RouteUrls.allPatients}`, {
        state: { keyword: globalSearchValue },
      });
      setGlobalSearchValue("");
    }
  };

  useEffect(() => {
    setBreadArr([]);
    setHeadRightUI(<></>);
  }, [pathname]);

  useEffect(() => {
    setSelectId(location.pathname.split("/")[1]);
  }, [location]);

  const HeaderIconSwitch: (el: HeaderType) => JSX.Element = (el) => {
    switch (el.icon) {
      case "home":
        return <ImgPath.HomeIcon />;
      case "mystaff":
        return <ImgPath.UserIcon />;
      case "master":
        return <ImgPath.MasterIcon />;
      case "org":
        return <ImgPath.OrganisationIcon />;
      case "settings":
        return <ImgPath.SettingsIcon />;
      default:
        return <ImgPath.HomeIcon />;
    }
  };

  return (
    <Box sx={{ ...sxMain, ...CommonStyle.sxRow }}>
      <Box width="8%" sx={{ ...CommonStyle.sxRowCenter }}>
        <img width="80%" src={ImgPath.CV_Logo_Mid} alt="" />
      </Box>
      <Box sx={{ ...CommonStyle.sxRow, ...sxCenterBox }}>
        {HeadArr(myInfo, myPermission?.headlinks).map((el) => {
          return (
            <ClickAwayListener
              key={el.id}
              onClickAway={() => dropId == el.id && setDropId("")}
            >
              <Box
                onClick={() => {
                  if (el.dropArr.length > 0) {
                    setDropId(dropId == el.id ? "" : el.id);
                  } else {
                    if (el.id == "organization-staff") {
                      dispatch(
                        addOrg({
                          id: myInfo.practiceId,
                          name: myInfo.practiceName,
                          masterPractice: myInfo.isMasterPractice,
                          modelId: myInfo.practiceModelId,
                          modelName: myInfo.practiceModelName,
                        })
                      );
                    }
                    setSelectId(el.id);
                    navigate(el.path);
                  }
                }}
                sx={{
                  ...CommonStyle.menuLinkBorder,
                  ...(el.id == selectId && CommonStyle.sxSelect),
                }}
              >
                {HeaderIconSwitch(el)}
                <Box sx={{ py: 0.5 }}>
                  <Typography variant="body2">{el.label}</Typography>
                </Box>
                {el.dropArr && dropId == el.id && (
                  <DropBox
                    dataArr={el.dropArr}
                    width="300%"
                    position="center"
                    changeDropState={dropId == el.id ? true : false}
                    onClick={() => setSelectId(el.id)}
                  />
                )}
              </Box>
            </ClickAwayListener>
          );
        })}
      </Box>
      {myPermission?.headlinks?.patientglobalserach ? (
        <Box sx={{ ...CommonStyle.sxRow, ...sxMid }}>
          <ControlledForm.Input
            id="patient-search"
            placeholder="Patient Search"
            iconId="search"
            value={globalSearchValue}
            onChange={(value) => {
              setGlobalSearchValue(value);
            }}
            iconClickHandler={() => {
              navigate(`${RouteUrls.hs}/${RouteUrls.allPatients}`, {
                state: { keyword: globalSearchValue },
              });
              setGlobalSearchValue("");
            }}
            handleEnter={(e) => handleEnterClick(e)}
          />
        </Box>
      ) : (
        <></>
      )}
      <Box sx={{ ...CommonStyle.sxRow, ...sxRight }}>
        {myPermission?.headlinks?.notifications && (
          <ClickAwayListener
            onClickAway={() => dropId == "notification" && setDropId("")}
          >
            <Box
              sx={{ ...CommonStyle.sxRow, mr: 2 }}
              onClick={() => {
                if (data.filter((el) => !el?.isNotificationRead).length > 0) {
                  setDropId(dropId == "notification" ? "" : "notification");
                } else {
                  navigate(`${RouteUrls.hs}/${RouteUrls.notifications}`);
                }
              }}
            >
              <Box sx={{ ...sxBadgeBox }}>
                <Badge
                  badgeContent={
                    data.filter((el) => !el?.isNotificationRead).length
                  }
                  color="secondary"
                >
                  <ImgPath.NotificationIcon color="primary" />
                </Badge>
              </Box>
              <Box sx={{ ...sxnotificationBox }}>
                <NotificationBox
                  dataArr={data}
                  changeDropState={dropId == "notification" ? true : false}
                  handleClick={(data) => handleNotificationClick(data)}
                />
              </Box>
            </Box>
          </ClickAwayListener>
        )}
        <ClickAwayListener
          onClickAway={() => dropId == "profile" && setDropId("")}
        >
          <Box
            sx={{ ...CommonStyle.sxRow }}
            onClick={() => setDropId(dropId == "profile" ? "" : "profile")}
          >
            <Typography variant="subtitle1">{name}</Typography>
            <Box sx={{ ...ProfileBagde }}>
              <ImgPath.PersonIcon color="primary" />
            </Box>
            <DropBox
              dataArr={ProfileArr(myInfo, myPermission?.headlinks)}
              width="170px"
              position="right"
              changeDropState={dropId == "profile" ? true : false}
              onClick={() => console.log("test")}
            />
          </Box>
        </ClickAwayListener>
      </Box>
    </Box>
  );
};
export default MainHeader;

const sxMain = {
  height: "8%",
  width: "100%",
  // boxShadow: "0px -2px 6px #00000029 inset",
};

const sxCenterBox = {
  width: "57%",
  height: "100%",
  "& >div": {
    ...CommonStyle.sxRowCenter,
    height: "100%",
    position: "relative",
    cursor: "pointer",
    mr: 3,
    "& >svg": {
      mr: 0.5,
      fontSize: "1.5rem",
    },
    ":first-of-type": {
      ml: 2,
    },
  },
};

const sxMid = {
  width: "15%",
  justifyContent: "center",
};

const sxRight = {
  justifyContent: "flex-end",
  width: "20%",
  height: "100%",
  pr: 1,
  "& >div:first-of-type": {
    height: "100%",
    cursor: "pointer",
    position: "relative",
    mr: 2,
    "& span": {
      color: "common.white",
      fontSize: "0.7rem",
    },
  },
  "& >div:nth-of-type(2), & >div:nth-of-type(3)": {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    position: "relative",
    cursor: "pointer",
    "& >div:first-of-type": {},
  },
};
const sxnotificationBox = {
  "& >div": {
    "&:before": {
      content: "''",
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "10px solid #ee7404",
      transform: "rotate(0deg)",
      backgroundColor: "transparent",
      top: "-10px",
      right: "2px",
      position: "absolute",
    },
    "& >div": {
      pl: 0,
      pr: 0,
      pt: 0,
      maxHeight: "20rem",
      overflow: "auto",
      h6: {
        borderRadius: 0,
        padding: "1em 0.6em",
        "&:hover": {
          backgroundColor: "#e7e7e791",
        },
        "&:last-child": {
          borderBottom: "none",
        },
      },
    },
  },
};

const ProfileBagde = {
  border: "1px solid",
  borderColor: "secondary.main",
  height: "1.5em",
  width: "1.5em",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  ml: 1,
  "& svg": {
    fontSize: "1rem",
  },
};

const sxBadgeBox = {
  span: {
    color: "common.white",
  },
};
