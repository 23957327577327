import { post } from ".";

export const list: () => any = () => {
  const request = {
    subUrl: "business-model/search",
  };
  return post(request);
};

export default { list };
