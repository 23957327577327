import { OrganizationInitialStateType } from "DataTypes/Redux";
import {
  ADD_MASTER_CAT,
  CLEAR_MASTER,
  CLEAR_HEADER,
  ADD_MASTER_OPENARR,
  ADD_ORG,
  CLEAR_ORG,
} from "./type";

export const addMasterCatId: (catId: number | null) => {
  type: string;
  payload: number | null;
} = (catId) => {
  return {
    type: ADD_MASTER_CAT,
    payload: catId,
  };
};

export const addMasterOpenArr: (openArr: number[]) => {
  type: string;
  payload: number[];
} = (openArr) => {
  return {
    type: ADD_MASTER_OPENARR,
    payload: openArr,
  };
};

export const clearMaster: () => {
  type: string;
} = () => {
  return {
    type: CLEAR_MASTER,
  };
};

export const addOrg: (data: OrganizationInitialStateType) => {
  type: string;
  payload: OrganizationInitialStateType;
} = (data) => {
  return {
    type: ADD_ORG,
    payload: data,
  };
};

export const clearOrg: () => {
  type: string;
} = () => {
  return {
    type: CLEAR_ORG,
  };
};

export const clearHeader: () => {
  type: string;
} = () => {
  return {
    type: CLEAR_HEADER,
  };
};
