import {
  UserReadApiType,
  UserRolePermissionApiType,
} from "DataTypes/Services/User.type";
import { FC, useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import UserApi from "Service/User.api";
import Formate from "./Formate";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import { FormDataType } from "DataTypes/Form";
import { DetailForm } from "./Data";
import { HandleFormChange, ValidateFormData } from "Utils/common";
import { HeadContextType } from "DataTypes/Common";
import CstmBtn from "Components/CstmBtn";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import { label } from "Constant/En";
import { ControlledBoxSizeSwitch, commonFormSwitch } from "Utils/SwitchCases";
import { useNavigate } from "react-router-dom";
import PermissionApi from "Service/Permission.api";
import { HeadContext } from "Context/HeadContext";
import { ListInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import UserFormatter from "Utils/Users.Formatter";
import { setMyInfo } from "Redux/User/Action";
import DateTime from "Utils/DateTime";

const MyProfile: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const { roleDropList: roleList, genderList }: ListInitialStateType =
    useSelector((state: any) => state?.list);
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const [myData, setMyData] = useState<UserReadApiType | null>(null);
  const [uniqueMailIds, setUniqueMailIds] = useState<string[]>([]);
  const [form1, setForm1] = useState<FormDataType[]>(
    JSON.parse(JSON.stringify(DetailForm([], [])))
  );
  const [permissionArr, setPermissionArr] = useState<
    UserRolePermissionApiType[]
  >([]);
  const [ccnotes, setCCNotes] = useState(false);
  const [threshold, setThreshold] = useState(false);

  useEffect(() => {
    setBreadArr([
      {
        id: 1,
        label: "My Profile",
        path: "",
      },
    ]);
    onLoad();
  }, []);

  const onLoad: () => void = async () => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    let FormatedPermission: UserRolePermissionApiType[] = [];
    if (permissionArr.length < 1) {
      const payload = {
        isUserlevelPermission: false,
        isDeleted: false,
      };
      const permissionRes = await PermissionApi.list({ data: payload });
      if (permissionRes?.success) {
        FormatedPermission = UserFormatter.VerifyUserRolePermissionResponse(
          permissionRes?.data
        );
        setPermissionArr(FormatedPermission);
      }
    }
    const userRes = await UserApi.read({ id1: myInfo.id });
    if (userRes?.success) {
      const formateUser = UserFormatter.VerifyReadResponse(userRes?.data);
      const payload = {
        data: {
          isDeleted: null,
        },
      };
      const res: any = await UserApi.list(payload);
      if (res?.success) {
        const newArr = UserFormatter.VerifyListArrResponse(res?.data);
        const uniqueMail = Formate.FindUniqueFromUser(newArr, formateUser);
        setUniqueMailIds(uniqueMail);
        const localMyDetailsForm: FormDataType[] = JSON.parse(
          JSON.stringify(DetailForm(roleList, genderList, uniqueMailIds))
        );
        if (formateUser) {
          const findCCNotesObj = FormatedPermission.find(
            (forPreEl) => forPreEl.slug == "pullforwardccnote:accesslevel"
          );
          if (findCCNotesObj) {
            const findCC = formateUser.permission.find(
              (myperEL) => myperEL.id == findCCNotesObj.id
            );
            if (findCC) setCCNotes(true);
          }
          const findThresObj = FormatedPermission.find(
            (forPreEl) => forPreEl.slug == "userpatientthreshold:accesslevel"
          );
          if (findThresObj) {
            const findTh = formateUser.permission.find(
              (myperEL) => myperEL.id == findThresObj.id
            );
            if (findTh) setThreshold(true);
          }
          setForm1(Formate.MapUserToForm(formateUser, localMyDetailsForm));
        }
      } else navigate("../");
      setMyData(formateUser);
    } else navigate("../");
    dispatch(stopLoading());
  };

  const handleSubmit = async () => {
    const newArr = ValidateFormData(form1);
    setForm1(newArr.formData);
    if (!newArr.errorCheck) {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      const newpermissionArr = permissionArr.filter((el) => {
        if (el.slug == "pullforwardccnote:accesslevel" && ccnotes) return el;
        if (el.slug == "userpatientthreshold:accesslevel" && threshold)
          return el;
      });
      const payload = Formate.ApiPayload(
        form1,
        newpermissionArr,
        myData?.practice || [],
        myData
      );
      const res = await UserApi.update({ id1: myData?.id, data: payload });
      if (res?.success) {
        const timezone = payload.timezone;
        const utcDiff = DateTime.HoursTimeZoneSwitch(timezone);
        const { date, datetime } = DateTime.TimeZoneRegionSwitch(timezone);
        dispatch(
          setMyInfo({
            ...myInfo,
            firstname: payload.firstName,
            lastname: payload.lastName,
            designation: payload.designation,
            timezone,
            dateFormat: date,
            dateTimeFormat: datetime,
            utcDiff: utcDiff == 0 ? null : utcDiff,
          })
        );
        dispatch(setAlertData(res?.alert));
        navigate("../");
      }
      dispatch(stopLoading());
    }
  };

  return (
    <Box height="91%" sx={{ ...CommonStyle.sxWhiteBox, overflow: "auto" }}>
      <Box sx={{ p: 2 }}>
        <Typography fontWeight="bold">{label.personalInfo}</Typography>
        <Grid container rowSpacing={1} columnSpacing={2}>
          {form1.length > 0
            ? form1.map((el: FormDataType) => {
              return (
                <Grid
                  key={el.id}
                  item
                  {...ControlledBoxSizeSwitch(el.boxSize)}
                >
                  {commonFormSwitch({
                    formObj: el,
                    onChange: (props) =>
                      setForm1(HandleFormChange(props, form1)),
                  })}
                </Grid>
              );
            })
            : null}
        </Grid>
        <Divider sx={{ mt: 2, mb: 1 }} />
        <Box
          justifyContent="flex-end"
          sx={{
            ...CommonStyle.sxRow,
            "& >button:first-of-type": {
              mr: 1,
            },
          }}
        >
          <CstmBtn
            sxProps={{ mr: 1 }}
            label="Save"
            onClick={handleSubmit}
            width="10%"
          />
          <CstmBtn
            label="Cancel"
            onClick={() => navigate("../")}
            width="10%"
            btnType="border"
          />
        </Box>
      </Box>
    </Box>
  );
};
export default MyProfile;
