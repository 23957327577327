import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { TableCol } from "./Data";
import { ControlledTable } from "Components";
import { TableLayout } from "Layouts";
import { RouteUrls } from "Constant/RouteUrls";
import { rowClickType } from "DataTypes/Table";
import { PatientInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { TaskListType } from "DataTypes/Services/Tasks";

const Tasks: FC<{
  data: TaskListType[];
  loading: boolean;
  onClose: () => void;
}> = ({ data, loading, onClose }) => {
  const navigate = useNavigate();
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const newPatientId = patientInfo.id;
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  
  const rowClick: (props: rowClickType) => void = ({ rowData }) => {
    const newData: TaskListType = rowData;
    onClose();
    const base = `/${RouteUrls.hs}/${RouteUrls.patient}/${newPatientId}/`;
    if (newData?.status == "Open") {
      if (newData?.createdbyId == myInfo.id) {
        navigate(
          `${base}${RouteUrls.tasking}/${RouteUrls.outgoing}/${newData?.id}/${RouteUrls.update}`
        );
      } else if (newData?.assigneeId == myInfo.id) {
        navigate(
          `${base}${RouteUrls.tasking}/${RouteUrls.incoming}/${newData?.id}/${RouteUrls.update}`
        );
      } else {
        navigate(`${base}${RouteUrls.task}/${newData?.id}/${RouteUrls.View}`);
      }
    } else {
      navigate(`${base}${RouteUrls.task}/${newData?.id}/${RouteUrls.View}`);
    }
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol(rowClick)}
      tableRow={data}
    />
  );

  return (
    <Box sx={{ ...sxMain }}>
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol(rowClick))}
        height={"95%"}
        loading={loading}
      />
    </Box>
  );
};
export default Tasks;

const sxMain = {
  height: "100%",
  overflow: "auto",
  px: 2,
  "&>div": {
    pt: 2,
  },
};
