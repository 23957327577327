import { FC, Fragment, useEffect, useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ControlledForm from "Components/ControlledForm";
import { SideModalProps } from "DataTypes/Modal";
import Category from "./Category";
import Time from "./Time";
import { CheckStringEmpty } from "Utils/common";
import TabPanel from "Components/TabPanel";
import { CommonStyle } from "Components";
import ImgPath from "Constant/Imgs";

import { setAlertData } from "Redux/Screen/Action";
import AddModal from "./AddModal";
import { useUploadForm } from "Hooks/useUploadFile";
import RedoxFormatter from "Utils/Redox.Formatter";
import Format from "./Format";

import { CategoryArr } from "./Data";

import RedoxApi from "Service/Redox.api";
import PatientApi from "Service/Patient.api";

import { PatientInitialStateType } from "DataTypes/Redux";
import { objectValues } from "DataTypes/Services/Index.type";
import { RedoxCategoryType } from "DataTypes/PatientManagement.type";
import ViewModal from "./ViewModal";
import { CatSearchDocType } from "DataTypes/Services/Redox.type";

const Find: FC<SideModalProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [openModal, setOpenModal] = useState(false);
  const [tabValue, setTabValue] = useState(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [indexLoading, setIndexLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [categoryType, setCategoryType] = useState<string | null>(null);
  const [categoryArr, setCategoryArr] = useState<RedoxCategoryType[]>(
    JSON.parse(JSON.stringify(CategoryArr))
  );
  const [timeTabData, setTimeTabData] = useState<objectValues[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalType, setModalType] = useState("");
  const [viewDoc, setViewDoc] = useState<CatSearchDocType | null>(null);

  const { uploadForm, progress, setProgress, inProgress } = useUploadForm(
    `${process.env.REACT_APP_BASE_REDOX_URL}/redox/patient/${patientInfo.id}/document/${categoryType}/upload`
  );

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    if (searchValue == "") {
      tabValue == 1 ? handleCatApiCall() : handleTimeApiCall();
    }
    setSearchValue("");
  }, [tabValue]);

  useEffect(() => {
    setSelectedFile(null);
  }, [categoryType]);

  useEffect(() => {
    setProgress(0);
  }, [selectedFile]);

  useEffect(() => {
    tabValue == 1 ? handleCatApiCall() : handleTimeApiCall();
  }, [searchValue]);

  const handleCatApiCall: () => void = async () => {
    setLoading(true);
    const payload = { keyword: searchValue, limit: 100, offset: 0 };
    const res = await RedoxApi.CategorySearch({
      data: payload,
      id1: patientInfo.id,
    });
    if (res?.success) {
      const CheckRes = RedoxFormatter.CategorySearch(res?.data);
      setCategoryArr(Format.FormatCatSearch(CheckRes, categoryArr));
    } else setCategoryArr(JSON.parse(JSON.stringify(CategoryArr)));
    setLoading(false);
  };

  const handleTimeApiCall = async () => {
    setLoading(true);
    const payload = { keyword: searchValue };
    const res: any = await PatientApi.timeSeries({
      data: payload,
      id1: patientInfo.id,
      offset: 0,
    });
    if (res?.success) setTimeTabData(res?.data);
    setLoading(false);
  };

  const handleDocIndexing: () => void = async () => {
    setIndexLoading(true);
    const res = await RedoxApi.DocIndex({ id1: patientInfo.id });
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      tabValue == 1 && handleCatApiCall();
    }
    setIndexLoading(false);
  };

  const hanldeImportFile: () => void = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      const res = await uploadForm({
        data: formData,
      });
      if (res?.success) {
        res?.alert && dispatch(setAlertData(res.alert));
        tabValue == 1 && handleCatApiCall();
      }
    }
  };

  useEffect(() => {
    setModalType(viewDoc ? "view" : "");
  }, [viewDoc]);

  useEffect(() => {
    setOpenModal(modalType ? true : false);
  }, [modalType]);

  const handleCloseModal: () => void = () => {
    setModalType("");
    setViewDoc(null);
    setSelectedFile(null);
    setCategoryType(null);
  };

  const Layer1 = (
    <Fragment>
      <Box sx={{ ...sxHeader, ...CommonStyle.sxRow }}>
        <Typography fontWeight="bold">Find</Typography>
        <Box display={"flex"}>
          {tabValue == 1 && (
            <Fragment>
              <Box
                sx={{ ...CommonStyle.sxRoundIconsMainSm }}
                onClick={() => setModalType("upload")}
              >
                <ImgPath.ImportIcon titleAccess="Document Upload" />
              </Box>
              <Box
                sx={{
                  ...(indexLoading
                    ? CommonStyle.sxRoundIconsDisabledSm
                    : CommonStyle.sxRoundIconsMainSm),
                  mx: 1,
                }}
                {...(!indexLoading && { onClick: handleDocIndexing })}
              >
                <ImgPath.RefreshIcon titleAccess="Indexing" />
              </Box>
            </Fragment>
          )}

          <Box sx={{ ...CommonStyle.sxRoundIconsMainSm }} onClick={onClose}>
            <ImgPath.CloseIcon titleAccess="Close" />
          </Box>
        </Box>
      </Box>
      <Box height="10%" px={2}>
        <ControlledForm.Input
          id="search"
          placeholder="Search"
          iconId="search"
          onChange={(val) => setSearchValue(CheckStringEmpty(val))}
          value={searchValue}
        />
      </Box>
      <Box sx={{ ...sxTab }}>
        <Tabs
          value={tabValue}
          onChange={(event, index) => {
            setTabValue(index);
          }}
          aria-label="basic tabs example"
          variant="fullWidth"
          indicatorColor="secondary"
        >
          <Tab label="Time" {...a11yProps(0)} />
          <Tab label="Category" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0} style={{ ...sxTabPanel }}>
        <Time data={timeTabData} loading={loading} />
      </TabPanel>
      <TabPanel value={tabValue} index={1} style={{ ...sxTabPanel }}>
        <Category
          data={categoryArr}
          loading={loading}
          setViewDoc={(data) => setViewDoc(data)}
        />
      </TabPanel>
    </Fragment>
  );

  return (
    <Fragment>
      <Box width="28%">{Layer1}</Box>
      <Box width="1%" />
      {openModal && (
        <Box width="71%">
          {modalType == "upload" && (
            <AddModal
              changeFile={(data) => setSelectedFile(data)}
              fileName={selectedFile?.name || ""}
              progress={progress}
              inProgress={inProgress}
              handleClose={handleCloseModal}
              handleUpload={hanldeImportFile}
              changeCategory={(val) => setCategoryType(val)}
              categoryVal={categoryType}
            />
          )}
          {modalType == "view" && viewDoc && (
            <ViewModal file={viewDoc} handleClose={handleCloseModal} />
          )}
        </Box>
      )}
    </Fragment>
  );
};

export default Find;

const sxHeader = {
  justifyContent: "space-between",
  height: "10%",
  px: 2,
};

const sxTab = {
  px: 2,
  "&>div": {
    borderBottom: 1,
    borderColor: "custom.main",
  },
};

const sxTabPanel = {
  height: "65%",
};
