export const STORE_MODEL_DROP_LIST = "STORE_MODEL_DROP_LIST";
export const STORE_PROGRAM_DROP_LIST = "STORE_PROGRAM_DROP_LIST";
export const STORE_PROGRAM_LIST = "STORE_PROGRAM_LIST";
export const STORE_ROLE_DROP_LIST = "STORE_ROLE_DROP_LIST";
export const CLEAR_LIST = "CLEAR_LIST";
export const STORE_NOTIFICATION_LIST = "STORE_NOTIFICATION_LIST";
export const STORE_GENDER_LIST = "STORE_GENDER_LIST";
export const STORE_QUALITY_LIST = "STORE_QUALITY_LIST";
export const STORE_CALLRESULT_LIST = "STORE_CALLRESULT_LIST";
export const STORE_FREQUENCY_LIST = "STORE_FREQUENCY_LIST";
