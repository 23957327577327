import { Box, Tab, Tabs, Typography } from "@mui/material";
import { SideModalProps } from "DataTypes/Modal";
import { FC, Fragment, useEffect, useState } from "react";
import { CommonStyle } from "Components";
import ImgPath from "Constant/Imgs";
import TabPanel from "Components/TabPanel";
import TestDue from "./TestDue";
import TestCompleted from "./TestCompleted";
import { QualityMeasureType } from "DataTypes/Services/Patient.type";
import {
  CheckStringEmpty,
  HandleFormChange,
  ValidateFormData,
} from "Utils/common";
import SwitchCase from "Utils/SwitchCase";
import PatientFormatter from "Utils/Patient.Formatter";
import AddModal from "./AddModal";
import { QualityDataArr } from "./Data";
import { FormDataType } from "DataTypes/Form";
import QualityApi from "Service/Quality.api";
import DateTime from "Utils/DateTime";
import { useDispatch } from "react-redux";
import { setAlertData } from "Redux/Screen/Action";
import { HandleChangeProps } from "DataTypes/Common";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

const Quality: FC<SideModalProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [tabValue, setTabValue] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState<FormDataType[]>([]);
  const [editData, setEditData] = useState<QualityMeasureType | null>(null);
  const [list, setList] = useState<QualityMeasureType[]>([]);
  const [loading, setLoading] = useState(true);
  const [modalLoad, setModalLoad] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    fetchQualitySearch();
    setEditData(null);
    setFormData([]);
  }, [tabValue]);

  const fetchQualitySearch: () => void = async () => {
    const data = {
      listType: tabValue === 0 ? "due_test" : "completed_test",
    };
    const res: any = await QualityApi.Search({
      data,
      id1: patientInfo.id,
      offset: 0,
      limit: 100,
    });
    if (res?.success) {
      setList(PatientFormatter.CheckQualitySearch(res?.data));
    }
    setLoading(false);
  };

  const searchQualityName: (data: string) => Promise<boolean> = async (
    data
  ) => {
    const res: any = await QualityApi.NameExist({
      data: { canonicalName: data },
      id1: patientInfo.id,
    });
    if (res?.success) {
      if (res?.data) return true;
      else return false;
    } else return false;
  };

  const handleChange: (
    props: HandleChangeProps,
    arr: FormDataType[]
  ) => void = async (props, arr) => {
    const { id, value } = props;
    if (id == "canonicalName") {
      arr[0].notUnique = await searchQualityName(CheckStringEmpty(value));
      const newFormArr = arr.map((el) => {
        if (el.id == id) {
          const temp = SwitchCase.FormValidation(el);
          el.error = temp.error;
          el.errorText = temp.errorText;
        }
        return el;
      });
      setFormData(newFormArr);
    }
  };

  const handleEdit: (
    editData: QualityMeasureType,
    viewMode: boolean
  ) => void = (editData, viewMode) => {
    const newFormData: FormDataType[] = JSON.parse(
      JSON.stringify(QualityDataArr)
    );
    newFormData[0].value = editData.canonicalName;
    newFormData[0].readOnly = true;
    newFormData[1].value = editData.measureId?.toString() || "";
    newFormData[1].readOnly = viewMode;
    newFormData[2].dropValue = editData.isCompleted ? "completed" : "due";
    newFormData[2].readOnly = viewMode;
    newFormData[3].value = editData.isCompleted
      ? DateTime.ToLocalDate(editData.takenOn)
      : DateTime.ToLocalDate(editData.dueDate);
    newFormData[3].readOnly = viewMode;
    newFormData[4].value = editData.note;
    newFormData[4].readOnly = viewMode;
    setViewOnly(viewMode);
    setEditData(editData);
    setFormData(newFormData);
  };

  useEffect(() => {
    setOpenModal(formData.length > 0 ? true : false);
  }, [formData]);

  const handleSave: () => void = async () => {
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData);
    if (!newArr.errorCheck) {
      setModalLoad(true);
      const payload = {
        canonicalName: formData[0].value,
        measureId: formData[1].value,
        measureType: "",
        dueDate:
          formData[2].dropValue == "completed"
            ? editData?.dueDate || ""
            : formData[3].value
              ? DateTime.ToUTCDate(formData[3].value) + "T00:00"
              : "",
        note: formData[4].value,
        takenOn:
          formData[2].dropValue == "completed"
            ? formData[3].value
              ? DateTime.ToUTCDate(formData[3].value) + "T00:00"
              : ""
            : "",
        isCompleted: formData[2].dropValue == "completed" ? true : false,
      };
      let res: any;
      if (editData && editData.id) {
        res = await QualityApi.Update({
          id1: patientInfo.id,
          id2: editData.id,
          data: payload,
        });
      } else {
        res = await QualityApi.Create({ id1: patientInfo.id, data: payload });
      }
      if (res?.success) {
        setLoading(true);
        dispatch(setAlertData(res?.alert));
        setFormData([]);
        setEditData(null);
        fetchQualitySearch();
      }
      setModalLoad(false);
    }
  };

  const Layer1 = (
    <Fragment>
      <Box sx={{ ...sxHeader, ...CommonStyle.sxRow }}>
        <Typography fontWeight="bold">Quality</Typography>
        <Box display={"flex"}>
          <Box
            sx={{ ...CommonStyle.sxRoundIconsMainSm, mr: 1 }}
            onClick={() => {
              setViewOnly(false);
              setEditData(null);
              setFormData(JSON.parse(JSON.stringify(QualityDataArr)));
            }}
          >
            <ImgPath.PlusIcon titleAccess="Add a Quality Measure" />
          </Box>
          <Box
            sx={{ ...CommonStyle.sxRoundIconsMainSm, mr: 1 }}
            onClick={() => {
              setFormData([]);
              onClose();
            }}
          >
            <ImgPath.CloseIcon titleAccess="Close" />
          </Box>
        </Box>
      </Box>
      <Box sx={{ ...sxTab }}>
        <Tabs
          value={tabValue}
          onChange={(event, index) => {
            setLoading(true);
            setList([]);
            setTabValue(index);
          }}
          aria-label="basic tabs example"
          variant="fullWidth"
          indicatorColor="secondary"
        >
          <Tab label="Due" {...a11yProps(0)} />
          <Tab label="Completed" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0} style={{ ...sxTabPanel }}>
        <TestDue data={list} loading={loading} handleEdit={handleEdit} />
      </TabPanel>
      <TabPanel value={tabValue} index={1} style={{ ...sxTabPanel }}>
        <TestCompleted data={list} loading={loading} handleEdit={handleEdit} />
      </TabPanel>
    </Fragment>
  );

  return (
    <Fragment>
      <Box width="45%">{Layer1}</Box>
      <Box width="1%"></Box>
      {openModal && (
        <Box width="54%">
          <AddModal
            formData={formData}
            editData={editData}
            handleSave={handleSave}
            onClose={() => setFormData([])}
            onChange={(props) => {
              const newArr = HandleFormChange(props, formData);
              if (props.id == "status") {
                newArr[3].required = props.value == "completed" ? false : true;
              }
              setFormData(newArr);
              handleChange(props, newArr);
            }}
            modalLoad={modalLoad}
            viewMode={viewOnly}
          />
        </Box>
      )}
    </Fragment>
  );
};
export default Quality;

const sxHeader = {
  justifyContent: "space-between",
  height: "10%",
  px: 2,
};

const sxTab = {
  px: 2,
  "&>div": {
    borderBottom: 1,
    borderColor: "custom.main",
  },
};

const sxTabPanel = {
  height: "75%",
};
