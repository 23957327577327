import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";
import CstmBtn from "Components/CstmBtn";
import { styled } from "@mui/material/styles";
import { FC } from "react";
import { ControlledForm, CommonStyle } from "Components";

interface ModalProps {
  changeFile: (file: File | null) => void;
  fileName: string;
  progress: number;
  inProgress: boolean;
}

const UploadFileModal: FC<ModalProps> = (props) => {
  const { changeFile, fileName, progress, inProgress } = props;

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <Box>
      <Box display={"flex"} flexDirection={"row"} alignItems={"flex-end"}>
        <Box width={"75%"}>
          <ControlledForm.Input
            id="File Name"
            value={fileName}
            label="Selected File"
            placeholder="Select a file"
            readOnly={true}
          />
        </Box>
        <CstmBtn
          label="Choose File"
          onClick={() => {
            const element = document.getElementById("myFileInput");
            if (element) element.click();
          }}
          sxProps={{ ml: 1 }}
          disable={inProgress}
        >
          <VisuallyHiddenInput
            onChange={(e) =>
              changeFile(
                e?.target?.files && e?.target?.files?.length > 0
                  ? e?.target.files?.[0]
                  : null
              )
            }
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            id="myFileInput"
          />
        </CstmBtn>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          mt: 2,
        }}
      >
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ width: "75%" }}
        />
        <Typography ml={1}>{`${progress}%`}</Typography>
        {inProgress && <CircularProgress size={20} sx={{ ml: 1 }} />}
      </Box>
    </Box>
  );
};

export default UploadFileModal;
