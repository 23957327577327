import { FC } from "react";
import { Box } from "@mui/material";
import { CommonStyle } from "Components";
import ImgPath from "Constant/Imgs";
import { ControlledAccordProps } from "DataTypes/Common";

const ControlledAccord: FC<ControlledAccordProps> = (props) => {
  const { open, handleClick, head, body } = props;
  const iconWidth = 40;
  return (
    <Box sx={{ ...sxMain }}>
      <Box sx={{ ...CommonStyle.sxRow, py: 0.5 }}>
        <Box width={iconWidth} sx={{ ...CommonStyle.sxRow }}>
          <Box
            sx={{ ...CommonStyle.sxRoundIconsMainSm }}
            onClick={handleClick}
          >
            {open ? <ImgPath.MinusIcon /> : <ImgPath.PlusIcon />}
          </Box>
        </Box>
        <Box width={"calc(100% - " + iconWidth + "px)"}>{head}</Box>
      </Box>
      {body && (
        <Box sx={{ ml: iconWidth }} width={"calc(100% - " + iconWidth + "px)"}>
          {body}
        </Box>
      )}
    </Box>
  );
};
export default ControlledAccord;

const sxMain = {
  borderBottom: "1px solid",
  borderColor: "custom.main",
  py: 0.5,
  width: "100%",
};
