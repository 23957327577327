// import { GET_USER_LIST, ADD_USER, EDIT_USER, DELETE_USER, LOGGED_USER, USER_PERMISSION } from "./type";

// export type UserInfo = {
//     userId: string;
//     firstName: string;
//     lastName: string;
//     gender: string;
//     designation: string;
//     email: string;
//     role: string;
//     userName: string;
// };

// export const getUsers = () => {
//   return {
//     type: GET_USER_LIST,
//   }
// }

// export const addUser = (userData: UserInfo) => {
//   return {
//     type: ADD_USER,
//     payload: userData
//   }
// }

// export const editUser = (userData: UserInfo) => {
//   return {
//     type: EDIT_USER,
//     payload: userData
//   }
// }

// export const deleteUser = (userData: UserInfo) => {
//   return {
//     type: DELETE_USER,
//     payload: userData
//   }
// }

// export const setLoggedUser = (userData: UserInfo) => {
//   return {
//     type: LOGGED_USER,
//     payload: userData
//   }
// }

// export const setmyPermission = (userData: UserInfo) => {
//   return {
//     type: USER_PERMISSION,
//     payload: userData
//   }
// }

import { MyInfoType, UserActionReturnType } from "DataTypes/Redux";
import {
  CLEAR_MY_INFO,
  CLEAR_MY_PERMISSION,
  CLEAR_USER,
  SET_MY_INFO,
  SET_MY_PERMISSION,
} from "./type";

export const setMyInfo: (data: MyInfoType) => UserActionReturnType = (data) => {
  return {
    type: SET_MY_INFO,
    payload: data,
  };
};

export const clearMyInfo: () => UserActionReturnType = () => {
  return {
    type: CLEAR_MY_INFO,
  };
};

export const setMyPermission: (data: any) => UserActionReturnType = (data) => {
  return {
    type: SET_MY_PERMISSION,
    payload: data,
  };
};

export const clearMyPermission: () => UserActionReturnType = () => {
  return {
    type: CLEAR_MY_PERMISSION,
  };
};

export const clearUser: () => UserActionReturnType = () => {
  return {
    type: CLEAR_USER,
  };
};
