import { useState, useEffect, FC, Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { AdditionalProvidarArr, PrimaryInfoArr, BreadData } from "./Data";
import CstmBtn from "Components/CstmBtn";
import { CommonStyle } from "Components";
import { ModalLayout } from "Layouts";
import ProviderBox from "./ProviderBox";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import { commonFormSwitch, ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { DeleteButtonArray } from "Components/Modal/Data";
import UserApi from "Service/User.api";
import { useDispatch, useSelector } from "react-redux";
import CarePlanApi from "Service/CarePlan.api";
import {
  HandleFormChange,
  ValidateFormData,
  getUniqueArr,
} from "Utils/common";
import { HandleChangeProps, HeadContextType } from "DataTypes/Common";
import CarePlanProblem from "Utils/ApiDataFormatter/CarePlanProblem";
import { addProviderArrayType } from "DataTypes/CarePlanProblems";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import { HeadContext } from "Context/HeadContext";
import { PatientInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { RouteUrls } from "Constant/RouteUrls";
import Patient from "Utils/ApiDataFormatter/Patient";
import UserFormatter from "Utils/Users.Formatter";
import { addSelectedPrgm } from "Redux/Patient/Action";
import PatientFormatter from "Utils/Patient.Formatter";
import Stages from "Constant/Stages";
import { FormatDropdownData } from "Utils/DataFormatter";

const AssignTeam: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { patientInfo, selectedPrgm, patientOrg }: PatientInitialStateType =
    useSelector((state: any) => state?.patient);
  const teamInitiatorRoleId = selectedPrgm?.teamInitiatorRoleId || null;
  const teamInitiatedBy = selectedPrgm?.teamInitiatedBy || null;
  const readOnly = selectedPrgm
    ? selectedPrgm.readOnlyArr.some((el) => el == "team")
    : true;
  const patId = patientInfo.id;
  const proId = selectedPrgm.programId;
  const pracId = patientOrg.practiceId;
  const locId = patientOrg.practiceLocationId;
  const careId = selectedPrgm.careplanId;
  const currentUrl = window.location.href;
  const isTCM = currentUrl.includes(RouteUrls.tcm);
  const [opendeleteAddProvider, setOpendeleteAddProvider] = useState(false);
  const [providerData, setProviderData] = useState<any>([]);
  const [providerDrop, setProviderDrop] = useState<DropdownArrPropsType[]>([]);
  const [careCoordinatorData, setCareCoordinatorData] = useState<
    DropdownArrPropsType[]
  >([]);
  const [formArr1, setFormArr1] = useState<FormDataType[]>([]);
  const [formArr2, setFormArr2] = useState<addProviderArrayType[]>([]);
  const [additionalProvidarArrId, setAdditionalProvidarArrId] =
    useState<number>(0);
  const [currentProviderId, setCurrentProviderId] = useState<number | null>(
    null
  );

  useEffect(() => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    setBreadArr(
      BreadData(
        patientInfo,
        isTCM,
        careId,
        selectedPrgm.isCareplanComprehensive
      )
    );
    handleSetData();
  }, [careId]);

  const handleDeleteAddProvider = async () => {
    const res = await CarePlanApi.AdditionalProviderRemove({
      id1: careId,
      id2: additionalProvidarArrId,
    });
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      const newArr = formArr2.filter((el: any) => el?.id != currentProviderId);
      const newArr1 = [...newArr].map((el, index) => {
        el.id = index + 1;
        return el;
      });
      setFormArr2([...newArr1]);
    } else {
      dispatch(setAlertData(res?.alert));
    }
    setOpendeleteAddProvider(false);
  };

  const handlecancleAddProvider = () => {
    setOpendeleteAddProvider(false);
  };

  const handleremoveProvider = async (
    id: number,
    additionalProviderId: number
  ) => {
    if (additionalProviderId !== 0) {
      setOpendeleteAddProvider(true);
    } else {
      const newArr = formArr2.filter((el: any) => el?.id != id);
      const newArr1 = [...newArr].map((el, index) => {
        el.id = index + 1;
        return el;
      });
      setFormArr2([...newArr1]);
    }
    setAdditionalProvidarArrId(additionalProviderId);
    setCurrentProviderId(id);
  };
  const handleSetData = async () => {
    const providerUserPayload = {
      data: {
        practiceLocationId: locId,
        roleId: 5,
      },
    };
    const careCoordinatorUserPayload = {
      data: {
        practiceLocationId: locId,
        roleId: 4,
      },
    };
    const careManagerUserPayload = {
      data: {
        practiceLocationId: locId,
        roleId: 3,
      },
    };
    const providerRes = await UserApi.list(providerUserPayload);
    setProviderData(providerRes?.data);
    const careCoordinatorRes = await UserApi.list(careCoordinatorUserPayload);
    const careManagerRes = await UserApi.list(careManagerUserPayload);
    const formattedCareManagerRes = UserFormatter.VerifyListArrResponse(
      careManagerRes?.data
    ).filter((el) => {
      return el.status != "INACTIVE";
    });
    const formattedProviderRes = UserFormatter.VerifyListArrResponse(
      providerRes?.data
    ).filter((el) => {
      return el.status != "INACTIVE";
    });
    const formattedCareCoordinatorRes = UserFormatter.VerifyListArrResponse(
      careCoordinatorRes?.data
    ).filter((el) => {
      return el.status != "INACTIVE";
    });
    const CareManagerDropData = FormatDropdownData(
      formattedCareManagerRes,
      "id",
      "fullNameDes",
      "id"
    );
    const ProviderDropData = FormatDropdownData(
      formattedProviderRes,
      "id",
      "fullNameDes",
      "id"
    );
    const careCoordinatorDropData = FormatDropdownData(
      formattedCareCoordinatorRes,
      "id",
      "fullNameDes",
      "id"
    );
    setProviderDrop(ProviderDropData);
    setCareCoordinatorData(careCoordinatorDropData);
    let newArr1: FormDataType[] = PrimaryInfoArr(
      teamInitiatorRoleId,
      ProviderDropData,
      careCoordinatorDropData,
      careCoordinatorDropData,
      CareManagerDropData,
      selectedPrgm?.abbrevation || ""
    ).map((el: any) => {
      if (el.id == "care-manager" && teamInitiatorRoleId == 3) {
        el.readOnly = true;
        el.dropValue = el.value = teamInitiatedBy;
        return el;
      }
      return el;
    });
    let newArr2: addProviderArrayType[] = [];
    const res: any = await CarePlanApi.careplanTeamSearch({ id1: careId });
    if (res?.data) {
      const primeCC: DropdownArrPropsType[] = getUniqueArr(
        careCoordinatorDropData,
        res?.data?.secondaryCareCoordinatorId
      );
      const secondaryCC: DropdownArrPropsType[] = getUniqueArr(
        careCoordinatorDropData,
        res?.data?.primaryCareCoordinatorId
      );
      newArr1 = PrimaryInfoArr(
        teamInitiatorRoleId || null,
        ProviderDropData,
        primeCC,
        secondaryCC,
        CareManagerDropData,
        selectedPrgm?.abbrevation || ""
      ).map((el) => {
        if (el.id == "care-manager" && teamInitiatorRoleId == 3) {
          el.readOnly = true;
          el.dropValue = el.value = teamInitiatedBy || "";
          return el;
        }

        return el;
      });
      const uniqueArray: any = [];
      res?.data?.careplanAdditionalProvider &&
        res?.data?.careplanAdditionalProvider.length &&
        res?.data?.careplanAdditionalProvider.map((el: any) => {
          if (el?.isDeleted == false) {
            uniqueArray.push(el);
          }
        });
      if (uniqueArray.length) {
        newArr2 = [
          {
            id: 1,
            additionalProvidarArrId: 0,
            additionalProvidarData: JSON.parse(
              JSON.stringify([...AdditionalProvidarArr])
            ),
          },
        ];
      }
      const formattedData1 = Patient.formatCareTeamSearchres(res?.data);
      const formattedData = CarePlanProblem.formatCareplanSearchRes(
        formattedData1,
        newArr1,
        newArr2,
        providerRes?.data,
        selectedPrgm?.abbrevation || "",
        teamInitiatorRoleId
      );
      newArr1 = formattedData.primaryInfoArray;
      if (formattedData?.secondaryInfoArray.length > 0) {
        newArr2 = formattedData?.secondaryInfoArray;
      }
    }
    dispatch(stopLoading());
    if (readOnly) {
      newArr1 = newArr1.map((el) => {
        el.readOnly = true;
        return el;
      });
      newArr2 = newArr2.map((el) => {
        const newArr = el.additionalProvidarData.map((el1) => {
          el1.readOnly = true;
          return el1;
        });
        return {
          ...el,
          additionalProvidarData: newArr,
        };
      });
    }
    setFormArr1(newArr1);
    setFormArr2(newArr2);
  };

  const handleChange = (props: HandleChangeProps): void => {
    const { value, id } = props;
    let foundVal: any;
    let uniqueCCDropData: DropdownArrPropsType[];
    // let uniqueProviderDropData: DropdownArrPropsType[];
    if (providerData?.length > 0) {
      foundVal = providerData.find((el: any) => el.id == value);
    }
    const newArr = HandleFormChange(props, formArr1);
    if (id == "billable-provider") {
      newArr.map((el1) => {
        el1.id == "speciality" &&
          (el1.value = el1.dropValue = foundVal?.speciality);
      });
    }
    if (id == "primary-care-coordinator") {
      if (myInfo.roleId == 3) {
        uniqueCCDropData = getUniqueArr(
          careCoordinatorData,
          newArr[3].dropValue
        );
        newArr[4].dropArr = uniqueCCDropData;
      } else {
        uniqueCCDropData = getUniqueArr(
          careCoordinatorData,
          newArr[4].dropValue
        );
        newArr[5].dropArr = uniqueCCDropData;
      }
    } else if (id == "secondary-care-coordinator") {
      if (myInfo.roleId == 3) {
        uniqueCCDropData = getUniqueArr(
          careCoordinatorData,
          newArr[4].dropValue
        );
        newArr[3].dropArr = uniqueCCDropData;
      } else {
        uniqueCCDropData = getUniqueArr(
          careCoordinatorData,
          newArr[5].dropValue
        );
        newArr[4].dropArr = uniqueCCDropData;
      }
    }
    setFormArr1(newArr);
  };

  const handleAddProviderChange = (
    value: string | number | (string | number)[],
    id: number | string,
    assignFieldId: number
  ) => {
    const index = formArr2.findIndex((el: any) => el?.id == assignFieldId);
    const additionalObjIndex = formArr2[
      index
    ]?.additionalProvidarData?.findIndex((el: any) => el.id == id);
    const newArr = [...formArr2];
    newArr[index].additionalProvidarData[additionalObjIndex].value = value;
    newArr[index].additionalProvidarData[additionalObjIndex].dropValue = value;
    setFormArr2([...newArr]);
  };

  const handleAddProvider = () => {
    setFormArr2([
      ...formArr2,
      {
        id: formArr2.length + 1,
        additionalProvidarArrId: 0,
        additionalProvidarData: JSON.parse(
          JSON.stringify([...AdditionalProvidarArr])
        ),
      },
    ]);
  };

  const handleTeamSave = async () => {
    let errorCheck = false;
    const newArr = ValidateFormData(formArr1);
    if (newArr.errorCheck) errorCheck = true;
    setFormArr1(newArr?.formData);
    const newArr2 = formArr2.map((el) => {
      const newArr3 = ValidateFormData(el?.additionalProvidarData);
      if (newArr3.errorCheck) errorCheck = true;
      el.additionalProvidarData = newArr3?.formData;
      return el;
    });
    setFormArr2(newArr2);
    if (!errorCheck) {
      const payload = CarePlanProblem.saveTeamPayload(
        careId,
        locId,
        proId,
        pracId,
        patId,
        formArr1,
        formArr2,
        selectedPrgm?.abbrevation || "",
        teamInitiatorRoleId
      );
      const response = await CarePlanApi.careplanTeamSave({
        id1: careId,
        data: payload,
      });
      if (response?.success) {
        dispatch(setAlertData(response.alert));
        selectedPrgm &&
          dispatch(
            addSelectedPrgm({
              ...selectedPrgm,
              ...(selectedPrgm.careplanStage >
              PatientFormatter.GetCPStage(Stages.CPTeam)
                ? {}
                : {
                  careplanState: Stages.CPShare,
                  careplanStage: PatientFormatter.GetCPStage(Stages.CPShare),
                }),
            })
          );
        navigate(`../${RouteUrls.careSummary}`, { replace: true });
      }
    }
  };

  return (
    <Fragment>
      <ModalLayout
        modwidth="30%"
        heading={"Warning"}
        content={
          <Typography textAlign={"center"}>
            Are you sure want to remove this Additional Provider?
          </Typography>
        }
        open={opendeleteAddProvider}
        onClose={() => setOpendeleteAddProvider(false)}
        buttonArr={DeleteButtonArray(
          handleDeleteAddProvider,
          handlecancleAddProvider
        )}
      />
      <Box width="91%" sx={{ overflow: "auto", ...CommonStyle.sxWhiteCard }}>
        <Typography height="5%" variant="body2" fontWeight="bold">
          Enrolled Program - {selectedPrgm?.abbrevation}
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={2} mb={2}>
          {formArr1.length > 0 &&
            formArr1.map((el: any) => {
              return (
                <Grid key={el.id} item {...ControlledBoxSizeSwitch(el.boxSize)}>
                  {commonFormSwitch({ formObj: el, onChange: handleChange })}
                </Grid>
              );
            })}
        </Grid>
        <ProviderBox
          addProvider={formArr2}
          handleremove={handleremoveProvider}
          handleAddChange={(
            { value, id }: HandleChangeProps,
            assignFieldId: number
          ) =>
            handleAddProviderChange(
              typeof value == "string" || typeof value == "number" ? value : "",
              id,
              assignFieldId
            )
          }
        />
        <Divider sx={{ pt: 1 }} />
        <Box
          height="10%"
          display="flex"
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          {!readOnly && formArr2.length < 5 && (
            <CstmBtn
              btnType="border"
              label={"Add Additional Provider"}
              onClick={() => {
                handleAddProvider();
              }}
              sxProps={{ ...spacerRight }}
            />
          )}
          {readOnly ? (
            (selectedPrgm?.careplanStage || 0) >
              PatientFormatter.GetCPStage(Stages.CPTeam) && (
              <CstmBtn
                label={"Next"}
                onClick={() =>
                  navigate(`../${RouteUrls.careSummary}`, { replace: true })
                }
                width="10%"
              />
            )
          ) : (
            <CstmBtn
              label={"Save & Next"}
              onClick={() => handleTeamSave()}
              width="10%"
            />
          )}
        </Box>
      </Box>
    </Fragment>
  );
};
export default AssignTeam;

const spacerRight = {
  mr: 2,
};
