import { ControlledTable } from "Components";
import { BreadArrType } from "DataTypes/Common";
import { columnType } from "DataTypes/Table";

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.NameCol("50%"),
  ControlledTable.ColumnData.ProgramSFCol("50%"),
];


export const MasterProgramBreadArr = () => {
  const Arr: BreadArrType[] = [
    {
      id: 1,
      label: "Program Master",
      path: "",
    },
  ];
  return Arr;
} 