import { Box, Divider, Grid, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import { FormDataType } from "DataTypes/Form";
import CstmBtn from "Components/CstmBtn";
import { FC, useContext, useEffect, useState } from "react";
import { BreadArr, DataArr } from "./Data";
import { commonFormSwitch, ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { useNavigate } from "react-router-dom";
import { HandleChangeProps, HeadContextType } from "DataTypes/Common";
import { PatientInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import PatientApi from "Service/Patient.api";
import DataFormatter from "Utils/DataFormatter";
import { HandleFormChange, ValidateFormData } from "Utils/common";
import PatientFormatter from "Utils/Patient.Formatter";
import { RouteUrls } from "Constant/RouteUrls";
import { useDispatch } from "react-redux";
import { setAlertData } from "Redux/Screen/Action";
import { HeadContext } from "Context/HeadContext";
import { EligiblePrgmResType } from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";

const AddOnTimeLog: FC = () => {
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const { patientInfo, patientOrg }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newPatientId = patientInfo.id;
  const [formData, setFormData] = useState<FormDataType[]>(
    JSON.parse(JSON.stringify(DataArr([])))
  );
  const [programList, setProgramList] = useState<EligiblePrgmResType[]>([]);
  const [cpObj, setCpObj] = useState<EligiblePrgmResType | null>(null);

  useEffect(() => {
    setBreadArr(BreadArr);
    handleOnload();
  }, []);

  const handleOnload: () => void = async () => {
    const formArr: FormDataType[] = JSON.parse(JSON.stringify(formData));
    const res = await PatientApi.eligiblePrgmList({ id1: newPatientId });
    if (res?.success) {
      const formattedRes = PatientFormatter.CheckEligiblePrgmRes(res?.data);
      setProgramList(formattedRes);
      const filteredRes = formattedRes.filter((el) => {
        if (el.abbrevation == "TCM") {
          ///discharge date implementation is done according to 10143 bug id
          let timeRemaining = true;
          if (el.dischargeDate) {
            const newDischargeDate = DateTime.AddSubDay(el.dischargeDate, +30);
            const newTimestamp = DateTime.GetTimeStamp(newDischargeDate);
            const currentStamp = DateTime.GetTimeStamp(DateTime.CurrentDate());
            if (currentStamp > newTimestamp) {
              timeRemaining = false;
            }
          }
          if (
            el.status == "Enrolled" &&
            // (el.careplanState != Stages.Recurring || timeRemaining) &&
            timeRemaining &&
            myInfo.roleId != 4
          ) {
            return el;
          }
        } else {
          if (el.status == "Enrolled") {
            if (myInfo.roleId == 4) {
              if (el.careplanRecurringId) return el;
            } else return el;
          }
        }
      });
      const prgDropData = DataFormatter.FormatDropdownData(
        filteredRes,
        "programId",
        "abbrevation",
        "programId"
      );
      formArr[0].dropArr = prgDropData;
      formArr[2].maxVal = DateTime.CurrentDate() + ", 11:59 PM";
      formArr[3].maxVal = DateTime.CurrentDate() + ", 11:59 PM";
      formArr[5].value = "0";
      setFormData(formArr);
    }
  };

  const handleChange: (props: HandleChangeProps) => void = (props) => {
    const { value, id } = props;
    const newFormData: FormDataType[] = JSON.parse(JSON.stringify(formData));
    if (id == "programName") {
      const find = programList.find((el) => el.programId == value);
      setCpObj(find || null);
      newFormData[1].value = find?.careplanActivity || "";
      newFormData[2].minVal = DateTime.ToLocalDate(
        value == 3 ? find?.dischargeDate : find?.enrollmentDate
      );
    }
    if (id == "start-date-time") {
      newFormData[5].value = DateTime.CalculateDuration(
        DateTime.ValidDate(value),
        DateTime.ValidDate(newFormData[3].value)
      ).toString();
      newFormData[3].minVal = DateTime.ValidDate(value);
    }
    if (id == "end-date-time") {
      newFormData[5].value = DateTime.CalculateDuration(
        DateTime.ValidDate(newFormData[2].value),
        DateTime.ValidDate(value)
      ).toString();
    }
    setFormData(HandleFormChange(props, newFormData));
  };

  const handleSave: () => void = async () => {
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData);
    if (!newArr.errorCheck && cpObj) {
      const payload = {
        careplanId: cpObj.careplanId,
        careplanRecurringId: cpObj?.careplanRecurringId,
        startDateTime: DateTime.ToUTCDateTime(formData[2].value),
        endDateTime: DateTime.ToUTCDateTime(formData[3].value),
        notes: formData[6].value,
        reasons: formData[1].value,
        state: "addon time",
        type: formData[4].dropValue,
      };
      const res = await PatientApi.timeLog({
        id1: cpObj?.careplanId || null,
        data: payload,
      });
      if (res?.success) {
        dispatch(setAlertData(res?.alert));
        navigate(-1);
      }
    }
  };

  return (
    <Box width="91%" sx={{ ...CommonStyle.sxWhiteCard }} overflow="auto">
      <Box sx={{ p: 2, ...CommonStyle.sxRowCenter }}>
        <Box width="70%">
          <Box sx={{ ...sxHead }}>
            <Box mr={3}>
              <Typography variant="body2">{"Patient's Name:"}</Typography>
              <Typography variant="body2" fontWeight="bold">
                {patientInfo.name}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">Organization Name:</Typography>
              <Typography variant="body2" fontWeight="bold">
                {patientOrg.practiceName}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ color: "custom.main", my: 0.5 }} />
          <Grid container columnSpacing={2} sx={{ mb: 1 }}>
            {formData && formData.length > 0
              ? formData.map((el: FormDataType) => {
                return (
                  <Grid
                    key={el.id}
                    item
                    {...ControlledBoxSizeSwitch(el.boxSize)}
                  >
                    {commonFormSwitch({
                      formObj: el,
                      onChange: (data) => handleChange(data),
                    })}
                  </Grid>
                );
              })
              : null}
          </Grid>
          <Box sx={{ mt: 2.5, ...CommonStyle.sxRow }} justifyContent={"end"}>
            <CstmBtn
              label="save"
              sxProps={{ mr: 2 }}
              onClick={() => {
                handleSave();
              }}
              width="15%"
            />
            <CstmBtn
              label="Cancel"
              onClick={() => navigate(`../${RouteUrls.clinicalSummary}`)}
              btnType="border"
              width="15%"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default AddOnTimeLog;

const sxHead = {
  display: "flex",
  flexDirection: "row",
  "& >div": {
    display: "flex",
    alignItems: "center",
    "& >p:first-of-type": {
      mr: 1,
    },
  },
};
