import { Box } from "@mui/material";
import { CommonStyle, ControlledTable, Filter2 } from "Components";
import { RouteUrls } from "Constant/RouteUrls";
import { TableLayout } from "Layouts";
import { FC, useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CreateFilter2Payload } from "Utils/common";
import { actionArr, TableCol, BreadArr } from "./Data";
import { HeadContext } from "Context/HeadContext";
import { Filter2DataProps, HeadContextType } from "DataTypes/Common";
import PatientApi from "Service/Patient.api";
import Formate from "./Formate";
import { FilterArr } from "./Data";
import { GlobalType } from "DataTypes/Services/Patient.type";

const AllPatients: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const [limit, setLimit] = useState<number>(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<GlobalType[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>(
    JSON.parse(JSON.stringify(FilterArr()))
  );
  const tableHeight = `calc(99% - ${filterHeight}px)`;

  useEffect(() => {
    setBreadArr(BreadArr);
  }, []);

  useEffect(() => {
    handleSetData(1, limit);
  }, [location.state, filterData]);

  const handleSetData = async (currentPage: number, currentLimit: number) => {
    setLoading(true);
    const payload = {
      data: {
        keyword: location?.state?.keyword,
        ...CreateFilter2Payload(filterData),
      },
      limit: currentLimit,
      offset: (currentPage - 1) * currentLimit,
    };
    const res = await PatientApi.GlobalPatientSearch(payload);
    if (res.success) {
      setRows(Formate.GlobalList(res?.data));
      setPage(res.criterion.page);
      setTotalRecord(res.criterion.total);
      setLimit(res.criterion.limit);
    } else {
      setRows([]);
      setPage(1);
      setTotalRecord(0);
      setLimit(10);
    }
    setLoading(false);
  };

  const handlePatientDetails: (data: GlobalType) => void = (data) => {
    navigate(`../${RouteUrls.patient}/${data.patientId}/${RouteUrls.details}`);
  };

  const handleChart: (data: GlobalType) => void = (data) => {
    navigate(
      `../${RouteUrls.patient}/${data.patientId}/${RouteUrls.clinicalSummary}`
    );
  };

  const handleTask: (data: GlobalType) => void = (data) => {
    navigate(
      `../${RouteUrls.patient}/${data.patientId}/${RouteUrls.task}/${RouteUrls.add}`
    );
  };

  const handleAddTimeLog: (data: GlobalType) => void = (data) => {
    navigate(
      `../${RouteUrls.patient}/${data.patientId}/${RouteUrls.addonTime}`
    );
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={rows}
      tableAction={(data) =>
        actionArr(
          () => handleChart(data),
          () => handlePatientDetails(data),
          () => handleTask(data),
          () => handleAddTimeLog(data),
          data
        )
      }
      searchText={searchText}
    />
  );

  return (
    <Box
      height="91%"
      sx={{ ...CommonStyle.sxWhiteBox, px: 2, overflow: "auto" }}
    >
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => setFilterData(data)}
        changeSearchText={(val) => setSearchText(val)}
        setFilterHeight={(val) => setFilterHeight(val)}
      />
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol)}
        height={tableHeight}
        loading={loading}
        Pagination={
          <ControlledTable.Pagination
            mainPage={page}
            limit={limit}
            total={totalRecord}
            handleClick={handleSetData}
            tableStyle={true}
          />
        }
      />
    </Box>
  );
};
export default AllPatients;
