import ControlledTable from "Components/ControlledTable";
import { RedoxAppointmentType } from "DataTypes/Services/Patient.type";
import { TableLayout } from "Layouts";
import { Fragment } from "react";
import { AppointmentsTabelCol } from "./Data";

const Appointments = (AppointmentsData: RedoxAppointmentType[]) => {
  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => AppointmentsTabelCol}
      tableRow={AppointmentsData}
    />
  );
  return (
    <Fragment>
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(AppointmentsTabelCol)}
        height={""}
      />
    </Fragment>
  );
};
export default Appointments;
