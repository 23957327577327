export const RouteUrls = {
  createTask: "create-task",
  patientDetails: "/patient-details",
  chart: "/chart",
  callLogs: "call-logs",
  startCarePlan: "/ques-catagory",
  enterProblem: "/enter-problem",
  heartratechart: "/heart-rate-chart",
  glucosechart: "/glucose-chart",
  bpchart: "/blood-pressure-chart",
  dischargeSummary: "dischargeSummary",
  myOrganization: "myOrganization",
  View: "view",
  login: "login",
  forgot: "forgot",
  passwordRecovery: "password-recovery",
  signUp: "sign-up",
  hs: "page",
  allPatients: "all-patient",
  patientUpload: "patientimport",
  changePass: "changepassword",
  aboutUs: "aboutus",
  glist: "g-list",
  eligible: "eligible",
  pendingAssign: "pendingassignment",
  enrolled: "enrolled",
  myPatients: "mypatient",
  pendingApproval: "pendingapproval",
  pendingCare: "pendingcare",
  completedCare: "completedcare",
  inactive: "inactive",
  ongoing: "ongoing",
  billing: "billing",
  consent: "consent",
  master: "master",
  category: "category",
  insPrgmMap: "programinsurance",
  prgmProbMap: "programproblem",
  prob: "problem",
  list: "list",
  icdCodes: "icdcode",
  prgm: "program",
  questionnaire: "question",
  add: "add",
  update: "update",
  myProfile: "myprofile",
  organization: "organization",
  staff: "staff",
  patient: "patient",
  details: "detail",
  clinicalSummary: "clinicalsummary",
  patientAudit: "patientAudit",
  callLog: "calllog",
  program: "program",
  carePlan: "careplan",
  tcm: "tcm",
  recurring: "recurring",
  carePlanSummary: "careplansummary",
  tcmSummary: "tcmsummary",
  recurringSummary: "recurringsummary",
  pastCarePlan: "pastcareplan",
  pendingCareplan: "pendingcareplan",
  careProblem: "problem",
  careTeam: "assignteam",
  editTeam: "editTeam",
  careQuestion: "question",
  careSummary: "summary",
  setting: "setting",
  task: "task",
  userAudit: "useraudit",
  notifications: "notifications",
  tasking: "tasking",
  incoming: "incoming",
  outgoing: "outgoing",
  global: "global",
  group: "group",
  addonTime: "addontime",
  redoxSettings: "redoxsettings",
  appSettings: "applicationsettings",
};
