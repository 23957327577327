import { TableRow, TableCell, Typography } from "@mui/material";
import { FC, Fragment } from "react";
import { columnType } from "DataTypes/Table";

const TblHeadColGroup = (tableColumn: columnType[]) => {
  return (
    <Fragment>
      <TableRow>
        {tableColumn.map((col) => {
          return (
            <TableCell
              key={col.id}
              align={col.align}
              sx={{ ...sxTblHead, width: col.width, borderBottom: "0px", }}
              colSpan={col.subCol ? col.subCol.length : 1}
            >
              <Typography variant="body2" color="common.white">
                {col.label}
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        {tableColumn.map((col) => {
          if (col.subCol) {
            return col.subCol.map((sub) => {
              return (
                <TableCell key={sub.id} align={col.align} sx={{ ...sxTblHead }}>
                  <Typography variant="body2" color="common.white">
                    {sub.label}
                  </Typography>
                </TableCell>
              );
            });
          } else {
            return (
              <TableCell key={col.id} align="center" sx={{ ...sxTblHead }} />
            );
          }
        })}
      </TableRow>
    </Fragment>
  );
};
export default TblHeadColGroup;

const sxTblHead = {
  padding: 0,
  p: 1,
  backgroundColor: "secondary.main",
};
