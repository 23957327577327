import { FC } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { CommonStyle } from "Components";
import { ThemeProvider, Typography } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { InputDataType } from "DataTypes/Form";
import InputLabel from "../Input/Label";
import dayjs, { Dayjs } from "dayjs";
import { Locale } from "Constant/En";
import DateTime from "Utils/DateTime";

const newTheme = (appTheme: any) =>
  createTheme({
    ...appTheme,
    components: {
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            width: 240,
            height: 260,
            maxHeight: 260,
          },
          viewTransitionContainer: {
            height: 220,
            "& >div": {
              height: "100%",
            },
          },
        },
      },
      MuiDayCalendar: {
        styleOverrides: {
          weekDayLabel: {
            width: 30,
            height: 30,
            margin: 0,
          },
        },
      },
      MuiYearCalendar: {
        styleOverrides: {
          root: {
            height: "100%",
            maxHeight: "100%",
            width: 240,
            padding: 0,
          },
        },
      },
      MuiDayPicker: {
        styleOverrides: {
          header: {
            "& >span": {
              width: 30,
              height: 30,
              margin: 0,
            },
          },
          weekContainer: {
            margin: 0,
          },
          slideTransition: {
            minHeight: 190,
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            width: 30,
            height: 30,
            margin: 0,
          },
        },
      },
      MuiCalendarOrClockPicker: {
        styleOverrides: {
          root: {
            "&>div": {
              width: 250,
              maxHeight: 260,
            },
          },
        },
      },
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            width: "100%",
            maxHeight: 260,
          },
          viewTransitionContainer: {
            maxHeight: 220,
            minHeight: 220,
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          root: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxHeight: 40,
            minHeight: 40,
            margin: 0,
            padding: 0,
          },
          labelContainer: {
            maxHeight: 40,
            minHeight: 40,
            margin: 0,
            fontSize: 14,
            marginLeft: 15,
          },
          switchViewButton: {
            padding: 0,
            margin: 0,
          },
        },
      },
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          root: {
            "& >button": {
              padding: 0,
              ":last-of-type": {
                marginRight: 10,
              },
            },
          },
        },
      },
      MuiYearPicker: {
        styleOverrides: {
          root: {
            padding: 0,
            maxHeight: 220,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: 0,
            paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 15,
            paddingLeft: 15,
            justifyContent: "space-between",
            "& >button": {
              fontSize: 12,
              fontWeight: "bold",
              minWidth: 0,
              padding: 0,
              borderRadius: 0,
            },
          },
        },
      },
    },
  });

const CalenderPicker: FC<InputDataType> = (props) => {
  const {
    label,
    id,
    onChange,
    required,
    readOnly,
    error,
    errorText,
    value,
    minVal,
    maxVal,
  } = props;

  return (
    <ThemeProvider theme={newTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {label && (
          <InputLabel label={label} required={required ? required : false} />
        )}
        <DesktopDatePicker
          format={Locale.date}
          value={dayjs(value)}
          onChange={(val: Dayjs | null) => {
            const date = DateTime.ToLocalDate(val?.format(Locale.date));
            onChange && onChange(date);
          }}
          disabled={readOnly ? readOnly : false}
          {...(minVal && { minDate: dayjs(minVal) })}
          {...(maxVal && { maxDate: dayjs(maxVal) })}
          slotProps={{
            textField: {
              name: id,
              sx: {
                ...(readOnly
                  ? { ...sxReadOnly }
                  : { ...CommonStyle.sxWhiteBox }),
                "& >div": { ...sxInput },
              },
              error: error ? error : false,
              fullWidth: true,
            },
            actionBar: { actions: ["clear", "today", "cancel", "accept"] },
          }}
        />
        {error && (
          <Typography variant="subtitle2" color="red">
            {errorText}
          </Typography>
        )}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default CalenderPicker;

const sxInput = {
  fontSize: "0.85rem",
  px: 0.5,
  py: 0,
  "& >input": {
    px: 0.5,
    py: 1,
  },
  "& >fieldset": {
    borderWidth: 0,
  },
  "& >div": {
    "&>button": {
      padding: 0,
      margin: 0,
      "& >svg": {
        width: 20,
        height: 20,
      },
    },
  },
};

const sxReadOnly = {
  backgroundColor: "custom.grey",
  boxShadow: "0px 0px 6px #00000029",
  borderRadius: 1.25,
  " >fieldset": {
    display: "none",
  },
};
