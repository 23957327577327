import { RouteUrls } from "./RouteUrls";

const Patient: (patientId: string | null) => string = (patientId) => {
  return `${RouteUrls.hs}/${RouteUrls.patient}/${patientId}`;
};

const ClinicalSummary: (patientId: string | null) => string = (patientId) => {
  return `/${Patient(patientId)}/${RouteUrls.clinicalSummary}`;
};

const CarePlan: (patientId: string | null, cpId: number | null) => string = (
  patientId,
  cpId
) => {
  return `/${Patient(patientId)}/${RouteUrls.carePlan}/${cpId}`;
};
const TcmCarePlan: (patientId: string | null, cpId: number | null) => string = (
  patientId,
  cpId
) => {
  return `/${Patient(patientId)}/${RouteUrls.tcm}/${cpId}`;
};

const CPProbs: (patientId: string | null, cpId: number | null) => string = (
  patientId,
  cpId
) => {
  return `${CarePlan(patientId, cpId)}/${RouteUrls.careProblem}`;
};

const CPQues: (patientId: string | null, cpId: number | null) => string = (
  patientId,
  cpId
) => {
  return `${CarePlan(patientId, cpId)}/${RouteUrls.careQuestion}`;
};
const TCMCPQues: (patientId: string | null, cpId: number | null) => string = (
  patientId,
  cpId
) => {
  return `${TcmCarePlan(patientId, cpId)}/${RouteUrls.careQuestion}`;
};

const CPTeam: (patientId: string | null, cpId: number | null) => string = (
  patientId,
  cpId
) => {
  return `${CarePlan(patientId, cpId)}/${RouteUrls.careTeam}`;
};
const TCMCPTeam: (patientId: string | null, cpId: number | null) => string = (
  patientId,
  cpId
) => {
  return `${TcmCarePlan(patientId, cpId)}/${RouteUrls.careTeam}`;
};

const CPSummary: (patientId: string | null, cpId: number | null) => string = (
  patientId,
  cpId
) => {
  return `${CarePlan(patientId, cpId)}/${RouteUrls.careSummary}`;
};

const Recurring: (
  patientId: string | null,
  cpId: number | null,
  recurringId: number | null
) => string = (patientId, cpId, recurringId) => {
  return `/${Patient(patientId)}/${RouteUrls.carePlan}/${cpId}/${
    RouteUrls.recurring
  }/${recurringId}`;
};

const RecurringQues: (
  patientId: string | null,
  cpId: number | null,
  recurringId: number | null
) => string = (patientId, cpId, recurringId) => {
  return `${Recurring(patientId, cpId, recurringId)}/${RouteUrls.careQuestion}`;
};

const RecurringSummary: (
  patientId: string | null,
  cpId: number | null,
  recurringId: number | null
) => string = (patientId, cpId, recurringId) => {
  return `${Recurring(patientId, cpId, recurringId)}/${RouteUrls.careSummary}`;
};

const OrgList: () => string = () => {
  return `/${RouteUrls.hs}/${RouteUrls.organization}/${RouteUrls.list}`;
};

const OrgStaffList: (orgId: number | null) => string = (orgId) => {
  return `/${RouteUrls.hs}/${RouteUrls.organization}/${orgId}/${RouteUrls.staff}/${RouteUrls.list}`;
};

const MasterQuesList: () => string = () => {
  return `/${RouteUrls.hs}/${RouteUrls.master}/${RouteUrls.questionnaire}/${RouteUrls.list}`;
};

export default {
  Patient,
  ClinicalSummary,
  CarePlan,
  CPProbs,
  CPQues,
  CPTeam,
  CPSummary,
  Recurring,
  RecurringQues,
  RecurringSummary,
  OrgList,
  OrgStaffList,
  MasterQuesList,
  TCMCPQues,
  TCMCPTeam,
};
