import { FormDataType } from "DataTypes/Form";
import { CPTeamSearchType } from "DataTypes/Services/Careplan.type";
import { UserListApiType } from "DataTypes/Services/User.type";
import { FormatDropdownData } from "Utils/DataFormatter";

export const FormAssignment: (
  data: CPTeamSearchType,
  formArr: FormDataType[],
  cmArr: UserListApiType[],
  ccArr: UserListApiType[],
  providerArr: UserListApiType[]
) => FormDataType[] = (data, formArr, cmArr, ccArr, providerArr) => {
  const newArr: FormDataType[] = JSON.parse(JSON.stringify(formArr));
  newArr[0].dropArr = FormatDropdownData(cmArr, "id", "fullNameDes", "id");
  if (data.caremanagerId) {
    newArr[0].dropValue = newArr[0].value = data.caremanagerId;
    newArr[0].required = true;
  }
  newArr[1].dropArr = FormatDropdownData(
    providerArr,
    "id",
    "fullNameDes",
    "id"
  );
  if (data.billableProviderId) {
    newArr[1].dropValue = newArr[1].value = data.billableProviderId;
    newArr[1].required = true;
  } else newArr[1].readOnly = true;
  newArr[2].dropArr = FormatDropdownData(
    providerArr,
    "id",
    "fullNameDes",
    "id"
  );
  if (data.providerId) {
    newArr[2].dropValue = newArr[2].value = data.providerId;
    newArr[2].required = true;
  }
  if (newArr.length > 3) {
    newArr[3].dropArr = FormatDropdownData(
      ccArr.filter((el) => el.id != data.secondaryCareCoordinatorId),
      "id",
      "fullNameDes",
      "id"
    );
    if (data.primaryCareCoordinatorId) {
      newArr[3].dropValue = newArr[3].value = data.primaryCareCoordinatorId;
      newArr[3].required = true;
    }
    newArr[4].dropArr = FormatDropdownData(
      ccArr.filter((el) => el.id != data.primaryCareCoordinatorId),
      "id",
      "fullNameDes",
      "id"
    );
    if (data.secondaryCareCoordinatorId) {
      newArr[4].dropValue = newArr[4].value = data.secondaryCareCoordinatorId;
      newArr[4].required = true;
    }
  }
  return newArr;
};
