import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import ControlledTable from "Components/ControlledTable";
import { TableLayout } from "Layouts";
import { TableCol, actionArr } from "./Data";
import CarePlanApi from "Service/CarePlan.api";
import {
  CpNoteType,
  EligiblePrgmResType,
} from "DataTypes/Services/Patient.type";
import { useDispatch } from "react-redux";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "Constant/RouteUrls";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import Format from "./Format";

const PreNotes: FC<{ data: EligiblePrgmResType; onClose: () => void }> = ({
  data,
  onClose,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { careplanId, abbrevation } = data;
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [rows, setRows] = useState<CpNoteType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData();
  }, []);

  const setData = async () => {
    const res = await CarePlanApi.CpNotes({ id1: careplanId });
    if (res?.success) {
      setRows(Format.NoteSearch(res?.data, abbrevation));
    } else {
      setRows([]);
    }
    setLoading(false);
  };

  const handleDownload = async (data: CpNoteType) => {
    const res: any = await CarePlanApi.CPPDFDownload(
      { id1: data.id },
      "CareplanSummaryRecurring"
    );
    if (res?.data) {
      const binaryResponse = res?.data;
      const blob = new Blob([binaryResponse], {
        type: "application/pdf",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${patientInfo.name}_Recurring_Note.pdf`;
      link.click();
    }
  };

  const handleView: (viewData: CpNoteType) => void = (viewData) => {
    onClose();
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...data,
        careplanRecurringId: viewData.id,
        readOnlyArr: [
          "uptoDate",
          "problem",
          "question",
          "team",
          "addNotes",
          "frequency",
        ],
      })
    );
    navigate(
      `/${RouteUrls.hs}/${RouteUrls.patient}/${data.patientId}/${RouteUrls.carePlan}/${data.careplanId}/${RouteUrls.recurring}/${viewData.id}/${RouteUrls.careSummary}`
    );
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={rows}
      tableAction={(data) =>
        actionArr(
          () => handleDownload(data),
          () => handleView(data)
        )
      }
    />
  );
  return (
    <Box width="100%">
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol)}
        height={"100%"}
        loading={loading}
      />
    </Box>
  );
};
export default PreNotes;
