import { TargetFormatter } from "DataTypes/PatientManagement.type";
import { objectValues } from "DataTypes/Services/Index.type";
import DateTime from "Utils/DateTime";
const capitalizeFirstLetter = (str: string) => {
  if (!str) return ""; // Handle empty string
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const formatFormData = (
  item: string,
  data: objectValues,
  dropArr?: objectValues[]
) => {
  switch (item) {
    case "source":
      return capitalizeFirstLetter(data?.source);

    case "type":
      return capitalizeFirstLetter(data?.type);

    case "task-description":
      return data?.taskDescription;

    case "subject":
      return data?.subject;

    case "task-assign-to":
      return dropArr && data?.assigneeUserId
        ? capitalizeFirstLetter(
          dropArr?.filter((item) => item.id === data?.assigneeUserId)[0]
            ?.label
        )
        : capitalizeFirstLetter(data?.groupName);

    case "note":
      return data?.notes;

    case "status":
      return data?.status;

    default:
      break;
  }
};

export const checkTargetdate = (data: TargetFormatter) => {
  const newArr = data.formData.map((el) => {
    if (data.priorityType == "Urgent") {
      el.readOnly = true;
    } else if (data.userId === data.assigneeUserId && data.incoming) {
      el.readOnly = true;
    } else if (!data.taskId && data.priorityType !== "Normal") {
      el.readOnly = false;
    } else {
      el.readOnly = data.view;
      el.value = "";
    }
    if (!data.taskId && data.priorityType !== "Normal") {
      el.value = DateTime.TomorrowDateTime();
    } else if (data.targateDate) {
      el.value = DateTime.UTCToLocalDateTime(data.targateDate);
    }
    return el;
  });
  return newArr;
};
