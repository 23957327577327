import { DropdownArrPropsType } from "DataTypes/Form";

// export const AppointmentType1 = [
//   {
//     id: 1,
//     label: "CCM Care Plan",
//     value: "CCM Care Plan",
//   },
//   {
//     id: 2,
//     label: "CCM Recurring",
//     value: "CCM Recurring",
//   },
//   {
//     id: 3,
//     label: "PCM Care Plan",
//     value: "PCM Care Plan",
//   },
//   {
//     id: 4,
//     label: "PCM Recurring",
//     value: "PCM Recurring",
//   },
//   {
//     id: 5,
//     label: "GCM Care Plan",
//     value: "GCM Care Plan",
//   },
//   {
//     id: 6,
//     label: "GCM Recurring",
//     value: "GCM Recurring",
//   },
//   {
//     id: 7,
//     label: "BHI Therapy Plan",
//     value: "BHI Therapy Plan",
//   },
//   {
//     id: 8,
//     label: "BHI Recurring",
//     value: "BHI Recurring",
//   },
//   {
//     id: 9,
//     label: "RPM Treatment Plan",
//     value: "RPM Treatment Plan",
//   },
//   {
//     id: 10,
//     label: "RPM Recurring",
//     value: "RPM Recurring",
//   },
//   {
//     id: 11,
//     label: "TCM",
//     value: "TCM",
//   },
// ];

export const AnsDropArr = [
  {
    id: 1,
    label: "Single Select",
    value: "singleSelect",
  },
  {
    id: 2,
    label: "Multi Select",
    value: "multiSelect",
  },
  {
    id: 3,
    label: "Free Text",
    value: "freeText",
  },
];

// export const ProgramType = [
//   {
//     id: 1,
//     label: "CCM",
//     value: "CCM",
//   },
//   {
//     id: 2,
//     label: "PCM",
//     value: "PCM",
//   },
//   {
//     id: 3,
//     label: "TCM",
//     value: "TCM",
//   },
//   {
//     id: 4,
//     label: "GCM",
//     value: "GCM",
//   },
//   {
//     id: 5,
//     label: "BHI",
//     value: "BHI",
//   },
// ];

// export const VisitTypeArr = [
//   {
//     id: 1,
//     label: "CCM Care Plan (Provider)",
//     value: "CCM Care Plan (Provider)",
//   },
//   {
//     id: 2,
//     label: "CCM Care Plan (Care Manager)",
//     value: "CCM Care Plan (Care Manager)",
//   },
//   {
//     id: 3,
//     label: "CCM Recurring (Provider)",
//     value: "CCM Recurring (Provider)",
//   },
//   {
//     id: 4,
//     label: "CCM Recurring (Care Manager)",
//     value: "CCM Recurring (Care Manager)",
//   },
//   {
//     id: 5,
//     label: "CCM Recurring (Care Coordinator)",
//     value: "CCM Recurring (Care Coordinator)",
//   },
//   {
//     id: 6,
//     label: "GCM Care Plan (Provider)",
//     value: "GCM Care Plan (Provider)",
//   },
//   {
//     id: 7,
//     label: "GCM Care Plan (Care Manager)",
//     value: "GCM Care Plan (Care Manager)",
//   },
//   {
//     id: 8,
//     label: "GCM Recurring (Provider)",
//     value: "GCM Recurring (Provider)",
//   },
//   {
//     id: 9,
//     label: "GCM Recurring (Care Manager)",
//     value: "GCM Recurring (Care Manager)",
//   },
//   {
//     id: 10,
//     label: "GCM Recurring (Care Coordinator)",
//     value: "GCM Recurring (Care Coordinator)",
//   },
//   {
//     id: 11,
//     label: "PCM Care Plan (Provider)",
//     value: "PCM Care Plan (Provider)",
//   },
//   {
//     id: 12,
//     label: "PCM Care Plan (Care Manager)",
//     value: "PCM Care Plan (Care Manager)",
//   },
//   {
//     id: 13,
//     label: "PCM Recurring (Provider)",
//     value: "PCM Recurring (Provider)",
//   },
//   {
//     id: 14,
//     label: "PCM Recurring (Care Manager)",
//     value: "PCM Recurring (Care Manager)",
//   },
//   {
//     id: 15,
//     label: "PCM Recurring (Care Coordinator)",
//     value: "PCM Recurring (Care Coordinator)",
//   },
//   {
//     id: 16,
//     label: "BHI Care Plan (Provider)",
//     value: "BHI Care Plan (Provider)",
//   },
//   {
//     id: 17,
//     label: "BHI Care Plan (Care Manager)",
//     value: "BHI Care Plan (Care Manager)",
//   },
//   {
//     id: 18,
//     label: "BHI Recurring (Provider)",
//     value: "BHI Recurring (Provider)",
//   },
//   {
//     id: 19,
//     label: "BHI Recurring (Care Manager)",
//     value: "BHI Recurring (Care Manager)",
//   },
//   {
//     id: 20,
//     label: "BHI Recurring (Care Coordinator)",
//     value: "BHI Recurring (Care Coordinator)",
//   },
//   {
//     id: 21,
//     label: "TCM (Provider)",
//     value: "TCM (Provider)",
//   },
//   {
//     id: 22,
//     label: "TCM (Care Manager)",
//     value: "TCM (Care Manager)",
//   },
//   {
//     id: 23,
//     label: "TCM (Care Coordinator)",
//     value: "TCM (Care Coordinator)",
//   },
// ];

// export const ModelArr = [
//   {
//     id: 1,
//     label: "Hybrid",
//     value: "Hybrid",
//   },
//   {
//     id: 2,
//     label: "Licenced",
//     value: "Licenced",
//   },
//   {
//     id: 3,
//     label: "Outsourced",
//     value: "Outsourced",
//   },
// ];

export const PageLimitArr: DropdownArrPropsType[] = [
  {
    id: 1,
    label: "10",
    value: 10,
  },
  {
    id: 2,
    label: "25",
    value: 25,
  },
  {
    id: 3,
    label: "50",
    value: 50,
  },
  {
    id: 4,
    label: "75",
    value: 75,
  },
];

export const LimitArr2: DropdownArrPropsType[] = [
  {
    id: 1,
    label: "100",
    value: 100,
  },
  {
    id: 2,
    label: "250",
    value: 250,
  },
  {
    id: 3,
    label: "500",
    value: 500,
  },
  {
    id: 4,
    label: "1000",
    value: 1000,
  },
];
