import { ApiServiceProps } from "DataTypes/Services/Index.type";
import { post } from ".";

const list: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "problem/search",
    data: {
      data: { ...(payload.data ? payload.data : {}) },
      criterion: {
        offset: payload?.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const icdCodeList: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `problem/${payload.id1}/icdcode/search`,
  };
  return post(request);
};

export default { list, icdCodeList };
