import { ControlledTable } from "Components";
import FilterData from "Components/Filter2/Data";
import { BreadArrType, Filter2DataProps } from "DataTypes/Common";
import { GlobalType } from "DataTypes/Services/Patient.type";
import { columnType, actionArrType } from "DataTypes/Table";

export const BreadArr: BreadArrType[] = [
  {
    id: 2,
    label: "All Patients",
    path: "",
  },
];

export const FilterArr: () => Filter2DataProps[] = () => {
  return [
    FilterData.patientFirstName,
    FilterData.patientMiddleName,
    FilterData.patientLastName,
    FilterData.dob,
    FilterData.homeNo,
    FilterData.mobile,
  ];
};

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.PicCol(),
  ControlledTable.ColumnData.PatientInfoCol(),
  ControlledTable.ColumnData.PracticeCol(),
  ControlledTable.ColumnData.ProviderCol(),
  ControlledTable.ColumnData.PrimeInsCol(),
  ControlledTable.ColumnData.SecInsCol(),
  ControlledTable.ColumnData.ProgramCol("", "text"),
  ControlledTable.ColumnData.StatusCol(),
  ControlledTable.ColumnData.ActionCol(),
];

export const actionArr: (
  handleChart: () => void,
  handlePatientDetails: () => void,
  handleTask: () => void,
  handleAddTimeLog: () => void,
  data: GlobalType
) => actionArrType[] = (
  handleChart,
  handlePatientDetails,
  handleTask,
  handleAddTimeLog,
  data
) => {
  const Arr: actionArrType[] = [
    ControlledTable.ActionData.userAction(handlePatientDetails),
    ControlledTable.ActionData.chartAction(handleChart),
    ControlledTable.ActionData.taskAction(handleTask),
    ...(data.status == "Enrolled"
      ? [ControlledTable.ActionData.timeLogAction(handleAddTimeLog)]
      : []),
  ];
  return Arr;
};
