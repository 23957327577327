import { CheckNumber, CheckStringEmpty, CheckStringId } from "./common";
import DateTime from "./DateTime";
import PatientFormatter from "./Patient.Formatter";
import { AppointmentType } from "DataTypes/Services/Patient.type";

const ReadAppointmemt: (data: any) => AppointmentType = (data) => {
  return {
    ...PatientFormatter.ListPatientInfo1(data),
    activity: CheckStringEmpty(data?.appointmentType),
    appointmentType: "",
    appointmentTimzone: CheckStringEmpty(data?.timeZone),
    careplanId: CheckNumber(data?.careplanId),
    careplanRecurringId: CheckNumber(data?.careplanRecurringId),
    careplanState: "",
    clinicalStaff: "",
    datetime: DateTime.ValidDate(data?.appointmentDate),
    id: CheckNumber(data?.id),
    importance: "",
    notes: CheckStringEmpty(data?.notes),
    patientId: CheckStringId(data?.patientId),
    practiceId: CheckNumber(data?.practiceId),
    practice: CheckStringEmpty(data?.practiceName),
    programId: CheckNumber(data?.programId),
    provider: "",
    providerName: CheckStringEmpty(data?.billableProviderName),
    timezone: CheckStringEmpty(data?.timeZone),
  };
};

export default {
  ReadAppointmemt,
};
