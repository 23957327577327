import { useState, FC, Fragment, useEffect, useContext } from "react";
import { Box, Typography } from "@mui/material";
import {
  Arr,
  Arr1,
  BreadData,
  TableCol,
  TCMTableCol,
  RecurringCol,
} from "./Data";
import CstmBtn from "Components/CstmBtn";
import { CommonStyle, ControlledTable, Modal } from "Components";
import { ModalLayout, TableLayout } from "Layouts";
import { useNavigate } from "react-router-dom";
import { CommonButtonArray, SingleBtnArray } from "Components/Modal/Data";
import {
  ListInitialStateType,
  PatientInitialStateType,
  UserInitialStateType,
} from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { FormDataType } from "DataTypes/Form";
import CarePlanApi from "Service/CarePlan.api";
import { HandleFormChange, ValidateFormData } from "Utils/common";
import { HeadContext } from "Context/HeadContext";
import { HeadContextType, TimerContextType } from "DataTypes/Common";
import { RouteUrls } from "Constant/RouteUrls";
import CPFormatter from "Utils/CP.Formatter";
import {
  CPSearchType,
  CPSummaryTableQuesType,
  CPSummaryTableType,
  TCMSummaryTableType,
} from "DataTypes/Services/Careplan.type";
import { commonFormSwitch } from "Utils/SwitchCases";
import { useDispatch } from "react-redux";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import DateTime from "Utils/DateTime";
import PatientApi from "Service/Patient.api";
import { TimerContext } from "Context/TimerContext";
import Stages from "Constant/Stages";

const CarePlan: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, setStatus } = useContext(TimerContext) as TimerContextType;
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const { frequencyList }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const { selectedPrgm, patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const careId = selectedPrgm.careplanId;
  const recurringId = selectedPrgm.careplanRecurringId;
  const currentUrl = window.location.href;
  const isRecurring = currentUrl.includes(RouteUrls.recurring);
  const isTCM = currentUrl.includes(RouteUrls.tcm);
  const readOnlyNotes = selectedPrgm
    ? selectedPrgm.readOnlyArr.some((el) => el == "addNotes")
    : true;
  const readOnlyFreq = selectedPrgm
    ? selectedPrgm.readOnlyArr.some((el) => el == "frequency")
    : true;
  const [openModal, setOpenModal] = useState(false);
  const [openthModal, setOpenthModal] = useState(false);
  const [summary, setSummary] = useState<CPSummaryTableType[]>([]);
  const [reSummary, setReSummary] = useState<CPSummaryTableQuesType[]>([]);
  const [TcmSummary, setTcmSummary] = useState<TCMSummaryTableType[]>([]);
  const [formData, setFormData] = useState<FormDataType[]>(Arr);
  const [formData1, setFormData1] = useState<FormDataType[]>(Arr1);
  const [timeLogModal, setTimeLogModal] = useState(false);

  useEffect(() => {
    setBreadArr(
      BreadData(
        patientInfo,
        isRecurring,
        isTCM,
        careId,
        recurringId,
        selectedPrgm.isCareplanComprehensive
      )
    );
    handleReadApiCalls();
  }, [careId, recurringId]);

  const handleReadApiCalls: () => void = async () => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    let newFormData: FormDataType[] = JSON.parse(JSON.stringify(formData));
    let newFormData1: FormDataType[] = JSON.parse(JSON.stringify(formData1));
    let CPFormatedRes: CPSearchType | null = null;

    if (isRecurring) {
      const recurringRes = await CarePlanApi.careplanRecurringsearch({
        id1: recurringId,
      });
      if (recurringRes?.success) {
        CPFormatedRes = CPFormatter.CheckRecurringCPSearch(recurringRes?.data);
      }
      const summaryRes = await CarePlanApi.careplanRecurringSummary({
        id1: recurringId,
      });
      if (summaryRes?.success) {
        const FormattedRes = CPFormatter.FormateApiQuesToTableQues(
          summaryRes?.data
        );
        setReSummary(FormattedRes);
      }
    } else {
      const searchRes = await CarePlanApi.search({ id1: careId });
      if (searchRes?.success) {
        CPFormatedRes = CPFormatter.CheckCPSearch(searchRes?.data);
      }
      if (isTCM) {
        //TCM summary Implementation
        newFormData1[0].readOnly = true;
        if (CPFormatedRes && !CPFormatedRes?.careplan_frequency) {
          CPFormatedRes.careplan_frequency = "30";
        }
        const TcmSummarySearchRes = await CarePlanApi.tcmSummarySearch({
          id1: careId,
        });
        let FormattedTcmRes: any;
        if (TcmSummarySearchRes?.success) {
          FormattedTcmRes = CPFormatter.FormateTcmSummary(
            TcmSummarySearchRes?.data
          );
          setTcmSummary(FormattedTcmRes);
        }
      } else {
        const summaryRes = await CarePlanApi.careplanSummary({
          id1: careId,
        });
        if (summaryRes?.success) {
          const FormattedRes = CPFormatter.FormateCarePlanSummary(
            summaryRes?.data?.problem
          );
          setSummary(FormattedRes);
        }
      }
      // newFormData1[1].readOnly = true; //open for testing purpose
    }
    newFormData1[0].dropArr = frequencyList;
    newFormData[0].value = CPFormatedRes?.carePlanAdditionalNotes || "";
    newFormData1[0].value = newFormData1[0].dropValue =
      CPFormatedRes?.careplan_frequency || "";
    newFormData1[1].readOnly = true;
    newFormData1[1].value = newFormData1[1].dropValue =
      DateTime.UTCToLocalDateTime(CPFormatedRes?.careplanCompletionDate) ||
      DateTime.CurrentDateTime();
    if (readOnlyNotes) {
      newFormData = newFormData.map((el) => {
        el.readOnly = true;
        return el;
      });
    }
    if (readOnlyFreq) {
      newFormData1 = newFormData1.map((el) => {
        el.readOnly = true;
        return el;
      });
    }
    if (
      selectedPrgm.isCareplanComprehensive &&
      newFormData.length > 0 &&
      !recurringId
    ) {
      newFormData[0].label = "Message";
      newFormData[0].readOnly = true;
      newFormData[0].value = `Care Plan initiated on ${DateTime.ToLocalDate(
        selectedPrgm.careplanInitDateInEhr
      )} by the provider in the EHR is comprehensive and up to date. You can search Care Plan in Find section.`;
    }
    setFormData(newFormData);
    setFormData1(newFormData1);
    dispatch(stopLoading());
  };

  const handleSave = () => {
    setOpenthModal(false);
  };

  const handleTimeLog: () => Promise<void> = async () => {
    const endDate = DateTime.CurrentUTCDateTime();
    const payload = {
      careplanId: careId,
      careplanRecurringId: recurringId,
      startDateTime: endDate,
      endDateTime: endDate,
      notes: "",
      reasons: selectedPrgm?.careplanActivity || null,
      status: "ACTIVE",
      date: endDate,
      billingMonth: parseInt(DateTime.CurrentMonth()),
      billingYear: parseInt(DateTime.CurrentYear()),
    };
    await PatientApi.timeLog({ data: payload, id1: careId });
  };

  const handleInitiateSaveExit: () => void = async () => {
    let errorCheck = false;
    const newArr = ValidateFormData(formData);
    if (newArr.errorCheck) errorCheck = true;
    setFormData(newArr.formData);
    const newArr1 = ValidateFormData(formData1);
    if (newArr1.errorCheck) errorCheck = true;
    setFormData1(newArr1.formData);
    if (!errorCheck) {
      if (selectedPrgm?.showLogTime) {
        const checkPayload = {
          careplanId: recurringId ? null : careId,
          careplanRecurringId: recurringId ? recurringId : null,
        };
        const checkRes = await PatientApi.CheckTimeLogged({
          data: checkPayload,
        });
        if (checkRes?.success) {
          if (checkRes?.data) handleFinalSaveExit(true);
          else {
            status == 1 ? setTimeLogModal(true) : handleFinalSaveExit(false);
          }
        }
      } else handleFinalSaveExit(true);
    }
  };

  const handleFinalSaveExit: (timeLogged: boolean) => void = async (
    timeLogged
  ) => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    timeLogModal && setTimeLogModal(false);
    status == 1 &&
      !timeLogged &&
      selectedPrgm?.showLogTime &&
      (await handleTimeLog());
    status == 2 && setStatus(1);
    const payload = {
      notes: formData[0]?.value,
      callFrequency: formData1[0]?.dropValue,
      careplanCompletionDate: DateTime.ToUTCDateTime(formData1[1]?.value),
    };
    if (isRecurring) {
      const updateRes = await CarePlanApi.RecurringCpQuestionStatusUpdate({
        id1: recurringId,
        data: { hasCareplanRecurringQuestionnaireCompleted: true },
      });
      if (updateRes?.success) {
        const recurringRes = await CarePlanApi.RecurringCPSubmit({
          id1: recurringId,
          data: payload,
        });
        if (recurringRes?.success) {
          await CarePlanApi.RecurringCPPdfGenerate({ id1: recurringId });
          setOpenModal(true);
        }
      }
    } else {
      const updateRes = await CarePlanApi.CpQuestionStatusUpdate({
        id1: careId,
        data: { hasCareplanQuestionnaireCompleted: true },
      });
      if (updateRes?.success) {
        const careRes = await CarePlanApi.careplanSubmit({
          id1: careId,
          data: payload,
        });
        if (careRes?.success) {
          if (!selectedPrgm.isCareplanComprehensive) {
            if (isTCM) await CarePlanApi.TcmPdfGenerate({ id1: careId });
            if (!isTCM && myInfo.roleId == 5)
              await CarePlanApi.CareplanPdfGenerate({ id1: careId });
          }
          setOpenModal(true);
        }
      }
    }
    status == 1 &&
      !timeLogged &&
      selectedPrgm?.showLogTime &&
      (await handleTimeLog());
    dispatch(stopLoading());
  };

  const handlePlanSave: () => void = async () => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    let errorCheck = false;
    const newArr = ValidateFormData(formData);
    if (newArr.errorCheck) errorCheck = true;
    setFormData(newArr.formData);
    const newArr1 = ValidateFormData(formData1);
    if (newArr1.errorCheck) errorCheck = true;
    setFormData1(newArr1.formData);
    if (!errorCheck) {
      const payload = {
        notes: formData[0]?.value,
        callFrequency: formData1[0]?.dropValue,
        careplanCompletionDate: DateTime.ToUTCDateTime(formData1[1]?.value),
      };
      if (isRecurring) {
        dispatch(
          setAlertData({
            alertMessage: "Recurring save is not implemented yet",
            alertVariant: "info",
            openAlert: true,
          })
        );
      } else {
        const res = await CarePlanApi.SummarySave({
          id1: careId,
          data: payload,
        });
        if (res?.success) {
          dispatch(setAlertData(res?.alert));
        }
      }
    }
    dispatch(stopLoading());
  };

  const fetchModalText: () => string = () => {
    if (selectedPrgm.showLogTime) {
      if (isRecurring) {
        return "All details saved successfully.";
      } else if (isTCM) {
        return `TCM for ${patientInfo?.name} has been successfully done.`;
      } else {
        if (
          selectedPrgm?.careplanState == Stages.CPShare &&
          myInfo.roleId == 3
        ) {
          return "Care plan has been successfully submitted to the Provider.";
        } else {
          return "Care plan has been Approved by Provider.";
        }
      }
    } else {
      if (
        selectedPrgm?.careplanState == Stages.CPSignOff &&
        myInfo.roleId == 5
      ) {
        return "Care plan has been Approved by Provider.";
      } else {
        return "All details saved successfully.";
      }
    }
  };

  const fetchBtnText: () => string = () => {
    if (selectedPrgm.showLogTime) {
      if (isRecurring || isTCM) {
        return "Complete";
      } else {
        if (
          selectedPrgm?.careplanState == Stages.CPShare &&
          myInfo.roleId == 3
        ) {
          return "Share with provider";
        } else {
          return "Sign Off";
        }
      }
    } else {
      if (
        selectedPrgm?.careplanState == Stages.CPSignOff &&
        myInfo.roleId == 5
      ) {
        return "Sign Off";
      } else {
        return "Save Changes";
      }
    }
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={summary}
      verticalAlign="top"
    />
  );
  const TCMTblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TCMTableCol}
      tableRow={TcmSummary}
      verticalAlign="top"
    />
  );
  const RecurringTblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => RecurringCol}
      tableRow={reSummary}
      verticalAlign="top"
    />
  );

  return (
    <Fragment>
      <ModalLayout
        modwidth="33%"
        content={
          <Box textAlign={"center"}>
            <Typography mb={1}>
              Please enter your time before proceeding.
            </Typography>
            <Typography>
              Do you want to continue without entering time?
            </Typography>
          </Box>
        }
        open={timeLogModal}
        onClose={() => setTimeLogModal(false)}
        buttonArr={CommonButtonArray(
          () => handleFinalSaveExit(false),
          () => setTimeLogModal(false),
          "Yes",
          "No"
        )}
      />
      <ModalLayout
        modwidth="55%"
        heading={"Patient Alert Thresholds"}
        content={<Modal.ThresholdsModal />}
        open={openthModal}
        onClose={() => setOpenthModal(false)}
        buttonArr={SingleBtnArray(handleSave)}
      />
      <ModalLayout
        modwidth="30%"
        content={<Typography>{fetchModalText()}</Typography>}
        open={openModal}
        buttonArr={SingleBtnArray(() =>
          navigate(
            isRecurring
              ? `../../../${RouteUrls.clinicalSummary}`
              : `../../${RouteUrls.clinicalSummary}`,
            { replace: true }
          )
        )}
      />
      <Box sx={{ ...sxMain }}>
        {isTCM ? (
          <TableLayout
            tblBody={TCMTblBodyJSX}
            tblHead={ControlledTable.TblHead(TCMTableCol)}
            height={"65%"}
          />
        ) : isRecurring ? (
          <TableLayout
            tblBody={RecurringTblBodyJSX}
            tblHead={ControlledTable.TblHead(RecurringCol)}
            height={"65%"}
          />
        ) : summary.length > 0 ? (
          <TableLayout
            tblBody={TblBodyJSX}
            tblHead={ControlledTable.TblHead(TableCol)}
            height={"65%"}
          />
        ) : (
          <></>
        )}
        <Box display="flex" justifyContent="space-between">
          {formData.map((formEl) => {
            return (
              <Box width="60%" key={formEl.id}>
                {commonFormSwitch({
                  formObj: formEl,
                  onChange: (data) =>
                    setFormData(HandleFormChange(data, formData)),
                })}
              </Box>
            );
          })}
          <Box
            width="20%"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            {!isTCM &&
              formData1.map((formEl) => {
                return (
                  <Box key={formEl.id}>
                    {commonFormSwitch({
                      formObj: formEl,
                      onChange: (data) =>
                        setFormData1(HandleFormChange(data, formData1)),
                    })}
                  </Box>
                );
              })}
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            sx={{ "&>button": { mt: 3 } }}
          >
            {selectedPrgm.showSaveBtn && (
              <CstmBtn label={"Save"} onClick={handlePlanSave} />
            )}
            {selectedPrgm.showSubmitBtn && (
              <CstmBtn
                label={fetchBtnText()}
                onClick={handleInitiateSaveExit}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
export default CarePlan;
const sxMain = {
  ...CommonStyle.sxWhiteCard,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "91%",
  overflow: "auto",
};
