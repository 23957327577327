import { Filter2DataProps, HandleChangeProps } from "DataTypes/Common";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import { store } from "Redux/Store";
import { setAlertData, stopLoading } from "Redux/Screen/Action";
import AuthApi from "Service/Auth.api";
import SwitchCase from "./SwitchCase";
import { QuesAnsType } from "DataTypes/Services/Question.type";

export const ValidateFormData: (formData: FormDataType[]) => {
  formData: FormDataType[];
  errorCheck: boolean;
} = (formData) => {
  let singleEntryErrorCheck = false;
  const newArr = formData.map((el) => {
    let result = { error: false, errorText: "" };
    if (el.required || el.value) result = SwitchCase.FormValidation(el);
    if (result.error) singleEntryErrorCheck = true;
    el.errorText = result.errorText;
    el.error = result.error;
    return el;
  });
  return { formData: newArr, errorCheck: singleEntryErrorCheck };
};

export const HandleFormChange: (
  data: HandleChangeProps,
  formArr: FormDataType[]
) => FormDataType[] = ({ value, id }, formArr) => {
  return formArr.map((formEl) => {
    if (formEl.id == id) {
      formEl.value = formEl.dropValue = value;
      const temp = SwitchCase.FormValidation(formEl);
      formEl.error = temp.error;
      formEl.errorText = temp.errorText;
    }
    return formEl;
  });
};

export const CreateFilter2Payload: (data: Filter2DataProps[]) => {
  [key: string]: string | number;
} = (data) => {
  return data.reduce((previousObject, currentObject) => {
    return Object.assign(
      previousObject,
      ...(currentObject.value.length > 0
        ? [
            {
              [currentObject.id]: SwitchCase.FilterVal(currentObject),
            },
          ]
        : [])
    );
  }, {});
};

export const CreateFilterPayload1: (data: Filter2DataProps[]) => {
  key: string;
  value: string | (string | number)[];
}[] = (data) => {
  return data
    .map((currentObject) => {
      return {
        key: currentObject.id,
        value: SwitchCase.FilterVal(currentObject),
      };
    })
    .filter((el) => el.value.length > 0);
};

export const AscendSort: (array: any[], keyId: string) => any[] = (
  array,
  keyId
) => {
  const arr1 = array.filter((el) => el[keyId]);
  const arr2 = array.filter((el) => !el[keyId]);
  arr1?.sort((a, b) => {
    const fa = a[keyId]?.toLowerCase(),
      fb = b[keyId]?.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  return [...arr1, ...arr2];
};

export const AscendSortByNumber: (array: any[], keyId: string) => any[] = (
  array,
  keyId
) => {
  array.sort(function (a, b) {
    return parseFloat(a[keyId]) - parseFloat(b[keyId]);
  });
  return array;
};

export const DescendSortByNumber: (array: any[], keyId: string) => any[] = (
  array,
  keyId
) => {
  array.sort(function (a, b) {
    return parseFloat(b[keyId]) - parseFloat(a[keyId]);
  });
  return array;
};

export const UpperCaseConvertor: (data: string) => string = (data) => {
  return data
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

export const CheckStringEmpty: (data: any) => string = (data) => {
  return typeof data == "string" && data.trim().length ? data : "";
};

export const CheckStringId: (data: any) => string | null = (data) => {
  return typeof data == "string" && data.trim().length ? data : null;
};

export const CheckNumber: (data: any) => number | null = (data) => {
  return typeof data == "number" ? data : null;
};

export const CheckPaginateNumber: (data: any) => number = (data) => {
  return typeof data == "number" ? data : 0;
};

export const getPaginationPage: (offset: any, limit: any) => number = (
  offset,
  limit
) => {
  return offset / limit + 1;
};

export const CheckBoolean: (data: any) => boolean = (data) => {
  return typeof data == "boolean" ? data : false;
};

export const ConvertStringToNum: (data: any) => number | null = (data) => {
  return typeof data == "string" ? CheckNumber(parseInt(data)) : null;
};

export const ConvertObjArrToIdArr: (
  data: any[],
  key: string
) => (number | null)[] = (data, key) => {
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => CheckNumber(el[key]));
};

export const CheckStatus: (
  data: any
) =>
  | "ACTIVE"
  | "INACTIVE"
  | "DISABLED"
  | "SUCCESS"
  | "FAILED"
  | "BLOCKED"
  | null = (data) => {
  return data == "ACTIVE" ||
    data == "INACTIVE" ||
    data == "DISABLED" ||
    data == "SUCCESS" ||
    data == "FAILED" ||
    data == "BLOCKED" ||
    data == null
    ? data
    : null;
};

export const CheckCPStatus: (
  data: any
) =>
  | "Enrolled"
  | "Unenrolled"
  | "Pending"
  | "Declined"
  | "Not Enrolled"
  | null = (data) => {
  return data == "Enrolled" ||
    data == "Unenrolled" ||
    data == "Pending" ||
    data == "Declined" ||
    data == "Not Enrolled" ||
    data == null
    ? data
    : null;
};

export const CheckTypeId: (data: any) => "number" | "string" = (data) => {
  return data == "number" || data == "string" ? data : "string";
};

export const CheckCPComprehensive: (data: any) => "yes" | "no" | null = (
  data
) => {
  if (typeof data == "string") {
    const newData = data.toLocaleLowerCase();
    if (newData == "yes") return newData;
    else if (newData == "no") return newData;
    else return null;
  } else {
    return null;
  }
};

export const CheckArr: (data: any) => any[] = (data) => {
  return Array.isArray(data) ? data : [];
};

export const GetUniqueArr: (arr: any[]) => any[] = (arr) => {
  return arr.filter((value, index, array) => {
    return array.indexOf(value) === index;
  });
};

export const GetUniqueObjArr: (arr: any[], key: string) => any[] = (
  arr,
  key
) => {
  return arr.filter((value, index, array) => {
    return array.findIndex((el) => el[key] == value[key]) === index;
  });
};

export const CheckStringNumber: (value: any) => boolean = (value) => {
  if (typeof value != "string") {
    return false;
  } else {
    if (!isNaN(parseInt(value))) return true;
    else return false;
  }
};

export const CheckAnsType: (data: any) => QuesAnsType = (data) => {
  if (data == "multiSelect" || data == "singleSelect" || data == "freeText")
    return data;
  else return null;
};

export const getUniqueArr = (
  filterArr: DropdownArrPropsType[],
  filterId: string | number
) => {
  // const uniqueDropData: DropdownArrPropsType[] = [];
  const uniqueDropData = filterArr.filter((el) => el.id != filterId);
  return uniqueDropData;
};

///this function will be used for all mobile numbers enter manually in the system
export const FormatSysMobileNo: (str: string) => string = (str) => {
  if (str.length == 10) {
    return `${str.slice(0, 3)}-${str.slice(3, 6)}-${str.slice(6)}`;
  } else return "";
};

///Logging out function
export const Logout: (userId?: number | null) => void = async (userId) => {
  const dispatch = store.dispatch;
  if (userId) {
    const res = await AuthApi.signOut({
      data: { userId },
    });
    if (res?.success) dispatch(setAlertData(res?.alert));
  }
  window.location.href = "/login";
  dispatch(stopLoading());
  localStorage.clear();
};
