import { TabPanelProps } from "DataTypes/Common";
import { FC } from "react";

const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, style, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style ?? null}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export default TabPanel;
