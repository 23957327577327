import { Fragment, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { FormDataType } from "DataTypes/Form";
import { commonFormSwitch, ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { HandleChangeProps } from "DataTypes/Common";
import DateTime from "Utils/DateTime";

type LogTimeModalProps = {
  formData: FormDataType[];
  onChange: (data: HandleChangeProps) => void;
  patientName: string;
  practiceName: string;
  billingMonth: string;
};

const LogTimeModal = ({
  formData,
  onChange,
  patientName,
  practiceName,
  billingMonth,
}: LogTimeModalProps) => {
  const [data, setData] = useState<FormDataType[]>([]);

  useEffect(() => {
    setData(formData);
  }, [formData]);

  return (
    <Fragment>
      <Box sx={{ ...sxHead }}>
        <Box mr={3}>
          <Typography variant="body2">{"Patient's Name:"}</Typography>
          <Typography variant="body2" fontWeight="bold">
            {patientName}
          </Typography>
        </Box>
        <Box mr={3}>
          <Typography variant="body2">Organization Name:</Typography>
          <Typography variant="body2" fontWeight="bold">
            {practiceName}
          </Typography>
        </Box>
        <Box mr={3}>
          <Typography variant="body2">Date:</Typography>
          <Typography variant="body2" fontWeight="bold">
            {DateTime.CurrentDate()}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">Billing Month:</Typography>
          <Typography variant="body2" fontWeight="bold">
            {billingMonth}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          {data.map((el: FormDataType) => {
            return (
              <Grid
                key={el.id}
                item
                {...(el.boxSize && ControlledBoxSizeSwitch(el.boxSize))}
              >
                {commonFormSwitch({ formObj: el, onChange: onChange })}
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Fragment>
  );
};
export default LogTimeModal;

const sxHead = {
  display: "flex",
  flexdirection: "row",
  width: "100%",
  justifyContent: "space-between",
  mb: 2,
  "& >div": {
    "& >p:first-of-type": {
      width: "100%",
    },
  },
};
