const CPDischarge = "Pending discharge form";
const CPAssignment = "Pending assignment"; //if no team member is assigned.
const CPProblem = "Pending problem assignment";
const CPQues = "Pending questionnaire";
const CPTeam = "Pending team assignment";
const CPShare = "Pending share with provider";
const CPSignOff = "Pending for provider approval";
const Recurring = "Pending for recurring";

const CPStage = [
  CPDischarge,
  CPAssignment,
  CPProblem,
  CPQues,
  CPTeam,
  CPShare,
  CPSignOff,
  Recurring,
];

export default {
  CPDischarge,
  CPAssignment,
  CPProblem,
  CPQues,
  CPTeam,
  CPShare,
  CPSignOff,
  Recurring,
  CPStage,
};
