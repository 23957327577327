import { useEffect, useState, FC, Fragment } from "react";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { DropdownArrPropsType, SingleDropDataType } from "DataTypes/Form";
import { CommonStyle } from "Components";
import InputLabel from "Components/ControlledForm/Input/Label";

const SingleSelectDropDown: FC<SingleDropDataType> = (
  props: SingleDropDataType
) => {
  const {
    id,
    label,
    placeholder,
    dropArr,
    dropValue,
    required,
    onClick,
    sxProps,
    error,
    errorText,
    readOnly,
  } = props;
  const [dropdownValue, setDropdownValue] =
    useState<DropdownArrPropsType | null>(null);
  const [dropdownArr, setDropdownArr] = useState<DropdownArrPropsType[]>([]);

  useEffect(() => {
    dropArr && setDropdownArr([...dropArr]);
  }, [dropArr]);

  useEffect(() => {
    if (dropValue) {
      const newObj = dropArr?.find((el) => el.value == dropValue);
      setDropdownValue(newObj ? newObj : null);
    } else {
      setDropdownValue(null);
    }
  }, [dropArr, dropValue]);

  return (
    <Fragment>
      {label && (
        <InputLabel label={label} required={required ? required : false} />
      )}
      <Autocomplete
        readOnly={readOnly ? readOnly : false}
        disablePortal
        clearIcon={false}
        id={id}
        value={dropdownValue}
        onChange={(event: any, newValue: DropdownArrPropsType | null) => {
          if (onClick) onClick(newValue?.value ? newValue?.value : "");
          setDropdownValue(newValue ? newValue : null);
        }}
        getOptionLabel={(option: DropdownArrPropsType) =>
          option.label ?? option
        }
        isOptionEqualToValue={(option, value) => option.id == value.id}
        options={dropdownArr}
        renderOption={(props, option) => (
          <Typography variant="subtitle1" {...props}>
            {option.label}
          </Typography>
        )}
        fullWidth
        sx={{ p: "0px !important", fontSize: "0.85rem", ...sxProps }}
        renderInput={(params) => (
          <TextField
            placeholder={placeholder}
            error={error ? error : false}
            {...params}
            sx={{
              ...(readOnly ? { ...sxReadOnly } : { ...CommonStyle.sxWhiteBox }),
              "&>div": {
                p: "0px !important",
                fontSize: "0.85rem",
                "& >div": {
                  right: "0px !important",
                  position: "unset",
                },
                "& >input": {
                  px: 0.5,
                  py: 1,
                },
                "& >fieldset": {
                  borderWidth: 0,
                },
              },
            }}
          />
        )}
      />
      {error && (
        <Typography variant="subtitle2" color="red">
          {errorText}
        </Typography>
      )}
    </Fragment>
  );
};
export default SingleSelectDropDown;

const sxReadOnly = {
  backgroundColor: "custom.grey",
  boxShadow: "0px 0px 6px #00000029",
  borderRadius: 1.25,
  " >fieldset": {
    display: "none",
  },
};
