import { PendingCareType } from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";
import PatientFormatter from "Utils/Patient.Formatter";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "Utils/common";

const PendingCareList: (data: any) => PendingCareType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: PendingCareType[] = CheckedArr.map((el, index) => {
    const provider = CheckStringEmpty(el?.teamProviderName);
    const providerDes = CheckStringEmpty(el?.teamProviderDesignation);
    const coord = CheckStringEmpty(el?.primaryCareCoordination);
    const coordDes = CheckStringEmpty(el?.primaryCareCoordinationDesignation);
    return {
      ...PatientFormatter.ListPatientInfo1(el),
      carecoordination: CheckStringEmpty(el?.careCoordination),
      careplanId: CheckNumber(el?.careplanId),
      duedate: DateTime.ValidDate(el?.dateOfCompletion),
      id: index,
      patientId: CheckStringId(el?.id),
      primcarecoordinator: coord
        ? `${coord}${coordDes ? ", " + coordDes : ""}`
        : "",
      program: CheckStringEmpty(el?.programAbbrevation),
      programId: CheckNumber(el?.programId),
      providerId: CheckNumber(el?.teamProviderId),
      provider: provider
        ? `${provider}${providerDes ? ", " + providerDes : ""}`
        : "",
      status: CheckStringEmpty(el?.status),
    };
  });
  return newArr;
};

export default { PendingCareList };
