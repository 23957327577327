import { useEffect, useState, FC } from "react";
import { Box, ClickAwayListener } from "@mui/material";
import { DropdownArrPropsType, MultiDropDataType } from "DataTypes/Form";
import MultiSelectDrop from "./Box";
import ControlledForm from "Components/ControlledForm";
const MultiSelectSearchDropDown: FC<MultiDropDataType> = (
  props: MultiDropDataType
) => {
  const {
    id,
    label,
    placeholder,
    dropArr,
    dropValue,
    required,
    onClick,
    width,
    error,
    errorText,
    disableArr,
    readOnly,
  } = props;
  const [openDrop, setOpenDrop] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [filteredDropArr, setFilteredDropArr] = useState<
    DropdownArrPropsType[]
  >([]);

  useEffect(() => {
    if (inputValue) {
      setFilteredDropArr([
        ...dropArr.filter((el) =>
          el.label.toLowerCase().includes(inputValue.toLowerCase())
        ),
      ]);
    } else setFilteredDropArr([...dropArr]);
  }, [inputValue, dropArr]);

  const handleSelect: (obj: DropdownArrPropsType) => void = (obj) => {
    const found = dropValue.find((el) => el == obj.value);
    if (!found) onClick && onClick([...dropValue, obj.value]);
    else {
      onClick && onClick(dropValue.filter((el) => el !== obj.value));
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setOpenDrop(false)}>
      <Box sx={{ ...sxDrop }} width={width ? width : "100%"}>
        <ControlledForm.Input
          readOnly={readOnly}
          id={id}
          {...(label && { label: label })}
          {...(placeholder && { placeholder: placeholder })}
          {...(required && { required: required })}
          value={inputValue}
          iconClickHandler={() => setOpenDrop(openDrop ? false : true)}
          iconId={openDrop ? "dropup" : "dropdown"}
          error={error ? error : false}
          errorText={errorText}
          required={required}
          onChange={(value) => typeof value == "string" && setInputValue(value)}
          onFocusChange={() => setOpenDrop(true)}
        />
        {openDrop && (
          <MultiSelectDrop
            dropArr={filteredDropArr}
            selectedItems={dropValue || []}
            handleSelect={(el) => handleSelect(el)}
            disableItems={disableArr || []}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};
export default MultiSelectSearchDropDown;

const sxDrop = {
  position: "relative",
  cursor: "pointer",
};
