import { ControlledTable } from "Components";
import { BreadArrType, Filter2DataProps } from "DataTypes/Common";
import { actionArrType, columnType } from "DataTypes/Table";
import { store } from "Redux/Store";
import { FormatDropdownData } from "Utils/DataFormatter";

export const TableCol: (
  columnClick: () => void,
  rowClick: (rowId: string | number) => void,
  selectAll: boolean,
  selectedIdChecked: boolean,
  disbleCheckBox: boolean,
  downloadPermission: boolean
) => columnType[] = (
  columnClick,
  rowClick,
  selectAll,
  selectedIdChecked,
  disbleCheckBox,
  downloadPermission
) => {
  const Arr: columnType[] = [
    ...(downloadPermission
      ? [
        ControlledTable.ColumnData.CheckBoxCol(
          "4%",
          columnClick,
          (props) => rowClick(props.value),
          selectAll,
          selectedIdChecked,
          disbleCheckBox
        ),
      ]
      : []),
    ControlledTable.ColumnData.PatientCol("9%"),
    ControlledTable.ColumnData.DOBCol("7%"),
    ControlledTable.ColumnData.ProviderCol("9%"),
    ControlledTable.ColumnData.ClinicalStaffCol("8%"),
    ControlledTable.ColumnData.ScopeMeCol("8%"),
    ControlledTable.ColumnData.CareTeamTimeCol("8%"),
    ControlledTable.ColumnData.CareTeamClinicTimeCol("8%"),
    ControlledTable.ColumnData.ProviderClinicTimeCol("8%"),
    ControlledTable.ColumnData.TotalTimeCol("8%"),
    ControlledTable.ColumnData.ProgramCol("8%", "text"),
    ControlledTable.ColumnData.CodeBilledCol("8%"),
    ControlledTable.ColumnData.StatusCol("8%"),
  ];
  return Arr;
};

export const actionArr: (data: any, permission?: any) => actionArrType[] = (
  data,
  permission
) => {
  const Arr: actionArrType[] = [];

  if (permission?.read) {
    const ArrTemp = [
      ControlledTable.ActionData.userAction(),
      ControlledTable.ActionData.chartAction(),
      ControlledTable.ActionData.callAction(),
    ];
    Arr.push(...ArrTemp);
  }

  if (permission?.create) {
    Arr.push(ControlledTable.ActionData.taskAction());
  }

  return Arr;
};

export const MonthArr = [
  {
    id: 1,
    label: "January",
    value: "January",
  },
  {
    id: 2,
    label: "Feburary",
    value: "Feburary",
  },
  {
    id: 3,
    label: "March",
    value: "March",
  },
  {
    id: 4,
    label: "April",
    value: "April",
  },
  {
    id: 5,
    label: "May",
    value: "May",
  },
  {
    id: 6,
    label: "June",
    value: "June",
  },
  {
    id: 7,
    label: "July",
    value: "July",
  },
  {
    id: 8,
    label: "August",
    value: "August",
  },
  {
    id: 9,
    label: "September",
    value: "September",
  },
  {
    id: 10,
    label: "October",
    value: "October",
  },
  {
    id: 11,
    label: "November",
    value: "November",
  },
  {
    id: 12,
    label: "December",
    value: "December",
  },
];

export const YearArr = [
  {
    id: 0,
    label: "2024",
    value: "2024",
  },
  {
    id: 1,
    label: "2023",
    value: "2023",
  },
  {
    id: 2,
    label: "2022",
    value: "2022",
  },
  {
    id: 3,
    label: "2021",
    value: "2021",
  },
  {
    id: 4,
    label: "2020",
    value: "2020",
  },
  {
    id: 5,
    label: "2019",
    value: "2019",
  },
];

export const BreadArr: BreadArrType[] = [
  {
    id: 1,
    label: "Billing",
    path: "",
  },
];

export const FilterArr: () => Filter2DataProps[] = () => {
  const { prgDropList } = store.getState().list;
  return [
    {
      id: "Patient Name",
      label: "Patient Name",
      type: "input",
      payType: "string",
      value: [],
    },
    {
      id: "Provider",
      label: "Provider Name",
      type: "input",
      payType: "array",
      value: [],
    },
    {
      id: "Provider Designation",
      label: "Provider Designation",
      type: "input",
      payType: "string",
      value: [],
    },
    {
      id: "Clinical Staff",
      label: "Clinical Staff",
      type: "input",
      payType: "array",
      value: [],
    },
    {
      id: "Clinical Staff Designation",
      label: "Clinical Staff Designation",
      type: "input",
      payType: "string",
      value: [],
    },
    {
      id: "Program",
      label: "Program",
      type: "multi-select",
      payType: "array",
      dropArr: FormatDropdownData(prgDropList, "id", "label", "label"),
      value: [],
    },
    {
      id: "Code Billed",
      label: "Code Billed",
      type: "input",
      payType: "string",
      value: [],
    },
    {
      id: "Status",
      label: "Status",
      type: "multi-select",
      payType: "array",
      dropArr: [
        {
          id: 1,
          label: "Billable",
          value: "Billable",
        },
        {
          id: 2,
          label: "Non-Billable",
          value: "Non-Billable",
        },
      ],
      value: [],
    },
  ];
};
