import { CpNoteType } from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";
import { CheckArr, CheckNumber, CheckStringEmpty } from "Utils/common";

const NoteSearch: (data: any, prgm: string) => CpNoteType[] = (data, prgm) => {
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => {
    const datetime = DateTime.ValidDate(el?.recurringCompletedDatetime);
    const staffName = CheckStringEmpty(el?.username);
    const staffDes = CheckStringEmpty(el?.userDesignation);
    const clinicalStaff = staffName
      ? staffName + `${staffDes ? ", " + staffDes : ""}`
      : "";
    return {
      id: CheckNumber(el?.recurringId),
      activity: `${prgm} Recurring`,
      datetime: datetime,
      clinicalStaff,
    };
  });
};

export default { NoteSearch };
