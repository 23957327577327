import ControlledTable from "Components/ControlledTable";
import { actionArrType, columnType } from "DataTypes/Table";
export const TableCol: columnType[] = [
  ControlledTable.ColumnData.ActivityCol(),
  ControlledTable.ColumnData.DateTimeCol1(),
  ControlledTable.ColumnData.ClinicalStaffCol(),
  ControlledTable.ColumnData.ActionCol(),
];

export const actionArr: (
  handleDownload: () => void,
  handleView: () => void
) => actionArrType[] = (handleDownload, handleView) => {
  return [
    ControlledTable.ActionData.downloadAction(
      handleDownload,
      "Download Recurring Notes"
    ),
    ControlledTable.ActionData.ViewCarePlanAction(handleView, "View Recurring Notes"),
  ];
};
