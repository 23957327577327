import { Fragment, FC, useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  Switch,
} from "@mui/material";
import { CommonStyle } from "Components";
import { FormDataType } from "DataTypes/Form";
import CstmBtn from "Components/CstmBtn";
import ImgPath from "Constant/Imgs";
import { commonFormSwitch, ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { BreadData, LocationArr, PracticeFormDataArr } from "./Data";
import PracticeApi from "Service/Practice.api";
import {
  CheckArr,
  CheckStringEmpty,
  ConvertStringToNum,
  HandleFormChange,
  ValidateFormData,
} from "Utils/common";
import { HandleChangeProps, HeadContextType } from "DataTypes/Common";
import { HeadContext } from "Context/HeadContext";
import { useDispatch } from "react-redux";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import { RouteUrls } from "Constant/RouteUrls";
import { FormatOrgLocationType } from "DataTypes/PracticeMangement.type";
import Format from "./Format";
import UserApi from "Service/User.api";
import OrganizationFormatter from "Utils/Organization.Formatter";
import { PracticeReadApiType } from "DataTypes/Services/Practice.type";
import SwitchCase from "Utils/SwitchCase";

const OrganizationUpdate: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const modelList = useSelector((state: any) => state?.list?.modelDropList);
  const prgList = useSelector((state: any) => state?.list?.prgDropList);
  const [formArr1, setFormArr1] = useState<FormDataType[]>(PracticeFormDataArr);
  const [formArr2, setFormArr2] = useState<FormatOrgLocationType[]>([]);
  const [orgData, setOrgData] = useState<PracticeReadApiType | null>(null);
  const currentUrl = window.location.href;
  const myOrganization = currentUrl.includes("myOrganization");

  useEffect(() => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    setBreadArr(BreadData(myOrganization, orgId));
    handleSetData();
  }, [currentUrl]);

  const handleSetData: () => void = async () => {
    let newFormArr1: FormDataType[] = JSON.parse(JSON.stringify(formArr1));
    newFormArr1[2].dropArr = modelList;
    newFormArr1[3].dropArr = prgList;
    let newFormArr2: FormatOrgLocationType[] = [];
    newFormArr2.push(JSON.parse(JSON.stringify(LocationArr)));
    if (orgId) {
      const res: any = await PracticeApi.read({ id1: parseInt(orgId) || null });
      if (res?.success) {
        const verifiedRes = OrganizationFormatter.VerifyReadResponse(res?.data);
        const formatedData = Format.FormateReadResponse(
          verifiedRes,
          newFormArr1,
          JSON.parse(JSON.stringify(LocationArr)),
          myOrganization
        );
        newFormArr1 = formatedData.orgInfo;
        if (formatedData.locInfo.length > 0) newFormArr2 = formatedData.locInfo;
        setOrgData(verifiedRes);
      }
    }
    dispatch(stopLoading());
    setFormArr1(newFormArr1);
    setFormArr2(newFormArr2);
  };

  const handleAddLocation = (): void => {
    const lastOrderId = formArr2[formArr2.length - 1].practiceLocationOrder;
    const newArr: FormatOrgLocationType[] = [
      ...formArr2,
      {
        ...JSON.parse(JSON.stringify(LocationArr)),
        isMasterPracticeLocation: false,
        practiceLocationOrder: lastOrderId ? lastOrderId + 1 : 1,
      },
    ];
    setFormArr2(newArr);
  };

  const removeLocationArr = (orderId: number | null): void => {
    const newArr = formArr2.filter((el) => el.practiceLocationOrder != orderId);
    const newArr1 = newArr.map((el, index) => {
      el.practiceLocationOrder = index + 1;
      return el;
    });
    setFormArr2(newArr1);
  };

  const handleChange: (
    props: HandleChangeProps,
    formArr: FormDataType[]
  ) => void = async ({ value, id }, formArr) => {
    if (id == "name" || id == "prim-email-id") {
      if (id == "name") {
        formArr[1].notUnique = await searchOrgName(CheckStringEmpty(value));
      }
      if (id == "prim-email-id") {
        formArr[5].notUnique = await searchUserEmail(CheckStringEmpty(value));
      }
      const newArr = formArr.map((el) => {
        if (el.id == id) {
          const temp = SwitchCase.FormValidation(el);
          el.error = temp.error;
          el.errorText = temp.errorText;
        }
        return el;
      });
      setFormArr1(newArr);
    }
  };

  const searchOrgName: (data: string) => Promise<boolean> = async (data) => {
    const payload = {
      name: data,
      isDeleted: null,
    };
    const res: any = await PracticeApi.list({
      data: payload,
      offset: 0,
      limit: 25,
    });
    if (res?.success) {
      const newRes = CheckArr(res?.data);
      if (newRes.length > 0) {
        if (orgId) {
          const find = newRes.find((el) => el?.id == orgId);
          if (find) return false;
          else return true;
        } else return true;
      } else return false;
    } else return false;
  };

  const searchUserEmail: (data: string) => Promise<boolean> = async (data) => {
    const payload = { isDeleted: null, email: data };
    const res: any = await UserApi.list({
      data: payload,
      offset: 0,
      limit: 25,
    });
    if (res?.success) {
      const newRes = CheckArr(res?.data);
      if (newRes.length > 0) {
        if (orgId) {
          const find = newRes.find((el) => el?.id == orgData?.user[0].id);
          if (find) return false;
          else return true;
        } else return true;
      } else return false;
    } else return false;
  };

  const handleLocationChange: (
    props: HandleChangeProps,
    orderId: number | null
  ) => void = (props, orderId) => {
    const { value, id } = props;
    const newFormArr: FormatOrgLocationType[] = JSON.parse(
      JSON.stringify(formArr2)
    );
    const formArrIndex = newFormArr.findIndex(
      (el) => el.practiceLocationOrder == orderId
    );
    if (id == "location1") {
      const find = newFormArr.find(
        (newEl) =>
          newEl.locationFormData[0].value == value &&
          newEl.practiceLocationOrder != orderId
      );
      newFormArr[formArrIndex].locationFormData[0].notUnique = find
        ? true
        : false;
    }
    const newFormObjArr = HandleFormChange(
      props,
      newFormArr[formArrIndex].locationFormData
    );
    newFormArr[formArrIndex].locationFormData = newFormObjArr;
    setFormArr2([...newFormArr]);
  };

  const handleSwitch = (orderId: number | null): void => {
    const newArr = formArr2.map((el) => {
      if (el.practiceLocationOrder == orderId)
        el.status = el.status == "ACTIVE" ? "INACTIVE" : "ACTIVE";
      return el;
    });
    setFormArr2(newArr);
  };

  const handleSubmitAddress = async () => {
    let errorCheck = false;
    const newArr = ValidateFormData(formArr1);
    if (newArr.errorCheck) errorCheck = true;
    setFormArr1(newArr.formData);
    const newArr2 = formArr2.map((el) => {
      const newArr3 = ValidateFormData(el.locationFormData);
      if (newArr3.errorCheck) errorCheck = true;
      el.locationFormData = newArr3.formData;
      return el;
    });
    setFormArr2(newArr2);
    if (!errorCheck) {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      const payload = Format.FormatPayload(formArr1, formArr2, orgData);
      if (orgId) {
        const updateRes = await PracticeApi.update({
          data: payload,
          id1: ConvertStringToNum(orgId),
        });
        if (updateRes?.success) {
          dispatch(setAlertData(updateRes?.alert));
          dispatch(stopLoading());
          navigate(`../${RouteUrls.list}`);
        }
      } else {
        const createRes = await PracticeApi.create({
          data: payload,
        });
        if (createRes?.success) {
          setFormArr1(JSON.parse(JSON.stringify(PracticeFormDataArr)));
          setFormArr1(JSON.parse(JSON.stringify([LocationArr])));
          dispatch(setAlertData(createRes?.alert));
          dispatch(stopLoading());
          navigate(`../${RouteUrls.list}`);
        }
      }
    }
  };

  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox, overflow: "auto" }}>
      <Box sx={{ p: 2 }}>
        <Grid container rowSpacing={1} columnSpacing={2}>
          {formArr1 && formArr1.length > 0
            ? formArr1.map((el: FormDataType) => {
              return (
                <Grid
                  key={el.id}
                  item
                  {...ControlledBoxSizeSwitch(el.boxSize)}
                >
                  {commonFormSwitch({
                    formObj: el,
                    onChange: (props) => {
                      const newArr = HandleFormChange(props, formArr1);
                      setFormArr1(newArr);
                      handleChange(props, formArr1);
                    },
                  })}
                </Grid>
              );
            })
            : null}
        </Grid>
        <Divider sx={{ mt: 3, mb: 2 }} />
        {formArr2.map((locEl) => {
          return (
            <Fragment key={locEl.practiceLocationOrder}>
              <Box
                sx={{
                  ...CommonStyle.sxRow,
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2" fontWeight="bold">
                  {locEl.practiceLocationOrder == 1
                    ? "Main Location"
                    : `Location ${locEl.practiceLocationOrder}`}
                </Typography>
                {!myOrganization && locEl.practiceLocationOrder != 1 && (
                  <Box sx={{ ...CommonStyle.sxRow }}>
                    <Switch
                      title={locEl.status || ""}
                      onChange={() => {
                        handleSwitch(locEl.practiceLocationOrder);
                      }}
                      checked={locEl.status == "ACTIVE" ? true : false}
                      color="secondary"
                    />
                    {!locEl.id && (
                      <IconButton
                        title="Delete"
                        onClick={() =>
                          removeLocationArr(locEl.practiceLocationOrder)
                        }
                        sx={{ ...CommonStyle.sxRoundIconsMainSm }}
                      >
                        <ImgPath.CloseIcon />
                      </IconButton>
                    )}
                  </Box>
                )}
              </Box>
              <Grid container rowSpacing={1} columnSpacing={2}>
                {locEl?.locationFormData?.map((el: FormDataType) => {
                  return (
                    <Grid
                      key={el.id}
                      item
                      {...ControlledBoxSizeSwitch(el.boxSize)}
                    >
                      {commonFormSwitch({
                        formObj: el,
                        onChange: (props) =>
                          handleLocationChange(
                            props,
                            locEl.practiceLocationOrder
                          ),
                      })}
                    </Grid>
                  );
                })}
              </Grid>
              <Divider sx={{ mt: 2, mb: 2 }} />
            </Fragment>
          );
        })}

        <Box
          textAlign="end"
          sx={{
            mt: 2,
            "& >button": {
              ml: 1,
            },
          }}
        >
          {!myOrganization && (
            <CstmBtn
              label="Add Another Location"
              onClick={() => handleAddLocation()}
              width="15%"
              btnType="border"
            />
          )}
          {!myOrganization && (
            <CstmBtn label="Save" onClick={handleSubmitAddress} width="10%" />
          )}
          <CstmBtn
            label={myOrganization ? "Close" : "Cancel"}
            onClick={() =>
              myOrganization ? navigate("/") : navigate(`../${RouteUrls.list}`)
            }
            width="10%"
            btnType="border"
          />
        </Box>
      </Box>
    </Box>
  );
};
export default OrganizationUpdate;
