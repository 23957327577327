import { RedoxCategoryType } from "DataTypes/PatientManagement.type";

export const CategoryArr: RedoxCategoryType[] = [
  {
    id: 1,
    label: "Clinical Document",
    docList: [],
    docKey: "Clinical Document",
    count: 0,
    countKey: "clinical document",
  },
  {
    id: 2,
    label: "Consent Form",
    docList: [],
    docKey: "Consent Form",
    count: 0,
    countKey: "consent form",
  },
  {
    id: 3,
    label: "Treatment Plan",
    docList: [],
    docKey: "Treatment Plan",
    count: 0,
    countKey: "treatment plan",
  },
  {
    id: 4,
    label: "Lab Result",
    docList: [],
    docKey: "Lab Result",
    count: 0,
    countKey: "lab result",
  },
];
