// import { MyInfoType, UserActionReturnType, UserInitialStateType } from "DataTypes/Redux";
// import { LOGGED_USER, USER_PERMISSION } from "./type";

// const MyInfoInitialState: MyInfoType = {
//   id: null,
//   firstname: "",
//   lastname: "",
//   roleId: null,
//   roleName: "",
//   practiceId: null,
//   practiceName: "",
// };

// const INITIAL_STATE: UserInitialStateType = {
//   myInfo: MyInfoInitialState,
//   myPermission: {},
// };

// const reducer = (state = INITIAL_STATE, action: UserActionReturnType) => {
//   switch (action.type) {
//     case LOGGED_USER:
//       return {
//         ...state,
//         myInfo: action.payload,
//       };
//     case USER_PERMISSION:
//       return {
//         ...state,
//         myPermission: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// export default reducer;

import {
  MyInfoType,
  UserActionReturnType,
  UserInitialStateType,
} from "DataTypes/Redux";
import {
  SET_MY_INFO,
  CLEAR_MY_INFO,
  SET_MY_PERMISSION,
  CLEAR_MY_PERMISSION,
  CLEAR_USER,
} from "./type";

const MyInfoInitialState: MyInfoType = {
  id: null,
  firstname: "",
  lastname: "",
  roleId: null,
  roleName: "",
  practiceId: null,
  practiceName: "",
  practiceModelId: null,
  practiceModelName: "",
  isMasterPractice: false,
  designation: "",
  timezone: "",
  dateFormat: "",
  dateTimeFormat: "",
  dateTimeSecFormat: "",
  utcDiff: null,
};

const INITIAL_STATE: UserInitialStateType = {
  myInfo: JSON.parse(JSON.stringify(MyInfoInitialState)),
  myPermission: {},
};

const reducer = (state = INITIAL_STATE, action: UserActionReturnType) => {
  switch (action.type) {
    case SET_MY_INFO:
      return {
        ...state,
        myInfo: action.payload,
      };
    case CLEAR_MY_INFO:
      return {
        ...state,
        myInfo: MyInfoInitialState,
      };
    case SET_MY_PERMISSION:
      return {
        ...state,
        myPermission: action.payload,
      };
    case CLEAR_MY_PERMISSION:
      return {
        ...state,
        myPermission: {},
      };
    case CLEAR_USER:
      return {
        ...state,
        myInfo: MyInfoInitialState,
        myPermission: {},
      };
    default:
      return state;
  }
};

export default reducer;
