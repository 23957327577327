import { ControlledTable } from "Components";
import FilterData from "Components/Filter2/Data";
import { BreadArrType, Filter2DataProps } from "DataTypes/Common";
import { MyInfoType } from "DataTypes/Redux";
import { actionArrType, columnType } from "DataTypes/Table";
import { store } from "Redux/Store";
import { FormatDropdownData } from "Utils/DataFormatter";

export const TableCol: (userInfo: MyInfoType) => columnType[] = (userInfo) => {
  return [
    ControlledTable.ColumnData.PicCol(),
    ControlledTable.ColumnData.PatientInfoCol(),
    ControlledTable.ColumnData.PracticeCol(),
    ...(userInfo.roleId == 1
      ? [
        ControlledTable.ColumnData.ClinicalStaffCol(),
        ControlledTable.ColumnData.ImportanceCol(),
      ]
      : []),
    ControlledTable.ColumnData.ProviderCol(),
    ...(userInfo.roleId == 1
      ? [ControlledTable.ColumnData.ActivityCol()]
      : [
        ControlledTable.ColumnData.CareCoordinationCol(),
        ControlledTable.ColumnData.PrimCareCoordinatorCol(),
      ]),
    ControlledTable.ColumnData.DueDateCol(),
    ControlledTable.ColumnData.StatusCol(),
    ControlledTable.ColumnData.ActionCol(),
  ];
};

export const CompletedContinuousBreadArr: BreadArrType[] = [
  {
    id: 2,
    label: "Completed Continuous Care",
    path: "",
  },
];

export const actionArr: (
  handleChart: () => void,
  handlePatientDetails: () => void,
  handleTask: () => void,
  handleViewCare: () => void,
  prgmName: string
) => actionArrType[] = (
  handleChart,
  handlePatientDetails,
  handleTask,
  handleViewCare,
  prgmName
) => {
  const Arr: actionArrType[] = [
    ControlledTable.ActionData.userAction(handlePatientDetails),
    ControlledTable.ActionData.chartAction(handleChart),
    ControlledTable.ActionData.taskAction(handleTask),
    ControlledTable.ActionData.ViewCarePlanAction(
      handleViewCare,
      prgmName == "TCM" ? "View TCM" : "View Care Plan"
    ),
  ];
  return Arr;
};

export const FilterArr: () => Filter2DataProps[] = () => {
  const { prgDropList } = store.getState().list;
  const Arr: Filter2DataProps[] = [
    FilterData.patientName,
    FilterData.dob,
    FilterData.homeNo,
    FilterData.mobile,
    FilterData.orgName,
    FilterData.orgLocation,
    FilterData.clinicalStaff,
    FilterData.clinicalStaffDesig,
    {
      ...FilterData.prgmName,
      dropArr: FormatDropdownData(prgDropList, "id", "label", "label"),
    },
    FilterData.provider,
    FilterData.providerDesig,
    FilterData.primCoordinator,
    FilterData.primCoordinatorDesig,
    FilterData.completionDateFrom,
    FilterData.completionDateTo,
  ];
  return Arr;
};
