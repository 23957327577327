import { useState } from "react";
import axios from "axios";
import { store } from "Redux/Store";
import {
  clearScreenData,
  setAlertData,
  stopLoading,
} from "Redux/Screen/Action";
import { clearListData } from "Redux/List/Action";
import { clearUser } from "Redux/User/Action";
import { clearPatient } from "Redux/Patient/Action";
import { clearHeader } from "Redux/Header/Action";
import {
  CheckPaginateNumber,
  CheckStringEmpty,
  getPaginationPage,
} from "Utils/common";
import { AlertProps } from "DataTypes/Common";
export const authTokenKey = "Authorization";

export const useUploadForm = (url: string) => {
  const dispatch = store.dispatch;
  const [progress, setProgress] = useState(0);
  const [inProgress, setInProgress] = useState(false);

  const uploadForm: (request: any) => Promise<{
    success: boolean;
    data?: any;
    criterion?: {
      limit: number;
      page: number;
      total: number;
    };
    alert?: AlertProps;
  }> = async (request: any) => {
    const localToken = localStorage.getItem("cm-token") || "";
    const tokenStorage = localToken ? JSON.parse(localToken) : "";
    const { data = {}, cancelToken } = request;
    setInProgress(true);
    return await axios
      .post(url, data, {
        headers: {
          [authTokenKey]: tokenStorage ? `Bearer ${tokenStorage}` : "",
        },
        onUploadProgress: (progressEvent) => {
          const progress = progressEvent.total
            ? Math.round((100 * progressEvent.loaded) / progressEvent.total)
            : 0;
          if (progress < 100) setProgress(progress);
        },
        cancelToken,
      })
      .then((response) => {
        setInProgress(false);
        if (response?.data?.status == 1) {
          setProgress(100);
          return {
            success: true,
            data: response?.data?.data,
            criterion: {
              limit: CheckPaginateNumber(response?.data?.criterion?.limit),
              page: getPaginationPage(
                CheckPaginateNumber(response?.data?.criterion?.offset),
                CheckPaginateNumber(response?.data?.criterion?.limit)
              ),
              total: CheckPaginateNumber(response?.data?.criterion?.total),
            },
            alert: {
              alertMessage: CheckStringEmpty(response?.data?.msg),
              openAlert: true,
              alertVariant: "success" as
                | "success"
                | "error"
                | "warning"
                | "info"
                | "",
            },
          };
        } else {
          setProgress(0);
          dispatch(
            setAlertData({
              alertMessage:
                CheckStringEmpty(response?.data?.error) ||
                "Something went wrong",
              openAlert: true,
              alertVariant: "error",
            })
          );
          dispatch(stopLoading());
          return {
            success: false,
          };
        }
      })
      .catch((error) => {
        setInProgress(false);
        setProgress(0);
        if (
          (error?.response?.status == 401 || error?.response?.status == 500) &&
          tokenStorage
        ) {
          dispatch(clearListData());
          dispatch(clearUser());
          dispatch(clearPatient());
          dispatch(clearScreenData());
          dispatch(clearHeader());
          localStorage.clear();
          window.location.href = "/login";
          dispatch(
            setAlertData({
              alertMessage: "Something went wrong! Please log in again.",
              openAlert: true,
              alertVariant: "error",
            })
          );
        } else if (error?.response?.status == 403 && tokenStorage) {
          dispatch(
            setAlertData({
              alertMessage: "Something went wrong! Please log in again.",
              openAlert: true,
              alertVariant: "error",
            })
          );
        } else {
          dispatch(
            setAlertData({
              alertMessage:
                error?.response?.data?.error ||
                error?.message ||
                "Something went wrong!",
              openAlert: true,
              alertVariant: "error",
            })
          );
        }
        dispatch(stopLoading());
        return {
          success: false,
        };
      });
  };

  return { uploadForm, progress, setProgress, inProgress };
};
