import { ControlledTable } from "Components";
import FilterData from "Components/Filter2/Data";
import { NotificationTypeArr } from "Constant/Arr";
import { Filter2DataProps } from "DataTypes/Common";
import { NotificationType } from "DataTypes/Notification";
import { actionArrType, columnType } from "DataTypes/Table";

export const TableCol: columnType[] = [
  {
    id: "message",
    label: "Message",
    width: "50%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  ControlledTable.ColumnData.DateTimeCol("25"),
  ControlledTable.ColumnData.ActionCol("25%"),
];

export const FilterArr: () => Filter2DataProps[] = () => {
  return [{ ...FilterData.notificationType, dropArr: NotificationTypeArr }];
};

export const actionArr: (
  handleView: () => void,
  handleDelete: () => void,
  data: NotificationType
) => actionArrType[] = (handleView, handleDelete, data) => {
  const Arr: actionArrType[] = [
    ControlledTable.ActionData.MarkReadAction({
      handleClick: handleView,
      disable: !data.showColorRow,
    }),
    ControlledTable.ActionData.deleteAction(handleDelete),
  ];
  return Arr;
};
