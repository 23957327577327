import { FC, Fragment, useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CategoryBox from "./CategoryBox";
import QuestionBox1 from "./QuestionBox";
import { CategoryApiObj } from "DataTypes/Services/Category.type";
import { HeadContextType } from "DataTypes/Common";
import { HeadContext } from "Context/HeadContext";
import { QuestioniarMasterBreadArr } from "./Data";
import CategoryApi from "Service/Category.api";
import CategoryFormatter from "Utils/Category.Formatter";
import { MasterQuestionType } from "DataTypes/Services/Question.type";
import QuestionApi from "Service/Question.api";
import QuestionFormatter from "Utils/Question.Formatter";
import { useNavigate } from "react-router";
import { RouteUrls } from "Constant/RouteUrls";
import { useDispatch, useSelector } from "react-redux";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import { addMasterCatId, addMasterOpenArr } from "Redux/Header/Action";
import { HeaderInitialStateType } from "DataTypes/Redux";
import { ModalLayout } from "Layouts";
import { DeleteButtonArray } from "Components/Modal/Data";

const QuestionList: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { master }: HeaderInitialStateType = useSelector(
    (state: any) => state?.header
  );
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const [categoryArr, setCategoryArr] = useState<CategoryApiObj[]>([]);
  const [catLoading, setCatLoading] = useState(true);
  const [selectedCat, setSelectedCat] = useState<CategoryApiObj | null>(null);
  const [questionArr, setQuestionArr] = useState<MasterQuestionType[]>([]);
  const [quesLoading, setQuesLoading] = useState(false);
  const [loadingQuesId, setLoadingQuesId] = useState<number | null>(null);
  const [openArr, setOpenArr] = useState<number[]>([]);
  const [deleteQues, setDeleteQues] = useState<MasterQuestionType | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    setBreadArr(QuestioniarMasterBreadArr());
    categoryApiCall();
  }, []);

  const categoryApiCall: () => void = async () => {
    setCatLoading(true);
    const localSelectedCat: CategoryApiObj | null = JSON.parse(
      JSON.stringify(selectedCat)
    );
    setSelectedCat(null);
    const payload = { isDeleted: false };
    const catRes: any = await CategoryApi.list({ data: payload });
    if (catRes?.success) {
      const formatedArr = CategoryFormatter.CheckCategoryArr(catRes?.data);
      setCategoryArr(formatedArr);
      if (master && master.questionCatId) {
        const find = formatedArr.find(
          (catEl) => catEl.id == master.questionCatId
        );
        if (find) setSelectedCat(find);
      } else if (localSelectedCat) setSelectedCat(localSelectedCat);
      else {
        formatedArr.length > 0 && setSelectedCat(formatedArr[0]);
      }

      setOpenArr([]);
    }
    setCatLoading(false);
  };

  const handleChange: (id: number | null) => void = (id) => {
    const find = categoryArr.find((el) => el.id == id);
    if (find) setSelectedCat(find);
    else setSelectedCat(null);
  };

  useEffect(() => {
    changeCategory();
  }, [selectedCat]);

  const questionApiCall: (
    catId: number | null,
    parentQuesId: number | null
  ) => Promise<MasterQuestionType[]> = async (catId, parentQuesId) => {
    const payload = {
      ...(catId && { categoryId: catId }),
      ...(parentQuesId && { parentQuestionId: parentQuesId }),
    };
    const res: any = await QuestionApi.list({
      data: payload,
      offset: 0,
      limit: 100,
    });
    if (res?.success) {
      return QuestionFormatter.CheckMasterQuestionArr(res?.data);
    } else return [];
  };

  const changeCategory: () => void = async () => {
    setQuesLoading(true);
    setLoadingQuesId(null);
    if (selectedCat)
      setQuestionArr(await questionApiCall(selectedCat.id, null));
    else setQuestionArr([]);
    setQuesLoading(false);
    setOpenArr([]);
  };

  const childQuestionCall: (quesData: MasterQuestionType) => void = async (
    quesData
  ) => {
    if (quesData.id) {
      const findQuesId = openArr.find((openEl) => openEl == quesData.id);
      if (findQuesId)
        setOpenArr(openArr.filter((openEl) => openEl != quesData.id));
      else {
        setOpenArr([...openArr, quesData.id]);
        setQuesLoading(true);
        setLoadingQuesId(quesData.id);
        const find = questionArr.find(
          (quesEl) => quesEl.parentQuestionId == quesData.id
        );
        if (!find) {
          const newQuesArr = await questionApiCall(null, quesData.id);
          setQuestionArr([...questionArr, ...newQuesArr]);
        }
        setQuesLoading(false);
        setLoadingQuesId(null);
      }
    }
  };

  const handleAdd: (
    quesData?: MasterQuestionType,
    optionId?: number | null
  ) => void = async (quesData, optionId) => {
    if (quesData && quesData.id && optionId) {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      const arr: number[] = [quesData.id];
      const newArr = Promise.resolve(
        OptionArrLoop(quesData.parentQuestionId, arr)
      );
      newArr.then((value) => {
        dispatch(addMasterOpenArr(value));
        if (selectedCat?.id) dispatch(addMasterCatId(selectedCat?.id));
        navigate(
          `../${quesData.categoryId}/${quesData.id}/${optionId}/${RouteUrls.add}`
        );
        dispatch(stopLoading());
      });
    } else {
      navigate(`../${selectedCat?.id}/${RouteUrls.add}`);
    }
  };

  const handleUpdate: (quesData: MasterQuestionType) => void = async (
    quesData
  ) => {
    if (quesData && quesData.id) {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      const arr: number[] = [quesData.id];
      const newArr = Promise.resolve(
        OptionArrLoop(quesData.parentQuestionId, arr)
      );
      newArr.then((value) => {
        dispatch(addMasterOpenArr(value));
        if (quesData.parentOptionId && quesData.parentQuestionId) {
          navigate(
            `../${quesData.categoryId}/${quesData.parentQuestionId}/${quesData.parentOptionId}/${quesData.id}/${RouteUrls.update}`
          );
        } else {
          navigate(
            `../${quesData.categoryId}/${quesData.id}/${RouteUrls.update}`
          );
        }
        if (selectedCat?.id) dispatch(addMasterCatId(selectedCat?.id));
        dispatch(stopLoading());
      });
    }
  };

  const OptionArrLoop: (
    parentId: number | null,
    arr: number[]
  ) => Promise<number[]> = async (parentId, arr) => {
    if (parentId) {
      const find = questionArr.find((quesEl) => quesEl.id == parentId);
      if (find) {
        if (find.id) arr.push(find.id);
        return await OptionArrLoop(find.parentQuestionId, arr);
      } else return arr;
    } else return arr;
  };

  useEffect(() => {
    setOpenDeleteModal(deleteQues ? true : false);
  }, [deleteQues]);

  const handleDeleteClick = async () => {
    const res = await QuestionApi.deleteQues({ id1: deleteQues?.id });
    if (res?.success) {
      categoryApiCall();
      dispatch(setAlertData(res?.alert));
    }
    setDeleteQues(null);
  };

  return (
    <Fragment>
      <ModalLayout
        modwidth="30%"
        heading={"Warning"}
        content={
          <Box textAlign="center">
            <Typography mb={2}>Do you want to delete the following:</Typography>
            <Typography>{deleteQues?.question}</Typography>
          </Box>
        }
        onClose={() => setDeleteQues(null)}
        open={openDeleteModal}
        buttonArr={DeleteButtonArray(handleDeleteClick, () => {
          setDeleteQues(null);
        })}
      />
      <Box display="flex" flexDirection="row" height="100%">
        <Box width="30%" height="100%" sx={{ mr: 2 }}>
          <CategoryBox
            categoryArr={categoryArr}
            catLoading={catLoading}
            selectedCat={selectedCat}
            onClick={handleChange}
          />
        </Box>
        <Box width="70%">
          {selectedCat && (
            <QuestionBox1
              selectedCat={selectedCat}
              questionArr={questionArr}
              openArr={openArr}
              quesLoading={quesLoading}
              loadingQuesId={loadingQuesId}
              handleAdd={handleAdd}
              handleDelete={(quesData) => setDeleteQues(quesData)}
              handleUpdate={handleUpdate}
              callChildQues={childQuestionCall}
            />
          )}
        </Box>
      </Box>
    </Fragment>
  );
};
export default QuestionList;
