import { Box, Typography } from "@mui/material";
import { Fragment, useEffect, useState, FC, useContext } from "react";
import { CommonStyle, ControlledTable, Modal } from "Components";
import CstmBtn from "Components/CstmBtn";
import { ModalLayout, TableLayout } from "Layouts";
import {
  InitialFormDataArr,
  APIFormDataArr,
  WebFormDataArr,
  TableCol,
  actionArr,
} from "./Data";
import { HandleChangeProps, HeadContextType } from "DataTypes/Common";
import { useSelector } from "react-redux";
import { CommonButtonArray, DeleteButtonArray } from "Components/Modal/Data";
import { HandleFormChange, ValidateFormData } from "Utils/common";
import { HeadContext } from "Context/HeadContext";
import { useDispatch } from "react-redux";
import { setAlertData, stopLoading } from "Redux/Screen/Action";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import PracticeApi from "Service/Practice.api";
import DataFormatter from "Utils/DataFormatter";
import Formate from "./Formate";
import SettingsApi from "Service/Settings.api";
import SettingsFormatter from "Utils/Settings.Formatter";

const RedoxSettings: FC = () => {
  const dispatch = useDispatch();
  const { setBreadArr } = useContext(HeadContext) as HeadContextType;
  const permission: any = useSelector(
    (state: any) => state?.user?.myPermission?.categories
  );
  const [settingsList, setSettingsList] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [uniqueCategory, setUniqueCategory] = useState<string[]>([]);

  const [practiceLocationDropDown, setPracticeLocationDropDown] = useState<
    DropdownArrPropsType[]
  >([]);
  const [practiceDropDown, setPracticeDropDown] = useState<
    DropdownArrPropsType[]
  >([]);
  const [formData1, setFormData1] = useState<FormDataType[] | []>([]);
  const [formData2, setFormData2] = useState<FormDataType[] | []>(
    JSON.parse(JSON.stringify(InitialFormDataArr([], [])))
  );
  useEffect(() => {
    setBreadArr([
      {
        id: 1,
        label: "Redox Settings",
        path: "",
      },
    ]);
    getRedoxSettingFunction();
  }, []);

  const getRedoxSettingFunction = async () => {
    const practiceRes = await PracticeApi.list({
      data: { isDeleted: null },
      offset: 0,
      limit: 100,
    });
    const practiceDropArr = DataFormatter.FormatDropdownData(
      practiceRes?.data,
      "id",
      "name",
      "id"
    );
    setPracticeDropDown(practiceDropArr);
    setFormData2(InitialFormDataArr(practiceDropArr, []));
    const payload = {};
    const res: any = await SettingsApi.listRedoxSettings({ data: payload });
    if (res?.success) {
      const formattedData = SettingsFormatter.formateRedoxSettingSearch(
        res?.data
      );
      setSettingsList(Formate.RedoxSettingList(formattedData));
    }
    setLoading(false);
  };

  const SettingFormChange: (data: HandleChangeProps) => void = async (
    props
  ) => {
    const { value, id } = props;
    const newArr = HandleFormChange(props, formData2);
    if (id == "organizations") {
      if (value !== "") {
        const res = await PracticeApi.practiceLocation({
          id1: newArr[0].dropValue,
        });
        const locationDropdown = DataFormatter.FormatDropdownData(
          res?.data,
          "id",
          "location1",
          "id"
        );
        setPracticeLocationDropDown(locationDropdown);
        newArr[1].dropArr = locationDropdown;
      } else {
        newArr[1].dropArr = [];
      }
    }
    if (id == "type") {
      if (newArr[2].value == "api") {
        setFormData1(APIFormDataArr);
      } else {
        setFormData1(WebFormDataArr);
      }
    }
    setFormData2(newArr);
  };

  const handleDeleteItem = async () => {
    const res: any = await SettingsApi.deleteRedoxSettings({
      id1: selectedRow?.id,
    });
    if (res?.success) {
      dispatch(
        setAlertData({
          alertMessage: "Setting Deleted Successfully",
          openAlert: true,
          alertVariant: "success",
        })
      );
    }
    getRedoxSettingFunction();
    // clearFormadataValues();
    setOpenDeleteModal(false);
  };

  const handleDeleteIcon = (data: any) => {
    setSelectedRow(data);
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    // clearFormadataValues();
    setOpenDeleteModal(false);
  };

  //Redox edit settings
  const handleEditItem = async () => {
    let payload;
    const newArr1 = ValidateFormData(formData1);
    const newArr2 = ValidateFormData(formData2);
    setFormData1(newArr1.formData);
    setFormData2(newArr2.formData);
    if (!newArr1.errorCheck && !newArr2.errorCheck) {
      selectedRow.settingType == "event"
        ? (payload = {
          data: {
            practiceLocationId: formData2[1].dropValue,
            events: [
              {
                type: formData2[2].value,
                dataModel: formData1[0].value,
                sourceId: formData1[1].value,
                sourceName: formData1[2].value,
                destinationId: formData1[3].value,
                destinationName: formData1[4].value,
                urlEndPoint: formData1[5].value,
                authType: formData1[6].value,
                webhookToken: formData1[7].value,
                webhookTokenExpireAt: formData1[8].value,
              },
            ],
          },
        })
        : (payload = {
          data: {
            practiceLocationId: formData2[1].dropValue,
            apiCalls: [
              {
                type: formData2[2].value,
                dataModel: formData1[0].value,
                sourceId: formData1[1].value,
                sourceName: formData1[2].value,
                destinationId: formData1[3].value,
                destinationName: formData1[4].value,
                urlEndPoint: formData1[5].value,
                authType: formData1[6].value,
                apiKey: formData1[7].value,
                apiSecret: formData1[8].value,
                apiExpiry: formData1[9].value,
                apiKID: formData1[10].value,
              },
            ],
          },
        });
      if (!formData1[0].error) {
        const res = await SettingsApi.updateRedoxSettings({
          data: payload,
          id1: selectedRow?.id,
        });
        if (res?.success) {
          clearFormadataValues();
          getRedoxSettingFunction();
          dispatch(setAlertData(res?.alert));
        }
        setOpenModal(false);
      }
    }
  };

  const handleEditIcon = async (data: any) => {
    const { value, id } = data;
    let locationDropdown: DropdownArrPropsType[] = [];
    const res = await PracticeApi.practiceLocation({
      id1: data?.practiceId,
    });
    if (res?.success) {
      locationDropdown = DataFormatter.FormatDropdownData(
        res?.data,
        "id",
        "location1",
        "id"
      );
      setPracticeLocationDropDown(locationDropdown);
    } else {
      setPracticeDropDown([]);
    }
    // newArr[1].dropArr = locationDropdown;
    setFormData2(
      formData2.map((el) => {
        if (el.id == "type") {
          el.value = el.dropValue = data?.settingType;
          el.readOnly = true;
        }
        if (el.id == "organizations") {
          el.readOnly = true;
          el.dropValue = data?.practiceId;
        }
        if (el.id == "practiceLocations") {
          el.dropArr = locationDropdown;
          el.dropValue = data?.praticeLocationId;
          el.readOnly = true;
        }
        return el;
      })
    );
    let newArr;
    if (data?.settingType == "api") {
      newArr = APIFormDataArr.map((el) => {
        if (el.id == "dataModel") {
          el.value = data?.dataModel;
        }
        if (el.id == "sourceId") {
          el.value = data?.sourceId;
        }
        if (el.id == "sourceName") {
          el.value = data?.sourceName;
        }
        if (el.id == "destinationId") {
          el.value = data?.destinationId;
        }
        if (el.id == "destinationName") {
          el.value = data?.destinationName;
        }
        if (el.id == "urlEndPoint") {
          el.value = data?.urlEndPoint;
        }
        if (el.id == "authType") {
          el.value = data?.authType;
        }
        if (el.id == "apiKey") {
          el.value = data?.apiKey;
        }
        if (el.id == "apiSecret") {
          el.value = data?.apiSecret;
        }
        if (el.id == "apiExpiry") {
          el.value = data?.apiExpiry;
        }
        if (el.id == "apiKID") {
          el.value = data?.apiKID;
        }
        return el;
      });
      setFormData1(newArr);
    } else if (data?.settingType == "event") {
      newArr = WebFormDataArr.map((el) => {
        if (el.id == "dataModel") {
          el.value = data?.dataModel;
        }
        if (el.id == "sourceId") {
          el.value = data?.sourceId;
        }
        if (el.id == "sourceName") {
          el.value = data?.sourceName;
        }
        if (el.id == "destinationId") {
          el.value = data?.destinationId;
        }
        if (el.id == "destinationName") {
          el.value = data?.destinationName;
        }
        if (el.id == "urlEndPoint") {
          el.value = data?.urlEndPoint;
        }
        if (el.id == "authType") {
          el.value = data?.authType;
        }
        if (el.id == "webhookToken") {
          el.value = data?.webhookToken;
        }
        if (el.id == "webhookTokenExpireAt") {
          el.value = data?.webhookTokenExpireAt;
        }
        return el;
      });
      setFormData1(newArr);
    }
    setSelectedRow(data);
    setOpenModal(true);
  };

  const handleAddItem = async () => {
    let payload;
    const newArr1 = ValidateFormData(formData1);
    const newArr2 = ValidateFormData(formData2);
    setFormData1(newArr1.formData);
    setFormData2(newArr2.formData);
    if (!newArr1.errorCheck && !newArr2.errorCheck) {
      formData2[2].dropValue == "event"
        ? (payload = {
          data: {
            practiceLocationId: formData2[1].dropValue,
            events: [
              {
                type: formData2[2].value,
                dataModel: formData1[0].value,
                sourceId: formData1[1].value,
                sourceName: formData1[2].value,
                destinationId: formData1[3].value,
                destinationName: formData1[4].value,
                urlEndPoint: formData1[5].value,
                authType: formData1[6].value,
                webhookToken: formData1[7].value,
                webhookTokenExpireAt: formData1[8].value,
              },
            ],
          },
        })
        : (payload = {
          data: {
            practiceLocationId: formData2[1].dropValue,
            apiCalls: [
              {
                type: formData2[2].value,
                dataModel: formData1[0].value,
                sourceId: formData1[1].value,
                sourceName: formData1[2].value,
                destinationId: formData1[3].value,
                destinationName: formData1[4].value,
                urlEndPoint: formData1[5].value,
                authType: formData1[6].value,
                apiKey: formData1[7].value,
                apiSecret: formData1[8].value,
                apiExpiry: formData1[9].value,
                apiKID: formData1[10].value,
              },
            ],
          },
        });
      // if (!formData2[0].error) {
      const updateRes = await SettingsApi.saveRedoxSettings(payload);
      if (updateRes?.success) {
        dispatch(setAlertData(updateRes?.alert));
        dispatch(stopLoading());
        getRedoxSettingFunction();
      }
      setOpenModal(false);
      clearFormadataValues();
      // }
    }
  };

  const handleAddIcon = () => {
    setOpenModal(true);
    setSelectedRow(null);
  };

  const handleSave = () => {
    selectedRow == null ? handleAddItem() : handleEditItem();
  };

  const handleClose = () => {
    clearFormadataValues();
    {
      selectedRow && setUniqueCategory([...uniqueCategory, selectedRow.name]);
    }
    setOpenModal(false);
  };
  const clearFormadataValues = () => {
    formData2[0].dropValue = "";
    formData2[0].error = false;
    formData2[0].readOnly = false;
    formData2[0].errorText = "";
    formData2[1].dropValue = "";
    formData2[1].error = false;
    formData2[1].errorText = "";
    formData2[1].readOnly = false;
    formData2[2].dropValue = "";
    formData2[2].error = false;
    formData2[2].errorText = "";
    formData2[2].readOnly = false;
    if (formData1.length) {
      formData1[0].value = "";
      formData1[0].error = false;
      formData1[0].errorText = "";
      formData1[1].value = "";
      formData1[1].error = false;
      formData1[1].errorText = "";
      formData1[2].value = "";
      formData1[2].error = false;
      formData1[2].errorText = "";
      formData1[3].value = "";
      formData1[3].error = false;
      formData1[3].errorText = "";
      formData1[3].error = false;
      formData1[3].errorText = "";
      formData1[4].value = "";
      formData1[4].error = false;
      formData1[4].errorText = "";
      formData1[5].value = "";
      formData1[5].error = false;
      formData1[5].errorText = "";
      formData1[6].value = "";
      formData1[6].error = false;
      formData1[6].errorText = "";
      formData1[7].value = "";
      formData1[7].error = false;
      formData1[7].errorText = "";
      formData1[8].value = "";
      formData1[8].error = false;
      formData1[8].errorText = "";
    }
    if (selectedRow?.settingType == "api") {
      formData1[9].value = "";
      formData1[9].error = false;
      formData1[9].errorText = "";
      formData1[10].value = "";
      formData1[10].error = false;
      formData1[10].errorText = "";
    }
    setFormData1([]);
  };
  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={(currentRow: any) => TableCol}
      tableRow={settingsList}
      tableAction={(data: any) =>
        actionArr(handleEditIcon, handleDeleteIcon, data, permission)
      }
    />
  );

  return (
    <Fragment>
      <ModalLayout
        modwidth="30%"
        heading={"Warning"}
        content={
          <Typography textAlign="center">
            Do you want to delete this setting?
          </Typography>
        }
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        buttonArr={DeleteButtonArray(handleDeleteItem, handleCloseDeleteModal)}
      />
      <ModalLayout
        modwidth="80%"
        heading={selectedRow == null ? "Add New Setting" : "Edit Setting"}
        content={
          <Modal.RedoxSettingsModal
            formArr={formData1}
            formArr2={formData2}
            handleChange={(data) =>
              setFormData1(HandleFormChange(data, formData1))
            }
            handleChange1={(data) => SettingFormChange(data)}
          />
        }
        open={openModal}
        onClose={() => {
          setOpenModal(false), clearFormadataValues();
        }}
        buttonArr={CommonButtonArray(
          handleSave,
          handleClose,
          selectedRow == null ? "Add" : "Save"
        )}
      />
      <Box
        height="90%"
        sx={{ ...CommonStyle.sxWhiteBox, px: 2 }}
        overflow={"auto"}
      >
        <Box
          height={"10%"}
          justifyContent={"space-between"}
          sx={{ ...CommonStyle.sxRow }}
        >
          <Typography sx={{ ...CommonStyle.sxHeading }}>
            {"Redox Settings"}
          </Typography>
          {permission?.create && (
            <CstmBtn
              label="Add New Setting"
              onClick={() => handleAddIcon()}
              width="15%"
            />
          )}
        </Box>

        <Box>
          <TableLayout
            tblBody={TblBodyJSX}
            loading={loading}
            tblHead={ControlledTable.TblHead(TableCol)}
            height={"100%"}
          />
        </Box>
      </Box>
    </Fragment>
  );
};
export default RedoxSettings;
