import { FC, Fragment } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import ImgPath from "Constant/Imgs";
import { FormDataType } from "DataTypes/Form";
import { ControlledBoxSizeSwitch, commonFormSwitch } from "Utils/SwitchCases";
import CstmBtn from "Components/CstmBtn";
import { QualityMeasureType } from "DataTypes/Services/Patient.type";
import { HandleChangeProps } from "DataTypes/Common";

interface AddModalProps {
  formData: FormDataType[];
  onClose: () => void;
  editData: QualityMeasureType | null;
  handleSave: () => void;
  onChange: (props: HandleChangeProps) => void;
  modalLoad: boolean;
  viewMode: boolean;
}

const AddModal: FC<AddModalProps> = (props) => {
  const {
    formData,
    onClose,
    editData,
    handleSave,
    onChange,
    modalLoad,
    viewMode,
  } = props;
  return (
    <Fragment>
      <Box sx={{ ...sxHeader, ...CommonStyle.sxRow }}>
        <Typography fontWeight="bold">
          {editData ? (viewMode ? "View" : "Edit") : "Add"} Quality Measure
        </Typography>
        <Box display={"flex"} alignItems={"center"}>
          <Box
            sx={{ ...CommonStyle.sxRoundIconsMainSm, mr: 1 }}
            onClick={onClose}
          >
            <ImgPath.CloseIcon titleAccess="Close" />
          </Box>
        </Box>
      </Box>
      <Box sx={{ ...sxMain }}>
        <Grid container rowSpacing={1} columnSpacing={2}>
          {formData.map((el: FormDataType) => {
            return (
              <Grid key={el.id} item {...ControlledBoxSizeSwitch(el.boxSize)}>
                {commonFormSwitch({
                  formObj: el,
                  onChange: onChange,
                })}
              </Grid>
            );
          })}
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Box
          display="flex"
          justifyContent={"flex-end"}
          sx={{ "&>button": { ml: 1 } }}
        >
          {!viewMode && (
            <CstmBtn
              label={modalLoad ? "Loading..." : "Save"}
              onClick={handleSave}
              disable={modalLoad}
            />
          )}
          <CstmBtn label={"Close"} onClick={onClose} btnType="border" />
        </Box>
      </Box>
    </Fragment>
  );
};

export default AddModal;

const sxHeader = {
  justifyContent: "space-between",
  height: "10%",
  px: 2,
};

const sxMain = {
  height: "85%",
  overflow: "auto",
  px: 2,
};
